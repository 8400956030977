import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../button";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../MVVM/State/modals";
import { ModalType } from "../../../../MVVM/@types";

export function FollowButton({ ...props }) {
  const [state, setState] = useState(null);
  const [firstFollowClick, setFirstFollowClick] = useState(false);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const setFollowModal = () => {
    setAllstarModalState({
      isOpen: ModalType.Signup,
      data: {
        target: props.displayName,
        avatar: props.avatar,
        action: "follow",
      },
      functions: {},
    });
  };
  useEffect(() => {
    try {
      return setState(
        props.allstarUser?.followers?.following
          ?.map((x) => x?._id)
          .includes(props?.userId)
          ? "Following"
          : "Follow"
      );
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allstarUser?.followers?.following, props.userId]);

  if (!state) return null;

  return (
    <>
      {state === "Follow" ? (
        <StyledButton
          name="follow-button"
          classification={
            props.followClassification ? props.followClassification : "follow"
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props?.allstarUser?.loggedIn)
              if (setFollowModal) {
                return setFollowModal();
              } else return;

            props.followUnfollow({
              variables: { userToFollow: props.userId, follow: true },
            });
            setState("Following");
            window.rudderanalytics.track("User Followed", {});
          }}
          {...props}
        >
          {state}
        </StyledButton>
      ) : (
        <StyledButton
          classification={
            props.followingClassification
              ? props.followingClassification
              : "following"
          }
          className={firstFollowClick ? "hold" : false}
          name="unfollow-button"
          onMouseEnter={() => {
            setFirstFollowClick(false);
            return setState("Unfollow");
          }}
          onMouseLeave={() => {
            setFirstFollowClick(false);
            return setState("Following");
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props?.allstarUser?.loggedIn)
              if (setFollowModal) {
                return setFollowModal();
              } else return;

            props.followUnfollow({
              variables: { userToFollow: props.userId, follow: false },
            });
            setState("Follow");
            window.rudderanalytics.track("User Unfollowed", {});
          }}
          {...props}
        >
          {state}
        </StyledButton>
      )}
      {/* <Modal isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
        <FloatingContainer>
          <Signup target={props.displayName} avatar={props.avatar} />
        </FloatingContainer>
      </Modal> */}
    </>
  );
}

const StyledButton = styled(Button)`
  ${(props) =>
    !props.flex
      ? `
  max-width: 104px; 
    @media (max-width: ${props.theme.breaks.medium}) {
      max-width: 104px;
    }
  `
      : null}
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 104px;
    min-height: 40px;
    // margin-right: 0px;
    // margin-left: 0px;
  }
`;
