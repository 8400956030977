import React, { useEffect, memo } from "react";
import { Headline, Large3, Title2, Title1 } from "../../components/text";
import { Button } from "../../components/atoms/form/button";
import { Flex, Box } from "rebass/styled-components";
import { Play } from "../../components/atoms/play";
import ReactTypingEffect from "./typeEffect/typeEffect";
import styled from "styled-components";
import Image from "../../assets/images/home/counterstrike.png";

//
const HomeContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[7]}px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const VideoContainer = styled(Flex)`
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.15);
  mix-blend-mode: normal;
  backdrop-filter: blur(70px);
  max-width: 550px;
  width: 100%;
  align-self: flex-end;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    align-self: inherit;
  }
`;

const VideoContent = styled(Flex)`
  background-image: url("${Image}");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
`;

const HowItWorks = styled(Headline)`
  cursor: pointer;
  display: inline-block;
  padding-bottom: ${(props) => props.theme.space[1]}px;
  border-bottom: 2px solid ${(props) => props.theme.colors.envy};
`;

const Homepage = memo(({ showModal }) => {
  // const { allstarUser } = useUser();

  useEffect(() => {
    window.rudderanalytics.page("Home");
  });

  return (
    <>
      <HomeContainer>
        <Flex flex="1" alignItems="center" pr={[0, 0, 0, 8]} pt={[9, 9, 9, 0]}>
          <Box>
            <Large3 mb={6}>
              Create amazing content without ever dropping FPS.
            </Large3>
            <Title2 mb={8}>
              Capture great moments with no burden on your PC. Free to use -
              share to Discord - perfect quality every time. It's your turn to
              star. Just type: <ReactTypingEffect text="!allstar" />
            </Title2>
            <Button flex={true} to="/signup">
              Sign up now
            </Button>
          </Box>
        </Flex>
        <Flex
          flex="1"
          flexDirection="column"
          pl={[0, 0, 0, 8]}
          py={[9, 9, 9, 0]}
        >
          <VideoContainer height={3}>
            <VideoContent>
              <Play
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/watch?v=eC6RNynvqZU",
                    "_blank"
                  );
                }}
              />
            </VideoContent>

            <Box px={[6, 6, 8]} py={[5, 5, 7]} flex="1" alignSelf="flex-start">
              <Title1 mb={6}>
                The future of gaming content creation is Allstar{" "}
              </Title1>
              <HowItWorks onClick={() => showModal()}>
                See how it works
              </HowItWorks>
            </Box>
          </VideoContainer>
        </Flex>
      </HomeContainer>
    </>
  );
});

export default Homepage;
