import React, { memo } from "react";
import { Large3, Title3 } from "../../components/text";
import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import TeamOne from "../../assets/images/culture/teamOne.jpg";
import TeamTwo from "../../assets/images/culture/teamTwo.jpg";
import TeamThree from "../../assets/images/culture/teamThree.jpg";
import TeamFour from "../../assets/images/culture/teamFour.jpg";

const Freq = styled(Flex)`
  background-color: ${(props) => props.theme.colors.midnightAlpha40};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.envy};
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 9px;
  font-weight: 500;
`;

const HomeContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[7]}px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    flex-direction: column;
  }
`;

const Title = styled(Title3)`
  display: flex;
  width: 100%;
`;

const ImageContainer = styled(Flex)`
  position: relative;
  width: 500px;
  max-width: 100%;
  height: 500px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    width: 300px;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    max-width: 100%;
    height: 300px;
  }
`;

const PicOne = styled(Image)`
  position: absolute;
  top: 80px;
  right: 60px;
  height: 220px;
  width: 359px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.3),
      0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const PicTwo = styled(Image)`
  position: absolute;
  top: 5px;
  right: 20px;
  height: 180px;
  width: 270px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.3),
      0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const PicThree = styled(Image)`
  position: absolute;
  top: 260px;
  right: -30px;
  height: 181px;
  width: 271px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.3),
      0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const PicFour = styled(Image)`
  position: absolute;
  top: 200px;
  right: 310px;
  height: 185px;
  width: 184px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow:
      0 6px 10px 0 rgba(0, 0, 0, 0.3),
      0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const Innovate = memo(() => {
  return (
    <>
      <HomeContainer
        pb={["500px", "500px", "500px", "500px", "0px"]}
        alignItems="center"
      >
        <Flex
          flex="1"
          alignItems="flex-start"
          flexDirection="column"
          pr={[0, 0, 0, 8]}
          pt={[9, 9, 9, 0]}
        >
          <Freq mb={4}>Company Culture</Freq>
          <Flex flexDirection="column">
            <Large3 mb={6}>Innovate. Celebrate. Build Value. </Large3>
            <Title color="chalk" mb={8}>
              We are an NYC based team with coworkers living and working all
              across the world in North America, Europe, Australia and Africa.
              We believe that creative collaboration can happen anywhere and
              want our team to work where they feel most comfortable and
              inspired.
            </Title>
          </Flex>
        </Flex>
        <ImageContainer flex="1">
          <PicTwo src={TeamTwo}></PicTwo>
          <PicFour src={TeamFour}></PicFour>
          <PicOne src={TeamOne}></PicOne>
          <PicThree src={TeamThree}></PicThree>
        </ImageContainer>
      </HomeContainer>
    </>
  );
});

export default Innovate;
