import React from "react";
import { Flex } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { ReactComponent as Finale } from "../../../../../../Assets/finale.svg";
import { TClipProps } from "../../../../@types";
import {
  formatNumber,
  dateFormatWithDistanceSuffix,
} from "../../../../../../Utilities/Clip";
import { Tooltip } from "../../../../../../Components/Tooltip";
import { Body } from "../../../../../../Components/Text";
import { countKills } from "../..";
import { AllstarModalState } from "../../../../../../State/modals";
import { Button } from "../../../../../../Components/Button";
import { ModalType } from "../../../../../../@types";
import { MontageSession } from "../../../../../../State";
import {
  ClipImage,
  ClipIndicator,
  ClipMeta,
  ClipStats,
  ClipTitle,
  Close,
  DeleteWrapper,
  FinaleWrapper,
  ImageWrapper,
  OverlayInfo,
  StyledClip,
  StyledSVG,
} from "./styledComponents";

export const Clip = ({
  clip,
  index,
  isSelected,
  isFinale,
  willExceedKillLimit,
  handleSelect,
  forceColumnDisplay,
  setModal,
}: TClipProps) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const shouldShowOverlay = willExceedKillLimit && !isSelected;
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  return (
    <StyledClip
      onClick={() => {
        if (shouldShowOverlay) return;
        handleSelect(clip);
      }}
      columnDisplay={forceColumnDisplay}
    >
      <ImageWrapper>
        <FinaleWrapper>
          {isFinale ? <Finale /> : <ClipIndicator>{index + 1}</ClipIndicator>}
        </FinaleWrapper>

        <OverlayInfo onlyOnHover={true}>
          <DeleteWrapper>
            <Tooltip
              position={{
                top: "0px",
                padding: "7px",
                right: "40px",
              }}
              hide={
                !isFinale || (isFinale && montageSession.clips.length !== 1)
              }
              theme="helpTooltip"
              align="right"
              tooltipContent={
                <>
                  {" "}
                  <Body flexDirection={"column"}>
                    Can't remove the last clip!
                  </Body>
                </>
              }
            >
              <Close
                onClick={() => {
                  if (montageSession.isFreeUser) {
                    window.rudderanalytics.track(
                      "Montage - Free User Adjust Clips Attempt",
                      {
                        type: "Remove",
                      },
                    );
                    return setModal({
                      body: "Pick your Montage clips, get Pro Only Creator Cards, a Profile Badge, Exclusive Content and much more!",
                      isOpen: true,
                    });
                  }
                  if (isFinale && montageSession.clips.length === 1) return;

                  const numKills = countKills([clip]);

                  setMontageSession({
                    ...montageSession,
                    clips: montageSession.clips.filter(
                      (x: any) => x._id !== clip._id,
                    ),
                    kills: montageSession.kills - numKills,
                  });
                }}
              >
                <StyledSVG />
              </Close>
            </Tooltip>
          </DeleteWrapper>
        </OverlayInfo>

        <ClipImage
          draggable="false"
          src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`}
          active={isSelected}
        />
      </ImageWrapper>

      <ClipMeta mb={0} mt={0}>
        <Flex flexDirection={"column"}>
          <ClipTitle>{clip.userClipTitle ?? clip.clipTitle}</ClipTitle>
          <ClipStats>
            {formatNumber(clip.views)} Views &bull;
            {dateFormatWithDistanceSuffix(clip?.createdDate)}
          </ClipStats>
        </Flex>

        <Button
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            setAllstarModalState({
              isOpen: ModalType.SimpleClipViewer,
              data: clip,
              functions: {},
            });
          }}
          style={{ width: 100 }}
          classification={"roundedFilled"}
          size={"small"}
          ml={5}
        >
          View
        </Button>
      </ClipMeta>
    </StyledClip>
  );
};
