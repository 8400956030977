import React, { useEffect } from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { PageHeader } from "../../components/molecules/pageHeader";

const AppOneLink = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const searchParams = new URLSearchParams(window.location.search);
        const campaign = searchParams.get("campaign") || "unknown";

        // Define URLs for Android and iOS
        const androidURL = "https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar&hl=en_US&gl=US";
        const iosURL = "https://apps.apple.com/us/app/allstar-play-share-star/id6476161266";
        const fallbackURL = "/appstorechooser";
        let destinationURL;

        // Check for Android or iOS devices
        let isMobileDevice = false;
        if (/android/i.test(userAgent)) {
            destinationURL = androidURL;
            isMobileDevice = true;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            destinationURL = iosURL;
            isMobileDevice = true;
        } else {
            destinationURL = fallbackURL;
        }

        // Set the campaign variable to the correct value
        const campaignValue = campaign || searchParams.get("utm_campaign") || "unknown";

        // Analytics call for tracking redirection with campaign information
        window.rudderanalytics.page("App Redirect", window.location.pathname, {
            campaign: campaignValue,
        });

        // Special condition: if the campaign is 'dreamhack2024' and the device is Android or iOS
        if ((campaignValue === "dreamhack2024" || searchParams.get("utm_campaign") === "dreamhack2024" || searchParams.get("utm_source") === "abghoststicker") && isMobileDevice) {
            window.rudderanalytics.track("Scanned QR Code", {
            campaign: campaignValue,
            deviceType: destinationURL === androidURL ? "Android" : "iOS",
            });
        }

        // Execute the redirection
        window.location.replace(destinationURL);
    }, []);

    console.log("Rendering AppOneLink");

    return (
        <>
            <PageHeader
                title="Download App"
                subtitle="Redirecting you to the appropriate app store..."
            />
            <StaticContainer py={8}>
                <PaddedWrapper justifyContent="center" alignItems="center" flexDirection="column">
                    <p>Redirecting...</p>
                </PaddedWrapper>
            </StaticContainer>
        </>
    );
};

export default AppOneLink;
