import React from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { PageHeader } from "../../components/molecules/pageHeader";
import { Flex } from "rebass/styled-components";
import { Page } from "../../MVVM/StyledComponents";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  padding-left: 80px;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding-left: 0px;
  }
`;
const AppStoreChooser = () => {
  const { REACT_APP_S3_CLIP_BUCKET: bucket_url } = process.env

  return (
    <>
      <PaddedWrapper justifyContent="center">
        <Page>
          <PageHeader
            title="Download the App"
            subtitle="Get our app from your respective app store"
          />

          <StyledFlex style={{ flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                width: "100%",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h2>iOS Users</h2>
                <p>Download our app for iPhone/iPad.</p>
                <a href="https://apps.apple.com/us/app/allstar-play-share-star/id6476161266">
                  <img
                    src={`${bucket_url}/static/web/3rdparty/apple.svg`}
                    alt="App Store"
                    style={{ width: "200px", marginBottom: "20px" }}
                  />
                </a>
              </div>
              <div style={{ textAlign: "center" }}>
                <h2>Android Users</h2>
                <p>Download our app for Android.</p>
                <a href="https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar&hl=en_US&gl=US">
                  <img
                    src={`${bucket_url}/static/web/3rdparty/google.png`}
                    alt="Google Play Store"
                    style={{ width: "200px" }}
                  />
                </a>
              </div>
            </div>
          </StyledFlex>
        </Page>
      </PaddedWrapper>
    </>
  );
};

export default AppStoreChooser;
