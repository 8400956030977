import { useExperiment } from "@statsig/react-bindings";
import { useIsDesktopApp } from "./MVVM/Hooks/useIsDesktopApp";
import { useIsMobileApp } from "./MVVM/Hooks/useIsMobileApp";

import { Nav } from "./components/organisms/nav";
import { Sidebar } from "./MVVM/Components/Sidebar";
import { NavSignup } from "./components/organisms/navSignup";
import { MINIMAL_ROUTES } from "./routes/utils";
import { pathExcludesNavigation } from "./MVVM/Utilities/Navigation/utils";

const Navigation = () => {
  const path = window.location.pathname;
  const experiment = useExperiment("navigation_redesign");
  const isDesktopView = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();

  if (
    pathExcludesNavigation(path) ||
    isDesktopView.isDesktop ||
    isMobileApp.isMobileApp
  )
    return <></>;

  if (MINIMAL_ROUTES.includes(window.location.pathname)) return <NavSignup />;

  if (
    process.env.NODE_ENV === "development" ||
    experiment.get("show_navigation_redesign")
  )
    return <Sidebar />;

  return <Nav />;
};

export { Navigation };
