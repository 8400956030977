import React from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(HiOutlineExternalLink).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const ExternalIcon = (props) => {
  return <StyledIcon {...props} />;
};

ExternalIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default ExternalIcon;
