import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { Link, useHistory } from "react-router-dom";

import {
  AvatarLine,
  Heading,
  StyledAvatar,
  UserDetailsText,
} from "../styledComponents";
import { FollowButton } from "../../../Components/Button";
import { IClipPreview } from "../@types";
import { useUser } from "../../../Hooks/useUser";
import { AllstarModalState } from "../../../State/modals";
import { useRecoilState } from "recoil";
import { ModalType } from "../../../@types";
import {
  AvatarWrapper,
  getAvatarBorderLevel,
  getAvatarImage,
} from "../../../Utilities/User";

interface IOwnerHeadlineProps {
  clip: IClipPreview;
  stats: {
    totalClips: string;
    totalViews: string;
  };
  style?: React.CSSProperties;
  closeModal?: () => void;
  mutateFollowers: () => void;
  username: string;
}

const OwnerHeadline = ({
  clip,
  closeModal,
  mutateFollowers,
  stats,
  style,
  username,
}: IOwnerHeadlineProps) => {
  const history = useHistory();
  const joinedDate = new Date(parseInt(clip.createdDate));
  const avatarImage = getAvatarImage(clip.user);
  const avatarBorderLevel = getAvatarBorderLevel(clip.user);
  const { allstarUser } = useUser();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const handleClick = () => {
    history.push(`/u/${clip.username}`);
    if (closeModal) closeModal();
  };
  const setFollowModal = ({
    displayName,
    avatar,
  }: {
    displayName: string;
    avatar: string;
  }) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      style={{ gap: "16px", ...style }}
    >
      <Flex style={{ width: "100%" }} mb={2} alignItems="center">
        <AvatarLine />
        <Link to="#" onClick={() => handleClick()}>
          <Box mr={4} ml={4}>
            <AvatarWrapper borderLevel={avatarBorderLevel} size="large">
              <StyledAvatar image={avatarImage} size="large" />
            </AvatarWrapper>
          </Box>
        </Link>
        <AvatarLine />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mb={0} pb={0} onClick={() => handleClick()}>
          {username}
        </Heading>
        <UserDetailsText pb={2}>
          Joined {joinedDate.toLocaleString("default", { month: "long" })},{" "}
          {joinedDate.getFullYear()}
        </UserDetailsText>
        <UserDetailsText>
          {stats.totalClips} Clips &bull;{" "}
          {parseInt(stats.totalViews).toLocaleString()} Views{" "}
        </UserDetailsText>
      </Flex>
      <FollowButton
        ml={1}
        flex={true}
        size="tiny"
        alignSelf="center"
        userId={clip.user._id}
        displayName={username}
        avatar={clip.user.avatar}
        openModal={true}
        followerData={undefined}
        isModal={false}
        followUnfollow={mutateFollowers}
        allstarUser={allstarUser}
        setFollowModal={setFollowModal}
        style={{ padding: "0 12px", borderRadius: "20px", minWidth: "40px" }}
      />
    </Flex>
  );
};

export { OwnerHeadline };
