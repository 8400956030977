import React, { forwardRef, useState } from "react";
import { Input } from "@rebass/forms/styled-components";
import { Validation } from "../../atoms/form/validation";
import { Box } from "rebass/styled-components";
import useSize from "@react-hook/size";
import styled from "styled-components";
import theme from "../../../theme/themeThree";

let StyledInput = styled(Input)((props) => {
  return props.error
    ? {
        borderColor: theme.colors.error,
        ":hover": {
          borderColor: `${theme.colors.error} !important`,
        },
        ":active": {
          borderColor: `${theme.colors.error} !important`,
        },
        ":focus": {
          borderColor: `${theme.colors.error} !important`,
        },
      }
    : {};
});

const FakeLabel = styled.div`
  position: absolute;
  color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.theme.colors[props.theme.input["::placeholder"].color]};
  opacity: 0;
  visibility: hidden;
  line-height: ${(props) => props.theme.variants.input.default.lineHeight};
  transition:
    font-size ${(props) => props.theme.transitionDuration},
    top ${(props) => props.theme.transitionDuration},
    line-height ${(props) => props.theme.transitionDuration},
    background-color ${(props) => props.theme.transitionDuration};
  top: 0;
  padding: 0 5px;
  left: ${(props) =>
    props.theme.space[props.theme.variants.input.default.px] - 5}px;

  ${(props) => {
    return props.forcePlaceholderTop
      ? ``
      : `  ${StyledInput}:focus + &,
      ${StyledInput}.filled + &`;
  }} {
    background: ${(props) => props.theme.input.bg};
    visibility: visible;
    font-size: 0.8em;
    opacity: 1;
    top: -0.8em;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: 1em;
    box-shadow: 0px 1px 4px
      ${(props) => props.theme.colors[props.theme.input["::boxshadow"].color]};
  }

  ${(props) => {
    return props.forcePlaceholderTop
      ? `
    background: ${props.theme.input.bg};
    visibility: visible;
    font-size: 0.8em;
    opacity: 1;
    top: -0.8em;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: 1em;
    box-shadow: 0px 1px 4px
      ${(props) =>
        props.theme.colors[props.theme.input["::boxshadow"].color]};    
    `
      : ``;
  }}
`;

const StyledPrefix = styled.div`
  color: ${(props) => {
    return props.theme.colors.chalk;
  }};
  position: absolute;
  top: 33%;
  left: 3%;
`;

const LabeledInputText = forwardRef(
  (
    { placeholder, input, prefix, children, forcePlaceholderTop, ...props },
    ref,
  ) => {
    let [filled, setIsFilled] = useState(input.defaultValue ? true : false);
    let [focused, setIsFocused] = useState(false);
    const prefixRef = React.useRef(null);
    const [prefixWidth, prefixHeight] = useSize(prefixRef);

    return (
      <>
        {props.error ? (
          <Validation mb={4}>
            {props.error.message ? props.error.message : ""}
          </Validation>
        ) : (
          <Box mb={4}></Box>
        )}
        <Box style={{ position: "relative" }}>
          <StyledInput
            sx={{
              ...theme.input,
              ...theme.labeledInput,
              ...theme.variants.input.default,
              paddingLeft: prefixWidth + 22,
            }}
            ref={ref}
            type={props.type ? props.type : "text"}
            className={filled ? "filled" : ""}
            onChange={(e) => {
              setIsFilled(e.target.value !== "");
            }}
            onFocus={(e) => {
              setIsFocused(true);
            }}
            onBlur={(e) => {
              setIsFocused(false);
            }}
            placeholder={
              focused || filled || forcePlaceholderTop ? "" : placeholder
            }
            {...input}
            {...props}
          />
          <FakeLabel
            error={props.error}
            forcePlaceholderTop={forcePlaceholderTop}
          >
            {placeholder}
          </FakeLabel>
          {prefix && <StyledPrefix ref={prefixRef}>{prefix}</StyledPrefix>}
          {children}
        </Box>
      </>
    );
  },
);

export { LabeledInputText };
