import React from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";
import {
  HiOutlineCheckCircle,
  HiOutlineDocumentAdd,
  HiOutlineDocumentText,
  HiOutlineExclamation,
} from "react-icons/hi";

import { EUploadStatus, IUploadState } from "../@types";
import { Body } from "../../../Text";

const Status = ({
  file,
  percentCompleted,
  state,
}: {
  file?: File;
  percentCompleted?: number;
  state: IUploadState;
}) => {
  switch (state.status) {
    case EUploadStatus.READY:
      return (
        <>
          <StatusCard>
            <StyledHiOutlineDocumentAdd />
            <Body color="envy" fontSize="3" fontWeight={500}>
              Upload a file
            </Body>
          </StatusCard>
          <Body color="ice" fontSize="3" width="40" mt={2} textAlign="center">
            Click anywhere to select your compressed file to upload, or simply
            drag and drop.
          </Body>
          <Body color="chalk" fontSize="2" width="40" mt={3} textAlign="center">
            Only zip, bz2 or gz file are accepted. Do not directly upload dem,
            mp4, etc.,
          </Body>
        </>
      );
    case EUploadStatus.FILE_INVALID:
      return (
        <StyledSection flexDirection="column">
          <ErrorCard>
            <StyledHiOutlineExclamation />
            <Body color="ice" fontSize="1" fontWeight={500}>
              {file?.name}
            </Body>
          </ErrorCard>
          <Text fontWeight={700} mb={2} color="error">
            Invalid file type
          </Text>
          <Body color="ice" fontSize="2" width="40">
            Make sure you're uploading a compressed demo file in either zip, bz2
            or gz format.
          </Body>
        </StyledSection>
      );
    case EUploadStatus.CONFIRMING:
      return (
        <>
          <StatusCard>
            <StyledHiOutlineDocumentText />
            <Text color="ice" fontSize="1" fontWeight={500}>
              {file?.name}
            </Text>
          </StatusCard>
          <Body color="chalk" fontSize="2" textAlign="center" width="40">
            {file?.size ? `${Math.floor(file.size / 1000)} kb` : ""}
          </Body>
        </>
      );
    case EUploadStatus.UPLOADING:
      return (
        <>
          <StatusCard>
            <StyledHiOutlineDocumentText />
            <Text color="ice" fontSize="1" fontWeight={500}>
              {file?.name}
            </Text>
          </StatusCard>
          <ProgressBar>
            <ProgressFill completed={percentCompleted} />
          </ProgressBar>
        </>
      );
    case EUploadStatus.ERROR:
      return (
        <StyledSection flexDirection="column">
          <ErrorCard>
            <StyledHiOutlineExclamation />
            <Text fontWeight={700} color="error" fontSize="3">
              {state.error?.kind}
            </Text>
          </ErrorCard>
          <Body color="ice" fontSize="2" width="40">
            {state.error?.message}
          </Body>
        </StyledSection>
      );
    case EUploadStatus.DONE:
      return (
        <StatusCard>
          <StyledHiOutlineCheckCircle />
          <Body color="ice" fontSize="3" fontWeight={500}>
            File uploaded successfully!
          </Body>
        </StatusCard>
      );
    default:
      return <></>;
  }
};

const ProgressFill = styled(Box)<{ completed?: number }>`
  background: ${({ theme }) => theme.colors.envy};
  border-radius: 20px;
  height: 8px;
  width: ${(props) => props.completed || 0}%;
`;

const ProgressBar = styled(Flex)`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.chalkAlpha40};
  border-radius: 20px;
  height: 8px;
  justify-content: flex-start;
  margin-top: 12px;
  width: 100%;
`;

const iconStyle = css`
  color: ${({ theme }) => theme.colors.envy};
  height: 24px;
  width: 24px;
`;

const StyledHiOutlineCheckCircle = styled(HiOutlineCheckCircle)`
  ${iconStyle}
`;

const StyledHiOutlineDocumentText = styled(HiOutlineDocumentText)`
  ${iconStyle}
`;

const StyledHiOutlineDocumentAdd = styled(HiOutlineDocumentAdd)`
  ${iconStyle}
`;

const StyledHiOutlineExclamation = styled(HiOutlineExclamation)`
  color: ${({ theme }) => theme.colors.error};
  height: 24px;
  width: 24px;
`;

const StyledSection = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  border-radius: 8px;
  padding: 12px 18px;
  width: 100%;
`;

const StatusCard = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 12px;
`;

const ErrorCard = styled(Flex)`
  gap: 6px;
  margin-bottom: 12px;
  width: 100%;
`;

export { Status };
