import { lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { RecoilRoot } from "recoil";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { Main } from "./main";
import history from "./history";
import theme from "./theme/themeThree";
import { UserStatePreFill } from "./MVVM/Hooks/userStatePreFill";
import { GlobalStyles } from "./theme/globalStyles";

const { REACT_APP_GQL_ENDPOINT } = process.env;

const LazyIframe = lazy(() => import("./MVVM/Views/Iframe"));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      /*
      TODO:
      Add logzio logging
      */
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  };
});

const httpLink = createHttpLink({
  uri: REACT_APP_GQL_ENDPOINT,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Promotions: {
        keyFields: ["name"],
      },
    },
  }),
  link: authLink.concat(errorLink).concat(httpLink),
});

export default function() {
  return (
    <ApolloProvider client={apolloClient}>
      <RecoilRoot id="root">
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router history={history}>
            <UserStatePreFill force={false} />
            <Switch>
              <Route exact path="/iframe" component={LazyIframe} />
              <Main />
            </Switch>
          </Router>
        </ThemeProvider>
      </RecoilRoot>
    </ApolloProvider>
  );
}
