import React, { useState } from "react";
import { FloatingContainer, Modal } from "../../Wrappers";
import styled from "styled-components";
import { Box, Flex, Image } from "rebass/styled-components";
import { Body, Title1 } from "../../Text";
import { Button, DiscordSocialButton, TiktokSocialButton } from "../../Button";
import { Divider } from "../../Divider";
import { Row } from "../../Form";
import { FormLink } from "../../Form/Link";
import { TSignupModalProps } from "../@types";
import { Avatar } from "../../Avatar";
import { WithStudio } from "../../Icons";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { URLS } from "../../../Utilities/Maps";

async function handleDiscord() {
  window.location.href = `${URLS.AUTH_ROUTES.DISCORD}?flow=signup&next=${window.location.pathname}`;
  return;
}
async function handleTikTok() {
  window.location.href = `${URLS.AUTH_ROUTES.TIKTOK}?flow=signup&next=${window.location.pathname}`;
  return;
}
const ReactionImage = styled(Image)`
  height: 24px;
  width: 24px;
`;

const ACTIONS: any = {
  SPICY: "Because this clip is SPICY",
  HYPE: "Because this clip has me DEAD",
  FIRE: "Because this clip be LIT",
  EXPLODE: "Because Omg WTF",
  signup: <></>,
};

const REASONS: any = {
  follow: "to follow",
  SPICY: "to react",
  HYPE: "to react",
  FIRE: "to react",
  EXPLODE: "to react",
  signup: "to Allstar",
  publish: "to Publish",
  signupGiveaway: "to enter",
};

export const SignupModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const currentPathAndQueryStringUrlEncoded = encodeURIComponent(
    window.location.pathname + window.location.search,
  );
  if (allstarModalState.isOpen !== ModalType.Signup) return <></>;
  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Signup}
        setModalOpen={() => {
          setAllstarModalState({
            ...allstarModalState,
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            justifyContent="center"
            maxWidth="335px"
          >
            <Title1 mb={5} alignSelf="center">
              Sign up {REASONS[allstarModalState.data.action]}
            </Title1>
            {allstarModalState.data.action === "signup" ||
            allstarModalState.data.action === "signupGiveaway" ? (
              <>
                <Flex alignSelf="center" mb={6}>
                  <Box>
                    <Body>{ACTIONS[allstarModalState.data.action]}</Body>
                  </Box>
                </Flex>
              </>
            ) : (
              <Flex variant="signupbacking" alignSelf="center" mb={6}>
                {allstarModalState.data.action === "follow" ? (
                  <>
                    <Box ml={1}>
                      <Avatar
                        size="tiny"
                        image={allstarModalState.data.avatar}
                      />
                    </Box>
                    <Box mx={3}>
                      <Body>{allstarModalState.data.target}</Body>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box ml={3} mt={1}>
                      <Body>{ACTIONS[allstarModalState.data.action]}</Body>
                    </Box>
                    <Box ml={2} mr={2}>
                      <ReactionImage
                        src={allstarModalState.data.emoji}
                      ></ReactionImage>
                    </Box>
                  </>
                )}
              </Flex>
            )}
            <DiscordSocialButton mb={5} onClick={() => handleDiscord()}>
              Sign up with Discord
            </DiscordSocialButton>{" "}
            <TiktokSocialButton onClick={() => handleTikTok()}>
              Sign up with TikTok
            </TiktokSocialButton>
            <Divider>or</Divider>
            <Row>
              <Button
                to={`/signup?next=${currentPathAndQueryStringUrlEncoded}`}
                onClick={() => {
                  setAllstarModalState({
                    ...allstarModalState,
                    isOpen: ModalType.None,
                  });
                }}
              >
                Sign up with Email
              </Button>
            </Row>
            <Flex alignItems="center" justifyContent="center">
              <FormLink
                to={`/login?next=${currentPathAndQueryStringUrlEncoded}`}
                onClick={() => {
                  setAllstarModalState({
                    ...allstarModalState,
                    isOpen: ModalType.None,
                  });
                }}
              >
                Already a member? Login
              </FormLink>
            </Flex>
          </Flex>
        </FloatingContainer>
      </Modal>
    </>
  );
};
