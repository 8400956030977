import { Route } from "react-router-dom";

import AppOneLink from "../pages/apponelink";
import AppStoreChooser from "../pages/appstorechooser";
import Faq from "../pages/faq/index";
import Privacy from "../pages/legal/privacy";
import Terms from "../pages/legal/terms";

const mobileWebviews = [
  <Route
    exact
    key="mobile-app-link/terms-of-use"
    path="/mobile-app-link/terms-of-use"
    component={Terms}
  />,
  <Route
    exact
    key="mobile-app-link/faq"
    path="/mobile-app-link/faq"
    component={Faq}
  />,
  <Route
    exact
    key="mobile-app-link/privacy-policy"
    path="/mobile-app-link/privacy-policy"
    component={Privacy}
  />,
  <Route exact key="apponelink" path="/apponelink" component={AppOneLink} />,
  <Route
    exact
    key="appstorechooser"
    path="/appstorechooser"
    component={AppStoreChooser}
  />,
];

export { mobileWebviews };
