export interface TabProps {
  active?: boolean;
}
export enum EAuthenticationTypes {
  "SIGNUP" = "SIGNUP",
  "LOGIN" = "LOGIN",
}

export type TTabBarProps = {
  type: EAuthenticationTypes;
};

export type TAuthenticationProps = {
  authType: EAuthenticationTypes;
  isEmbedded: boolean;
};
