import { useRecoilValue } from "recoil";
import { Box, Flex, Text } from "rebass";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { useUser } from "../../Hooks/useUser";
import { SidebarExpandedState } from "../../State/sidebar";
import { SidebarCollapsed } from "./SidebarCollapsed";
import { SidebarLoginWidget } from "./SidebarLoginWidget";
import { NAV_LINKS, isActive } from "./utils";
import {
  navLinkActive,
  sidebarContainerStyle,
  NavItem,
  NavLinkText,
  StyledBiCompass,
  StyledBiGroup,
  StyledBiPalette,
  StyledBiTime,
  StyledBiVideo,
  StyledDivider,
} from "./styledComponents";

const Sidebar = () => {
  const expanded = useRecoilValue(SidebarExpandedState);
  const { allstarUser } = useUser();

  if (!expanded) return <SidebarCollapsed />;

  return (
    <SidebarContainer>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ gap: 8 }}
      >
        <NavItem>
          <NavLink to={NAV_LINKS.root} isActive={isActive([NAV_LINKS.root])}>
            <StyledBiCompass />
            <NavLinkText>Explore</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={allstarUser.loggedIn ? NAV_LINKS.following : "/login"}
            isActive={isActive(["/u", NAV_LINKS.following])}
          >
            <StyledBiGroup />
            <NavLinkText>Following</NavLinkText>
          </NavLink>
        </NavItem>
        <SidebarLoginWidget />
        <StyledDivider />
        <NavSubheader>Create</NavSubheader>
        <NavItem>
          <NavLink
            to={NAV_LINKS.matchHistory}
            isActive={isActive([NAV_LINKS.matchHistory])}
          >
            <StyledBiTime />
            <NavLinkText>Match History</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={NAV_LINKS.studio} isActive={false}>
            <StyledBiPalette />
            <NavLinkText>Studio</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={NAV_LINKS.montages} isActive={false}>
            <StyledBiVideo />
            <NavLinkText>Montages</NavLinkText>
          </NavLink>
        </NavItem>
        <StyledDivider />
        <NavItem>
          <NavLink
            to="#"
            onClick={() => window.location.replace(NAV_LINKS.partners)}
            isActive={false}
          >
            <NavLinkText>For Partners</NavLinkText>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            onClick={() => window.location.replace(NAV_LINKS.support)}
            isActive={false}
          >
            <NavLinkText>Support</NavLinkText>
          </NavLink>
        </NavItem>
      </Flex>
      <Box style={{ paddingBottom: "100px" }} />
    </SidebarContainer>
  );
};

const SidebarContainer = styled(Box)`
  ${sidebarContainerStyle}
  width: 240px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 75vw;
    z-index: 3;
  }
`;

export const navLinkStyle = css`
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  text-decoration: none;
  width: 100%;
`;

export const NavLink = styled(Link)<{ isActive: boolean }>`
  ${(props) => (props.isActive ? navLinkActive : "")}

  ${navLinkStyle}

  :hover {
    ${navLinkActive}
  }
`;

const NavSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 700;
  padding: 8px 16px;
`;

export { Sidebar };
