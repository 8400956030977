import React from "react";
import { Box } from "rebass/styled-components";

export default function Title1(props) {
  return (
    <Box variant="text.title1" {...props}>
      {props.children}
    </Box>
  );
}
