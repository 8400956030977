import styled from "styled-components";
import { ModalStyledProps } from "./@types";
import { Box, Flex } from "rebass/styled-components";
import { Headline } from "../../../../Components/Text";
import { Button } from "../../../../Components/Button";

export const Headers = styled(Flex)`
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 35px;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
  }
`;

export const StyledHeading = styled(Headline)<any>`
  font-weight: 600;
  display: flex;
  min-width: 90px;
`;

export const ClipsList = styled(Box)`
  width: 99%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  overflow-y: scroll;
  height: auto;
  margin-bottom: 69px;
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.colors.ned};
  }
  ::-webkit-scrollbar {
    width: 25px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.midnightAlpha50};
    border: 5px solid transparent;
    border-radius: 999px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.midnightAlpha90};
  }
`;

export const AddButtonContainer = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.ned};
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  box-shadow: 0px -10px 10px #18252d80;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 60px;
  }
`;

export const AddButton = styled(Button)<any>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 25px;
  width: 90px;
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? "#8FA9AF" : "#F7FCFD"};
  background: ${(props: { disabled: boolean }) =>
    props.disabled ? "#213442" : "#4DBD94"};
  transition: 0.2s ease;
  ${(props: { disabled: boolean }) =>
    props.disabled
      ? ``
      : `&:hover {
    cursor: pointer;
    background: #4dbd9490;
  }`};
`;

export const ModalContent = styled(Flex)`
  flex-direction: column;
  position: fixed;
  margin-top: auto;
  margin-left: 20px;
  margin-right: 50px;
  margin-bottom: auto;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  flex-direction: column;
  align-items: start;
  height: 650px;
  max-height: 650px;
  width: 750px;
  max-width: 100%;
  justify-content: start;
  background: ${({ theme }) => theme.colors.ned};
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
  z-index: 1050;
  padding-left: 25px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 25px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    padding-right: 0px;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-0%) translateY(-0%);
    margin-bottom: 0px;
    border-radius: 16px 16px 0px 0px;
    padding-bottom: 44px;
  }
`;

export const ClipsModal = styled.div<ModalStyledProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
  background-color: rgba(12, 19, 24, 0.8);
`;
export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: row;
  align-items: center;
  align-content: start;
  justify-content: start;
  color: ${({ theme }) => theme.colors.ice};
  gap: 15px;
  font-weight: 500;
  z-index: 100;
  box-shadow: 0px 10px 10px #18252d80;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    gap: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-wrap: wrap;
    justify-content: start;
    height: 75px;
  }
`;

export const Select = styled.select`
  all: unset;
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 8px;
  border: 0px solid transparent;
  font-weight: 500;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 0px;
  justify-content: flex-start;
  text-align: left;

  color: ${({ theme }) => theme.colors.envy};
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    font-size: 12px;
  }
`;
