import { ReactNode } from "react";
import { Flex, Text } from "rebass";
import styled, { css } from "styled-components";
import {
  BiCompass,
  BiDotsHorizontalRounded,
  BiGroup,
  BiJoystick,
  BiPalette,
  BiTime,
  BiTrophy,
  BiVideo,
} from "react-icons/bi";
import { useSetRecoilState } from "recoil";

import { SidebarExpandedState } from "../../State/sidebar";
import { useMediaQuery } from "../../Hooks/useMediaQuery";
import theme from "../../../theme/themeThree";
import { Divider } from "../Divider";

export const NavItem = ({
  children,
}: {
  children?: ReactNode | ReactNode[];
}) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints[1]})`);
  const setExpanded = useSetRecoilState(SidebarExpandedState);
  const collapseOnMobile = () => isMobile && setExpanded(false);

  return (
    <Flex style={{ width: "100%" }} onClick={() => collapseOnMobile()}>
      {children}
    </Flex>
  );
};

export const NavLinkText = styled(Text)`
  color: ${({ theme }) => theme.colors.chalk};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 500;
`;

export const sidebarContainerStyle = css`
  background-color: ${({ theme }) => theme.colors.darkerNed};
  padding: 16px;
`;

export const sidebarIconStyle = css`
  color: ${({ theme }) => theme.colors.chalk};
  height: 20px;
  width: 20px;
`;

export const navLinkActive = css`
  background: ${({ theme }) => theme.colors.ned};

  * {
    color: ${({ theme }) => theme.colors.envy};
  }
`;

export const StyledBiCompass = styled(BiCompass)`
  ${sidebarIconStyle}
`;

export const StyledBiGroup = styled(BiGroup)`
  ${sidebarIconStyle}
`;

export const StyledBiTime = styled(BiTime)`
  ${sidebarIconStyle}
`;

export const StyledBiPalette = styled(BiPalette)`
  ${sidebarIconStyle}
`;

export const StyledBiTrophy = styled(BiTrophy)`
  ${sidebarIconStyle}
`;

export const StyledBiJoystick = styled(BiJoystick)`
  ${sidebarIconStyle}
`;

export const StyledBiVideo = styled(BiVideo)`
  ${sidebarIconStyle}
`;

export const StyledBiDotsHorizontalRounded = styled(BiDotsHorizontalRounded)`
  ${sidebarIconStyle}
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 0;
`;
