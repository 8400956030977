import { Location } from "history";

const MAPPED_PAGES = ["clips", "clip", "profile", "u"];
const PACKAGE_NAME = "com.androidapp.gg.allstar";
const FALLBACK_URL =
  "https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar";

export const isValidAppMappedPage = (location: Location) => {
  const resource = location.pathname.split("/")[1];

  return MAPPED_PAGES.includes(resource);
};

export const generateIntent = (location: Location) => {
  let params = "";
  const resource = location.pathname.split("/")[1];

  switch (resource) {
    case "clip":
      params = `page=clip&clipId=${location.search.split("=")[1]}`;
      break;
    case "profile":
      params = `page=user&userId=${location.search.split("=")[1]}`;
      break;
    case "u":
      params = `page=user&userId=${location.pathname.split("/")[2]}`;
      break;
    default:
      params = "";
  }

  const fallbackUrl = encodeURIComponent(FALLBACK_URL);
  const intent = `intent://deeplink?${params}#Intent;scheme=androidapp;package=${PACKAGE_NAME};S.browser_fallback_url=${fallbackUrl};end;`;

  return intent;
};
