import React from "react";
import { Flex } from "rebass/styled-components";
import { Avatar } from "../../atoms/avatar";
import { Link } from "../../atoms/link";
import styled from "styled-components";
import ReactPlaceholder from "react-placeholder";
import { TextRow } from "react-placeholder/lib/placeholders";
import { FollowButton } from "../../atoms/form/button/follow";
import theme from "../../../theme/themeThree";
import { AvatarBorder } from "../../../MVVM/Utilities/User";
import {
  getAvatarBorderLevel,
  getUserTitle,
} from "../../../MVVM/Utilities/User";
import { Headline, Subheader } from "../../../components/text";
import { getDisplayName } from "./utils";
import { useGetUser } from "../../../userStore";
import { RiRefreshLine } from "react-icons/ri";
import { getAvatarImage } from "../../../MVVM/Utilities/User";

const StyledOwner = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`;

const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.colors.ice};
`;
const StyledFollowButton = styled(FollowButton)`
  @media (max-width: 500px) {
    display: none;
  }
`;
const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    justify-content: space-between;
  }
`;
const titlePlaceholder = (
  <>
    <TextRow
      color={theme.colors.chalkAlpha20}
      style={{
        marginBottom: theme.space[2],
        height: "1.75em",
        width: "100%",
        maxWidth: "430px",
        marginTop: 0,
      }}
    />
    <TextRow
      color={theme.colors.chalkAlpha20}
      style={{
        height: "1.125em",
        width: "80%",
        maxWidth: "300px",
        marginTop: 0,
      }}
    />
  </>
);

export function Owner({
  loading,
  hideFollow,
  hideName,
  hideTitle,
  hideRank,
  avatarSize = "small",
  allstarUser,
  followUnfollow,
  setFollowModal,
  ...props
}) {
  let avatarBorderLevel = !hideRank && getAvatarBorderLevel(props.user);

  let userTitle = getUserTitle(props.user);
  const user = useGetUser();
  const isOwnClip = user && user._id === props.ownerId;
  return (
    <Wrapper {...props}>
      <Flex alignItems="center" justifyContent="center">
        {avatarBorderLevel ? (
          <AvatarBorder type={avatarBorderLevel} size={avatarSize}>
            <Avatar
              size={avatarSize}
              image={props.avatar}
              to={`/profile?user=${props.ownerId}`}
              link={props.link}
            />
          </AvatarBorder>
        ) : (
          <Avatar
            size={avatarSize}
            image={props.avatar}
            to={`/profile?user=${props.ownerId}`}
            link={props.link}
          />
        )}
        {!hideName && (
          <StyledOwner ml={3}>
            <ReactPlaceholder
              customPlaceholder={titlePlaceholder}
              ready={!loading}
            >
              <StyledHeadline>
                <Link
                  variant="link.clipPageOwner"
                  to={`/profile?user=${props.ownerId}`}
                >
                  {props.displayName}
                </Link>
              </StyledHeadline>
              {!hideTitle && (
                <Flex alignItems="flex-end">
                  <Subheader mt={1} pr={4} color="chalk">
                    {userTitle}
                  </Subheader>
                </Flex>
              )}
            </ReactPlaceholder>
          </StyledOwner>
        )}{" "}
        {!isOwnClip && props.ownerId && !hideFollow ? (
          <StyledFollowButton
            flex={true}
            ml={5}
            size="tiny"
            alignItems="center"
            followClassification={"ownerFollow"}
            followingClassification={"ownerFollowing"}
            userId={props.ownerId}
            displayName={props.displayName}
            avatar={props.avatar}
            allstarUser={allstarUser}
            followUnfollow={followUnfollow}
            setFollowModal={setFollowModal}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Wrapper>
  );
}

const StyledFlex = styled(Flex)`
  padding-left: 15px;
  padding-right: 0px;
  padding-top: 3px;
  padding-bottom: 5px;
  height: 42px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.chalk};
  font-size: 0.875em;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
    background-color: transparent;
    padding-left: 0px;
    padding-top: 5px;
    color: ${(props) => props.theme.colors.chalk};
    margin-bottom: 0px;
  }
`;

const StyledRemixedIcon = styled(RiRefreshLine)``;

const StyledLink = styled(Link)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;

  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;

const StyledRemixOwner = styled(Owner)`
  font-size: 0.875em;
  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;

const StyledText = styled(Flex)``;

function RemixOwner({ clip }) {
  const originalUser = clip.remix.user;
  const hide = clip.remix.clonedVersion;

  console.log("Remix Owner", hide);

  if (hide) return <></>;
  return (
    <StyledFlex
      ml={[4, 5]}
      alignSelf="flex-end"
      alignItems="center"
      maxHeight="42px"
    >
      <StyledRemixedIcon size={"16"} alignItems="center"></StyledRemixedIcon>
      <StyledText>
        <StyledLink
          to={`/clip?clip=${clip.remix.clip.shareId}`}
          color="chalk"
          mr={1}
          ml={2}
        >
          Original clip
        </StyledLink>{" "}
      </StyledText>

      <StyledRemixOwner
        ml={3}
        avatarSize="tiny"
        ownerId={originalUser._id}
        user={originalUser}
        hideFollow
        hideTitle
        displayName={getDisplayName(originalUser)}
        avatar={getAvatarImage(originalUser)}
      />
    </StyledFlex>
  );
}
