//@Phillip for loadouts to be typed I need to have a default state to reset a nested object of our session to..
//I am not sure the best way to approach my problem here so if ya have a minute to chat when reading this ping me!

import { TLoadout, TSequence } from "../@types";

export const DEFAULT_LOADOUT: TLoadout = {
  name: "",
  description: "",
  image: "",
  hoverEffectSrc: "",
  hoverEffect: "",
  displayCards: [],
  cards: [],
  badge: "",
};

export const DEFAULT_SEQUENCE: TSequence = {
  sequenceType: "",
  description: "",
  image: "",
  requires: [],
  video: "",
  qualify: "",
  buildDoc: {
    amsTiming: 0,
    minKills: 0,
    maxKills: 0,
  },
  comingSoon: false,
};
