import { gql } from "@apollo/client";

export const BASE_REQUEST = gql`
  query Clips(
    $search: SearchClips!
    $filter: [String]
    $page: Int
    $game: Int
    $remixes: Boolean
    $user: String
    $followers: Boolean
    $mobile: Boolean
    $hlsOnly: Boolean
    $limit: Int
  ) {
    clips(
      search: $search
      filter: $filter
      page: $page
      game: $game
      remixes: $remixes
      user: $user
      followers: $followers
      mobile: $mobile
      hlsOnly: $hlsOnly
      limit: $limit
    ) {
      dataCount
      pageCount
      currentPage
      next
      prev
      totalPages
      hasPrevPage
      hasNextPage
      data {
        _id
        partner {
          useCase
        }
        user {
          _id
          avatar
          userGroup {
            type
            active
          }
          rank
          username
          discord {
            avatar
            id
            username
          }
        }
        personalizations {
          cards {
            _id
            ui {
              title
            }
            facet {
              type
            }
          }
        }
        clipProperties {
          _id
          key
          value
          displayType
        }
        userClipTitle
        clipTitle
        clipSizeBytes
        game
        clipLength
        views
        shareId
        username
        steamHandle
        createdDate
        clipImageSource
        clipImageThumb
        clipLinkHLS
        clipLink
        clipSnapshot
        isRemixable
        comments {
          count
          reaction
        }
        cf {
          hls
          dash
          thumbnail
          downloadUrl
          readyToStream
        }
        remix {
          isRemixed
          originalUser
          clonedVersion
          originalClip
          ui {
            username
            discordId
            userRank
            userGroup
            shareId
            allstarAvatar
            clipThumbnail
          }
        }
        mobile {
          status
          clipLink
          aspectRatio
          resolution
          clipImageThumb
          cf {
            hls
            downloadUrl
          }
        }
      }
    }
  }
`;

export const MONTAGE_REQUEST = gql`
  query Query($search: SearchClips!, $page: Int, $user: String) {
    montages(search: $search, page: $page, user: $user) {
      hasNextPage
      hasPrevPage
      totalPages
      prev
      next
      currentPage
      pageCount
      dataCount
      data {
        _id
        createdDate

        user {
          discord {
            username
            id
            avatar
          }
          rank
          scope
          userGroup {
            type
          }
          avatar
          admin
          username
          _id
        }
        originalClips {
          _id
          user {
            _id
            avatar
            userGroup {
              type
              active
            }
            rank
            username
            discord {
              avatar
              id
              username
            }
          }
          userClipTitle
          clipTitle
          game
          clipLength
          views
          shareId
          username
          createdDate
          clipImageSource
          clipImageThumb
          clipLinkHLS
          clipLink
          clipSnapshot
          cf {
            hls
            dash
            thumbnail
            downloadUrl
            readyToStream
          }
          remix {
            isRemixed
            originalUser
            originalClip
            ui {
              username
              discordId
              userRank
              userGroup
              shareId
              allstarAvatar
              clipThumbnail
            }
          }
          mobile {
            status
            clipLink
            aspectRatio
            resolution
            clipImageThumb
            cf {
              hls
              downloadUrl
            }
          }
        }
        cf {
          hls
          dash
          readyToStream
          thumbnail
          downloadUrl
        }
        views
        shareId
        username
        clipImageSource
        clipImageThumb
        clipLinkHLS
        clipLink
        clipSnapshot
        clipTitle
        userClipTitle
      }
    }
  }
`;

export const PLAYLIST_REQUEST = gql`
  query Query($userId: String) {
    usersPlaylists(userId: $userId) {
      playlists {
        clips {
          clip {
            _id
            partner {
              useCase
            }
            clipPreviewPath
            user {
              _id
              avatar
              userGroup {
                type
                active
              }
              rank
              username
              discord {
                avatar
                id
                username
              }
            }
            personalizations {
              cards {
                _id
                ui {
                  title
                }
                facet {
                  type
                }
              }
            }
            clipProperties {
              _id
              key
              value
              displayType
            }
            userClipTitle
            clipTitle
            clipSizeBytes
            game
            clipLength
            views
            shareId
            username
            createdDate
            clipImageSource
            clipImageThumb
            clipLinkHLS
            clipLink
            clipSnapshot
            isRemixable
            comments {
              count
              reaction
            }
            cf {
              hls
              dash
              thumbnail
              downloadUrl
              readyToStream
            }
            remix {
              isRemixed
              originalUser
              clonedVersion
              originalClip
              ui {
                username
                discordId
                userRank
                userGroup
                shareId
                allstarAvatar
                clipThumbnail
              }
            }
            mobile {
              status
              clipLink
              aspectRatio
              resolution
              clipImageThumb
              cf {
                hls
                downloadUrl
              }
            }
          }
        }
        name
      }
    }
  }
`;

export const RECOMMENDED_FEED = gql`
  query RecommendedFeedsForClip($clipId: String!) {
    recommendedFeedsForClip(input: { clipId: $clipId }) {
      success
      feeds {
        title
        category
        clips {
          clipImageThumb
          clipTitle
          createdDate
          shareId
          steamHandle
          username
          views
        }
      }
    }
  }
`;

export const MUTATE_PLAYLIST = gql`
  mutation Mutation(
    $isAddRemove: Boolean
    $isAdd: Boolean
    $playlistName: String
    $isMobileReq: Boolean
    $clipId: String
  ) {
    modifyPlaylist(
      isAddRemove: $isAddRemove
      isAdd: $isAdd
      playlistName: $playlistName
      isMobileReq: $isMobileReq
      clipId: $clipId
    )
  }
`;
