import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(HiOutlineUserCircle).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const UserIcon = (props) => {
  return <StyledIcon {...props} />;
};

UserIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default UserIcon;
