import React from "react";
import {
  CtaBody,
  GoToMontageCTAContainer,
  GoToMontageContainer,
  GoToMontageText,
  MontageCallToAction,
  StyledLaunchIcon,
} from "./styledComponents";
import { Image } from "rebass/styled-components";

import { useHistory } from "react-router-dom";
import { URLS } from "../../../../../utils";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export const GoToMontageCTA = () => {
  const history = useHistory();

  return (
    <GoToMontageCTAContainer>
      <Image
        src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/montages/MatchMontage_Horizontal@2x.webp`}
        width={180}
        flexShrink={0}
        style={{ marginBottom: 12 }}
      />
      <MontageCallToAction>
        <CtaBody style={{ marginBottom: 24 }}>
          Don't have enough clips to create a Career Montage yet? No worries!
          Head over to Match History and you can start creating Montages of any
          match you play with just a few clicks.
        </CtaBody>
        <GoToMontageContainer
          onClick={() => {
            window.rudderanalytics.track(
              "Match Montage - Career X-Promo Click",
            );
            return history.push(URLS.MATCH_HISTORY);
          }}
        >
          <GoToMontageText>Go to Match History</GoToMontageText>
          <StyledLaunchIcon />
        </GoToMontageContainer>
      </MontageCallToAction>
    </GoToMontageCTAContainer>
  );
};
