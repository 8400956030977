import React, { useEffect } from "react";
import { Box, Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "../Components/Button";
import { Divider } from "../Components/Divider";
import { Avatar } from "../Components/Avatar";
import { Row as FormRow } from "../Components/Form";
import { DiscordSocialButton, TiktokSocialButton } from "../Components/Button";
import WithStudio from "../../components/atoms/icons/withStudio";
import { FormLink } from "../Components/Button";
import { Title1, Body } from "../Components/Text";
import { URLS } from "../Utilities/Maps";

async function handleDiscord() {
  window.location.href = `${URLS.AUTH_ROUTES.DISCORD}?flow=signup&next=${window.location.pathname}`;
  return;
}
async function handleTikTok() {
  window.location.href = `${URLS.AUTH_ROUTES.TIKTOK}?flow=signup&next=${window.location.pathname}`;
  return;
}
const ReactionImage = styled(Image)`
  height: 24px;
  width: 24px;
`;

const ACTIONS: any = {
  SPICY: "Because this clip is SPICY",
  HYPE: "Because this clip has me DEAD",
  FIRE: "Because this clip be LIT",
  EXPLODE: "Because Omg WTF",
  signup: (
    <Flex mb={5}>
      <WithStudio mt={5} $shrinkMobile={false} to="/signup"></WithStudio>
    </Flex>
  ),
};

const REASONS: any = {
  follow: "to follow",
  SPICY: "to react",
  HYPE: "to react",
  FIRE: "to react",
  EXPLODE: "to react",
  signup: "to Allstar",
  publish: "to Publish",
  signupGiveaway: "to enter",
};

let Signup: any = (props: any) => {
  useEffect(() => {
    window.rudderanalytics.page(
      "Signup CTA",
      `${props.action === "follow" ? "Follow" : "Reaction"}`,
    );
  });
  console.log(props);
  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        maxWidth="335px"
      >
        <Title1 mb={5} alignSelf="center">
          Sign up {REASONS[props.action]}
        </Title1>
        {props.action === "signup" || props.action === "signupGiveaway" ? (
          <>
            <Flex alignSelf="center" mb={6}>
              <Box>
                <Body>{ACTIONS[props.action]}</Body>
              </Box>
            </Flex>
          </>
        ) : (
          <Flex variant="signupbacking" alignSelf="center" mb={6}>
            {props.action === "follow" ? (
              <>
                <Box ml={1}>
                  <Avatar size="tiny" image={props.avatar} />
                </Box>
                <Box mx={3}>
                  <Body>{props.target}</Body>
                </Box>
              </>
            ) : (
              <>
                <Box ml={3} mt={1}>
                  <Body>{ACTIONS[props.action]}</Body>
                </Box>
                <Box ml={2} mr={2}>
                  <ReactionImage src={props.emoji}></ReactionImage>
                </Box>
              </>
            )}
          </Flex>
        )}
        <DiscordSocialButton mb={5} onClick={() => handleDiscord()}>
          Sign up with Discord
        </DiscordSocialButton>{" "}
        <TiktokSocialButton onClick={() => handleTikTok()}>
          Sign up with TikTok
        </TiktokSocialButton>
        <Divider>or</Divider>
        <FormRow>
          <Button to={`/signup?next=${window.location.pathname}`}>
            Sign up with Email
          </Button>
        </FormRow>
        <Flex alignItems="center" justifyContent="center">
          <FormLink to={`/login?next=${window.location.pathname}`}>
            Already a member? Login
          </FormLink>
        </Flex>
      </Flex>
    </>
  );
};

Signup.defaultProps = {
  action: "follow",
  target: false,
};

export { Signup };
