import React, { useState, useEffect } from "react";
import { StaticContainer } from "../Wrappers";
import SaveToProfile from "./Components/SaveToProfile";
import { useHistory } from "react-router-dom";
import { Clip } from "./clipDisplay";
import { URLS } from "../../Utilities/Maps";
import { ClipEditViewModel } from "./ViewModel";
import { Notice } from "./Components/Notice";
import { FormLink } from "../Button";
import { Body } from "../Text";
import { ReactComponent as Info } from "../../Assets/information-circle.svg";
import { EPaywallType } from "../Modals/Paywall";
import { AllstarModalState } from "../../State/modals";
import { useRecoilState } from "recoil";
import { ModalType } from "../../@types";

export default function Edit({
  passedClip,
  allstarUser,

  isFinalizeMontage = false,
}) {
  const { editTitle, requestMobile } = ClipEditViewModel();
  const history = useHistory();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const [clip, setClip] = useState(() => {
    const isOwnClip = allstarUser?.user?._id === passedClip?.user?._id;
    const mobileData = passedClip?.mobile?.find(
      (x) => x.aspectRatio === "9:16",
    );

    const canRequestMobile = !mobileData?.status && passedClip.game === 730;
    return {
      clip: passedClip,
      userClipTitle: undefined,
      mobileQueued: false,
      mobileError: false,
      titleSaved: false,
      saving: false,
      loading: false,
      error: !isOwnClip || passedClip.userClipTitle,
      isOwnClip: isOwnClip,
      canRequestMobile: canRequestMobile,
      mobileSelected: canRequestMobile,
      fromEdit: true,
    };
  });

  const [titleError, setTitleError] = useState(false);

  useEffect(() => {
    // ensure we are not rerendering edit.js
    // console.count(`Edit`);
  }, []);

  function onTitleSave(e) {
    setClip({ ...clip, userClipTitle: e.trim() });
  }

  function onMobileClick(e) {
    setClip({ ...clip, mobileSelected: !clip.mobileSelected });
  }

  async function saveToProfile(e) {
    setClip({ ...clip, saving: true });
    let mobileQueued = false;
    let titleSaved = false;
    let mobileError = false;
    let titleError = false;
    let clipCopy = Object.assign({}, clip.clip);

    const clipId = clip.clip._id;
    if (clip.mobileSelected) {
      mobileQueued = await requestMobile(clipId);

      if (mobileQueued?.error?.response?.data?.version === 2) {
        setClip({
          ...clip,
          clip: { ...clipCopy },
          titleSaved: false,
          saving: false,
          mobileQueued: mobileQueued,
          mobileError: mobileError,
          titleError: titleError,
        });
        return setAllstarModalState({
          type: ModalType.Paywall,
          data: {
            type: EPaywallType.Mobile,
          },
          functions: {},
        });
      }
    }

    if (clip.userClipTitle) {
      const clipTitle = clip.userClipTitle;
      const requestTitleChange = await editTitle(
        clipId,
        clipTitle,
        isFinalizeMontage,
      );
      if (!requestTitleChange) {
        setTitleError(true);
        return setClip({
          ...clip,
          titleSaved: false,
          loading: false,
          saving: false,
          mobileQueued: mobileQueued,
          mobileError: mobileError,
        });
      }

      clipCopy.clipSnapshot = requestTitleChange.clipThumb;
      clipCopy.userClipTitle = clip.userClipTitle;
    }

    window.rudderanalytics.track("Edit Clip", {
      shareId: clipCopy.shareId,
      titleEdited: titleSaved,
      mobileQueued: mobileQueued,
    });

    setClip({
      ...clip,
      clip: { ...clipCopy },
      titleSaved: true,
      saving: false,
      mobileQueued: mobileQueued,
      mobileError: mobileError,
      titleError: titleError,
    });
  }

  function toProfile(state) {
    history.push(URLS.PROFILE, { ...state });
  }

  // if (clip.error) {
  //   return <Redirect to={`/clip?clip=${clip.clip.shareId}`}></Redirect>;
  // }

  if (clip.titleSaved) {
    toProfile({
      queuedMobile: clip.mobileQueued,
      fromEdit: { ...clip.clip, fromEdit: false },
    });
  }

  return (
    <>
      <StaticContainer
        pt={5}
        pb={8}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        {isFinalizeMontage && (
          <Notice>
            <Info size={24} />
            <Body>
              You can close this page, we’ll notify you via Email or Discord
              when your Montage is ready. If you need to link your Discord you
              can <FormLink to={"/connectedaccounts"}>click here</FormLink>
            </Body>
          </Notice>
        )}
        <SaveToProfile
          save={saveToProfile}
          redirect={toProfile}
          saving={clip.saving}
          clip={clip}
          onMobileClick={onMobileClick}
          titleError={titleError}
          isFinalizeMontage={isFinalizeMontage}
        />

        <Clip
          clip={clip}
          titleError={titleError}
          setTitleError={setTitleError}
          onTitleSave={onTitleSave}
          isFinalizeMontage={isFinalizeMontage}
          title={clip.clip.clipTitle}
        />
      </StaticContainer>
    </>
  );
}
