import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Reorder } from "framer-motion";

import { AllstarUser, MontageSession } from "../../../../State";
import { Modal } from "../../Modal/Clips";
import { LoadingSkeleton } from "../../../../Components/Skeloton";

import { SelectClipCard } from "./Components/SelectClipCard";
import { Clip } from "./Components/Clip";
import { Count, CountIndicator } from "../CountIndicator";

import { TMontageClip, IClipsData } from "../../@types";
import { Tooltip } from "../../../../Components/Tooltip";
import { Body } from "../../../../Components/Text";
import { Grid } from "../../../../Components/Grid";
export const countKills = (clips: TMontageClip[]) => {
  //
  const count = clips
    .map((clip: TMontageClip) => {
      // console.log(clip);
      if (
        Array.isArray(clip.personalizations?.data?.kills) &&
        clip.personalizations?.data?.kills.length
      ) {
        // console.log("return kills length");
        return clip.personalizations.data.kills.length;
      }
      return (
        clip.timeline.filter((event) => {
          return event.event === "kill";
        }).length ?? 0
      );
    })
    .reduce((prev: number, next: number) => prev + next, 0);

  return count;
};

interface TClipsProps extends IClipsData {
  setModal: (modal: { body: string; isOpen: boolean }) => void;
}

type TClipSelectorModalState = {
  isOpen: boolean;
  insertIndex: number;
};

const StyledReorder = styled(Reorder.Group)<any>`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Clips = ({
  clipsLoading,
  clipsData,
  getClips,
  setModal,
}: TClipsProps) => {
  const allstarUser = useRecoilValue(AllstarUser);
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const [clipSelectorModalState, setClipSelectorModalState] =
    useState<TClipSelectorModalState>({
      isOpen: false,
      insertIndex: 0,
    });

  const [canProceed, setCanProceed] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const openClipSelectorModal = ({ insertIndex }: { insertIndex: number }) => {
    setClipSelectorModalState({ isOpen: true, insertIndex });
  };
  const closeClipSelectorModal = () => {
    setClipSelectorModalState({ isOpen: false, insertIndex: 0 });
  };
  const proceedCriteria = (type: string, count: number) => {
    if (type === "freeform")
      return (
        count >= montageSession.sequence.buildDoc.minKills &&
        count <= montageSession.sequence.buildDoc.maxKills
      );

    return false;
  };

  useEffect(() => {
    if (montageSession.kills < 0 && !clipsLoading) {
      if (countKills(montageSession.clips) > 20) {
        const trim = montageSession.clips.slice(0, 4);
        setMontageSession({
          ...montageSession,
          kills: countKills(trim),
          clips: trim,
        });
      } else {
        setMontageSession({
          ...montageSession,
          kills: countKills(montageSession.clips),
        });
      }
    }
  }, [clipsLoading]); //eslint-disable-line

  useEffect(() => {
    setCanProceed(
      proceedCriteria(montageSession.sequence.qualify, montageSession.kills),
    );
  }, [montageSession.clips]); //eslint-disable-line

  const alreadyInMontage = montageSession.clips.map(
    (clip: TMontageClip) => `${clip._id}`,
  );

  const setItems = (newOrder: TMontageClip[]) => {
    setMontageSession({
      ...montageSession,
      clips: newOrder,
    });
  };

  // const y = useMotionValue(0);
  // const boxShadow = useRaisedShadow(y);

  const queryClips = async (filter: string[], sort: string) => {
    setLoading(true);
    const safeFilter = filter.length ? filter : null;

    await getClips({
      variables: {
        sequence: montageSession?.sequence?.qualify,
        user: allstarUser.user._id,
        filter: safeFilter,
        sort: sort,
        game: montageSession.game,
      },
    });
    setLoading(false);
  };

  if (clipsLoading && !loading) return <Loading />;

  const handleNoClips: any = () => {
    if (!clipsData) return [];
    else {
      return clipsData.createMontageClips?.filter(
        (clip: TMontageClip) => !alreadyInMontage.includes(`${clip._id}`),
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={clipSelectorModalState.isOpen}
        closeClipSelectorModal={closeClipSelectorModal}
        clips={handleNoClips()}
        loading={loading}
        queryClips={queryClips}
        count={montageSession.kills}
        openProCTA={setModal}
        insertIndex={clipSelectorModalState.insertIndex}
      />
      <Flex>
        <Tooltip
          position={{
            top: "-65px",
            padding: "7px",
            right: "-188px",
          }}
          type="hover"
          theme="helpTooltip"
          align="right"
          hide={canProceed}
          tooltipContent={
            <>
              <Flex flexDirection={"column"}>
                <Box>
                  <Count
                    count={montageSession.kills}
                    max={montageSession.sequence.buildDoc.maxKills}
                    canProceed={canProceed}
                  />
                </Box>
                <Body>
                  You need at least{" "}
                  {montageSession.sequence.buildDoc.minKills -
                    montageSession.kills}{" "}
                  more kills.
                </Body>
              </Flex>
            </>
          }
        >
          <CountIndicator
            heading={"Kills"}
            count={montageSession.kills}
            max={montageSession.sequence.buildDoc.maxKills}
            canProceed={canProceed}
          />
        </Tooltip>
      </Flex>
      <StyledReorder
        axis="y"
        values={montageSession.clips}
        onReorder={setItems}
        className="reorder-group"
        style={{
          listStyleType: "none",
          all: "unset",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "20px",
          overflowY: "scroll",
        }}
        layoutScroll
      >
        {montageSession.clips.map((clip: TMontageClip, index: number) => {
          return (
            <Reorder.Item
              key={clip._id}
              value={clip}
              as="div"
              style={{
                listStyleType: "none",
                padding: "0px",
              }}
            >
              <Clip
                key={clip._id}
                clip={clip}
                index={index}
                isFinale={index === montageSession.clips.length - 1}
                willExceedKillLimit={false}
                isSelected={false}
                selected={[]}
                handleSelect={() => {}}
                setModal={setModal}
                forceColumnDisplay={false}
              />
            </Reorder.Item>
          );
        })}
        <SelectClipCard
          openClipSelectorModal={() => {
            openClipSelectorModal({
              insertIndex: montageSession.clips.length,
            });
          }}
        />
      </StyledReorder>
    </>
  );
};

const Loading = () => {
  return (
    <Flex
      justifyContent={"flex-start"}
      width="685px"
      pb={10}
      flexDirection="column"
    >
      <LoadingSkeleton
        width={"150px"}
        height={"28px"}
        containerClassName={"align-self-start"}
      />
      <Grid>
        {[1, 1, 1, 1].map((_, index: number) => {
          return (
            <Flex
              key={index}
              height="240px"
              width="320px"
              flexDirection="column"
              pt={3}
            >
              <LoadingSkeleton
                height="290px"
                width="320px"
                style={{ borderRadius: "14px" }}
                containerClassName="flex-grow-1"
              />
              <Flex pt={2}>
                <LoadingSkeleton
                  height="30px"
                  width="160px"
                  style={{ borderRadius: "8px" }}
                />
              </Flex>
            </Flex>
          );
        })}
      </Grid>
    </Flex>
  );
};

const ScrollableWrapper = styled(Flex)<any>`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
