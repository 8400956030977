import React, { Component } from "react";
import PropTypes from "prop-types";
import Cursor from "./cursor.js";

import "./type.css";

export default class TypingEffect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      displayText: "",
    };
    this.getRawText = this.getRawText.bind(this);
    this.type = this.type.bind(this);
    this.startTyping = this.startTyping.bind(this);
  }

  componentDidMount() {
    this.startTyping();
  }

  componentWillUnmount() {
    this._timeout && clearTimeout(this._timeout);
  }

  startTyping() {
    this._timeout = setTimeout(() => {
      this.type();
    }, this.props.typingDelay);
  }

  getRawText() {
    const { text } = this.props;
    return typeof text === "string" ? [text] : [...text];
  }

  type() {
    let { index, displayText } = this.state;
    let text = this.getRawText()[index];
    if (text.length > displayText.length) {
      displayText = text.substr(0, displayText.length + 1);
      this.setState({ displayText }, () => {
        this._timeout = setTimeout(() => {
          this.type();
        }, this.props.speed);
      });
    } else {
      //typing ended
      this._timeout = setTimeout(() => {
        document
          .getElementById("type")
          .setAttribute("class", "jsType animate-out");
        this._timeout = setTimeout(() => {
          document.getElementById("type").setAttribute("class", "jsType");
          this.setState({ displayText: "", index: 0 });
          this.startTyping();
        }, 2000);
      }, 1000);
    }
  }

  render() {
    const { displayText } = this.state;
    return (
      <div className="jsType" id="type">
        <div style={{ display: "contents" }}>
          <span style={{ fontWeight: "bold" }}>{displayText}</span>
          <Cursor />
        </div>
      </div>
    );
  }
}

TypingEffect.defaultProps = {
  speed: 200,
  typingDelay: 1000,
};

TypingEffect.propTypes = {
  speed: PropTypes.number.isRequired,
  typingDelay: PropTypes.number.isRequired,
  staticText: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  className: PropTypes.string,
  cursor: PropTypes.string,
  cursorClassName: PropTypes.string,
};
