import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select as RebassSelect } from "@rebass/forms/styled-components";
import styled from "styled-components";
import { useUser } from "../../../MVVM/Hooks/useUser";

const StyledSelect = styled(RebassSelect)({
  appearance: "none",
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  color: (props) => props.theme.colors.ice,

  outline: "none",
  cursor: "pointer",
  backgroundColor: (props) => props.theme.colors.nedLight,
  borderColor: "transparent",
});

export function LanguagesDropdown() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const { allstarUser } = useUser();

  function handleChange(event) {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    window.rudderanalytics.identify(allstarUser?.user?._id, {
      langPref: newLanguage,
    });
    i18n.changeLanguage(newLanguage);
  }

  useEffect(() => {
    if (!["en", "pt-BR", "es", "de"].includes(i18n.language)) {
      setLanguage("en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <StyledSelect value={language} onChange={handleChange} variant="small">
      <option value="en" defaultValue>
        English (US)
      </option>
      <option value="pt-BR">Portugués (Brasil)</option>
      <option value="es">Español (España)</option>
      <option value="de">Deutsch</option>
    </StyledSelect>
  );
}
