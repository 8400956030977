import React, { Fragment } from "react";
import { Switch } from "react-router-dom";
import Axios from "axios";
import Fire from "./assets/images/reactions/FIRE.svg";
import Hype from "./assets/images/reactions/HYPE.svg";
import Spicy from "./assets/images/reactions/SPICY.svg";
import Explode from "./assets/images/reactions/EXPLODE.svg";

import { postApiSegment } from "./dataLayer/dataPost";

const CLIP_STATUS = {
  DELETED: "Deleted",
  PROCESSED: "Processed",
  INACTIVE: "Inactive",
  SUBMITTED: "Submitted",
};

export const SVG_MAP = {
  FIRE: Fire,
  HYPE: Hype,
  SPICY: Spicy,
  EXPLODE: Explode,
};

const STUDIO_URL = "/studio";
export const URLS = {
  OAUTH_REDIRECT: "connectedaccounts",
  DASHBOARD: "dashboard",
  ACCOUNT_SETTINGS: "accountsettings",
  LOGIN: "/login",
  SIGNUP: "/signup",
  SUPPORT: "/support",
  PROFILE: "/profile",
  CLIPS: "/clips",
  REMIX: "/remix",
  SOCIAL: "/connectedaccounts",
  MONTAGE: "/montages/create",
  STUDIO: STUDIO_URL,
  STUDIO_CARD: `${STUDIO_URL}/:cardId`,
  SETUP: "/setup",
  NFLUENCE: "/nfluence",
  MATCH_HISTORY: "/match-history",
};

export default function FragmentSupportingSwitch({ children }) {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(
    React,
    [Switch, null].concat(flattenedChildren),
  );
}

function flatten(target, children) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}

export function rgba(h, a) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgb(" + +r + "," + +g + "," + +b + ", " + a + ")";
}

// This function takes in the userQuery variable on the profile and will return if it is a league partner
export function isLeaguePartner(userQuery) {
  //The profile may pass in a username or an ID depending on the route it originated from
  let partnerIds = ["Broxah", "broxah", "621cb1aad015506d2fdf1caf"];

  return partnerIds.includes(userQuery);
}

// This function takes in the userQuery variable on the profile and will return if it is a league partner
export function isDotaPartner(userQuery) {
  //The profile may pass in a username or an ID depending on the route it originated from
  let partnerIds = ["Topson", "topson", "61f1687acfbb563fe3740910"];

  return partnerIds.includes(userQuery);
}

export function luminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export function getUserTitle(user) {
  if (!user.rank || !user.userGroup) return null;
  if (
    user.rank === "Official Partner" ||
    user.rank === "Superstar" ||
    user.rank === "Team Allstar"
  )
    return user.rank;

  if (user.userGroup.type.includes("PRO")) return "Allstar Pro";

  return "Allstar";
}

export async function CheckExpiry() {
  return new Promise((res, rej) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    let payload = localStorage.getItem("payload");

    if (!payload) return;

    payload = JSON.parse(payload);

    const secondsToExpiry = (payload.exp * 1000 - Date.now()) / 1000;
    const hoursToExpiry = secondsToExpiry / 3600;

    if (secondsToExpiry < 0) {
      return logout(payload?.data?.id);
    }

    //hours to expiry less than 3 days? refresh.
    if (hoursToExpiry <= 72) {
      const PostOptions = {
        method: "POST",
        url: `${BASE_URL}/users/auth/refresh?token=${payload.refreshToken}`,
      };
      let token;
      Axios(PostOptions)
        .then((data) => {
          if (data.status !== 201) {
            logout(payload?.data?.id);
          }
          token = data.data.token;
          localStorage.setItem("token", token);

          try {
            const [headerEncoded, payloadEncoded] = token.split(".");
            const [, payload] = [headerEncoded, payloadEncoded].map(
              decodeTokenComponent,
            );

            localStorage.setItem("payload", JSON.stringify(payload));

            return res();
          } catch (e) {
            return logout(payload?.data?.id);
          }
        })
        .catch((e) => {
          localStorage.clear();
          return logout(payload?.data?.id);
        });
    }

    return res();
  });
}

async function refreshPayload(setPayload) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const [headerEncoded, payloadEncoded] = token.split(".");
  // eslint-disable-next-line
  const [header, payload] = [headerEncoded, payloadEncoded].map(
    decodeTokenComponent,
  );

  const opt = {
    method: "GET",
    url: `${BASE_URL}/items/profile/@me?token=${token}&skipClips=true`,
  };

  return new Promise(async (res, rej) => {
    if (payload) {
      try {
        let response = await Axios(opt);
        if (response.data.status === "ok") {
          let payloadData = {
            ...payload,
            eternalgg: response.data.data.eternalgg,
            discord: response.data.data.discord,
            faceit: response.data.data.faceit,
            admin: response.data.data.admin,
            tiktok: response.data.data.tiktok,
            profile: response.data.data.profile,
            userGroup: response.data.data.userGroup,
            userScopes: response.data.data.scope,
            hasUsername: response.data.data.profile?.username ? true : false,
            hasClips: response.data.data.clips ? true : false,
            rank: response.data.data.rank,
            google: response.data.data.google,
            avatar: response.data.data.avatar,
          };

          //get a new token if we see outdated usergroup
          if (payload.userGroup !== response.data.data.userGroup.type) {
            await getNewTokenAndRefresh(setPayload);
          }

          // refreshPayload will update the app's state and trigger a redirect,
          // so no need to update login's local state here
          window.rudderanalytics.identify(payload._id, {
            email: payload.sub,
            steamId: payload.SteamID,
            discord: payload.discord,
            userGroup: payloadData?.userGroup?.type,
            userScopes: payloadData?.userScopes
              ? payloadData.userScopes
              : "N/A",
          });
          localStorage.setItem("payload", JSON.stringify(payloadData));

          setPayload({
            loggedin: true,
            data: payloadData,
          });
          return res();
        } else {
          return logout(payload?.data?.id);
        }
      } catch {
        return logout(payload?.data?.id);
      }
    }
  });
}

async function getNewTokenAndRefresh(setPayload) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [headerEncoded, payloadEncoded] = token.split(".");
  // eslint-disable-next-line
  const [header, payload] = [headerEncoded, payloadEncoded].map(
    decodeTokenComponent,
  );
  return new Promise(async (res, rej) => {
    if (payload) {
      try {
        const PostOptions = {
          method: "POST",
          url: `${BASE_URL}/users/auth/refresh?token=${payload.refreshToken}`,
        };
        let token;
        Axios(PostOptions)
          .then(async (data) => {
            if (data.status !== 201) {
              logout(payload?.data?.id);
            }
            token = data.data.token;
            localStorage.setItem("token", token);

            try {
              return res();
            } catch (e) {
              console.log(e);
              return res(logout(payload?.data?.id));
            }
          })
          .catch((e) => {
            console.log(e);
            localStorage.clear();
            return res(logout(payload?.data?.id));
          });
      } catch {
        localStorage.setItem("payload", JSON.stringify(payload));
        return res(
          setPayload({
            loggedin: true,
            data: payload,
          }),
        );
      }
    }
  });
}
async function getProfile(setPayload, setLoading) {
  try {
    await refreshPayload(setPayload);
    setLoading(false);
  } catch (e) {
    console.log(e);
  }
}

function decodeTokenComponent(value) {
  const buff = new Buffer.from(value, "base64");
  const text = buff.toString("ascii");
  return JSON.parse(text);
}

function logout(id) {
  localStorage.clear();
  window.rudderanalytics.track("Logout", {
    logoutReason: "Expired Token",
    id: id,
  });
  return (window.location.href = `/login`);
}

export function getWelcomeQuery() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get("welcome");
  return token;
}

export const clipHasStudio = (clip) =>
  clip?.personalizations?.cards?.length > 0 || clip?.studio?.length > 0;

const GAME_MAP = {
  "CS:GO": 730,
  "Dota 2": 570,
  "League of Legends": 101,
  Fortnite: 407,
};

export const trackClipDownload = (clip, aspectRatio, extra) => {
  window.rudderanalytics.track(
    `${aspectRatio ? "Mobile Clip Download" : "Clip Download"}`,
    {
      game: clip.game,
      tags: clip.clipProperties,
      length: clip.clipLength,
      shareId: clip.shareId,
      ...extra,
    },
  );

  postApiSegment({
    event: "Creator - Clip Downloaded",
    userId: clip.user?._id,
    properties: {
      clipName: clip.clipTitle,
      game: clip.game,
      tags: clip.clipProperties,
      length: clip.clipLength,
      total_reactions: clip.totalReactions,
      total_views: clip.views,
      shareId: clip.shareId,
      aspectRatio,
      ...extra,
    },
  });
};

/**
 * Creates and returns an axios instance enriched with the localstorage token
 * if it exists. Token can be overriden by passing an optional authToken
 *
 * @param {string | object} config - axios request config or a simple string for the url
 * @param {string} authToken - optional token
 * @returns Axios instance
 *
 */
export const Api = async (config, authToken) => {
  const _config = typeof config === "string" ? { url: config } : config;
  const { noToken, ...fetchConfig } = _config;
  const token = !noToken
    ? authToken || localStorage.getItem("token")
    : undefined;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        ...fetchConfig,
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          ...(token && {
            authorization: `Bearer ${token}`,
          }),
          ...fetchConfig.headers,
        },
      });
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};
