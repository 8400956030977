import React, { forwardRef } from "react";
import styled from "styled-components";

import { Input } from "@rebass/forms/styled-components";
import theme from "../../../theme/themeThree";

let StyledInlineInput = styled(Input)((props: any) => {
  return props.error
    ? {
        borderColor: theme.colors.error,
        ":hover": {
          borderColor: theme.colors.error,
        },
        ":active": {
          borderColor: theme.colors.error,
        },
        ":focus": {
          borderColor: theme.colors.error,
        },
      }
    : {};
});

const InlineInputText = forwardRef((props: any, ref) => {
  let size = props.size ? props.size : "default";
  return (
    <>
      <StyledInlineInput
        sx={{
          ...theme.inlineInput,
          ...theme.variants.input[size as keyof typeof theme.variants.input],
        }}
        ref={ref}
        type={props.type ? props.type : "text"}
        {...props}
      />
    </>
  );
});

const StyledInputText = styled(InlineInputText)`
  max-width: 100%;
  width: 1265px;
  caret-color: ${(props: any) => {
    return props.titleError
      ? props.theme.colors.alert
      : props.theme.colors.envy;
  }};
  color: ${(props: any) => {
    return props.titleError ? props.theme.colors.alert : "";
  }};
  &:focus {
    color: ${(props: any) => {
      return props.titleError ? props.theme.colors.alert : "#CBD2D5";
    }};
  }
`;

export default function InlineEdit({
  value,
  onChange,
  onBlur,
  titleError,
  placeholder,
}: any) {
  const onKeyDown = (event: any) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  return (
    <StyledInputText
      size="title"
      type="text"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      titleError={titleError}
    />
  );
}
