import React from "react";
import styled from "styled-components";
import { Body } from "../../../Text";
import { Flex } from "rebass/styled-components";
import { Link } from "../../../Button";
import { useRecoilState } from "recoil";
import { MontageSession } from "../../../../State";
import { ReactComponent as Mythic } from "../../../../Assets/Tiers/Badges/mythic.svg";
import { ReactComponent as Legendary } from "../../../../Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../../Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../../Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../../Assets/Tiers/Badges/standard.svg";
import { EView } from "../../../../Views/CreateMontage";
import { TIER_USERGROUP_MAP } from "../../../../Utilities/Maps";

export const TieredCard = ({ rarity }: any) => {
  const [montageSession] = useRecoilState(MontageSession);
  const TIER_BADGE_MAP: any = {
    500: <StyledMythic />,
    400: <StyledLegendary />,
    300: <StyledRare />,
    200: <StyledPremium />,
    100: <StyledStandard />,
  };
  const width_map: any = {
    500: 175,
    400: 175,
    300: 125,
    200: 100,
    100: 100,
  };
  // const GoToUpgrade = () => {
  //   try {
  //     window.rudderanalytics.track(`${TIER_USERGROUP_MAP[rarity]} Tooltip Click`, {
  //       view: EView[montageSession.view],
  //     });
  //   } catch {}
  //   window.open("https://allstar.gg/upgrade", "_blank")?.focus();
  // };
  return (
    <>
      {" "}
      <Flex
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <> {TIER_BADGE_MAP[rarity]}</>
        <StyledBody width={width_map[rarity]} height={20}>
          Requires{" "}
          <Link
            variant="link.title"
            onClick={() => {
              try {
                window.rudderanalytics.track("Pro Tooltip Click", {
                  view: EView[montageSession.view],
                });
              } catch {}

              window.open("https://allstar.gg/upgrade", "_blank")?.focus();
            }}
          >
            {TIER_USERGROUP_MAP[rarity]}
          </Link>{" "}
        </StyledBody>
      </Flex>
    </>
  );
};

const StyledMythic = styled(Mythic)`
  height: 32px;
  width: 32px;
`;

const StyledLegendary = styled(Legendary)`
  height: 32px;
  width: 32px;
`;

const StyledRare = styled(Rare)`
  height: 32px;
  width: 32px;
`;

const StyledPremium = styled(Premium)`
  height: 32px;
  width: 32px;
`;

const StyledStandard = styled(Standard)`
  height: 32px;
  width: 32px;
`;
// const ProBadge = styled(Pro)`
//   top: 0%;
//   position: absolute;
//   z-index: 99;
//   width: 47px;
//   height: 70px;
// `;

// const ClickBox = styled(Box)`
//   text-align: center;
//   z-index: 10;
//   left: 9px;
//   position: absolute;
//   &:hover {
//     cursor: pointer;
//   }
// `;
// const Badge = styled(Pro)<any>`
//   text-align: center;
//   z-index: 10;
//   top: 0%;
//   right: ${(props: any) => {
//     return 3 + 10 * props.index;
//   }}%;

//   &:hover {
//     cursor: pointer;
//   }
// `;

const StyledBody = styled(Body)<any>`
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
  padding-left: 5px;
`;
