import React from "react";
import { MenuLink } from "../../atoms/menu/link";
import { MenuDivider } from "../../atoms/menu/divider";
import { Box, Flex, Image } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import { LanguagesDropdown } from "./languagesDropDown";
import { UserIcon, SettingsIcon } from "../../atoms/icons";
import { SubscriptionsIcon } from "../../../MVVM/Components/Icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../MVVM/Utilities/Maps";
import { HiSupport } from "react-icons/hi";
import { MenuButton } from "../../../MVVM/Components/Button/Menu";
import { AllstarModalState } from "../../../MVVM/State/modals";
import { ModalType } from "../../../MVVM/@types";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

export function NavListDropdown(props) {
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const { t } = useTranslation("translation", {
    keyPrefix: "nav.listDropdown",
  });

  return (
    <DropdownContainer className={props.className}>
      <MenuButton
        onClick={() => {
          window.rudderanalytics.track("Signature - Open Modal", {
            where: "Nav Menu",
          });
          setAllstarModalState({
            isOpen: ModalType.SignatureSelector,
            functions: {},
            data: {},
          });
        }}
      >
        <Image
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/sig.svg`}
          height={22}
          width={22}
          mr={4}
        />
        Manage Signature
      </MenuButton>
      <MenuLink
        isActive={(location) => {
          if (!location) {
            return false;
          }
          const urlParams = new URLSearchParams(window.location.search);
          const viewingProfile = urlParams.get("user");
          return viewingProfile === props.loggedInUser._id;
        }}
        to={`/profile?user=${props.loggedInUser._id}`}
        name="profile-dropdown-option"
      >
        <UserIcon size={22} mr={4} />
        {t("profile")}
      </MenuLink>
      <MenuLink to="/accountsettings" name="profile-dropdown-option">
        <SettingsIcon size={22} mr={4} />
        {t("accountSettings")}
      </MenuLink>
      <MenuLink to="/support" name="profile-dropdown-option">
        <Box mr={4}>
          {" "}
          <HiSupport size={22} />
        </Box>
        {t("support")}
      </MenuLink>
      <MenuDivider />
      <MenuLink to={URLS.UPGRADE} name="profile-dropdown-option">
        <SubscriptionsIcon size={22} mr={4} />
        {t("upgrade")}
      </MenuLink>
      <MenuDivider />
      <Languages
        mx={3}
        variant="link.railFooter"
        alignContent={"center"}
        justifyContent="center"
      >
        <LanguagesDropdown />
      </Languages>
      <MenuDivider />
      <MenuLink
        href="#"
        onClick={props.logoutOnClick}
        name="profile-dropdown-option"
      >
        {t("signOut")}
      </MenuLink>
    </DropdownContainer>
  );
}

const Languages = styled(Box)`
  z-index: 2000;
  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;

const DropdownContainer = styled(Flex)`
  font-size: ${(props) => props.theme.text.body.fontSize};
  white-space: nowrap;
  flex-direction: column;
`;
