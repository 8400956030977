import React from "react";
import { Headline } from "../../text";

export function Title(props) {
  return (
    <Headline mb={20} {...props}>
      {props.children}
    </Headline>
  );
}
