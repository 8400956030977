import React from "react";
import { ButtonProps, Flex } from "rebass/styled-components";
import styled from "styled-components";

export const MenuButton = (props: ButtonProps) => {
  return <StyledFlex {...props}>{props.children}</StyledFlex>;
};

const StyledFlex = styled(Flex)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition-property: color;
  transition-property: color;
  color: inherit;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  color: #f7fcfd;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875em;
  font-weight: 500;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  background-color: transparent;

  &:hover {
    background-color: rgb(12, 19, 24, 0.4);
    color: #4dbd94;
    cursor: pointer;
  }
`;
