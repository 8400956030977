import React, { useEffect, useRef, useState } from "react";
import {
  DropdownContainer,
  DropdownOption,
  DropdownSelectorContainer,
} from "../styledComponents";
import { Box } from "rebass/styled-components";
import { IValues } from "./@types";

const getLabelColor = (
  noSelection: boolean,
  isOpen: boolean,
  disabled: boolean,
) => {
  if (noSelection) return "chalk";
  if (isOpen) return "envy";
  if (disabled) return "#8fa9af99";
  return "ice";
};

export const DropdownSelector = ({
  onChange,
  inline,
  disabled,
  labelText,
  values,
  noSelection,
}: {
  onChange?: (value: string) => void;
  saveOnSelect?: boolean;
  inline?: boolean;
  disabled?: boolean;
  labelText: string | JSX.Element;
  values: IValues[];
  noSelection: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };
  const dropdownRef = useRef(null as HTMLDivElement | null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <DropdownSelectorContainer
      ref={dropdownRef}
      onClick={(e) => {
        if (!disabled) {
          handleDropdownClick();
        }
      }}
      isOpen={isOpen}
      inline={inline}
      disabled={disabled}
    >
      <Box
        variant="text.labelMedium"
        color={getLabelColor(noSelection, isOpen, !!disabled)}
      >
        {labelText}
      </Box>

      {isOpen && (
        <Box>
          <DropdownContainer inline={inline}>
            {values.map(({ valueToDisplay, valueToSave }, index) => {
              if (index === 0) return null;
              return (
                <DropdownOption
                  key={index}
                  value={valueToSave}
                  onClick={() => {
                    if (onChange) onChange(valueToSave);
                    setIsOpen(false);
                  }}
                  color={"ice"}
                >
                  {valueToDisplay}
                </DropdownOption>
              );
            })}
          </DropdownContainer>
        </Box>
      )}
    </DropdownSelectorContainer>
  );
};
