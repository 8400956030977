import React from "react";

import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import Innovate from "./Innovate";
import Events from "./events";
import Values from "./values";
import Jobs from "./jobs";
import Perks from "./perks";
import { Page } from "../../MVVM/Components/Wrappers";

export default function Culture() {
  return (
    <Page flexDirection="column">
      <StaticContainer pt={[4, 4, 4, 4]}>
        <PaddedWrapper
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Innovate />
        </PaddedWrapper>
      </StaticContainer>
      <Values />
      <PaddedWrapper
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        data-testid="careers"
      >
        <Jobs />
      </PaddedWrapper>
      <PaddedWrapper
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Perks />
      </PaddedWrapper>
      <PaddedWrapper
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Events />
      </PaddedWrapper>
    </Page>
  );
}
