import React, { useEffect } from "react";
import { Body } from "../../components/text";
import { FloatingContent } from "../../components/molecules/floatingContent";
import { FloatingContainer } from "../../MVVM/Components/Wrappers";

export function ResetRequestSuccess() {
  useEffect(() => {
    window.rudderanalytics.page("Password Reset - Email Sent");
  });
  return (
    <FloatingContainer>
      <FloatingContent
        title="Reset email sent!"
        body="Check your email for the link to reset your password. This may take up
    to 5 minutes."
      >
        <Body>If you dont recieve an email within 10 minutes, try again.</Body>
      </FloatingContent>
    </FloatingContainer>
  );
}
