import { gql } from "@apollo/client";

export const STUDIO_QUERY = gql`
  query FacetQuery(
    $type: [String]
    $game: [String]
    $option2: Boolean
    $myCards: Boolean
    $theme: [String]
    $series: [String]
    $mechanic: [String]
    $behaviour: [String]
    $proOnly: Boolean
    $useApplicableCards: [String]
    $getUserCards: Boolean
  ) {
    facets(
      type: $type
      game: $game
      option2: $option2
      myCards: $myCards
      theme: $theme
      series: $series
      mechanic: $mechanic
      behaviour: $behaviour
      proOnly: $proOnly
      useApplicableCards: $useApplicableCards
      getUserCards: $getUserCards
    ) {
      userCards {
        _id
        isActive
        userActivated
        scopeUse
        scopeVis
        userGroups
        ui {
          title
          category
          slug
          useType
          rarity
          badge
          explanation
          flavorText
          hoverEffect
          hoverEffectSrc
          hoverEffectSrcBefore
          image
          game
          campaign {
            color
            campaignBlurbs {
              creatorCard {
                description
                linkText
                linkUrl
                image
                sponsorName
              }
            }
          }
        }
        facet {
          type
          mechanic
          game
          theme
          series
        }
      }
      total
      homepage
      homepageData {
        entries {
          _id
          isActive
          userActivated
          scopeUse
          scopeVis
          userGroups
          ui {
            title
            category
            slug
            useType
            rarity
            badge
            explanation
            flavorText
            hoverEffect
            hoverEffectSrc
            hoverEffectSrcBefore
            image
            game
            campaign {
              color
              campaignBlurbs {
                creatorCard {
                  description
                  linkText
                  linkUrl
                  image
                  sponsorName
                }
              }
            }
          }
          facet {
            type
            mechanic
            game
            theme
            series
          }
        }
        label
        identifier
      }
      cards {
        _id
        isActive
        userActivated
        scopeUse
        scopeVis
        userGroups
        ui {
          title
          category
          slug
          useType
          rarity
          badge
          explanation
          flavorText
          hoverEffect
          hoverEffectSrc
          hoverEffectSrcBefore
          image
          game
          campaign {
            color
            campaignBlurbs {
              creatorCard {
                description
                linkText
                linkUrl
                image
                sponsorName
              }
            }
          }
        }
        facet {
          type
          mechanic
          game
          theme
          series
        }
      }
      facets {
        entries {
          label
          count
        }
        identifier
        label
        showHeader
        open
        facetType
      }
    }
  }
`;

export const MUTATE_TOGGLE_CREATORCARD = gql`
  mutation MutateCreatorCard($cardIds: [String!]!, $isActive: Boolean!) {
    toggleCreatorCard(cardIds: $cardIds, isActive: $isActive) {
      _id
    }
  }
`;

export const MUSIC_FACETS_QUERY = gql`
  query Query(
    $type: [String]
    $ignoreStudioVisibilityConstraints: Boolean
    $option2: Boolean
  ) {
    facets(
      type: $type
      ignoreStudioVisibilityConstraints: $ignoreStudioVisibilityConstraints
      option2: $option2
    ) {
      total
      cards {
        _id
        ui {
          title
        }
      }
    }
  }
`;
