import React, { useState } from "react";
import { AndroidView } from "react-device-detect";
import { Flex } from "rebass";
import { useLocation } from "react-router-dom";

import {
  Heading,
  StyledAMark,
  StyledButton,
  StyledFlex,
  StyledGlobe,
  StyledIconContainer,
  StyledText,
} from "./styledComponents";
import { Divider } from "../Divider";
import { generateIntent, isValidAppMappedPage } from "./utils";

const AndroidInterstitial = () => {
  const [dismissed, setDismissed] = useState(false);
  const location = useLocation();

  if (dismissed || !isValidAppMappedPage(location)) return <></>;

  const handleDismiss = () => setDismissed(true);
  const handleDeeplink = () => {
    const intent = generateIntent(location);
    window.open(intent, "_blank");
  };

  return (
    <AndroidView>
      <StyledFlex data-testid="android-interstitial" flexDirection={"column"}>
        <Heading>Continue watching Allstar with...</Heading>
        <Divider style={{ marginTop: "0px", marginBottom: "20px" }} />
        <Flex style={{ gap: "20px" }} flexDirection={"column"}>
          <Flex
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Flex style={{ alignItems: "center", gap: "12px" }}>
              <StyledIconContainer>
                <StyledAMark />
              </StyledIconContainer>
              <StyledText variant="">Allstar App</StyledText>
            </Flex>
            <StyledButton size="tiny" onClick={() => handleDeeplink()}>
              Open
            </StyledButton>
          </Flex>
          <Flex
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Flex style={{ alignItems: "center", gap: "12px" }}>
              <StyledIconContainer>
                <StyledGlobe />
              </StyledIconContainer>
              <StyledText>Browser</StyledText>
            </Flex>
            <StyledButton
              classification="secondary"
              size="tiny"
              onClick={() => handleDismiss()}
            >
              Continue
            </StyledButton>
          </Flex>
        </Flex>
      </StyledFlex>
    </AndroidView>
  );
};

export { AndroidInterstitial };
