import React, { useState } from "react";
import { Flex } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { Subheader } from "../../../../Text";
import { useFetch } from "../../../../../../hooks";
import { remixModal } from "../../../../../../globalStorage/remix";
import { ReactComponent as Loadout } from "../../../../../Assets/Studio/Loadout.svg";
import { AllstarModalState } from "../../../../../State/modals";
import { useUser } from "../../../../../Hooks/useUser";
import { ModalType } from "../../../../../@types";
import {
  DisableableButton,
  StyledContainer,
  StyledFootnote,
} from "../../styledComponents";

const API_URL = process.env.REACT_APP_API_URL;

export default function DefaultCardsRemixClip({
  clip,
  disabled,
  setRemixQueued,
  checked,
  ...props
}: any) {
  const [disabledButton, setDisabledButton] = useState(disabled);
  const { fetch, query }: any = useFetch(undefined, true);
  const [, setModalOpen] = useRecoilState(remixModal);
  const { allstarUser } = useUser();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  if (query.status === "success") {
    setRemixQueued(true);
    setModalOpen(!checked);
  }

  const handleClick = async () => {
    if (disabledButton) return;

    if (!allstarUser?.loggedIn) {
      return setAllstarModalState({
        ...allstarModalState,
        isOpen: ModalType.Signup,
        data: {
          action: "signup",
        },
      });
    }

    window.rudderanalytics.track("remix default cards click", {});

    fetch({
      method: "POST",
      url: `${API_URL}/items/clip/remix?queue=true`,
      data: {
        defaultCards: true,
        _id: clip._id,
      },
    });

    /* This is a little janky but in the event of a user clearing their cards
     * in another tab with the modal open, they can't just spam 404s*/

    if (query?.error?.version === 2)
      setAllstarModalState({
        ...allstarModalState,
        isOpen: ModalType.Paywall,
        data: {
          type: "remix",
        },
        functions: {},
      });

    if (query.status === "error") setDisabledButton(true);
  };

  return (
    <>
      <StyledContainer
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
        >
          <DisableableButton
            justifyContent="center"
            textAlign="center"
            classification="actionIcons"
            alignItems="center"
            disabled={disabledButton ?? query.status === "loading"}
            onClick={handleClick}
            {...props}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Loadout />
            </Flex>
            <Subheader mt={3}>Use my loadout</Subheader>
            <StyledFootnote>Apply your preset effects & music</StyledFootnote>
          </DisableableButton>
        </Flex>
      </StyledContainer>
    </>
  );
}
