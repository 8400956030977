import React, { useState, useEffect, useRef } from "react";
import { useFetch } from "../../../../../hooks";
import { Modal } from "../../../Wrappers";
import { Button } from "../../../Button";
import { Title3 } from "../../../Text";
import { FloatingContainer } from "../../../Wrappers";
import { useRecoilState } from "recoil";
import { YoutubeShareModal } from "../../../../State";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { trackClipSocialShare } from "../../../../Analytics";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
type TYoutubeUploadProps = {
  data: {
    title: string;
    description: string;
    privacy: string;
  };
  queue: boolean;
};

export function AlreadyPublished({ clip, aspectRatio }: any) {
  const { fetch, query }: any = useFetch(undefined, true);
  const [isModalOpen, setModalOpen] = useState(
    Boolean(getQueryParam("youtube"))
  );
  const [_, setYoutubeModal] = useRecoilState(YoutubeShareModal);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const formData = useRef(allstarModalState.data);
  const aspectRatioRef = useRef(aspectRatio);

  useEffect(() => {
    if (query.statusCode === 409) {
      setAllstarModalState({
        data: {},
        functions: {},
        isOpen: ModalType.DuplicateUploadYoutube,
      });
    }
  }, [query.statusCode]);

  if (!clip) return null;

  const onSubmit: any = ({ data, queue }: TYoutubeUploadProps) => {
    formData.current = data;

    trackClipSocialShare({
      platform: "YouTube",
      handler: fetch,
      clip,
      queue,
      data,
      aspectRatio: aspectRatioRef.current,
    });
  };

  if (query.status === "error") {
    if (isModalOpen) {
      setModalOpen(false);
      setYoutubeModal({ open: true });
    }
  }

  return (
    <>
      <Modal
        isModalOpen={
          allstarModalState.isOpen === ModalType.DuplicateUploadYoutube
        }
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.DuplicateUploadYoutube,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Title3 mb={5}>Clip already published.</Title3>
          {query.error?.url && (
            <p>
              <b>Upload Url:</b>{" "}
              <a
                href={query.error.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {query.error.url}
              </a>
            </p>
          )}
          <Button
            onClick={() => {
              onSubmit({ data: formData.current, queue: true });
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
          >
            Publish again
          </Button>
          <Button
            classification="subtle"
            size="small"
            onClick={() => {
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </FloatingContainer>
      </Modal>
    </>
  );
}
