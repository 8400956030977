import React, { useEffect, memo } from "react";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import Homepage from "./content";
import styled from "styled-components";
import Clips from "./sections/clips";
import Fps from "./sections/fps";
import Steps from "./sections/steps";
import Future from "./sections/future";

import { useSpring, animated } from "react-spring";

const BackgroundPanel = styled(animated.div)`
  position: fixed;
  top: 0;
  z-index: 1;
  background: ${(props) => props.theme.colors.midnightAlpha90};
  height: 100vh;
  width: 100%;
  transform: translateZ(0);
`;

const PanelContainer = styled.div`
  overflow: hidden;
  transform: translateZ(0);
  position: relative;
  z-index: 2;
`;

const Home = memo(({ showModal }) => {
  let [per, setPer] = React.useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setPer(
        Math.min(1, e.target.documentElement.scrollTop / window.innerHeight)
      );
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  let panelProps = useSpring({ opacity: per });

  return (
    <>
      <BackgroundPanel style={panelProps} />
      <PanelContainer>
        <FullScreenWrapper>
          <Homepage showModal={showModal} />
        </FullScreenWrapper>

        <Clips />

        <Fps />

        <Steps />

        <Future />
      </PanelContainer>
    </>
  );
});

export default Home;
