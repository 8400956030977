import Axios from "axios";

export async function postApiSegment(data) {
  const opt = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/analytics/segment`,
    data: data,
  };
  try {
    return Axios(opt);
  } catch (e) {
    return e.response;
  }
}
