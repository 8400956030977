import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({ key: "autoplayState" });

export const cotdPlayer = atom({
  key: "cotdPlayer", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

export const feedPlayer = atom({
  key: "feedPlayer", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const muteState = atom({
  key: "muteState", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
