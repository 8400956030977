import { gql } from "@apollo/client";

export const LEADERBOARDS = gql`
  query Leaderboards($campaign: String) {
    leaderboards(campaign: $campaign) {
      campaign {
        images {
          verifiedBadge
          carouselBackground
        }
        campaignBlurbs {
          dynamicProgressText {
            progress
            text
            linkText
            linkUrl
            image
          }
        }
      }
      users {
        currentScore
        campaign
        verified
        progress
        user {
          avatar
          rank
          discord {
            avatar
            id
          }
          _id
        }
        userProfile {
          username
        }
      }
    }
  }
`;

export const JOIN_CAMPAIGN = gql`
  mutation JoinCampaign($campaign: String!) {
    joinCampaign(campaign: $campaign)
  }
`;

export const IN_CAMPAIGN = gql`
  query InCampaign($campaign: String) {
    inCampaign(campaign: $campaign) {
      exists
      progress
    }
  }
`;

export const TOP_CLIPS = gql`
  query MyTopClips($campaign: String) {
    myTopClips(campaign: $campaign) {
      _id
      clipTitle
      clipImageThumb
      shareId
      user {
        _id
      }
      clipProperties {
        _id
        key
        value
        displayType
      }
    }
  }
`;

export const CAMPAIGN_POSITION = gql`
  query InCampaignPosition($campaign: String) {
    inCampaignPosition(campaign: $campaign) {
      position
      checklist
      user {
        userProfile {
          username
        }
        currentScore
        progress
        verified
      }
    }
  }
`;

export const CAMPAIGN_CREATOR_CARD = gql`
  query CampaignCreatorCards($campaign: String) {
    campaignCreatorCards(campaign: $campaign) {
      _id
      isActive
      scopeUse
      scopeVis
      userGroups
      ui {
        title
        category
        slug
        useType
        rarity
        badge
        explanation
        flavorText
        hoverEffect
        hoverEffectSrc
        hoverEffectSrcBefore
        image
        game
        campaign {
          color
          campaignBlurbs {
            creatorCard {
              description
              linkText
              linkUrl
              image
              sponsorName
            }
          }
        }
      }
      facet {
        type
        mechanic
        game
      }
    }
  }
`;

export const BATCH_REMIX = gql`
  mutation BatchRemix($clips: [String]!) {
    batchRemix(clips: $clips)
  }
`;

export const MY_CREATOR_CARDS = gql`
  query MyCreatorCards {
    myCreatorCards {
      _id
    }
  }
`;

const COMPETITION_FIELDS = gql`
  fragment CompetitionFields on Competitions {
    campaign
    slug
    headline
    tagline
    endDate
    campaignVisible
    campaignJoinStart
    campaignJoinEnd
    campaignStart
    campaignEnd
    remix
    remixMaxCount
    creatorCards
    games
    checklist {
      active
      headline
      isSequential
      steps {
        images {
          completionBadge
          promo
        }
        text
        subHeadline
        scoreValue
        requiresWebhook
        linkTo {
          location
          internal
          buttonAction
        }
        headline
        buttonCopy
        allstarCreditsReward
        _id
      }
      subHeadline
    }
    dynamicPlayPageHero {
      progress
      headline
      tagline
      buttonCopy
      buttonLink
      mainButtonCopy
      mainButtonLink
    }
    images {
      partner
      campaign
      logo
      promo
      carouselBackground
    }
    modal {
      headline
      tagline
      link
      image
    }
    color
    prizeNote
    prizes {
      order
      heading
      body
      image
    }
    how {
      headline
      body
    }
  }
`;

export const COMPETITIONS = gql`
  ${COMPETITION_FIELDS}
  query Competitions($campaign: String) {
    competitions(campaign: $campaign) {
      ...CompetitionFields
    }
  }
`;

export const MY_COMPETITION = gql`
  ${COMPETITION_FIELDS}
  query Competitions($campaign: String) {
    inCampaignPosition(campaign: $campaign) {
      checklist
    }
    competitions(campaign: $campaign) {
      ...CompetitionFields
    }
  }
`;

export const COMPETITION_CLIPS = gql`
  query CreatorCardClips($limit: Int, $cardIds: [String]) {
    creatorCardClips(limit: $limit, cardIds: $cardIds) {
      _id
      clipImageThumb
      clipSnapshot
      clipLink
      clipPreviewPath
      clipTitle
      createdDate
      shareId
      views
      cf {
        hls
      }
      user {
        _id
        avatar
        username
      }
    }
  }
`;
