import { gql } from "@apollo/client";

export const FOLLOWERS_QUERY = gql`
  query Followers($clips: Boolean!, $profiles: Boolean!, $userId: String!) {
    myFollowing(clips: $clips, profiles: $profiles, userId: $userId) {
      profiles {
        followers {
          username
          rank
          userGroup {
            type
          }
          avatar
          _id
          discord {
            avatar
            id
            username
          }
        }
        following {
          username
          rank
          userGroup {
            type
          }
          avatar
          _id
          discord {
            avatar
            id
            username
          }
        }
      }
    }
  }
`;

export const MY_FOLLOWERS_QUERY = gql`
  query MyFollowers($clips: Boolean!, $profiles: Boolean!) {
    myFollowing(clips: $clips, profiles: $profiles) {
      profiles {
        followers {
          username
          rank
          avatar
          _id
          userGroup {
            type
          }
          discord {
            avatar
            id
            username
          }
        }
        following {
          username
          rank
          avatar
          _id
          userGroup {
            type
          }
          discord {
            avatar
            id
            username
          }
        }
      }
    }
  }
`;

export const FOLLOW_UNFOLLOW_MUTATION = gql`
  mutation MutateFollowers($userToFollow: String!, $follow: Boolean!) {
    followUnfollow(userToFollow: $userToFollow, follow: $follow)
  }
`;
