import React from "react";
import { Subheader } from "../../text";

const Label = (props) => {
  return <Subheader mb={props.mb}>{props.children}</Subheader>;
};

Label.defaultProps = {
  mb: 3,
};

export { Label };
