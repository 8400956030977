import React, { useEffect, useState } from "react";
import { Image, Flex } from "rebass/styled-components";

import { ErrorViewModel } from "./viewModel";

import { Title1 } from "../../Components/Text";
import { PaddedWrapper, StaticContainer } from "../../Components/Wrappers";
import { Button } from "../../Components/Button";

// import CardGrid from "../../../components/organisms/clips/cardGrid";
import { PageHeader } from "../../../components/molecules/pageHeader";
import DrunkLogo from "../../../assets/images/misc/logo-404a.png";

export default function Error404() {
  const { loading, data } = ErrorViewModel();
  const [, setLatestClips] = useState(null); //eslint-disable-line
  useEffect(() => {
    window.rudderanalytics.page("404", window.location.pathname);
  }, []);

  useEffect(() => {
    if (data) setLatestClips(data);
  }, [loading, data]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "780px",
        height: "calc(100vh - 427px)",
      }}
    >
      <StaticContainer>
        <PageHeader
          z1
          title="Oops! Page not found."
          subtitle="We can't seem to scope the page you're looking for."
          image={<Image src={DrunkLogo} mb={7} maxWidth="180px" />}
          pt={8}
        />
        <PaddedWrapper py={8}>
          <Flex
            width="100%"
            margin="auto"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            flexDirection="column"
            maxWidth="320px"
          >
            <Title1 my={8} pb={7} textAlign="center">
              Claim your profile name now to help us hunt it.
            </Title1>
            <Button
              size="small"
              mb={7}
              to="/signup"
              classification="roundedSecondary"
              onClick={() => {
                window.rudderanalytics.track(
                  "404 Page - Sign Up button click",
                  {},
                );
              }}
            >
              Sign Up
            </Button>
          </Flex>
        </PaddedWrapper>
      </StaticContainer>
    </div>
  );
}
