import React from "react";
import styled from "styled-components";

import { Modal } from "../../Modal";
import { AllstarModalState } from "../../../State/modals";
import { FloatingContainer } from "../../Wrappers";
import { ModalType } from "../../../@types";
import { useRecoilState } from "recoil";
import { MontageCredits } from "../../MontageCreditsDropdown";

const CreditsModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const closeModal = () =>
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.None,
    });

  return (
    <Modal
      isModalOpen={allstarModalState?.isOpen === ModalType.Credits}
      setModalOpen={() => null}
      handleModalClose={closeModal}
      disableOutsideClose={false}
    >
      <StyledFloatingContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        maxWidth="1153px"
      >
        <CloseButton onClick={closeModal}>X</CloseButton>
        <MontageCredits externalHandler={closeModal} />
      </StyledFloatingContainer>
    </Modal>
  );
};

const StyledFloatingContainer = styled(FloatingContainer)`
  background-color: ${({ theme }) => theme.colors.ned};
  box-shadow: none;
  padding: 20px 12px 12px;
  position: relative;
  width: 100%;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export { CreditsModal };
