import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ApolloError, useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { AllstarUser, MontageSession } from "../../State";

import {
  CLIPS,
  FINAL_TOUCHES,
  LOADOUT_QUERY,
  MY_BALANCE,
  QUALIFY,
  SEQUENCE_QUERY,
  SINGLE_MONTAGE,
  STUDIO_CARDS,
} from "../../GraphQL/montage";
import { EView } from "./index";
import { Montage } from "../../Model/Montage";
import {
  GAMES,
  TLoadoutsQuery,
  TMontageQualify,
  TSequence,
  TSequenceQuery,
} from "./@types";

import { EClipsViews } from "../../@types";

export const MontageViewModel = () => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const [allstarUser] = useRecoilState(AllstarUser);
  const [error, setError] = useState<ApolloError | null>(null);
  const [isFreeUser] = useState(allstarUser?.user?.userGroup?.type === "FREE");

  // const isFreeUser = true;
  const montageModel = new Montage();
  const history = useHistory();

  useEffect(() => {
    if (montageSession.view === EView.Music) {
      getLoadouts({ variables: { game: montageSession.game } });
      getMusic();
    }
    if (montageSession.view === EView.Loadout) {
      getFinalTouches();
    }
  }, [montageSession.view]); //eslint-disable-line

  useEffect(() => {
    setMontageSession({ ...montageSession, isFreeUser: isFreeUser });
  }, []); //eslint-disable-line

  const checkSequenceQualify = async (sequence: TSequence): Promise<void> => {
    const qualify = await getQualify({
      variables: {
        sequence: sequence.qualify,
        user: allstarUser.user._id,
      },
    });
    const qualifyRes: TMontageQualify = qualify?.data?.montageQualify;

    const failedFromCredits = qualifyRes.failedFromCredits;

    if (failedFromCredits) {
      return setMontageSession({
        ...montageSession,
        failedFromCredits,
      });
    }

    // console.log({ qualifyRes });
    if (!qualifyRes?.passed) {
      return setMontageSession({
        ...montageSession,
        sequence: sequence,
        qualify: qualifyRes.passed,
        checkedQualify: true,
        userGroup: allstarUser?.user?.userGroup?.type,
        failedFromCredits,
      });
    }

    const initialClips = await getClips({
      variables: {
        sequence: sequence.qualify,
        user: allstarUser.user._id,
        filter: null,
        game: montageSession.game,
      },
    });

    //const isFreeUser = true;\
    const isRapidFireDefined = sequence.qualify.startsWith("rapid");

    const predefinedClips = isFreeUser
      ? initialClips.data.createMontageClips.slice(0, 5)
      : initialClips.data.createMontageClips.slice(0, 1);

    setMontageSession({
      ...montageSession,
      sequence: sequence,
      qualify: qualifyRes.passed,
      checkedQualify: true,
      isRapidFireDefined,
      view: !qualifyRes.passed
        ? EView.Sequence
        : isRapidFireDefined
        ? EView.Music
        : EView.Clips,
      clips: isRapidFireDefined
        ? initialClips.data.createMontageClips
        : predefinedClips,
      userGroup: allstarUser?.user?.userGroup?.type,
    });
  };

  const {
    data: sequenceData,
    error: sequenceError,
    loading: sequencesLoading,
  } = useQuery<TSequenceQuery>(SEQUENCE_QUERY);

  const { error: balanceError, loading: balanceLoading } = useQuery(
    MY_BALANCE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setMontageSession({
          ...montageSession,
          isFreeUser:
            !data.myLedgerBalance.enableProFeatures &&
            allstarUser?.user?.userGroup?.type === "FREE",
          enableProFeatures: data.myLedgerBalance.enableProFeatures,
          hasBalance: !data.myLedgerBalance.block,
        });
      },
    },
  );

  const [getMusic, { loading: musicLoading, data: musicData }] = useLazyQuery(
    STUDIO_CARDS,
    {
      variables: {
        type: ["Music"],
      },
    },
  );

  const [getLoadouts, { loading: loadoutsLoading, data: loadoutsData }] =
    useLazyQuery<TLoadoutsQuery>(LOADOUT_QUERY, {});

  const [
    getFinalTouches,
    { loading: finalTouchesLoading, data: finalTouchesData },
  ] = useLazyQuery(FINAL_TOUCHES, {
    variables: {
      type: ["Memes"],
      audioType: ["Audio FX"],
      mechanic: ["Camera Angle"],
      memeMechanic: ["Montage"],
    },
    onError(error) {
      setError(error);
    },
    onCompleted: () => {},
  });

  const [getQualify] = useLazyQuery(QUALIFY, {
    fetchPolicy: "cache-and-network",
  });

  const [getClips, { loading: clipsLoading, data: clipsData }] = useLazyQuery(
    CLIPS,
    {
      fetchPolicy: "network-only",
      onError(error) {
        setError(error);
      },
    },
  );

  const MIN_POLLING_TIME = 10;
  const MAX_POLLING_TIME = 90;
  const {
    loading: montageLoading,
    data: montageData,
    startPolling,
    stopPolling,
  } = useQuery(SINGLE_MONTAGE, {
    skip: !montageSession.montageId,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (montageSession.montagePolling.isPolling) {
        const secondsPolling =
          (Date.now() - montageSession.montagePolling.startedPolling) / 1000;

        if (secondsPolling > MIN_POLLING_TIME) {
          if (data?.getMontage?.originalClips) {
            stopPolling();
            history.push(`/profile?view=${EClipsViews.Montages}`);
          } else if (secondsPolling > MAX_POLLING_TIME) {
            stopPolling();
            setMontageSession({ ...montageSession, view: EView.Error });
          }
        }
      }
    },
    onError(error) {
      setError(error);
    },
    variables: { clipIdentifier: montageSession.montageId },
  });

  if (sequenceError) {
    setError(sequenceError);
  }
  if (balanceError) {
    setError(balanceError);
  }

  return {
    allstarUser,
    montageSession,
    setMontageSession,
    error,
    sequences: {
      sequenceData,
      sequencesLoading: sequencesLoading || balanceLoading,
      checkSequenceQualify,
    },
    clips: { clipsData, clipsLoading, getClips },
    music: { musicData, musicLoading },
    loadouts: { loadoutsData, loadoutsLoading },
    finalTouches: {
      finalTouchesData: [
        {
          description: "Remove Watermark",
          cards: [{ _id: "60a6d86a8c4fd555132afc97" }],
          pro: true,
          game: [GAMES.CSGO, GAMES.CounterStrike2],
        },
        {
          description: "Enable Multiple Camera Angles",
          cards: finalTouchesData?.cameraCards?.cards,
          pro: true,
          game: [GAMES.CSGO],
        },
        {
          description: "Enable Audio FX",
          cards: finalTouchesData?.audioEffects?.cards,
          pro: false,
          game: [GAMES.CSGO, GAMES.CounterStrike2],
        },
        {
          description: "Enable Gun Cam",
          cards: [{ _id: "60a6d86a8c4fd555132afcb4" }],
          pro: false,
          game: [GAMES.CSGO],
        },
        {
          description: "Hide Kill Feed",
          pro: false,
          cards: [{ _id: "622be8b4ff38f13a9d241322" }],
          game: [GAMES.CSGO],
        },
        {
          description: "Hide Crosshair",
          pro: false,
          cards: [{ _id: "622be70eff38f13a9d241312" }],
          game: [GAMES.CSGO, GAMES.CounterStrike2],
        },
        {
          description: "Mute Voice Comms",
          pro: false,
          cards: [{ _id: "61e0912ab4a61f0e6138e801" }],
          game: [GAMES.CSGO],
        },
        {
          description: "Enable Random Memes",
          pro: false,
          cards: finalTouchesData?.memes?.cards,
          game: [GAMES.CSGO, GAMES.CounterStrike2],
        },
      ],
      finalTouchesLoading,
    },
    montage: { startPolling, stopPolling, montageLoading, montageData },
    review: { montageModel },
  };
};
