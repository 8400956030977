import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

import { MontageCredits } from "../../../MontageCreditsDropdown";
import { Tooltip } from "../../../Tooltip";
import { ReactComponent as CreditsIcon } from "../../../../Assets/CreateMontage/montage.svg";
import { LedgerState } from "../../../../State/ledger";
import { useRecoilState } from "recoil";

const Credits = () => {
  const [ledgerState] = useRecoilState(LedgerState);

  return (
    <CurrencySection>
      <DropdownContainer
        type="click"
        position={{
          top: "40px",
          left: "-190px",
        }}
        tooltipContent={<MontageCredits />}
      >
        <CreditsClickable>
          <StyledCreditIcon />
          <TotalCreditsContainer>
            <Box>
              {ledgerState.localBalance > 100
                ? "\u221E"
                : ledgerState.localBalance}
            </Box>
          </TotalCreditsContainer>
        </CreditsClickable>
      </DropdownContainer>
    </CurrencySection>
  );
};

const StyledCreditIcon = styled(CreditsIcon)`
  height: 20px;
  width: 20px;
`;

const TotalCreditsContainer = styled(Flex)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.turboAlpha10};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.turbo};
  font-size: 14px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  min-width: 24px;
  padding: 5px;
`;

const CreditsClickable = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  justify-content: center;
  width: 70px;

  &:hover {
    background: ${(props) => props.theme.colors.darkerNed};
    cursor: pointer;
  }
`;

const DropdownContainer = styled(Tooltip)`
  &:hover {
    cursor: default;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding: 0;
  }
`;

const CurrencySection = styled(Flex)`
  background: ${({ theme }) => theme.colors.grey850};
  border-radius: 12px;
  padding: 6px;
`;

export { Credits };
