import React from "react";
import { FiPlus } from "react-icons/fi";

import { RoundedAddButton } from "./styledComponents";

export const AddButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <RoundedAddButton onClick={onClick}>
      <FiPlus />
    </RoundedAddButton>
  );
};
