import React from "react";
import { Button } from "../../button";
import { FaFacebookMessenger } from "react-icons/fa";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: linear-gradient(
    45deg,
    #008fff 0%,
    #4f5eff 33%,
    #bc36cd 66%,
    #ff6462 100%
  );

  &:hover {
    background: linear-gradient(
      45deg,
      #008fff 0%,
      #4f5eff 25%,
      #bc36cd 75%,
      #ff6462 100%
    );
  }
`;

const StyledMessenger = styled(FaFacebookMessenger)`
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

export default function MessengerSocialButton(props) {
  return (
    <StyledButton {...props}>
      <StyledMessenger size={22} mr={!!props.children ? 3 : null} />
      {props.children}
    </StyledButton>
  );
}
