import React from "react";
import { Flex } from "rebass/styled-components";
import { CloseButton, Button } from "../../../Button";

import theme from "../../../../../theme/themeThree";
import { Body, Subheader } from "../../../Text";
import Lottie from "lottie-react";
import loading from "../../../../../animations/loading.json";
import { Tooltip } from "../../../../../components/atoms/tooltip";
import styled from "styled-components";
import { Toggle } from "../../../Toggle";
import { ReactComponent as Lightning } from "../../../../Assets/lightning.svg";
let StyledText = styled.span<any>`
  @media (max-width: ${(props: any) => props.theme.breakpoints[1]}) {
    ${(props: any) => (props.hideOnMobile ? "display:none" : null)};
  }
`;

const lottieStyle = {
  height: 28,
};

export default function SaveToProfile({
  save,
  redirect,
  saving,
  clip,
  onMobileClick,
  titleError,
  isFinalizeMontage,
}: any) {
  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="flex-start">
        {isFinalizeMontage ? (
          <StyledFlex alignItems={"flex-end"}>
            {" "}
            <Lightning></Lightning>
            <Body>Rendering...</Body>
          </StyledFlex>
        ) : (
          <CloseButton
            size="small"
            classification="round"
            onClick={() => {
              redirect();
            }}
          ></CloseButton>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        />
        {clip.clip.game === 730 ? (
          <Flex alignItems="center" justifyContent="flex-end">
            {clip.canRequestMobile ? (
              <>
                <Subheader mr={3} color="chalk">
                  Add Mobile <StyledText hideOnMobile={true}> Clip</StyledText>
                </Subheader>{" "}
                <Toggle
                  offColor={theme.colors.midnight}
                  checked={clip.mobileSelected}
                  onChange={() => {
                    onMobileClick();
                  }}
                ></Toggle>
              </>
            ) : (
              <>
                <Subheader mr={3} color="chalkAlpha40">
                  Add Mobile Clip
                </Subheader>{" "}
                <Tooltip
                  position={{
                    top: "-40px",

                    padding: "6px",
                    right: "-27px",
                  }}
                  type="hover"
                  theme="helpTooltip"
                  align="center"
                  tooltipContent="Mobile clip exists"
                >
                  {" "}
                  <Toggle
                    checked={false}
                    disabled={true}
                    offColor={theme.colors.midnight}
                  ></Toggle>
                </Tooltip>
              </>
            )}
          </Flex>
        ) : (
          <></>
        )}

        {saving ? (
          <>
            <Flex justifyContent="center" ml={6}>
              <Lottie animationData={loading} style={lottieStyle} loop={true} />
            </Flex>
          </>
        ) : (
          <Button
            flex
            ml={[3, 4, 6, 6]}
            size="small"
            disabled={
              (!clip.userClipTitle && !clip.mobileSelected) || titleError
            }
            onClick={() => {
              save();
            }}
          >
            Save <StyledText hideOnMobile={true}> Edit</StyledText>
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
