import React, { useEffect } from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { PageHeader } from "../../components/molecules/pageHeader";
import { About as AboutContent } from "./content";

export default function About() {
  useEffect(() => {
    window.rudderanalytics.page("Static Content", window.location.pathname);
  });
  return (
    <>
      <PageHeader
        title="Company"
        subtitle="So what's this Allstar thing about anyway?"
      />
      <StaticContainer py={8}>
        <PaddedWrapper
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <AboutContent />
        </PaddedWrapper>
      </StaticContainer>
    </>
  );
}
