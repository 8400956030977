import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { Select as RebassSelect } from "@rebass/forms/styled-components";
import theme from "../../../theme/themeThree";

const StyledSelect = styled(RebassSelect)({
  appearance: "none",
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  "+ svg": {
    fill: (props) => props.theme.colors.navyAlpha90,
    marginLeft: (props) => `-${props.theme.space[8]}px`,
  },
  color: (props) =>
    props.showingPlaceholder ? props.theme.colors.chalk : null,
  ...(props) => {
    return props.error
      ? {
          borderColor: theme.colors.error,
          ":hover": {
            borderColor: theme.colors.error,
          },
          ":active": {
            borderColor: theme.colors.error,
          },
          ":focus": {
            borderColor: theme.colors.error,
          },
        }
      : {};
  },
});

const FakeLabel = styled.div`
  position: absolute;
  color: ${(props) =>
    props.theme.colors[props.theme.input["::placeholder"].color]};
  opacity: 0;
  visibility: hidden;
  line-height: ${(props) => props.theme.variants.input.default.lineHeight};
  transition:
    font-size ${(props) => props.theme.transitionDuration},
    top ${(props) => props.theme.transitionDuration},
    line-height ${(props) => props.theme.transitionDuration},
    background-color ${(props) => props.theme.transitionDuration};
  top: 0;
  padding: 0 5px;
  left: ${(props) =>
    props.theme.space[props.theme.variants.input.default.px] - 5}px;

  .filled & {
    background: ${(props) => props.theme.input.bg};
    visibility: visible;
    font-size: 0.8em;
    opacity: 1;
    top: -0.8em;
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 3px;
    line-height: 1em;
    box-shadow: 0px 1px 4px
      ${(props) => props.theme.colors[props.theme.input["::boxshadow"].color]};
  }
`;

const LabeledSelect = forwardRef(
  ({ options, placeholder, input, ...props }, ref) => {
    let [hasSelected, setHasSelected] = useState(
      !!options.filter((o) => {
        return !!o.selected;
      }).length,
    );
    let [isFocused, setIsFocused] = useState(false);

    return (
      <div
        style={{ position: "relative" }}
        className={
          hasSelected || isFocused || props.defaultValue ? "filled" : ""
        }
      >
        <StyledSelect
          sx={{
            ...theme.input,
            ...theme.labeledInput,
            ...theme.variants.select.default,
          }}
          ref={ref}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          defaultValue=""
          onChange={(e) => {
            setHasSelected(!!e.currentTarget.value.length);
          }}
          showingPlaceholder={!hasSelected}
          {...props}
          {...input}
        >
          <option value="" disabled selected={!hasSelected}>
            {placeholder}
          </option>
          <>
            {props.topList ? (
              <>
                {props.topList.map((option, i) => {
                  return (
                    <option value={option.value} key={`option-${i}`}>
                      {option.label}
                    </option>
                  );
                })}
                <option value="--------------" disabled>
                  -----------------------
                </option>
              </>
            ) : null}
          </>
          {options.map((option, i) => {
            return (
              <option value={option.value} key={`option-${i}`}>
                {option.label}
              </option>
            );
          })}
        </StyledSelect>
        <FakeLabel>{placeholder}</FakeLabel>
      </div>
    );
  },
);

LabeledSelect.defaultProps = {
  selectCallback: () => {},
};

export { LabeledSelect };
