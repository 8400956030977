import React, { useEffect } from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { PageHeader } from "../../components/molecules/pageHeader";
import { Faq as FaqContent } from "./content";

export default function Faq() {
  useEffect(() => {
    window.rudderanalytics.page("FAQ", window.location.pathname);
  }, []);
  return (
    <>
      <PageHeader
        title="FAQ"
        subtitle="You've got questions, we've got answers"
      />
      <StaticContainer>
        <PaddedWrapper
          pb={10}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <FaqContent />
        </PaddedWrapper>
      </StaticContainer>
    </>
  );
}
