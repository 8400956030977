import React from "react";
import Switch from "react-switch";

const Toggle = (props) => {
  let offHandleColor = props.disabled ? "#4F6672" : "#374E5D";
  let offColor = props.offColor ? props.offColor : "#2C4354";
  return (
    <>
      <Switch
        offColor={offColor}
        onColor="#4DBD94"
        offHandleColor={offHandleColor}
        onHandleColor="#F7FCFD"
        onChange={props.onChange}
        checked={props.checked}
        width={props.size === "sm" ? 28 : undefined}
        height={props.size === "sm" ? 14 : undefined}
        uncheckedIcon={props.uncheckedIcon ?? false}
        checkedIcon={props.checkedIcon ?? false}
        boxShadow="1px 1px 5px rgba(12, 19, 24, .6)"
        activeBoxShadow="1px 1px 5px rgba(12, 19, 24, .1)"
        variation="switch"
        disabled={props.disabled}
      />
    </>
  );
};

export default Toggle;
