import React from "react";
import { Box } from "rebass/styled-components";

export default function Title2(props) {
  return (
    <Box variant="text.title2" {...props}>
      {props.children}
    </Box>
  );
}
