import React, { memo } from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Title1, Large3, Headline } from "../../../components/text";
import { PaddedWrapper } from "../../../components/wrappers/paddedWrapper";
import { Button } from "../../../components/atoms/form/button";

import { ReactComponent as SafeByDesign } from "../../../assets/images/home/fps/SafeByDesign.svg";
import { ReactComponent as MinimalBurden } from "../../../assets/images/home/fps/MinimalBurden.svg";
import { ReactComponent as PerfectQuality } from "../../../assets/images/home/fps/PerfectQuality.svg";
import { useUser } from "../../../MVVM/Hooks/useUser";

const BlockContainer = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.space[10]}px;
  grid-template-columns: auto auto auto;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    grid-template-columns: auto;
  }
`;

const Block = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 320px;
`;

const Panel = styled(Flex)`
  justify-content: center;
  flex-directon: column;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    text-align: center;
  }
`;

const IconBox = styled(Box)`
  height: 81px;
`;

const StyledHeadline = styled(Headline)`
  font-weight: normal;
  line-height: 1.35em;
`;

const BlockTitle = styled(Title1)`
  font-weight: 500;
`;

const Future = memo(
  () => {
    const { allstarUser } = useUser();

    return (
      <Panel key="future-panel" py={[9, 9, 10]}>
        <PaddedWrapper flexDirection="column" alignItems="center">
          <Large3 mb={9}>The future of content creation is Allstar</Large3>

          <BlockContainer>
            <Block>
              <IconBox as={MinimalBurden} mb={[5, 5, 7]} />
              <BlockTitle mb={[5, 5, 7]}>No Burden</BlockTitle>
              <StyledHeadline>
                Allstar seamlessly passes back the minimum necessary game data
                in order to reproduce your moments in the cloud.
              </StyledHeadline>
            </Block>

            <Block>
              <IconBox as={SafeByDesign} mb={[5, 5, 7]} />
              <BlockTitle mb={[5, 5, 7]}>Safe by design</BlockTitle>
              <StyledHeadline>
                Allstar is creating your clips off the network and in the cloud,
                your personal information never leaves your computer.
              </StyledHeadline>
            </Block>

            <Block>
              <IconBox as={PerfectQuality} mb={[5, 5, 7]} />
              <BlockTitle mb={[5, 5, 7]}>Perfect quality</BlockTitle>
              <StyledHeadline>
                Every clip made with Allstar is perfect. We fine tune every
                possible setting to ensure your clips look amazing.
              </StyledHeadline>
            </Block>
          </BlockContainer>

          <Flex pt={10} pb={8} flexDirection="column" alignItems="center">
            <Title1 mb={[6, 6, 8]}>Are you ready to star?</Title1>
            {allstarUser.loggedIn ? (
              <Button flex={true} to="/clips">
                Watch Clips Now
              </Button>
            ) : (
              <Button flex={true} to="/signup">
                Sign up now
              </Button>
            )}
          </Flex>
        </PaddedWrapper>
      </Panel>
    );
  },
  () => true,
);

export default Future;
