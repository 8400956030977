import { useState } from "react";
import { ApolloError, useQuery } from "@apollo/client";

import { BASE_REQUEST } from "../../GraphQL/clipFeed";
import { AllstarSiteData } from "../../State";
import { useRecoilValue } from "recoil";

export const ErrorViewModel = () => {
  const siteData = useRecoilValue(AllstarSiteData);

  const [error, setError] = useState<ApolloError | null>(null);

  const {
    loading,
    data,
    error: requestError,
  } = useQuery(BASE_REQUEST, {
    variables: {
      search: "createdDate",
      game: null,
      page: 1,
    },
  });

  if (requestError) setError(requestError);

  return {
    loading,
    data: data?.clips?.data,
    error,
    cotd: siteData?.cotd,
  };
};
