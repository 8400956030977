import { Title1 } from "../../text";
import styled from "styled-components";

const StaticContentTopicTitle = styled(Title1)`
  display: inline-block;
  border-bottom: 3px solid ${(props) => props.theme.colors.envy};
  padding-bottom: ${(props) => props.theme.space[3]}px;
  font-weight: 500;
`;

export default StaticContentTopicTitle;
