import React from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { StudioIcon } from "../../../../../Studio/Components/StudioIcons";
import { TLoadout } from "../../../../@types";
import { EStudioIconType } from "../../../../../Studio/@types";

export const SeriesRow = ({ cards }: TLoadout) => {
  let transitionSeries: string = "",
    specialFxTitle: string = "";

  const isRandom = cards.length === 0;
  const transitionWithSeries = cards
    .filter((card) => {
      return (
        card.facet.type.includes("Transitions") &&
        card.ui.explanation.includes("Series - ")
      );
    })
    .pop();
  const specialFxCard = cards
    .filter((card) => {
      return card.facet.type.includes("Special FX");
    })
    .pop();

  if (transitionWithSeries) {
    transitionSeries = transitionWithSeries.ui.explanation.split(" - ")[0];
  }
  if (specialFxCard) {
    specialFxTitle = specialFxCard.ui.title;
  }

  const getTransitionSeries = () => {
    if (isRandom) return "Random";
    if (transitionSeries) return transitionSeries;
    return "None";
  };

  const getSpecialFXTitle = () => {
    if (isRandom) return "Random";
    if (specialFxTitle) return specialFxTitle;
    return "None";
  };

  return (
    <>
      {" "}
      <SeriesWrapper>
        <StyledText>With:</StyledText>{" "}
        <StyledSeries>
          <Flex pb={3} pr={1}>
            {" "}
            <StudioIcon
              type={EStudioIconType.Transitions}
              size={14}
              styles={{ marginRight: "4px", marginTop: "3px" }}
            />
            <SeriesText>{getTransitionSeries()}</SeriesText>
          </Flex>

          <Flex pb={3}>
            {" "}
            <StudioIcon
              type={EStudioIconType.SpecialFX}
              size={14}
              styles={{ marginRight: "4px", marginTop: "3px" }}
            />
            <SeriesText>{getSpecialFXTitle()}</SeriesText>
          </Flex>
        </StyledSeries>
      </SeriesWrapper>
    </>
  );
};

const SeriesWrapper = styled.div`
  background: #203243;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-left-radius: 10px;
  align-items: start;
  border-bottom-right-radius: 10px;
  height: auto;
  padding-top: 10px;
`;

const StyledText = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #8fa9af;
`;
const StyledSeries = styled.div`
  flex-direction: row;
  display: flex;
`;

const SeriesText = styled.div`
  margin-right: 10px;
`;
