import React from "react";
import { NavLink as RoutedLink } from "react-router-dom";
import { Link as RebassLink } from "rebass/styled-components";
import theme from "../../theme/themeThree";

export function Link({ flex, flexShrink, children, ...otherProps }) {
  if (otherProps.href === undefined && otherProps.to === undefined) {
    console.warn(
      "Warning: Link `to` is missing, setting `#` instead:",
      children,
    );
    otherProps.to = "#";
  }

  return otherProps.href ? (
    <RebassLink {...otherProps} __css={theme.link}>
      {children}
    </RebassLink>
  ) : (
    <RebassLink __css={theme.link} as={RoutedLink} exact={true} {...otherProps}>
      {children}
    </RebassLink>
  );
}
