import React from "react";
import Sticky from "react-stickynode";
import { PaddedWrapper } from "./paddedWrapper";
import { Flex } from "rebass/styled-components";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

/** THIS IS NOT A WWRAPPER IF IT ACCESS STATE */
import { noticeDisplayed } from "../../../../globalStorage/noticeBanner";

export function StickySideRail({
  enabled = true,
  showOnMobile = true,
  children,
  display,
  offset = false,
}) {
  const bannerDisplayed = useRecoilValue(noticeDisplayed);

  let topOffset = bannerDisplayed ? 20 : 0; //82 is our original offset - height of the banner
  if (offset) topOffset = offset;
  return (
    <>
      {" "}
      <SideRail pb={[7, 7, 10]} showOnMobile={showOnMobile} display={display}>
        <Sticky
          top={topOffset}
          bottomBoundary={"#sitecontent"}
          enabled={enabled}
        >
          <SideRailContent>{children}</SideRailContent>
        </Sticky>
      </SideRail>
    </>
  );
}

export const Page = styled(PaddedWrapper)`
  width: 100%;
  padding-left: 40px;
  margin: auto;
  column-gap: ${(props) => props.theme.space[9]}px;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    column-gap: ${(props) => props.theme.space[6]}px;
    padding-left: ${(props) => (props.isDesktop ? 40 : 120)}px;
    padding-right: ${(props) => (props.isDesktop ? 40 : 20)}px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: column;
    padding-left: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const MainRail = styled(Flex)`
  flex-basis: 900px;
  flex-grow: 1;
  flex-direction: column;
`;

export const SideRailContent = styled(Flex)`
  flex-direction: column;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  > div:not(:last-child) {
    margin-bottom: ${(props) => props.theme.space[5]}px;
  }
`;

const SideRail = styled(Flex).attrs({
  pb: [7, 7, 10],
})`
  flex-basis: 400px;
  min-width: 320px;
  width: 360px;
  max-width: 100%;
  flex-direction: column;
  display: ${(props) => {
    return props.display;
  }};
  ${(props) => {
    return ` 
      @media (max-width: 1000px) {
        ${
          props.showOnMobile
            ? `min-height: auto;
          flex-basis: unset;
          ${SideRailContent} {
            height: auto;
            position: static;
          }
          `
            : "display: none;"
        }
      }
    `;
  }}
  @media (max-width:  ${(props) => props.theme.breakpoints[3]}) {
    flex-basis: 380px;
    min-width: 300px;
  }
`;

export const ClipMainRail = styled(Flex)`
  width: 1100px;
  max-width: 100%;
  flex-grow: 1;
  flex-direction: column;
`;

export const ClipSideRailContent = styled(Flex)`
  flex-direction: column;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  > div:not(:last-child) {
    margin-bottom: ${(props) => props.theme.space[5]}px;
  }
`;

const ClipSideRail = styled(Flex).attrs({
  pb: [7, 7, 10],
})`
  flex-basis: 360px;

  flex-direction: column;

  ${(props) => {
    return ` 
      @media (max-width: ${props.theme.breakpoints[2]}) {
        ${
          props.showOnMobile
            ? `min-height: auto;
          flex-basis: unset;
          
          ${SideRailContent} {
            height: auto;
            position: static;
          }
          `
            : "display: none;"
        }
      }
    `;
  }}
`;
