import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Body } from "../../../../Components/Text";
import { MdLaunch } from "react-icons/md";

export const GoToMontageCTAContainer = styled(Flex)`
  flex-direction: column;
  padding: 24px;
  background: var(--Gray-850, #141e26);
  align-items: flex-start;

  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const MontageCallToAction = styled(Flex)`
  flex-direction: column;
  gap: 12px;

  align-items: start;
  justify-content: start;
  text-align: start;
`;

export const CtaBody = styled(Body)`
  color: var(--Chalk-100, var(--colors-chalk-100, #8fa9af));
  justify-content: start;
  text-align: start;
`;

export const GoToMontageContainer = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.envy};
    transition: color 0.1s;
    div {
      transition: color 0.1s;
      color: ${({ theme }) => theme.colors.envy};
    }
  }
`;

export const GoToMontageText = styled(Body)`
  color: var(--Ice-100, var(--colors-ice-500100, #f7fcfd));
`;

export const StyledLaunchIcon = styled(MdLaunch)`
  width: 16px;
  height: 16px;
`;
