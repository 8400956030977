import React from "react";
import styled from "styled-components";
import { Box, Flex, Image } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { ReactComponent as Check } from "../../../../../../Assets/check.svg";
import { TClipProps } from "../../../../@types";
import {
  formatNumber,
  dateFormatWithDistanceSuffix,
} from "../../../../../../Utilities/Clip";

import { AllstarModalState } from "../../../../../../State/modals";
import { Button } from "../../../../../../Components/Button";
import { ModalType } from "../../../../../../@types";
import {
  TClipImageProps,
  TClipMetaProps,
  TImageProps,
  TStyledClipProps,
} from "../../@types";

export const ModalClip = ({
  clip,
  isSelected,
  willExceedKillLimit,
  handleSelect,
  hideViewClipButton,
}: TClipProps) => {
  const shouldShowOverlay = willExceedKillLimit && !isSelected;
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  return (
    <StyledClip
      onClick={() => {
        if (shouldShowOverlay) return;
        handleSelect(clip);
      }}
      shouldShowOverlay={shouldShowOverlay}
    >
      <ImageWrapper
        isSelected={isSelected}
        willExceedKillLimit={willExceedKillLimit}
      >
        {isSelected && <Checkmark />}
        {shouldShowOverlay && (
          <ExceedInfo>This clip exceeds the kill limit</ExceedInfo>
        )}
        <ClipImage
          draggable="false"
          src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`}
          active={isSelected}
        />
      </ImageWrapper>
      <ClipMeta mb={0} mt={3} biggerText={false}>
        <Flex flexDirection={"column"}>
          <ClipTitle>{clip.userClipTitle ?? clip.clipTitle}</ClipTitle>
          <ClipStats>
            {formatNumber(clip.views)} Views &bull;{" "}
            {dateFormatWithDistanceSuffix(clip?.createdDate)}
          </ClipStats>
        </Flex>

        {!hideViewClipButton && (
          <Button
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              setAllstarModalState({
                isOpen: ModalType.SimpleClipViewer,
                data: clip,
                functions: {},
              });
            }}
            style={{ width: 50 }}
            classification={"roundedFilled"}
            size={"tiny"}
            ml={0}
          >
            View
          </Button>
        )}
      </ClipMeta>
    </StyledClip>
  );
};
const Checkmark = styled(Check)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
`;

const ExceedInfo = styled(Flex)`
  transition: 0.3s ease;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 98.5%;
  background: rgba(249, 83, 112, 0.2);
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.ice};
  border: 2px solid ${({ theme }) => theme.colors.alert};
  border-radius: 14px;
`;

const StyledClip = styled(Flex)<TStyledClipProps>`
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 100%;
  border-radius: 14px;
  overflow: visible;

  ${({ shouldShowOverlay }: TStyledClipProps) => {
    return (
      !shouldShowOverlay &&
      `&:hover {
    cursor: pointer;
    
  }`
    );
  }}

  @media
    (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
`;

const ImageWrapper = styled(Box)<TImageProps>`
  z-index: 0;
  flex-shrink: 0;
  position: relative;
  width: 100%;
`;

const ClipImage = styled(Image)<TClipImageProps>`
  border-radius: 14px;
  flex-shrink: 0;
  width: 100%;

  ${({ active, theme }) => `
    border: 2px solid ${active ? theme.colors.turbo : "transparent"};
  `}
`;
const ClipMeta = styled(Flex)<TClipMetaProps>`
  align-content: start;
  justify-content: space-between;
  align-items: start;
  text-align: start;
  font-family: "Barlow";
  font-style: normal;
  flex-direction: row;
  width: 100%;
`;

const ClipTitle = styled(Box)<TClipMetaProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.ice};
  letter-spacing: -0.0016em;
`;

const ClipStats = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.chalk};
  letter-spacing: -0.0006em;
`;
