import React from "react";
import { Button } from "../button";
import { FaRegEnvelope } from "react-icons/fa";
import styled from "styled-components";

const StyledEmail = styled(FaRegEnvelope)`
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

export default function EmailButton(props) {
  return (
    <Button {...props}>
      <StyledEmail size={20} mr={!!props.children ? 3 : null} />
      {props.children}
    </Button>
  );
}
