import React from "react";
import { Button } from "../../button";
import { FaDiscord } from "react-icons/fa";
import { luminance } from "../../../../../utils";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: #5865f2;

  &:hover {
    background: ${luminance("#5865F2", -0.1)};
  }
`;

const StyledDiscord = styled(FaDiscord)`
  vertical-align: middle;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

export default function DiscordSocialButton(props) {
  return (
    <StyledButton {...props}>
      <StyledDiscord size={22} mr={!!props.children ? 3 : null} />
      {props.children}
    </StyledButton>
  );
}
