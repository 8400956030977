import React from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { TCountIndicator, TCountProps } from "../../@types";

export const CountIndicator: React.FC<TCountIndicator> = ({
  heading,
  count,
  max,
  canProceed,
}) => {
  return (
    <CountWrapper>
      <Box mr={2} style={{ fontWeight: 500 }}>
        {heading}
      </Box>
      <Count count={count} max={max} canProceed={canProceed} />
    </CountWrapper>
  );
};

export const Count = ({ count, max, canProceed }: TCountProps) => {
  return (
    <>
      <Flex flexDirection={"row"}>
        {" "}
        <SelectedCount canProceed={canProceed}>{count}</SelectedCount>{" "}
        <Slash>/</Slash>
        <Max>{max}</Max>
      </Flex>
    </>
  );
};

const CountWrapper = styled.div`
  color: #f7fcfd;
  display: flex;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
  cursor: default;
`;

type TSelectedCountProps = {
  canProceed: boolean;
};

const SelectedCount = styled.div<TSelectedCountProps>`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  cursor: default;
  color: ${(props: TSelectedCountProps) => {
    return props.canProceed ? `#f7fcfd;` : `rgba(198, 188, 245, 0.5);`;
  }};
`;

const Max = styled.div<any>`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-right: 5px;
  cursor: default;
  color: #f7fcfd;
`;
const Slash = styled.div<any>`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: default;
  color: #f7fcfd;
`;
