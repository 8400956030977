import React from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";

type NoticeProps = {
  children: JSX.Element;
};

export const Notice = ({ children }: NoticeProps) => {
  return (
    <StyledFlex mb={7} pl={3} pr={3}>
      {children}
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #ffa87d;
  border-radius: 10px;
  background: rgba(255, 168, 125, 0.12);
  min-height: 50px;
  gap: 10px;
`;
