import { useHistory } from "react-router-dom";
import { URLS } from "../../Utilities/Maps";
import { useIsDesktopApp } from "../useIsDesktopApp";

export const useRemixButton = () => {
  const history = useHistory();
  const isDesktopApp = useIsDesktopApp();

  // variable that holds the current relative path
  const currentPath = window.location.pathname;

  const onClick = (shareId: string) => {
    let append = "";
    if (isDesktopApp.isDesktop) {
      append = `?external=desktopapp`;
      history.push(`${URLS.REMIX}/${shareId}${append}`);
    } else {
      history.push(`${URLS.REMIX}/${shareId}?next=${currentPath}`);
    }
  };

  return onClick;
};
