export enum EUploadStatus {
  READY = "READY",
  FILE_INVALID = "FILE_INVALID",
  CONFIRMING = "CONFIRMING",
  UPLOADING = "UPLOADING",
  DONE = "DONE",
  ERROR = "ERROR",
}

export interface IError {
  kind: string;
  message: string;
}

export interface IUploadState {
  error?: IError;
  heading?: string;
  status: EUploadStatus;
  text?: string;
}
