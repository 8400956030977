import React, { useEffect, useState } from "react";
import { Label } from "../../atoms/form/label";
import { Validation } from "../../atoms/form/validation";
import { InputText } from "../../atoms/form/input";
import { Box } from "rebass/styled-components";
import { FiEye, FiEyeOff } from "react-icons/fi";
import styled from "styled-components";

const iconStyles = {
  color: (props) => props.theme.colors.chalk,
  fontSize: "22px",
};

const StyledEye = styled(FiEye)({ ...iconStyles });

const StyledOffEye = styled(FiEyeOff)({ ...iconStyles });

const PasswordToggle = styled(Box)`
  cursor: pointer;
  position: absolute;
  bottom: 11px;
  right: 30px;
`;

const FormRow = styled(Box)`
  position: relative;
`;

export function InputWithLabel(props) {
  let [showPassword, setShowPassword] = useState(false);
  let [modifiedInputProps, setModifiedInputProps] = useState(props.input);

  useEffect(() => {
    if (props.input.type !== "password") return;

    setModifiedInputProps((modifiedInputProps) => {
      return {
        ...modifiedInputProps,
        type: showPassword ? "text" : "password",
      };
    });
  }, [showPassword, props.input.type]);

  return (
    <FormRow variant={props.applySpace ? "form" : null}>
      {props.error ? (
        <Validation mb={3}>
          {props.label} -{" "}
          {props.error.message ? props.error.message : "Required"}
        </Validation>
      ) : props.label ? (
        <Label>{props.label}</Label>
      ) : (
        <></>
      )}
      <InputText {...modifiedInputProps} error={props.error} />
      {props.input.type === "password" ? (
        <PasswordToggle
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? <StyledOffEye /> : <StyledEye />}
        </PasswordToggle>
      ) : null}
    </FormRow>
  );
}
