import React from "react";
import { StyledHeader, StyledSubheader } from "./styledComponents";
import { HeaderContainer } from "./styledComponents";
import { THeaderProps } from "./@types";

export const Header = ({ headerText, subHeaderText }: THeaderProps) => {
  return (
    <>
      <HeaderContainer>
        <StyledHeader>{headerText}</StyledHeader>
        <StyledSubheader>{subHeaderText}</StyledSubheader>
      </HeaderContainer>
    </>
  );
};
