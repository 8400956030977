import { RiCloseFill } from "react-icons/ri";
import styled from "styled-components";
import { Box, Flex, Image } from "rebass/styled-components";

import {
  TClipImageProps,
  TClipMetaProps,
  TImageProps,
  TOverlayInfoProps,
  TStyledClipProps,
} from "./@types";

export const StyledSVG = styled(RiCloseFill)`
  height: 18px;
  width: 18px;
  color: ${({ theme }) => theme.colors.iceAlpha60};
`;

export const Close = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  background: ${({ theme }) => theme.colors.navy};
  border: 1px solid ${({ theme }) => theme.colors.navy};
  height: 26px;
  width: 26px;
  border-radius: 999px;
  transition: 0.3s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.alert};
    border: 1px solid ${({ theme }) => theme.colors.alert};
    cursor: pointer;
  }
  &:hover ${StyledSVG} {
    color: ${({ theme }) => theme.colors.ice};
  }
`;

export const OverlayInfo = styled(Flex)<TOverlayInfoProps>`
  transition: 0.3s ease;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 19, 24, 0.6);
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.ice};
  border: 2px dashed rgba(113, 170, 255, 0.5);

  border-radius: 14px;
  ${(props) => {
    return props.onlyOnHover
      ? `  &:hover {
    opacity: 1;
    cursor: grab;
  }`
      : "opacity:1";
  }}
`;

export const ClipIndicator = styled.div`
  background: rgba(12, 19, 24, 0.7);
  border: 2px solid #71aaff;
  border-radius: 999px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.0016em;

  min-height: 22px;
  min-width: 22px;
`;

export const StyledClip = styled(Flex)<TStyledClipProps>`
  position: relative;
  z-index: 0;
  width: 100%;
  border-radius: 14px;
  overflow: visible;
  flex-direction: row;
  margin-bottom: 20px;
  :not(:first-child) {
    margin-top: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
`;

export const ImageWrapper = styled(Box)<TImageProps>`
  z-index: 0;
  flex-shrink: 0;
  position: relative;
  max-width: 300px;
  max-height: 169px;

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 100%;
    max-width: unset;
    max-height: unset;
  }
  ${({ willExceedKillLimit }) =>
    willExceedKillLimit
      ? ` 
      background: rgba(12, 19, 24, 0.6);
      `
      : ""};
`;

export const ClipImage = styled(Image)<TClipImageProps>`
  border-radius: 14px;
  flex-shrink: 0;
  max-width: 300px;
  max-height: 169px;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 100%;
    max-width: unset;
    max-height: unset;
  }
  ${({ active, theme }) => `
    border: 2px solid ${active ? theme.colors.turbo : "transparent"};
  `}
`;

export const DeleteWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
`;

export const FinaleWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
`;

export const ClipMeta = styled(Flex)<TClipMetaProps>`
  align-content: start;
  justify-content: space-between;
  align-items: start;
  text-align: start;
  font-family: "Barlow";
  font-style: normal;
  flex-direction: row;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breaks.standard}) {
    margin-left: 15px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    margin-top: 10px;
  }
`;

export const ClipTitle = styled(Box)<TClipMetaProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.ice};
  letter-spacing: -0.0016em;
  font-weight: 500;
  font-size: 23px;
`;

export const ClipStats = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.chalk};
  letter-spacing: -0.0006em;
  font-weight: 500;
  font-size: 15px;
`;
