import { gql } from "@apollo/client";

export const CROSS_SITE_PROMOTIONS = gql`
  query Query {
    promotions {
      name

      activeSiteSideRailAd {
        name
        title
        subtitle
        image
        link
        buttonCopy
        external
        buttonAction
      }
      activeSiteVideoPlayerPromos {
        name
        title
        subtitle
        image
        link
        buttonCopy
      }
      activeSiteTopBanner {
        name
        link
        colorCSS
        content
        external
        buttonAction
      }
      activeSiteFirstLandModal {
        title
        subtitle
        image
        link
        buttonCopy
        displayType
        name
        external
        buttonAction
      }
      activeSitePaywallPromo {
        name
        topBannerCopy
        colorCSS
        link
        endDate
        countdownBackgroundColor
        countdownFontColor
        countdownSubFontColor
      }
      activeUpgradePagePromo {
        pricing {
          monthly {
            pro
            proPlus
            platinum
          }
          annual {
            pro
            proPlus
            platinum
          }
        }
        productIds {
          monthly {
            pro
            proPlus
            platinum
          }
          annual {
            pro
            proPlus
            platinum
          }
        }
        savings {
          monthly {
            pro
            proPlus
            platinum
          }
          annual {
            pro
            proPlus
            platinum
          }
        }
        pcntOff {
          upTo
          monthly {
            pro
            proPlus
            platinum
          }
          annual {
            pro
            proPlus
            platinum
          }
        }
        imageUrl
        countdownTimerOffset
        endDate
        countdownBackgroundColor
        countdownFontColor
        countdownSubFontColor
        name
      }
      # videoPlayerCTA {
      #   title
      #   subtitle
      #   image
      #   link
      #   active
      #   buttonCopy
      # }
    }
  }
`;
