import { gql } from "@apollo/client";

export const LOADOUT_SELECTOR_QUERY = gql`
  query SelectableLoadouts($excludeCampaignLoadouts: Boolean) {
    selectableLoadouts(excludeCampaignLoadouts: $excludeCampaignLoadouts) {
      clipCards {
        settingsUiToggleDescription
        settingsUiToggleName
        settingsUiToggleable
        enabled
        card {
          effect
          _id
        }
      }
      scopeUse
      metadataThemeCountryFlagEnabled
      metadataThemeCountryFlagDisplay
      metadataThemeCountryFlagToggleName
      metadataThemeCountryFlagToggleDescription
      metadataThemeName
      metadataThemeImageSrc
      metadataStylePreviewColor
      metadataStyleName
      metadataPreviewType
      metadataPreviewSrc
      metadataDisplayName
      metadataCustomStyleSelection {
        metadataStyleName
        settingsUiToggleName
      }
    }
  }
`;

export const CREATE_LOADOUT = gql`
  mutation Mutation(
    $clipCards: [selectableLoadoutCardInput]!
    $signature: Boolean
    $metadataStyleName: String
    $metadataThemeName: String
    $metadataThemeCountryFlagEnabled: Boolean
    $active: Boolean
    $metadataCustomStyleSelection: [customLoadoutStyleSelection]
  ) {
    createLoadout(
      clipCards: $clipCards
      signature: $signature
      metadataStyleName: $metadataStyleName
      metadataThemeName: $metadataThemeName
      metadataThemeCountryFlagEnabled: $metadataThemeCountryFlagEnabled
      active: $active
      metadataCustomStyleSelection: $metadataCustomStyleSelection
    ) {
      _id
      metadataThemeName
      metadataStyleName
      metadataThemeCountryFlagEnabled

      active
      clipCards {
        enabled
        card {
          effect
          _id
          ui {
            title
          }
        }
      }
    }
  }
`;

export const TOGGLE_LOADOUT = gql`
  mutation Mutation($loadoutId: String!, $active: Boolean!) {
    toggleLoadout(loadoutId: $loadoutId, active: $active) {
      _id
      active
    }
  }
`;

export const GET_MY_SIGNATURE = gql`
  query Query($user: String!, $signature: Boolean) {
    getLoadouts(user: $user, signature: $signature) {
      _id
      metadataThemeName
      metadataStyleName
      metadataThemeCountryFlagEnabled
      active
      metadataCustomStyleSelection {
        metadataStyleName
        settingsUiToggleName
      }
      clipCards {
        enabled
        card {
          _id
          effect
          ui {
            title
          }
        }
      }
    }
  }
`;

export const GET_CONFLICTING_CARDS = gql`
  query Query($cardIds: [String]!) {
    checkLoadoutAndCreatorCardConflicts(cardIds: $cardIds)
  }
`;
