import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { MdBrush } from "react-icons/md";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Wrapper = styled(Flex)`
  max-height: 29px;

  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const AllstarStudioIcon = styled(Box)`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: ${(props) => (props.$shrinkMobile ? "0" : "5px")};
  }
`;

const WithStudio = (props) => {
  const history = useHistory();
  return (
    <Wrapper
      variant="studio"
      mt={props.mt ? props.mt : 0}
      onClick={() => {
        history.push(`${props.to ? props.to : `/studio/intro`}`);
      }}
    >
      <AllstarStudioIcon
        $shrinkMobile={props.$shrinkMobile}
        size="16px"
        as={MdBrush}
      />
      {props.children}
    </Wrapper>
  );
};

export default WithStudio;
