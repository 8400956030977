import { RecoilState, atom } from "recoil";
import { TPinnedClip } from "../Components/ClipFeed/@types";

type TPinnedClipWithError = {
  clipId: string;
  errorMessage: string;
};

export const PinnedClipWithError: RecoilState<TPinnedClipWithError> = atom({
  key: "PinnedClipWithError_V0.1",
  default: {
    clipId: "",
    errorMessage: "",
  },
});

export const PinnedClipsAtom: RecoilState<TPinnedClip[]> = atom({
  key: "PinnedClip_V0.1",
  default: [] as TPinnedClip[],
});
