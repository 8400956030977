import React from "react";
import { Box, Image } from "rebass/styled-components";
import styled from "styled-components";
import { Player } from "../../../../../components/molecules/clip/player";

export const Media = ({ clip, isImage, image }: any) => {
  if (!isImage)
    return (
      <>
        {" "}
        <StyledPlayer
          clip={clip}
          fillScreen={false}
          autoplay={false}
          playsinline={false}
          muted={true}
        />
      </>
    );

  return (
    <>
      <StyledBackgroundWrapper>
        <StyledImage src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${image}`} />
      </StyledBackgroundWrapper>
    </>
  );
};

const StyledPlayer = styled(Player)`
  background-color: #333f48;
  border-radius: 10px;
  width: 100%;
`;

const StyledImage = styled(Image)`
  width: 100%;
  filter: blur(7px);
  border-radius: 20px;
`;

const StyledBackgroundWrapper = styled(Box)`
  width: 100%;
  border-radius: 20px;
  border: 5px solid ${({ theme }) => theme.colors.turbo};
`;
