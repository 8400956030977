import { useState } from "react";
import { useQuery } from "@apollo/client";

import { ICreatorCard } from "../../../@types";
import { MY_CREATOR_CARDS } from "../../../GraphQL/competitions";

interface MyCreatorCardsData {
  myCreatorCards: ICreatorCard[];
}

const RemixModalViewModel = () => {
  const [myCreatorCards, setMyCreatorCards] = useState<ICreatorCard[]>();
  const { loading } = useQuery<MyCreatorCardsData>(MY_CREATOR_CARDS, {
    fetchPolicy: "network-only",
    onCompleted: (data: MyCreatorCardsData) =>
      setMyCreatorCards(data.myCreatorCards),
  });

  return {
    loading,
    myCreatorCards,
  };
};

export { RemixModalViewModel };
