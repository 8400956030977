import React, { forwardRef } from "react";
import { Input } from "@rebass/forms/styled-components";
import styled from "styled-components";
import theme from "../../../theme/themeThree";

let StyledInput = styled(Input)((props) => {
  return props.error
    ? {
        borderColor: theme.colors.error,
        ":hover": {
          borderColor: theme.colors.error,
        },
        ":active": {
          borderColor: theme.colors.error,
        },
        ":focus": {
          borderColor: theme.colors.error,
        },
      }
    : {};
});

const InputText = forwardRef((props, ref) => {
  let size = props.size ? props.size : "default";
  return (
    <>
      <StyledInput
        sx={{
          ...theme.input,
          ...theme.variants.input[size],
        }}
        ref={ref}
        type={props.type ? props.type : "text"}
        {...props}
      />
    </>
  );
});

export { InputText };
