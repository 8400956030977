import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { Link, useHistory } from "react-router-dom";

import { Avatar } from "../../../Components/Avatar";
import { dateFormatWithDistanceSuffix } from "../../../Utilities/Clip";
import { StyledHeadline, StyledText } from "../styledComponents";
import { IClipPreview } from "../@types";
import {
  AvatarWrapper,
  getAvatarBorderLevel,
  getAvatarImage,
} from "../../../Utilities/User";

interface IOwnerProps {
  clip: IClipPreview;
  closeModal?: () => void;
  style?: React.CSSProperties;
  username: string;
}

const Owner = ({ closeModal, clip, style, username }: IOwnerProps) => {
  const history = useHistory();
  const avatarImage = getAvatarImage(clip.user);
  const avatarBorderLevel = getAvatarBorderLevel(clip.user);
  const handleClick = () => {
    history.push(`/u/${username}`);
    if (closeModal) closeModal();
  };

  return clip ? (
    <Flex alignItems="center" style={{ gap: "16px", ...style }}>
      <Box mt={2}>
        <Link to="#" onClick={() => handleClick()}>
          <AvatarWrapper size="nav" borderLevel={avatarBorderLevel}>
            <Avatar
              image={avatarImage}
              onClick={() => history.push(`/u/${username}`)}
              size="nav"
            />
          </AvatarWrapper>
        </Link>
      </Box>
      <Flex flexDirection="column" style={{ gap: "4px" }}>
        <StyledHeadline onClick={() => handleClick()}>
          {username}
        </StyledHeadline>
        <Flex style={{ gap: "4px" }}>
          <StyledText>{clip.views} views</StyledText>
          <StyledText>&bull;</StyledText>
          <StyledText>
            {dateFormatWithDistanceSuffix(clip.createdDate)}
          </StyledText>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};

export { Owner };
