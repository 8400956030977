import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { FcLikePlaceholder } from "react-icons/fc";

import { Large3, Body } from "../../components/text";
import { Divider } from "../../components/atoms/divider";
import { Link } from "../../components/atoms/link";

const Jobs = memo(() => {
  useEffect(() => {
    const script = document.createElement("script");

    script.async = true;
    script.src = "https://allstar.breezy.hr/embed/js?inline=true&group_by=none";

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  return (
    <>
      <HomeContainer alignItems="center" flexDirection="column">
        <Flex flexDirection="column" textAlign="center">
          <Large3 mb={9}>Want to be an Allstar? </Large3>
        </Flex>
        <JobContainer>
          <JobLink
            href={"https://allstar.breezy.hr/"}
            target="_blank"
            variant="link.job"
          >
            Find your next job at Allstar, view open positions
          </JobLink>
        </JobContainer>

        <JobOpeningsContainer id="bzOpeningsContainer"></JobOpeningsContainer>

        <Divider my={[0, 0, 3, 3]} width={[2, 2, 3, 4]}></Divider>
        <Flex alignItems="center" flexDirection="column">
          <Box as={Globe} mb={3} />
          <Body color="chalk" width={[2, 2, 5, 5]} mb={8} textAlign="center">
            We are an equal opportunity employer and value diversity at our
            company. Women, people of color, members of the LGBTQ community,
            individuals with disabilities, and veterans are strongly encouraged
            to apply. We are teammates, and gamers, above all else.
          </Body>
        </Flex>
      </HomeContainer>
    </>
  );
});

const JobOpeningsContainer = styled(Box)`
  .bzButtonApply {
    display: none;
  }

  a {
    color: ${({ theme }) => theme.colors.ice};
    text-decoration: none;
  }

  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .bzOpening {
    /* width: 60%; */
  }

  .bzOpeningsList {
    width: 100%;
    flex-direction: column;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;

    > li {
      display: inline;

      :first-child {
        padding-left: 0;
      }

      .bzLocation {
        color: ${({ theme }) => theme.colors.envy};
        font-weight: 900;
      }

      .bzSalary {
        display: none;
      }
    }
  }
`;

const Globe = styled(FcLikePlaceholder).attrs(() => ({ size: 36 }))`
  color: ${(props) => props.theme.colors.envy};
`;

const HomeContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[7]}px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    max-width: 100%;
  }
`;

const JobContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.darkerNed};
  flex-direction: row;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  height: 100%;
  justify-content: space-between;
  align-content: center;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    text-align: left;
    flex-direction: column;
    align-content: flex-start;
  }
`;

const JobLink = styled(Link)`
  overflow: hidden;
  cursor: pointer;
  color: ${(props) => props.theme.colors.darkerEnvy};
`;

export default Jobs;
