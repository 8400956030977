import React, { useEffect, useState } from "react";
import {
  RegionSelectorColumn,
  SelectRegionWithLabel,
  TitleAndSubtitle,
  ButtonsFlex,
} from "./styledComponents";
import { Box } from "rebass/styled-components";

import { Button } from "../Button";
import { DropdownSelector } from "./DropdownSelector";
import { Error } from "../Inputs";
import styled from "styled-components";
import { useUser } from "../../Hooks/useUser";
import { getRiotSelectionData } from "./utils";

export const RiotRegionSelector = ({
  title,
  subtitle,
  onSave,
}: {
  title: string;
  subtitle: string;
  onSave: (region: string) => Promise<{
    error?: boolean;
    message?: string;
    success?: boolean;
  }>;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { allstarUser } = useUser();
  const [dropdownDisabled, setDropdownDisabled] = useState(
    !!allstarUser?.user?.riot?.region,
  );
  const [selectedValue, setSelectedValue] = useState(
    allstarUser?.user?.riot?.region || "NONE",
  );
  const [labelText, setLabelText] = useState("No Selection");
  const riotRegionSelectionData = getRiotSelectionData(false);
  useEffect(() => {
    setLabelText(
      `${riotRegionSelectionData.find(
        (region) => region.valueToSave === selectedValue,
      )?.valueToDisplay}`,
    );
  }, [selectedValue, riotRegionSelectionData]);

  useEffect(() => {
    setDropdownDisabled(!!allstarUser?.user?.riot?.region);
  }, [allstarUser?.user?.riot?.region]);

  return (
    <RegionSelectorColumn>
      <TitleAndSubtitle>
        <Box variant="text.titleSmall" color={"ice"}>
          {title}
        </Box>
        <Box variant="text.paragraphMedium" color={"chalk"}>
          {subtitle}
        </Box>
      </TitleAndSubtitle>
      <SelectRegionWithLabel>
        <DropdownSelector
          onChange={async (region) => {
            setSelectedValue(region);
          }}
          disabled={dropdownDisabled}
          values={riotRegionSelectionData}
          labelText={labelText}
          noSelection={selectedValue === "NONE"}
        />
      </SelectRegionWithLabel>
      {errorMessage && <StyledError>{errorMessage}</StyledError>}

      <ButtonsFlex>
        <Button
          classification="roundedFilled"
          flex={true}
          disabled={selectedValue === "NONE" || loading}
          onClick={async () => {
            setLoading(true);
            window.rudderanalytics.track("Riot Region Updated", {
              selectedValue,
            });
            const response = await onSave(selectedValue);
            setLoading(false);
            if (response.error && response.message) {
              setErrorMessage(response.message);
            }
          }}
        >
          Finish
        </Button>
      </ButtonsFlex>
    </RegionSelectorColumn>
  );
};

const StyledError = styled(Error)`
  width: 100%;
`;
