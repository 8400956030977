import React, { useState, Dispatch, SetStateAction } from "react";
import { Box, Flex, Image } from "rebass/styled-components";

import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper as TSwiper } from "swiper/types";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import styled, { css } from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { TFunction } from "i18next";

import { Body, Title3 } from "../../../../Components/Text";
import { Button } from "../../../../Components/Button";
import { FloatingContainer, Modal } from "../../../../Components/Wrappers";
import Close from "../../../../../assets/images/floatingannouncement/close.svg";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { ImageWithLoading } from "../../../../Components/ImageWithLoading";
import { useIsMobileApp } from "../../../../Hooks/useIsMobileApp";
import { useIsDesktopApp } from "../../../../Hooks/useIsDesktopApp";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";

interface IOverlaySlide {
  title?: string;
  subtitle?: string;
  image?: string;
  link?: string;
  buttonCopy?: string;
  displayType: string;
  name: string;
  external: boolean;
  buttonAction?: ModalType;
}

interface IFirstLandTakeoverCTAProps {
  overlaySlides: IOverlaySlide[];
}

interface ISwiperButtonProps {
  slide: IOverlaySlide;
  index: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  translateHook: TFunction<"translation", "crossSitePromo", "translation">;
  totalSlides: number;
  localStorageKey: string;
}

function hashCode(text: string | undefined) {
  if (!text) return;

  let h = 1;
  for (let i = 0; i < text.length; i++)
    h = (Math.imul(31, h) + text.charCodeAt(i)) | 0;

  return h;
}

export const FirstLandTakeover = ({
  overlaySlides,
}: IFirstLandTakeoverCTAProps) => {
  const location = useLocation();
  const { t } = useTranslation("translation", {
    keyPrefix: "crossSitePromo",
  });
  const [isOpen, setIsOpen] = useState(true);
  const isMobileApp = useIsMobileApp();

  const hashImage = overlaySlides[0].image;
  const hash = hashCode(hashImage);
  const hideArrows = overlaySlides?.length === 1;
  const localStorageKey = `announcement-${hash}-has-been-closed`;
  const [shouldShow] = useState(
    !localStorage.getItem(localStorageKey) &&
      getQueryParam("hide") !== "popover" &&
      !location.pathname.includes("/oauth") &&
      !location.pathname.includes("/iframe") &&
      !location.pathname.includes("/authenticate") &&
      !location.pathname.includes("/register") &&
      !location.pathname.includes("/login") &&
      !isMobileApp.isMobileApp,
  );

  if (!shouldShow) return <></>;

  return (
    <Modal
      isModalOpen={isOpen}
      setModalOpen={setIsOpen}
      handleModalClose={() => {
        setIsOpen(false);
        localStorage.setItem(localStorageKey, "true");
      }}
      disableOutsideClose={false}
    >
      <StyledFloatingContainer>
        <>
          <CloseIcon
            size={36}
            src={Close}
            onClick={() => {
              setIsOpen(false);
              localStorage.setItem(localStorageKey, "true");
            }}
          />
          <SwipyFlex>
            <StyledSlider
              centeredSlides={true}
              spaceBetween={30}
              slidesPerView={1}
              mousewheel={true}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              modules={[Mousewheel, Pagination, Navigation]}
              onSlideChange={(swiper: TSwiper) => {
                const promo = overlaySlides[swiper.activeIndex];
                window.rudderanalytics.track("Promotion - Impression", {
                  type: "firstLandOverlay",
                  promotionName: promo?.name,
                });
              }}
              onAfterInit={(swiper: TSwiper) => {
                const promo = overlaySlides[swiper.activeIndex];
                window.rudderanalytics.track("Promotion - Impression", {
                  type: "firstLandOverlay",
                  promotionName: promo?.name,
                });
              }}
            >
              {overlaySlides.map((slide, index) => {
                return (
                  <SwiperSlide>
                    <StyledBox>
                      <StyledFlex flexDirection="column">
                        {slide.displayType === "video" ? (
                          <></>
                        ) : (
                          <ImageWithLoading
                            imageSource={`${process.env.REACT_APP_S3_STATIC}/${slide.image}`}
                            aspectRatio={16 / 9}
                          />
                        )}
                        <Flex flexDirection="column">
                          <StyledTitle3
                            style={{ marginTop: "16px", marginBottom: "8px" }}
                            fontSize={[3, 4, 5, 5, 6]}
                          >
                            {t(`${slide.title}`, {
                              defaultValue: "This is Madness!",
                            })}
                          </StyledTitle3>
                          <Body
                            color="chalk"
                            fontSize={[1, 2, 3]}
                            style={{ marginBottom: "16px" }}
                          >
                            {t(`${slide.subtitle}`, {
                              defaultValue:
                                "Want to go Pro? We're offering our biggest discounts yet, so upgrade now for up to 65% savings! Hurry, offer ends 3/31.",
                            })}
                          </Body>
                        </Flex>
                        <SwiperButton
                          slide={slide}
                          index={index}
                          setIsOpen={setIsOpen}
                          translateHook={t}
                          totalSlides={overlaySlides?.length}
                          localStorageKey={localStorageKey}
                        />
                      </StyledFlex>
                    </StyledBox>
                  </SwiperSlide>
                );
              })}
            </StyledSlider>
          </SwipyFlex>
        </>
        {!hideArrows && (
          <>
            <StyledLeftArrow className="swiper-button-prev" />
            <StyledRightArrow className="swiper-button-next" />
          </>
        )}
      </StyledFloatingContainer>
    </Modal>
  );
};

const SwiperButton = ({
  slide,
  index,
  setIsOpen,
  translateHook,
  totalSlides,
  localStorageKey,
}: ISwiperButtonProps) => {
  const swiper = useSwiper();
  const history = useHistory();
  const isDesktopApp = useIsDesktopApp();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  return (
    <Button
      mt={[5]}
      size="small"
      flex={true}
      onClick={() => {
        if (slide.buttonAction) {
          setAllstarModalState({
            isOpen: slide.buttonAction,
            functions: {},
            data: {},
          });
          localStorage.setItem(localStorageKey, "true");
          return setIsOpen(false);
        }
        if (slide.link) {
          window.rudderanalytics.track("Promotion - Button Click", {
            type: "firstLandOverlay",
            promotionName: slide?.name,
          });
          if (slide?.external) {
            if (isDesktopApp.isDesktop) {
              window.parent.postMessage(
                {
                  type: "openLink",
                  link: slide.link,
                },
                "*",
              );
            } else window.open(slide.link, "_blank");
          } else {
            if (isDesktopApp.isDesktop) {
              window.parent.postMessage(
                {
                  type: "openLink",
                  link: `https://allstar.gg${slide.link}`,
                },
                "*",
              );
            } else history.push(slide.link);
          }
          localStorage.setItem(localStorageKey, "true");
          setIsOpen(false);
        } else {
          if (totalSlides === 1) {
            localStorage.setItem(localStorageKey, "true");
            return setIsOpen(false);
          }
          if (totalSlides === index + 1) {
            localStorage.setItem(localStorageKey, "true");
            return setIsOpen(false);
          }
          swiper.slideNext();
        }
      }}
    >
      {translateHook(`${slide.buttonCopy}`, {
        defaultValue: "Upgrade",
      })}
    </Button>
  );
};

const StyledFlex = styled(Flex)`
  posiiton: relative;
  justify-content: center;
  align-items: center;
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 60px;
`;

const StyledTitle3 = styled(Title3)`
  font-weight: 600;
`;

const CloseIcon = styled(Image)`
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.navy};
  border-radius: 999px;

  &:hover {
    background-color: ${(props) => props.theme.colors.ned};
    transition: 0.3s;
  }
`;

const StyledFloatingContainer = styled(FloatingContainer)`
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 640px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    max-width: 640px;
  }
`;

const SwipyFlex = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledSlider = styled(Swiper)`
  position: relative;
  width: 100%;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 0.2;
    background: #4dbd94;
    position: relative;
    bottom: 0px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #4dbd94;
  }

  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after {
    display: none;
  }
`;

const StyledArrowCss = css`
  positon: absolute;
  top: 50%;
  padding: 2px;
  z-index: 100000;
  border-radius: 40px;
  width: 28px;
  height: 28px;

  background-color: ${(props) => {
    return props.theme.colors.ice;
  }};

  color: ${(props) => {
    return props.theme.colors.envyAlpha80;
  }};

  &:hover {
    cursor: pointer;
    color: ${(props) => {
      return props.theme.colors.envy;
    }};
    background-color: ${(props) => {
      return props.theme.colors.darkerIce;
    }};
  }
`;

const StyledLeftArrow = styled(HiChevronLeft)`
  ${StyledArrowCss};
  left: -18px;
`;

const StyledRightArrow = styled(HiChevronRight)`
  ${StyledArrowCss};
  right: -18px;
`;
