import React from "react";
import { Subheader } from "../../text";
import { Flex } from "rebass/styled-components";
import { GoAlert } from "react-icons/go";
import { FiDownloadCloud, FiInfo } from "react-icons/fi";
import styled from "styled-components";
import { space } from "styled-system";

const AlertIcon = styled(GoAlert)(space);
const InfoIcon = styled(FiInfo)(space);
const DownloadIcon = styled(FiDownloadCloud)(space);

const iconType = {
  notify: DownloadIcon,
  info: InfoIcon,
  alert: AlertIcon,
};

export function Error(props) {
  let showIcon = props?.children?.length < 90 || props.showIcon;

  const Icon = iconType[props.type] || AlertIcon;

  return (
    <Flex variant="formError" my={3} {...props}>
      {showIcon ? <Icon size={22} mr={4} style={{ marginTop: -3 }} /> : null}

      <Subheader mb={1} color={props.color ? props.color : "navy"}>
        {props.children}
      </Subheader>
      {props.remove}
    </Flex>
  );
}
