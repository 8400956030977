import React, { useEffect } from "react";
import { UploadDetails } from "./UploadDetails";
import { AlreadyPublished } from "./AlreadyPublished";
import { MobileOrDesktopUpload } from "../MobileOrDesktopUpload";
import { UploadingModal } from "../Uploading";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { LinkYourSocial } from "../LinkYourSocial";

export function YouTubeShareModals() {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  return (
    <>
      {allstarModalState.isOpen === ModalType.DuplicateUploadYoutube && (
        <AlreadyPublished />
      )}
      {allstarModalState.isOpen === ModalType.YoutubeUploadDetails && (
        <UploadDetails />
      )}
      {allstarModalState.isOpen === ModalType.LinkYourSocial && (
        <LinkYourSocial />
      )}
      {allstarModalState.isOpen === ModalType.MobileOrDesktopUpload && (
        <MobileOrDesktopUpload />
      )}{" "}
      {allstarModalState.isOpen === ModalType.Uploading && <UploadingModal />}
    </>
  );
}
