import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Box, Flex, Image } from "rebass/styled-components";
import { HiChevronLeft } from "react-icons/hi";
import SVG from "react-inlinesvg";
import { Redirect, useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../../../animations/creatingMontage.json";
import Award from "../../Assets/award.svg";

import { CloseButton, Button, Link } from "../../Components/Button";
import {
  Body,
  Headline,
  Subheader,
  Title1,
  Title2,
} from "../../Components/Text";
import { SequenceSelector } from "./Components/SeqenceSelector";
import { Clips } from "./Components/Clips";
import { MusicSelector } from "./Components/Music";
import { LoadoutSelector } from "./Components/Loadouts";
import { Review } from "./Components/ReviewMontage";
import { Upgrade } from "./Components/Upgrade";
import { CreatorCardModals } from "./Components/Modals";
import { MontageViewModel } from "./ViewModel";
import { TSequence } from "./@types";
import { FinalTouches } from "./Components/FinalTouches";
import { useRecoilState, useResetRecoilState } from "recoil";
import { MontageSession, xsollaModalOpen } from "../../State";
import { Tooltip } from "../../Components/Tooltip";
import { Count } from "./Components/CountIndicator";
import { MicroTransactions } from "../../Components/Modals/MTX";
import {
  FloatingContainer,
  FloatingContent,
  Modal,
} from "../../Components/Wrappers";

import { useUser } from "../../Hooks/useUser";
import { URLS } from "../../Utilities/Maps";
import { GameSelector } from "./Components/GameSelector";
import { AllstarModalState } from "../../State/modals";
import { ModalType } from "../../@types";
import { GoToMontageCTA } from "./Components/MatchMontageCTA";
import { CustomizeProfile } from "../Account/Components/ConnectedAccounts";

const lottieStyle = {
  height: 100,
};

export enum EView {
  Game,
  Sequence,
  Clips,
  Music,
  Loadout,
  Review,
  Complete,
  Error,
  FinalTouches,
}

const getRandom = (array: any[] | undefined) => {
  if (!array || array.length < 1) return {};
  return array[~~(Math.random() * array.length)];
};

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
export default function CreateMontagePage() {
  const [paystation, setPaystationOpen] = useRecoilState(xsollaModalOpen);
  const [modal, setModal] = useState<any>({
    type: "",
  });
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const [, setProFeatureModal] = useState({
    body: "",
    isOpen: false,
  });
  const { getFreshProfile } = useUser();
  const history = useHistory();
  const [usernameModal, setUsernameModal] = useState(false);
  const resetSession = useResetRecoilState(MontageSession);
  const [toolTipVisible, setToolTipVisible] = useState(false);

  const {
    allstarUser,
    montageSession,
    setMontageSession,
    sequences,
    clips,
    music,
    loadouts,
    review,
    montage,
    finalTouches,
    // error,
  } = MontageViewModel();

  const hideBackButton = montageSession.view === EView.Game;
  const isGameOrSequenceView = [EView.Game, EView.Sequence].includes(
    montageSession.view,
  );
  useEffect(() => {
    if (allstarUser.loggedIn && !allstarUser?.user?.hasUsername) {
      setUsernameModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allstarUser]);

  useEffect(() => {
    if (montageSession.failedFromCredits) {
      setAllstarModalState((allstarModalState) => ({
        ...allstarModalState,
        isOpen: ModalType.MicroTransactions,
        data: { type: "montages" },
      }));
      setMontageSession({ ...montageSession, failedFromCredits: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [montageSession.failedFromCredits]);

  useEffect(() => {
    if (paystation.shouldRefreshMtx) {
      setAllstarModalState((allstarModalState) => ({
        ...allstarModalState,
        isOpen: ModalType.None,
      }));
      setPaystationOpen({
        ...paystation,
        shouldRefreshMtx: false,
        MTX: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paystation]);

  useEffect(() => {
    //USE SESSION lastMontagePoll to control this.
    //if lastRun
    if (montageSession.montageId) {
      montage.startPolling(1000);
      setMontageSession({
        ...montageSession,
        montagePolling: { isPolling: true, startedPolling: Date.now() },
      });
      //After 10 seconds, stop polling no matter what,
    }
    return () => {
      resetSession();
    };
  }, [montageSession.montageId]); //eslint-disable-line

  useEffect(() => {
    window.rudderanalytics.page("Create Montage", "Page Land");
  }, []);

  if (!allstarUser.loggedIn) {
    return <Redirect to={`/login?next=${URLS.MONTAGE}`} />;
  }

  const Back = () => {
    switch (
      montageSession.view //eslint-disable-line
    ) {
      case EView.Sequence:
        return setMontageSession({
          ...montageSession,
          view: EView.Game,
        });
      case EView.Clips:
        if (
          window.confirm(
            "Do you really want to leave? You will have to re build...",
          )
        ) {
          return setMontageSession({
            ...montageSession,
            view: EView.Sequence,
            sequence: {} as TSequence,
            music: {},
            kills: -1,
          });
        }
        return null;
      case EView.Music:
        if (montageSession.isRapidFireDefined) {
          if (
            window.confirm(
              "Do you really want to leave? You will have to re build...",
            )
          ) {
            return setMontageSession({
              ...montageSession,
              view: EView.Sequence,
              sequence: {} as TSequence,
              music: {},
              kills: -1,
            });
          }
        }
        return setMontageSession({
          ...montageSession,
          view: montageSession.isRapidFireDefined
            ? EView.Sequence
            : EView.Clips,
        });
      case EView.Loadout:
        return setMontageSession({
          ...montageSession,
          view: EView.Music,
        });
      case EView.FinalTouches:
        return setMontageSession({
          ...montageSession,
          view: EView.Loadout,
        });
      case EView.Review:
        return setMontageSession({
          ...montageSession,
          validatedSelection: false,
          view: EView.FinalTouches,
        });
    }
  };

  const Next = () => {
    switch (montageSession.view) {
      case EView.Game:
        return setMontageSession({
          ...montageSession,
          view: EView.Sequence,
        });
      case EView.Sequence:
        return sequences.checkSequenceQualify(montageSession.sequence);
      case EView.Clips:
        return setMontageSession({
          ...montageSession,
          view: EView.Music,
        });
      case EView.Music:
        return setMontageSession({
          ...montageSession,
          view: EView.Loadout,
        });
      case EView.Loadout:
        //Select a random loadout
        let randomLoadout = undefined;
        if (montageSession.loadout.name === "Random") {
          randomLoadout = getRandom(
            loadouts?.loadoutsData?.loadouts?.filter((loadout: any) => {
              return loadout.name !== "Random";
            }),
          );
        }

        return setMontageSession({
          ...montageSession,
          loadout: randomLoadout ? randomLoadout : montageSession.loadout,
          view: EView.FinalTouches,
        });
      case EView.FinalTouches:
        let cardsToAdd: any = [];

        for (const option of finalTouches.finalTouchesData) {
          if (
            montageSession.finalTouchesSelected.includes(option.description)
          ) {
            cardsToAdd = [...cardsToAdd, ...option.cards];
          }
        }

        return setMontageSession({
          ...montageSession,
          view: EView.Review,
          loadout: {
            ...montageSession.loadout,
            cards: [...montageSession.loadout.cards, ...cardsToAdd],
          },
        });
      case EView.Review:
        createMontage();
    }
  };

  const createMontage = async () => {
    const data = await review.montageModel.PostMontage(montageSession.payload);
    if (data.status === "ok") {
      setMontageSession({
        ...montageSession,
        view: EView.Complete,
        montageId: data._id,
      });
    }
  };

  const NextButtonText = () => {
    switch (montageSession.view) {
      case EView.FinalTouches:
        return "Finish";
      case EView.Review:
        return "Create Montage";
      default:
        return "Next";
    }
  };

  const GetNextButton = () => {
    switch (montageSession.view) {
      case EView.Clips:
        return (
          <>
            <Tooltip
              position={{
                top: "-75px",
                padding: "7px",
                right: "155px",
              }}
              hide={!NextIsDisabled()}
              theme="helpTooltip"
              align="right"
              outsideControl={true}
              isVisible={toolTipVisible}
              tooltipContent={NextToolTip()}
            >
              <Box
                onMouseEnter={() => {
                  setToolTipVisible(true);
                }}
                onMouseLeave={() => {
                  setToolTipVisible(false);
                }}
                padding={"3px"}
              >
                {" "}
                <NextButton
                  classification="roundedFilled"
                  size="small"
                  disabled={NextIsDisabled()}
                  onClick={() => Next()}
                >
                  {NextButtonText()}
                </NextButton>
              </Box>
            </Tooltip>
          </>
        );
      case EView.Review:
        return (
          <>
            <FinishButton
              classification="finishMontage"
              size="small"
              disabled={NextIsDisabled()}
              flexShrink={0}
              onClick={() => !NextIsDisabled() && Next()}
            >
              {NextButtonText()}
            </FinishButton>
          </>
        );
      default:
        return (
          <>
            {
              //dont remove this box. It is needed with the 3px padding to match the above tooltip.
              //ask me (john) if you want to understand why i did this. it was a pain in the ass hacky solution
              //to displaying a tooltip on a disabled button.
            }
            <Box
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              padding={"3px"}
            >
              <NextButton
                classification="roundedFilled"
                size="small"
                disabled={NextIsDisabled()}
                onClick={() => Next()}
              >
                {NextButtonText()}
              </NextButton>
            </Box>{" "}
          </>
        );
    }
  };

  const NextIsDisabled = () => {
    switch (montageSession.view) {
      case EView.Game:
        return false;
      case EView.Sequence:
        if (montageSession?.sequence?.sequenceType) return false;
        return true;
      case EView.Clips:
        if (
          montageSession?.kills >= montageSession?.sequence?.buildDoc?.minKills
        ) {
          return false;
        }
        return true;
      case EView.Music:
        if (montageSession?.music?._id) return false;
        return true;
      case EView.Loadout:
        if (montageSession?.loadout?.name) return false;
        return true;
      case EView.Review:
        if (montageSession?.validatedSelection) return false;
        return true;
      case EView.FinalTouches:
        return false;
      default:
        return true;
    }
  };

  const NextToolTip = () => {
    switch (montageSession.view) {
      case EView.Sequence:
        return <></>;
      case EView.Clips:
        return (
          <>
            <Flex flexDirection={"column"}>
              <Box>
                <Count
                  count={montageSession.kills}
                  max={montageSession.sequence.buildDoc.maxKills}
                  canProceed={!NextIsDisabled()}
                />
              </Box>
              <Body>
                You need at least{" "}
                {montageSession.sequence.buildDoc.minKills -
                  montageSession.kills}{" "}
                more kills.
              </Body>
            </Flex>
          </>
        );
      default:
        return <></>;
    }
  };

  if (montageSession.view === EView.Error) {
    return (
      <FullHeightColumn>
        <MontageCreatedText>Error</MontageCreatedText>
        <MontageCreatedSubText>Something went wrong</MontageCreatedSubText>
      </FullHeightColumn>
    );
  }

  if (montageSession.view === EView.Complete) {
    return (
      <FullHeightColumn>
        <Lottie animationData={loading} style={lottieStyle} loop={true} />
        <MontageCreatedText mb={3}>Creating Montage</MontageCreatedText>
        <MontageCreatedSubText>
          Don't close this page or your work will be lost
        </MontageCreatedSubText>
      </FullHeightColumn>
    );
  }

  if (montageSession.view === EView.Review) {
    return (
      <FullHeightColumn id="cpage">
        <Review
          review={review}
          finalTouchesData={finalTouches.finalTouchesData}
        />{" "}
        <ReviewBottomControls>
          <ReviewBottomControlsWrapper>
            {" "}
            <BottomFinishFlex>
              {" "}
              <BackBtn onClick={() => Back()} hide={hideBackButton}>
                <StyledSVG />
                Back
              </BackBtn>{" "}
              <FinishFlex>
                {" "}
                <Flex flexDirection={"column"}>
                  {" "}
                  <Title2 pb={3} style={{ fontWeight: 400 }}>
                    Finish & Submit
                  </Title2>
                  {GetNextButton()}
                </Flex>
                <MontageGauranteeContainer>
                  <GauranteeText>
                    <GuaranteeTitleWrapper
                      flexDirection={"row"}
                      style={{ position: "relative" }}
                    >
                      <StyledGuaranteeSVG src={Award}></StyledGuaranteeSVG>{" "}
                      <GauranteeTitle>Montage Guarantee</GauranteeTitle>
                    </GuaranteeTitleWrapper>
                    <GauranteeDescription>
                      If you're not happy with your montage, tell us and let us
                      make it right!
                    </GauranteeDescription>
                  </GauranteeText>
                </MontageGauranteeContainer>
              </FinishFlex>
            </BottomFinishFlex>
          </ReviewBottomControlsWrapper>
        </ReviewBottomControls>
      </FullHeightColumn>
    );
  }

  return (
    <>
      <CreatorCardModals
        modal={modal}
        setModal={setModal}
        handleCardToggle={() => {
          setMontageSession({ ...montageSession, music: modal.data.card });
        }}
      />

      <MicroTransactions />

      <Modal
        isModalOpen={usernameModal}
        setModalOpen={setUsernameModal}
        handleModalClose={() => {
          history.push(`/${URLS.ACCOUNT_SETTINGS}`);
        }}
        disableOutsideClose={undefined}
        name="studio-username-modal"
      >
        <FloatingContainer>
          <FloatingContent
            title={<>Set up your profile</>}
            sub={<>A username is required to use Creator Cards</>}
            body={
              <CustomizeProfile
                isPopup={true}
                setModalOpen={setUsernameModal}
                getFreshProfile={getFreshProfile}
                allstarUser={allstarUser}
              ></CustomizeProfile>
            }
          />
        </FloatingContainer>
      </Modal>
      {/* <ProFeatureModal modal={proFeatureModal} setModal={setProFeatureModal} /> */}

      <ContentColumns>
        <MontageStepDescriptionContainer>
          <StyledCloseButton
            alignSelf="flex-start"
            size="small"
            classification="round"
            hideOnMobile={false}
            flex
            to="/clips"
          />
          <Flex flexDirection="column">
            <StyledMontageHeroImage
              src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/montages/CareerMontage_Stacked@2x.webp`}
            />
            {montageSession.view === EView.Game ? (
              <LeftCopy
                title={"Welcome, select your game!"}
                subtitle={
                  "Create a Montage of your favorite moments across your entire Allstar career, using clips you've already created. Select from a varity of sequences & effects! It's super simple, just select a game to get started."
                }
              />
            ) : montageSession.view === EView.Sequence ? (
              <LeftCopy
                title={"Choose your Montage Sequence"}
                subtitle={
                  "Sequences are how Allstar's engine determines the pacing as well as what intro, outro and B-Roll is used in your clips. It will also affect what clips you can choose and how many kills are required."
                }
              />
            ) : montageSession.view === EView.Clips ? (
              <LeftCopy
                title={"Pick and order your clips"}
                subtitle={
                  "You'll need at least 10 kills for this sequence, and you can add up to 20. Allstar will select your best clips automatically, and subscribers can pick choose clips. Drag and drop to re-order. Pro tip: your best clip should be the Finale."
                }
              />
            ) : montageSession.view === EView.Music ? (
              <LeftCopy
                title={"Select your soundtrack"}
                subtitle={
                  "Pick your favorite song as the backdrop to your montage. Allstar will trim your music to the length of your montage and auto-magically sync every kill to the beat (wow!)"
                }
              />
            ) : montageSession.view === EView.Loadout ? (
              <LeftCopy
                title={"Customize the style of your Montage"}
                subtitle={
                  "Loadouts are hand-curated packs of Creator Cards, with each offering a unique look and feel, perfectly paired transitions and more. Loadouts are random unless you are subscribed."
                }
              />
            ) : montageSession.view === EView.FinalTouches ? (
              <LeftCopy
                title={"Apply your finishing touches"}
                subtitle={
                  "Give your montage your own finishing touches. These are preferences like disabling voice comms, hiding the kill feed, or super lit in-game camera angles. The choice is yours and yours alone!"
                }
              />
            ) : (
              <div />
            )}
          </Flex>
          {montageSession.isFreeUser && <Upgrade />}
        </MontageStepDescriptionContainer>
        <Right>
          <StyledCloseButtonRight
            alignSelf="flex-start"
            size="small"
            classification="round"
            hideOnMobile={false}
            flex
            to="/clips"
          />{" "}
          <ContentWrapper
            style={{
              paddingTop: isGameOrSequenceView ? 0 : 24,
            }}
          >
            {isGameOrSequenceView && <GoToMontageCTA />}

            {montageSession.view === EView.Game ? (
              <GameSelector />
            ) : montageSession.view === EView.Sequence ? (
              <SequenceSelector
                sequenceData={sequences.sequenceData}
                sequencesLoading={sequences.sequencesLoading}
                checkSequenceQualify={sequences.checkSequenceQualify}
                setProFeatureModal={setProFeatureModal}
              />
            ) : montageSession.view === EView.Clips ? (
              <Clips {...clips} setModal={setProFeatureModal} />
            ) : montageSession.view === EView.Music ? (
              <MusicSelector {...music} setModal={setModal} />
            ) : montageSession.view === EView.Loadout ? (
              <LoadoutSelector {...loadouts} setModal={setProFeatureModal} />
            ) : montageSession.view === EView.FinalTouches ? (
              <FinalTouches
                finalTouchesLoading={finalTouches.finalTouchesLoading}
                finalTouchesData={finalTouches.finalTouchesData}
                setProFeatureModal={setProFeatureModal}
              />
            ) : (
              <></>
            )}
          </ContentWrapper>
          <BottomControls>
            <BackBtn onClick={() => Back()} hide={hideBackButton}>
              <StyledSVG />
              Back
            </BackBtn>

            <PageIndicator>
              <Dot id="game" active={montageSession.view === EView.Game} />
              <Dot
                id="sequences"
                active={montageSession.view === EView.Sequence}
              />
              <Dot id="clips" active={montageSession.view === EView.Clips} />
              <Dot id="music" active={montageSession.view === EView.Music} />
              <Dot
                id="loadout"
                active={montageSession.view === EView.Loadout}
              />
              <Dot
                id="loadout"
                active={montageSession.view === EView.FinalTouches}
              />
            </PageIndicator>
            {GetNextButton()}
          </BottomControls>
        </Right>
      </ContentColumns>
    </>
  );
}

type TLeftCopyProps = {
  title: string;
  subtitle: string;
};

const LeftCopy = ({ title, subtitle }: TLeftCopyProps) => {
  return (
    <CopyWrapper>
      <LeftTitle>{title}</LeftTitle>
      <LeftSubtitle>{subtitle}</LeftSubtitle>
    </CopyWrapper>
  );
};

const ErrorMessageWrapper = styled(Flex)`
  position: absolute;
  top: 30px;
  left: 15px;
  right: 15px;

    @media (max-width: ${({ theme }) => theme.breaks.standard}) {
      top: 6px;
      left: 31px;
      right: 32px;
    }
     @media (max-width: ${({ theme }) => theme.breaks.medium}) {
        top: -2px;
      left: 70px;
      right: 32px;
  }

  }
`;

const BottomFinishFlex = styled(Flex)<any>`
  justify-content: space-between;
  width: 100%;

  align-items: center;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
  }
`;
const FinishFlex = styled(Flex)<any>`
  justify-content: space-between;
  width: 68%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
    gap: 15px;
    padding-top: 0px;
    padding-bottom: 10px;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  align-items: center;
`;
const CopyWrapper = styled(Flex)<any>`
  flex-direction: column;
  gap: 5px;
  text-align: start;
`;

const LeftTitle = styled(Title1)<any>`
  font-weight: 600;
  line-height: 28px;
  margin-top: 20px;
  margin-right: 15px;
  margin-bottom: 10px;
`;

const LeftSubtitle = styled(Body)<any>`
  line-height: 28px;
  font-weight: 500;
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.iceAlpha75};

  max-width: 600px;
  padding-right: 50px;
`;

const ContentColumns = styled(Flex)<any>`
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledCloseButton = styled(CloseButton)<any>`
  position: absolute;
  top: 30px;
  left: 30px;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    top: 25px;
    left: 25px;
  }
`;

const StyledCloseButtonRight = styled(CloseButton)`
  display: none;
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 99;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    top: 20px;
    left: 20px;
    display: flex;
  }
`;

const MontageStepDescriptionContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: start;
  color: ${({ theme }) => theme.colors.ice};
  padding-left: 80px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

const Right = styled(Flex)`
  flex-direction: column;
  align-content: start;
  justify-content: space-between;
  position: relative;
  align-items: center;
  text-align: center;
  height: 100%;
  background: rgba(24, 37, 45, 0.9);
  backdrop-filter: blur(8px);
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
  }
`;

const ContentWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  padding-left: 20px;  
  padding-right: 20px;

  flex-direction: column;
  position: relative;
  justify-content: center
  align-items: center;
  overflow-y: scroll;  
  margin-bottom: 100px;
  ::-webkit-scrollbar {
    display: none;
  }
  
`;

const MontageCreatedText = styled(Title1)<any>`
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0016em;
  color: ${({ theme }) => theme.colors.ice};
`;

const MontageCreatedSubText = styled.div`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0016em;
  color: ${({ theme }) => theme.colors.chalk};
`;

const BottomControls = styled(Flex)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;

  background: rgba(24, 32, 40, 0.9);
  backdrop-filter: blur(8px);
  z-index: 100;
  position: absolute;
  bottom: 0;
  height: 100px;
`;
const ReviewBottomControls = styled(Flex)`
  position: absolute;
  bottom: 0;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: rgba(24, 32, 40, 0.9);
  backdrop-filter: blur(8px);
  height: 12vh;
  z-index: 100;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 25vh;
  }
`;

const ReviewBottomControlsWrapper = styled(Flex)`
  width: 100%;
  max-width: 950px;
  margin-left: 150px !important;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-left: 0px !important;
  }
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const BackBtn = styled.button<any>`
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 32px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8fa9af;
  border-radius: 8px;
  visibility: ${(props) => {
    return props.hide ? "hidden" : "visible";
  }};
  margin-left: 33px;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.envy};
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-left: 5px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    margin-left: 0px;
  }
`;

const StyledSVG = styled(HiChevronLeft)<any>`
  height: 24px;
  width: 24px;
  margin-right: 5px;
  margin-top: 2px;
  ${BackBtn}:hover & {
    path {
      stroke: #4dbd94;
    }
  }
`;

type TNextProps = {
  disabled: boolean;
};

const NextButton = styled(Button)<TNextProps>`
  width: 93px;
  margin-right: 60px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: 25px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    margin-right: 15px;
    width: 80px;
  }
`;
const FinishButton = styled(Button)<TNextProps>`
  width: 203px;
  height: 39px;
  transition: 1s ease;
  z-index: 10;
  background: linear-gradient(130.44deg, #1394c8 6.35%, #824cb0 83.2%);
  &:hover:not([disabled]) {
    background: #6062b8;
  }
  border-radius: 8px;
`;

const FullHeightColumn = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #1c2a34;
  height: 100%;
`;

const PageIndicator = styled(Flex)`
  flex-direction: row;
  gap: 8px;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

type TDotProps = {
  active: boolean;
};

const Dot = styled.div<TDotProps>`
  transition: 0.4s ease;
  width: ${(props) => (props.active ? "24px" : "8px")};
  height: 8px;
  background: ${(props) =>
    props.active ? "#4dbd94" : "rgba(146, 209, 246, 0.2)"};
  border-radius: 50px;
`;

const GuaranteeTitleWrapper = styled(Flex)`
  flex-direction: row;
  position: relative;
`;

const GauranteeText = styled(Flex)`
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  gap: 5px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const GauranteeTitle = styled(Headline)`
  text-align: left;
  font-weight: 500;

  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.ice};

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const GauranteeDescription = styled(Subheader)`
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  color: ${({ theme }) => theme.colors.chalk};
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    text-align: center;
  }
`;

const StyledGuaranteeSVG = styled(SVG)`
  vertical-align: middle;
  position: absolute;
  left: -25px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    position: relative;
    left: 0px;
  }
`;
const MontageGauranteeContainer = styled(Flex)`
  flex-direction: row;
  margin-top: 25px;
  max-width: 250px;
`;

const StyledMontageHeroImage = styled(Image)`
  max-width: 275px;
`;
