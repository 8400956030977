import React, { useEffect } from "react";

import { Body } from "../../components/text";
import { FloatingContent } from "../../components/molecules/floatingContent";

import { Row as FormRow } from "../../components/molecules/form/row";

import { FormLink } from "../../components/atoms/form/link";
import { URLS } from "../../utils";

function bodyContent() {
  return (
    <Body>
      We'll need to verify your email before you can join Allstar's community
      and get the app. Don't see the email? Check spam & promotions.
    </Body>
  );
}

export function Verify() {
  useEffect(() => {
    window.rudderanalytics.page("Activation", "Verify");
  });

  if (localStorage.getItem("referralCode")) {
    // clean up referral code
    localStorage.removeItem("referralCode");
  }

  return (
    <FloatingContent title="Check your email" body={bodyContent()}>
      <FormRow>
        <FormLink to={URLS.SUPPORT}>
          Can't find your email? Contact support.
        </FormLink>
      </FormRow>
    </FloatingContent>
  );
}
