import { useLazyQuery } from "@apollo/client";
import { MUSIC_FACETS_QUERY } from "../../GraphQL/studioFacet";

export const MusicGenreSelectorViewModel = () => {
  const [getMusicGenres] = useLazyQuery(MUSIC_FACETS_QUERY);

  return {
    getMusicGenres,
  };
};
