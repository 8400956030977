import { atom, RecoilState } from "recoil";

export const StudioSession: RecoilState<any> = atom({
  key: "StudioSession_v1",
  default: {
    userActivated: [],
    isRemixSession: false,
    useSignature: false,
    validatedSelection: false,
    success: false,
    toggleAllCategories: [],
  },
});
