import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Title3, Body } from "../../components/text";
import { FloatingContent } from "../../components/molecules/floatingContent";
import { Label } from "../../components/atoms/form/label";
import { Row as FormRow } from "../../components/molecules/form/row";
import { CloudDoneButton } from "../../components/atoms/form/button/cloudDone";
import { Box } from "rebass/styled-components";
import { FiMousePointer } from "react-icons/fi";
import { AiOutlineLogin, AiOutlineSync } from "react-icons/ai";
import styled from "styled-components";
import { color } from "styled-system";

const iconStyles = {
  marginRight: (props) => props.theme.space[3] + "px",
};

const LoginIcon = styled(AiOutlineLogin)(iconStyles, color);

const ClickIcon = styled(FiMousePointer)(iconStyles, color);

const SyncIcon = styled(AiOutlineSync)(iconStyles, color);

const StyledTitle = styled(Title3)`
  align-items: center;
  display: flex;
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;

function bodyContent() {
  return <Body>Just three more steps to glorious cloud clip capture:</Body>;
}

export function AlmostDone({ onStep, setOnStep }) {
  let history = useHistory();
  useEffect(() => {
    window.rudderanalytics.page("Activation", "Almost Done");
  });
  return (
    <FloatingContent title="Almost there!" body={bodyContent()}>
      <Box mb={7}>
        <StyledTitle>
          <ClickIcon color="envy" /> 1. Install
        </StyledTitle>
        <Body>Allstar's app sits silently in your system tray.</Body>
      </Box>

      <Box mb={7}>
        <StyledTitle>
          <LoginIcon color="envy" /> 2. Login
        </StyledTitle>
        <Body>Log in on launch. You only have to do this once.</Body>
      </Box>

      <Box mb={7}>
        <StyledTitle>
          <SyncIcon color="envy" /> 3. Sync
        </StyledTitle>
        <Body>Sync your Steam account with Allstar's app.</Body>
      </Box>

      <FormRow>
        <Label>Done installing?</Label>
        <CloudDoneButton
          onClick={() => {
            setOnStep(onStep + 1);
            history.push("/activation/complete");
          }}
        >
          Take me to the last step!
        </CloudDoneButton>
      </FormRow>
    </FloatingContent>
  );
}
