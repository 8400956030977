import React from "react";
import { Box } from "rebass/styled-components";
import { ReactComponent as AllstarLogo } from "../../../assets/images/logo/logo.svg";
import styled from "styled-components";

let Logo = styled(Box)`
  display: block;
`;

export default function Brand(props) {
  return <Logo as={AllstarLogo} fill="currentColor" {...props} />;
}
