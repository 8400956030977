import React from "react";
import { Link } from "../../Button";
import { Modal } from "../../Wrappers";
import { Title3 } from "../../Text";
import { FloatingContainer } from "../../Wrappers";
import { URLS } from "../../../Utilities/Maps";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

const AUTH_ROUTES: any = {
  TikTok: URLS.AUTH_ROUTES.TIKTOK,
  Google: URLS.AUTH_ROUTES.GOOGLE,
};

export function LinkYourSocial() {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const next = allstarModalState.data.next;
  localStorage.setItem("next", next);
  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.LinkYourSocial}
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Title3 mb={5}>Link your {allstarModalState.data.platform}</Title3>
          Click
          <Link
            to={`${
              AUTH_ROUTES[allstarModalState.data.platform]
            }${"?flow=link"}`}
            onClick={() => {
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
            variant="link.title"
          >
            here
          </Link>
          to proceed.
        </FloatingContainer>
      </Modal>
    </>
  );
}
