import React from "react";
import { CSSProperties } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./containerClasses.css";

interface LoadingProps {
  width?: number | string;
  height?: number | string;
  count?: number;
  style?: CSSProperties;
  color?: string;
  containerClassName?: string;
}

export const LoadingSkeleton = ({
  width,
  height,
  count,
  color,
  style,
  containerClassName,
}: LoadingProps) => {
  return (
    <>
      <SkeletonTheme baseColor={color || "#253a48"} highlightColor="#213442">
        <Skeleton
          count={count}
          height={height}
          width={width}
          style={style}
          containerClassName={containerClassName}
        />
      </SkeletonTheme>
    </>
  );
};
