import { Box, Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { BiCog, BiSupport, BiLogOut } from "react-icons/bi";
import { MdTranslate } from "react-icons/md";
import { useSetRecoilState } from "recoil";

import {
  navLinkActive,
  NavLinkText,
  sidebarIconStyle,
  StyledDivider,
} from "../../../Sidebar/styledComponents";
import { useUser } from "../../../../Hooks/useUser";
import { NavLink, navLinkStyle } from "../../../Sidebar";
import { NAV_LINKS } from "../../../Sidebar/utils";
import { LanguagesDropdown } from "../../../../../components/organisms/nav/languagesDropDown";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import { UpgradeCard } from "../../UpgradeCard";
import { UserCard } from "../../UserCard";

const DropdownNav = ({ close }: { close: () => void }) => {
  const { allstarUser, logout } = useUser();
  const { REACT_APP_S3_CLIP_BUCKET } = process.env;
  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const userGroupType = allstarUser.user?.userGroup?.type;

  return (
    <DropdownContainer>
      <UserCard close={close} />
      <UpgradeCard currentTier={userGroupType} close={close} />
      <DropdownNavContainer>
        <NavLink
          to={NAV_LINKS.accountSettings}
          isActive={false}
          onClick={close}
        >
          <StyledBiCog />
          <NavLinkText>Account Settings</NavLinkText>
        </NavLink>
        <PseudoNavLink
          onClick={() => {
            window.rudderanalytics.track("Signature - Open Modal", {
              where: "Nav Menu",
            });
            setAllstarModalState({
              isOpen: ModalType.SignatureSelector,
              functions: {},
              data: {},
            });
          }}
        >
          <Image src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/sig.svg`} />
          <NavLinkText>Manage Signature</NavLinkText>
        </PseudoNavLink>
        <PseudoNavLink onClick={() => window.open(NAV_LINKS.support, "_blank")}>
          <StyledBiSupport />
          <NavLinkText>Support</NavLinkText>
        </PseudoNavLink>
        <StyledDivider />
        <PseudoNavLink>
          <StyledMdTranslate />
          <NavLinkText>
            <NavLinkDropdownWrapper>
              <LanguagesDropdown />
            </NavLinkDropdownWrapper>
          </NavLinkText>
        </PseudoNavLink>
        <StyledDivider />
        <PseudoNavLink onClick={() => logout("Site Header logged out.")}>
          <StyledBiLogOut />
          <NavLinkText>Logout</NavLinkText>
        </PseudoNavLink>
      </DropdownNavContainer>
    </DropdownContainer>
  );
};

const DropdownNavContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const NavLinkDropdownWrapper = styled(Box)`
  width: 100%;

  * {
    font-family: "Barlow";
    color: ${({ theme }) => theme.colors.chalk};
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: 0;
  }

  svg {
    padding-left: 30px;
  }
`;

const PseudoNavLink = styled(Flex)`
  ${navLinkStyle}

  :hover {
    ${navLinkActive}
  }
`;

const StyledBiCog = styled(BiCog)`
  ${sidebarIconStyle}
`;

const StyledBiSupport = styled(BiSupport)`
  ${sidebarIconStyle}
`;

const StyledBiLogOut = styled(BiLogOut)`
  ${sidebarIconStyle}
`;

const StyledMdTranslate = styled(MdTranslate)`
  ${sidebarIconStyle}
`;

const DropdownContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  padding: 6px;
  width: 304px;
`;

export { DropdownNav };
