import { Flex } from "rebass/styled-components";
import styled from "styled-components";

//Container with 3 column grid display
export const MusicGenreSelectorContainer = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  max-height: 445px;
  overflow-y: auto;
  padding-right: 10px;
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.colors.ned};
  }
  ::-webkit-scrollbar {
    width: 25px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.ned};
    border: 5px solid transparent;
    border-radius: 999px;
    background-clip: content-box;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const GenreContainer = styled(Flex)`
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha40};
  background-color: ${({ theme }) => theme.colors.midnight};
  position: relative;
  &:hover {
    ${({ selected, theme }) => {
      return !selected && `    border: 1px solid ${theme.colors.envyAlpha40};`;
    }}
    background-color: #060a0c;
    cursor: pointer;
  }
  ${({ selected, theme }) => {
    return (
      selected &&
      `background-color:#060A0C; border:1px solid ${theme.colors.envy};`
    );
  }}
  ${({ disabled, theme }) => {
    return (
      disabled &&
      `&:hover{
      cursor: not-allowed;
      background-color: ${theme.colors.midnight};
      border: 1px solid ${theme.colors.chalkAlpha40};
      }`
    );
  }}
`;

export const DisabledOverlay = styled(Flex)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.midnightAlpha80};
  border-radius: 9px;
  width: 100%;
  height: 100%;
`;
