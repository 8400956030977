import React, { useEffect, useState } from "react";
import { Flex, Box } from "rebass/styled-components";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

/*  Our GLOBAL component imports */
import { ReactComponent as Top } from "../../../assets/images/badges/top-badge.svg";
import { ReactComponent as New } from "../../../assets/images/badges/newIcon.svg";
import { ReactComponent as Exclusive } from "../../../assets/images/badges/exclusive-badge.svg";
import { MutedIcon, UnMutedIcon } from "../Icons";
import { useRecoilState } from "recoil";
import { AllstarSiteData } from "../../State";
import { ReactCompareSlider } from "react-compare-slider";

type TCreatorCardVideoProps = {
  card: any;
  setModal: any;
  handleCardClick: any;

  isModal: any;
  handleModalClose: any;
};
const CHANGE_ON_HOVER = ["gif", "video", "swiper"];
const VIDEO_PREVIEW_TYPES = ["gif", "video"];
const AUDIO_PREVIEW_TYPES = ["video", "audio"];

const CompareSlider = ({
  srcA,
  srcB,
  isModal,
}: {
  srcA: string;
  srcB: string;
  isModal: boolean;
}) => {
  const [swiperIsReady, setSwiperIsReady] = useState(false);
  if (!srcA || !srcB) return <></>;
  const srcAFilename = srcA.split("/").pop();
  const srcBFilename = srcB.split("/").pop();

  const srcAParts = srcA.split("/");
  const srcBParts = srcB.split("/");

  if (isModal) {
    srcAParts[srcAParts.length - 1] = `lrg_${srcAFilename}`;
    srcBParts[srcBParts.length - 1] = `lrg_${srcBFilename}`;
  }

  const joinedA = srcAParts.join("/");
  const joinedB = srcBParts.join("/");

  const srcALink = `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/${joinedA}`;
  const srcBLink = `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/${joinedB}`;

  const SwipeWrapper = styled.div`
    position: absolute;
    top: 0;
  `;

  const VideoWrapper = styled.video`
    width: 100%;

    ${isModal ? "height: 99.2%;" : "height: 98.5%;"}
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    ${isModal ? "border-radius: 10px;" : ""}
  `;

  return (
    <SwipeWrapper style={{ opacity: swiperIsReady ? 1 : 0 }}>
      <ReactCompareSlider
        handle={<></>}
        changePositionOnHover={true}
        itemOne={
          <VideoWrapper
            loop
            autoPlay={true}
            muted
            onLoadedData={() => setSwiperIsReady(true)}
          >
            <source src={srcBLink} type="video/mp4" />
          </VideoWrapper>
        }
        itemTwo={
          <VideoWrapper loop autoPlay={true} muted>
            <source src={srcALink} type="video/mp4" />
          </VideoWrapper>
        }
      />
    </SwipeWrapper>
  );
};

export function CreatorCard({
  card,
  setModal,
  handleCardClick,
  isModal,
  handleModalClose,
}: TCreatorCardVideoProps) {
  const [siteData, setSiteData] = useRecoilState(AllstarSiteData);
  const imageSource = `${process.env.REACT_APP_S3_STATIC}/static/${card.ui.image}`;
  const hoverSource = `${process.env.REACT_APP_S3_STATIC}/static/${card.ui.hoverEffectSrc}`;
  const [hovering, setHovering] = useState(false);
  const [cardPlaying, setCardPlaying] = useState(false);
  const isVideo = VIDEO_PREVIEW_TYPES.includes(card.ui.hoverEffect);
  const [thisCardPlaying, setThisCardPlaying] = useState(
    cardPlaying === card._id,
  );

  const shouldChangeOnHover =
    hovering &&
    thisCardPlaying &&
    CHANGE_ON_HOVER.includes(card.ui.hoverEffect);

  const shouldShowMute = AUDIO_PREVIEW_TYPES.includes(card.ui.hoverEffect);

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  const badges = getBadges(card.ui.badge, card.scopeUse, setModal);

  /*eslint-disable*/
  useEffect(() => {
    setThisCardPlaying(cardPlaying === card._id);
  }, [cardPlaying]);

  useEffect(() => {
    if (!inView) {
      setCardPlaying(false);
    }
  }, [inView]);
  /*eslint-enable*/

  function handleMouseOver() {
    // console.log(card);
    setCardPlaying(card._id);
    setHovering(true);
    // console.log({ isTouchScreen });
  }

  function handleMouseLeave() {
    setCardPlaying(false);
    setHovering(false);
  }

  function getBadges(uiBadge: any, scopeUse: any, setModal: any) {
    const badges = [];
    // if (uiBadge === "pro" || needsPro(scopeUse)) {
    //   badges.push(
    //     <Badge
    //       index={badges.length}
    //       key={badges.length}
    //       onClick={(e: any) => {
    //         window.rudderanalytics.track(`Allstar Studio - Pro Badge Click`);
    //         e.preventDefault();
    //         e.stopPropagation();
    //         setModal({ type: rarity });
    //         if (handleModalClose) {
    //           handleModalClose();
    //         }
    //       }}
    //     />
    //   );
    // }
    if (scopeUse.includes("OG")) {
      badges.push(
        <ExclusiveBadge
          index={badges.length}
          mr={10}
          onClick={(e: any) => {
            window.rudderanalytics.track(
              "Allstar Studio - Exclusive Badge Click",
            );
            e.preventDefault();
            e.stopPropagation();
            setModal({ type: "exclusive" });
            if (handleModalClose) {
              handleModalClose();
            }
          }}
        />,
      );
    }
    if (uiBadge === "top") {
      badges.push(
        <TopBadge
          index={badges.length}
          mr={10}
          onClick={(e: any) => {
            window.rudderanalytics.track("Allstar Studio - Top Badge Click");
            e.preventDefault();
            e.stopPropagation();
            setModal({ type: "top" });
            if (handleModalClose) {
              handleModalClose();
            }
          }}
        />,
      );
    }
    if (uiBadge === "new") {
      badges.push(
        <NewBadge
          index={badges.length}
          mr={10}
          onClick={(e: any) => {
            window.rudderanalytics.track("Allstar Studio - New Badge Click");
            e.preventDefault();
            e.stopPropagation();
            setModal({ type: "new" });
            if (handleModalClose) {
              handleModalClose();
            }
          }}
        />,
      );
    }

    return badges;
  }

  return (
    // <StyledCard variant="creatorCard" onMouseLeave={handleMouseLeave}>
    <PreviewImage
      isV2
      isModal={isModal}
      imageSrc={imageSource}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      onClick={() => {
        if (handleCardClick) {
          handleCardClick(card);
        }
      }}
      onTouchEnd={() => {
        if (thisCardPlaying) {
          setSiteData({
            ...siteData,
            creatorCardMuted: !siteData.creatorCardMuted,
          });
        }
      }}
      hoverEffect={card.ui.hoverEffect}
      ref={ref}
    >
      <>
        {badges.map((badge, index) => {
          return <Box key={"BADGES" + index}>{badge}</Box>;
        })}
      </>
      {shouldChangeOnHover ? (
        <>
          {shouldShowMute ? (
            <SpeakerIconWrapper
              onClick={(e: any) => {
                setSiteData({
                  ...siteData,
                  creatorCardMuted: !siteData.creatorCardMuted,
                });
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {siteData.creatorCardMuted ? (
                <StyledMuted></StyledMuted>
              ) : (
                <StyledUnMuted></StyledUnMuted>
              )}
            </SpeakerIconWrapper>
          ) : null}

          {isVideo ? (
            <CardVideo
              src={hoverSource}
              autoPlay={true}
              loop={true}
              muted={siteData.creatorCardMuted || !shouldShowMute}
              preload
              isModal={isModal}
              onLoadStart={(e: any) => {
                e.currentTarget.volume = 0.3;
              }}
            />
          ) : (
            <>
              <CompareSlider
                srcA={card.ui.hoverEffectSrc}
                srcB={card.ui.hoverEffectSrcBefore}
                isModal={isModal}
              />
            </>
          )}
        </>
      ) : null}
    </PreviewImage>
    // </StyledCard>
  );
}

const StyledMuted = styled(MutedIcon)<any>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledUnMuted = styled(UnMutedIcon)<any>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpeakerIconWrapper = styled(Box)<any>`
  background-color: ${(props: any) => props.theme.colors.midnightAlpha50};
  top: 5%;
  left: 3%;
  height: 36px;
  width: 36px;
  border-radius: 7px;
  position: absolute;
  text-align: center;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;

const ExclusiveBadge = styled(Exclusive)<any>`
  top: 0%;
  right: ${(props: any) => {
    return 3 + 10 * props.index;
  }}%;
  position: absolute;
  text-align: center;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;

const TopBadge = styled(Top)<any>`
  top: 0%;
  right: ${(props) => {
    return 3 + 10 * props.index;
  }}%;
  position: absolute;
  text-align: center;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;
const NewBadge = styled(New)<any>`
  top: 0%;
  right: ${(props) => {
    return 3 + 10 * props.index;
  }}%;
  position: absolute;
  text-align: center;
  width: 37px;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 30px;
  }
`;
const CardVideo = styled.video<any>`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  border-radius: ${(props) => {
    return props.isModal ? "10px 10px 10px 10px" : "10px 10px 0 0";
  }};
  object-fit: cover;
  object-position: center;
  object-repeat: no-repeat;
  height: auto;
`;

const PreviewImage = styled(Flex)<any>`
  cursor: pointer;
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  padding-top: 56%;
  border-radius: ${(props) => {
    return props.isModal ? "10px 10px 10px 10px" : "10px 10px 0 0";
  }};
  background-color: ${(props: any) => props.theme.colors.darkerNed};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image:${(props) => {
    return `url(${props.imageSrc})`;
  }};

  }};
  ${(props: any) =>
    AUDIO_PREVIEW_TYPES.includes(props.hoverEffect)
      ? ":hover {cursor:pointer;}"
      : ":active {cursor:pointer;}"}
`;
