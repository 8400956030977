import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as TikTok } from "../../../../Assets/Platforms/tiktokLight.svg";
import { Body } from "../../../Text";
import styled from "styled-components";
import { useFetch } from "../../../../../hooks";
import { Modal } from "../../../Wrappers";
import { Flex } from "rebass";
import { Button } from "../../../Button";
import { Title3 } from "../../../Text";

import { FloatingContainer } from "../../../Wrappers";
import { trackClipSocialShare } from "../../../../Analytics";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { AllstarModalState } from "../../../../State/modals";
import { useRecoilState } from "recoil";
import { ModalType } from "../../../../@types";

const IconWrapper = styled(Flex)`
  position: relative;
  padding: 8px;
  background: #ffffff;
  border-radius: 50%;
`;

export function UploadTikTok() {
  const { fetch, query }: any = useFetch(undefined, true);

  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  useEffect(() => {
    if (query.statusCode === 409) {
      setAllstarModalState({
        data: {
          aspectRatio: allstarModalState.data.aspectRatio,
        },
        functions: {},
        isOpen: ModalType.DuplicateUploadTikTok,
      });
    }
    if (query.status === "success") {
      setAllstarModalState({
        data: { platform: "TikTok" },
        functions: {},
        isOpen: ModalType.Uploading,
      });
    }
  }, [query.statusCode, query.status]);

  const onSubmit = (queue: any) => {
    trackClipSocialShare({
      platform: "TikTok",
      handler: fetch,
      clip: allstarModalState.data.clip,
      queue,
      aspectRatio: allstarModalState.data.aspectRatio,
    });

    return;
  };
  const isLoading = query.status === "loading";

  return (
    <>
      {query.status !== "success" && (
        <Modal
          isModalOpen={
            Boolean(getQueryParam("tiktok")) ||
            allstarModalState.isOpen === ModalType.TikTokUpload
          }
          setModalOpen={() => {
            setAllstarModalState({
              data: {},
              functions: {},
              isOpen: ModalType.None,
            });
          }}
          handleModalClose={() => {}}
          disableOutsideClose={false}
        >
          <FloatingContainer>
            <Flex flexDirection="column">
              <Flex alignItems="center" justifyContent="center">
                <IconWrapper>
                  <TikTok
                    style={{
                      height: "36px",
                      width: "36px",
                      position: "relative",
                    }}
                  />
                </IconWrapper>
              </Flex>
              <Title3 mt={4} textAlign="center" mb={2}>
                Publish to TikTok
              </Title3>
              <Body mb={3} textAlign="center" color="chalk">
                After you press publish, you will receive a notification from
                TikTok on your phone. From there you can edit and post your clip
                on the TikTok App.
              </Body>
            </Flex>

            <Flex flexDirection="column-reverse" mt={4}>
              <Button
                classification="subtle"
                size="small"
                onClick={() => {
                  setAllstarModalState({
                    data: {},
                    functions: {},
                    isOpen: ModalType.None,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                mb={4}
                size="small"
                disabled={query.status === "success" || isLoading}
                type="submit"
                onClick={() => onSubmit(false)}
              >
                {isLoading
                  ? "Loading..."
                  : query.status === "success"
                  ? "Publish queued"
                  : "Publish"}
              </Button>
            </Flex>
          </FloatingContainer>
        </Modal>
      )}
    </>
  );
}
