import React, { memo } from "react";
import { FormLink } from "../../components/atoms/form/link";
import { Brand } from "../logo";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

const BrandLogo = styled(Brand)`
  overflow: visible;
  }
`;

const NavSignup = memo(() => {
  return (
    <>
      <Flex
        variant="navSignup"
        px={8}
        py={8}
        alignItems="center"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-between"
      >
        <Flex>
          <BrandLogo
            flex="flex-start"
            id="allstar-logo"
            variant="logo.nav"
            target="/clips"
            className="logo"
          />
        </Flex>
        <Flex>
          <FormLink to="/login" flex="flex-end">
            Already a member? Login
          </FormLink>
        </Flex>
      </Flex>
    </>
  );
});

export { NavSignup };
