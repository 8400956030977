import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { Reaction } from "../../../../../components/molecules/reaction";
import { SVG_MAP } from "../../../../../utils";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../State/modals";
import { useUser } from "../../../../Hooks/useUser";
import { ModalType } from "../../../../@types";

export function ClipReactions(props) {
  const [comments, setComments] = useState(props?.clip?.comments || []);
  const { allstarUser } = useUser();
  const [totalReactions, setTotalReactions] = useState(
    comments?.reduce((n, { count }) => n + count, 0),
  );
  const [highestReaction, setHighestReaction] = useState(
    [...comments].sort((a, b) => b.count - a.count)[0],
  );

  const [type] = useState();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  useEffect(() => {
    setTotalReactions(comments?.reduce((n, { count }) => n + count, 0));
    setHighestReaction([...comments].sort((a, b) => b.count - a.count)[0]);
  }, [comments]);

  if (!comments.length) return <></>;

  async function submitReaction(type) {
    if (!allstarUser.loggedIn)
      return setAllstarModalState({
        isOpen: ModalType.Signup,
        data: {
          action: type,
          emoji: SVG_MAP[type],
        },
        functions: {},
      });
    props.mutateReaction({
      variables: { input: { clipId: props.clip._id, reaction: type } },
    });

    setComments((comments) => {
      const reactions = comments.map((reaction) => {
        const copy = { ...reaction };
        if (reaction.reaction === type) {
          copy.count++;
        }
        return copy;
      });

      return reactions;
    });
    return true;
  }

  const mappedCount = {
    FIRE: comments.find((x) => x.reaction === "FIRE").count,
    HYPE: comments.find((x) => x.reaction === "HYPE").count,
    SPICY: comments.find((x) => x.reaction === "SPICY").count,
    EXPLODE: comments.find((x) => x.reaction === "EXPLODE").count,
  };

  return (
    <>
      <StyledSingleWidth
        justifyContent="space-between"
        {...props}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <StyledWrapper>
          <div id="like-link-wrapper">
            <div id="reactions">
              <div id="reactions__wrapper">
                {comments.map((reaction, index) => (
                  <div className="reactions__item" key={index}>
                    <span className="reactions__item__info" />
                    <Reaction
                      onClick={submitReaction}
                      reactionId={reaction.reaction}
                      startingNumber={mappedCount[reaction.reaction]}
                    />
                  </div>
                ))}
              </div>
            </div>
            <span id="like-link">
              <Reaction
                reactionId={highestReaction.reaction}
                onClick={submitReaction}
                startingNumber={
                  totalReactions
                    ? mappedCount[highestReaction.reaction]
                    : "  Like"
                }
              />
            </span>
          </div>
        </StyledWrapper>
      </StyledSingleWidth>
    </>
  );
}

const StyledSingleWidth = styled(Flex)``;

const StyledWrapper = styled(Flex)`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: flex-end;

  #like-link-wrapper {
    position: relative;
    top: 0px;
    right: 0px;
    &:hover {
      #reactions {
        visibility: visible;
        opacity: 1;
        transform: translate(-100px, 60%) translateX(-100px, -80%);
        transition:
          0.2s transform 0.5s,
          0.2s visibility 0.5s,
          0 opacity;
      }
      #reactions__wrapper {
        transform: translateY(0);
      }
      .reactions__item svg {
        transform: scale(1.2);
      }
    }
  }

  #like-link {
    display: block;
    cursor: pointer;
    position: relative;
  }

  #reactions {
    padding: 5px 5px;

    background: ${(props) => props.theme.colors.darkerNavy};
    display: inline-block;
    position: absolute;
    top: -50px;
    right: -130px;
    transform: translate(0px, calc(-0% - 10px));
    border-radius: 999px;
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.08),
      0 2px 2px rgba(0, 0, 0, 0.15);
    visibility: hidden;
    transition:
      0.1s transform 0.1s,
      0.1s visibility 0.1s,
      0.1s opacity 0.1s;
    opacity: 0;
    @media (max-width: ${({ theme }) => theme.breaks.standard}) {
      top: -50px;
      right: -71px;
    }
    @media (max-width: ${({ theme }) => theme.breaks.medium}) {
      top: -50px;
      right: -71px;
    }
  }

  #reactions__wrapper {
    transform: translateY(3px);
    transform-origin: center;
    transition: 0.2s transform 0.2s cubic-bezier(0.06, 1.51, 0.73, 1.81);
  }

  .reactions__item {
    display: table-cell;
    border-right: 0px solid transparent;
    cursor: pointer;
    min-width: 50px;
    position: relative;
    transform-origin: bottom center;
    animation: iconZoomOut 0.2s forwards;
    svg {
      width: 40px;
      transform: scale(0.5);
      transition: 0.2s transform 0.2s;
    }
    &:last-child {
      border-right: 0;
    }
  }

  .reactions__item__info {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(calc(-50% - 5px), calc(-100% - 10px));
    font-size: 12px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    color: white;
    padding: 20px 17px;
    display: none;
    min-width: 40px;
    transform-origin: center;
  }
`;
