import React from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

export function DividerAlt(props: any) {
  return <Box variant="dividerAlt" {...props} />;
}
export function Divider(props: any) {
  return (
    <StyledHorizontalDivider
      variant={props.variant ? props.variant : "divider"}
      {...props}
    >
      <Box px={props.children ? 15 : null} py={props.children ? 0 : null}>
        {props.children}
      </Box>
    </StyledHorizontalDivider>
  );
}

export function MenuDivider(props: any) {
  return <Box variant="menuDivider" {...props} />;
}

const StyledHorizontalDivider = styled(Flex)`
  &:before,
  &:after {
    content: "";
    display: block;
    height: 1px;
    background: ${(props) => props.theme.colors.navy};
    flex: 1;
    margin-top: 3px;
  }
`;

const StyledVerticalDivider = styled(Flex)`
  background: ${(props) => props.theme.colors.navy};
  flex: 1;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export function VerticalDivider({
  height,
  width,
  mx,
}: {
  mx: number;
  height: string;
  width: string;
}) {
  return (
    <StyledVerticalDivider
      variant="divider"
      height={height}
      maxWidth={width}
      width={width}
      mx={mx}
    />
  );
}
