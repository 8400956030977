import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";

import { FloatingContainer } from "../../../Wrappers";
import { Body, Headline, Large2 } from "../../../Text";
import { Button } from "../../../Button";

export const ModalContainer = styled(FloatingContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: 812px;
  margin: 0 auto;
`;

export const StepImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export const StyledFlex = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const StyledBody = styled(Body)`
  color: ${(props) => props.theme.colors.chalk};
  font-weight: 400;
  margin: 0 0 12px 0;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 0.8rem;
  }
`;

export const StyledHeadline = styled(Headline)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  margin: 0 0 0 6px;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 1rem;
  }
`;

export const StyledLarge2 = styled(Large2)`
  font-weight: 600;
  line-height: 0.75em;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    font-size: 2rem;
    line-height: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 0px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`;

export const StepText = styled.p`
  color: ${({ theme }) => theme.colors.envy};
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  font-weight: 700;
  margin: 6px 0;

  color: ${({ theme }) => theme.colors.envy};
`;

export const HeroContainer = styled(Flex)`
  width: 100%;
  flexdirection: column;
  max-height: 451px;
  margin: 0 0 14px 0;
`;

export const NavButton = styled(Button)`
  padding: 18px 0;
  width: 87px;

  :disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    padding: 12px 0;
    width: 87px;
  }
`;
