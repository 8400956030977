import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { BiEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

import { useUser } from "../../../Hooks/useUser";
import { Avatar } from "../../Avatar";
import { NAV_LINKS } from "../../Sidebar/utils";

const UserTierText = ({ tier }: { tier: string }) => {
  switch (tier) {
    case "FREE":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(316.03deg, #8FA9AF -1.86%, #8FA9AF 100%)",
          }}
        >
          Free Tier
        </StyledTierText>
      );
    case "TRIAL":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(316.03deg, #43A1C7 -1.86%, #4DBD94 100%)",
          }}
        >
          Pro Trial
        </StyledTierText>
      );
    case "PRO":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(316.03deg, #43A1C7 -1.86%, #4DBD94 100%)",
          }}
        >
          Pro Tier
        </StyledTierText>
      );
    case "PRO_PLUS":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(319.71deg, #A464DA 0%, #F66E86 100%)",
          }}
        >
          Pro Plus Tier
        </StyledTierText>
      );
    case "CREATOR_PLAT":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(130.31deg, #71AAFF 2.37%, #9A58D1 100%)",
          }}
        >
          Platinum Tier
        </StyledTierText>
      );
    case "INTERNAL":
      return (
        <StyledTierText
          style={{
            backgroundImage:
              "linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #e6a724 78%)",
          }}
        >
          Internal User
        </StyledTierText>
      );
    default:
      return <></>;
  }
};

const UserCard = ({ close }: { close: () => void }) => {
  const { allstarUser } = useUser();

  return (
    <StyledCard mb={4}>
      <Link to={`/profile?user=${allstarUser.user._id}`} onClick={close}>
        <StyledAvatar image={allstarUser?.user?.avatar} size="tiny" />
      </Link>
      <Flex flexGrow={1} flexDirection="column" style={{ gap: 4 }}>
        <Link
          to={`/profile?user=${allstarUser.user._id}`}
          onClick={close}
          style={{ textDecoration: "none" }}
        >
          <Text color="ice" fontWeight={500}>
            {allstarUser.user?.profile?.username}
          </Text>
        </Link>
        <UserTierText tier={allstarUser.user?.userGroup.type} />
      </Flex>
      <Link to={NAV_LINKS.accountSettings} onClick={close}>
        <StyledBiEditAlt />
      </Link>
    </StyledCard>
  );
};

const StyledTierText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 500;
  color: transparent;
  background-clip: text;
`;

const StyledAvatar = styled(Avatar)``;

const StyledCard = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 12px;
  gap: 20px;
  padding: 16px;
`;

const StyledBiEditAlt = styled(BiEditAlt)`
  color: ${({ theme }) => theme.colors.chalk};
  height: 24px;
  width: 24px;

  :hover {
    color: ${({ theme }) => theme.colors.envy};
  }
`;

export { UserCard };
