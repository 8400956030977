import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "../../Link";
import { Text, Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Modal } from "../../Wrappers";
import { Title3 } from "../../Text";
import { FloatingContainer } from "../../Wrappers";
import { MenuDivider } from "../../Divider";
import Lottie from "lottie-react";
import Remixing from "../../../../animations/remixing.json";

import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

const StyledFloatingContainer = styled(FloatingContainer)`
  max-width: 640px;
`;

export function UploadingModal() {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const redirectSecs = 5;
  const history = useHistory();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAllstarModalState({
        data: {},
        functions: {},
        isOpen: ModalType.None,
      });
      history.push("/profile");
    }, redirectSecs * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Modal
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        isModalOpen={allstarModalState.isOpen === ModalType.Uploading}
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <StyledFloatingContainer justifyContent="center" alignItems="center">
          <Box textAlign="center">
            <Title3 mb={5}>
              Clip on its way to {allstarModalState.data.platform}
            </Title3>
            <Flex style={{ width: "100%", justifyContent: "center" }}>
              {" "}
              <Lottie
                animationData={Remixing}
                style={{ height: 150, width: 150 }}
                loop={true}
              />
            </Flex>

            <MenuDivider mt={5} mb={7} mx={0} />
            <Title3>You can now close this modal.</Title3>
            <Text color="chalk" size="small" pt={3} pb={2}>
              {" "}
              <Link
                to={{
                  pathname: "#",
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  history.push("/profile");
                }}
                variant="link.title"
              >
                Click here
              </Link>{" "}
              if the modal does not close within {redirectSecs} seconds.
            </Text>
          </Box>
        </StyledFloatingContainer>
      </Modal>
    </>
  );
}
