import React from "react";
import { Link } from "../Button";
import { Flex } from "rebass/styled-components";
import { RiUserStarLine } from "react-icons/ri";
import theme from "../../../theme/themeThree";
import styled from "styled-components";

const avatarStyles = `
  align-items: center;
  justify-content: center;
`;

const AvatarLinkContainer = styled(Link)`
  display: flex;
  ${avatarStyles}
`;

const AvatarNotLinkContainer = styled(Flex)`
  ${avatarStyles}
`;

const AvatarLoadingContainer = styled(Flex)`
  ${avatarStyles}
`;

const iconSizes: any = {
  tiny: 17,
  nav: 24,
  small: 30,
  medium: 40,
  large: 52,
};

export const Avatar = (props: any) => {
  const passProps = {
    mr: props.mr,
    mb: props.mb,
    ml: props.ml,
    mt: props.mt,
    sx: theme.avatar,
    variant: `variants.avatar.${props.size}`,
    style: {
      backgroundImage: `url('${props.image}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    ...props.link,
  };

  if (props.image) {
    passProps.sx.bg = "ice";
  } else {
    passProps.children = <RiUserStarLine size={iconSizes[props.size]} />;
  }

  if (props.to) {
    passProps.to = props.to;
  }

  return props.loading ? (
    <AvatarLoadingContainer
      mr={passProps.mr}
      ml={passProps.ml}
      mb={passProps.mb}
      mt={passProps.mt}
      sx={passProps.sx}
      variant={passProps.variant}
    />
  ) : passProps.to ? (
    <AvatarLinkContainer {...passProps} />
  ) : (
    <AvatarNotLinkContainer {...passProps} />
  );
};

Avatar.defaultProps = {
  size: "small",
  loading: false,
};
