import React from "react";
import { Button } from "../button";
import { MdCloudDone } from "react-icons/md";
import styled from "styled-components";

const StyledDone = styled(MdCloudDone)`
  position: relative;
  top: ${(props) => props.theme.space[2]}px;
  margin-right: ${(props) => props.theme.space[3]}px;
`;

export function CloudDoneButton(props) {
  return (
    <Button {...props}>
      <StyledDone size={22} />
      {props.children}
    </Button>
  );
}
