import React, { useState } from "react";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { ResetRequest as ResetRequestContent } from "./request";
import { ResetRequestSuccess } from "./success";

export default function ResetRequest() {
  let [requestSent, setRequestSent] = useState(false);

  return (
    <FullScreenWrapper>
      {requestSent ? (
        <ResetRequestSuccess />
      ) : (
        <ResetRequestContent setRequestSent={setRequestSent} />
      )}
    </FullScreenWrapper>
  );
}
