import React from "react";
import { useQuery } from "@apollo/client";
import { MY_BALANCE } from "../GraphQL/montage";
import { useUser } from "../Hooks/useUser";
import { useRecoilState } from "recoil";
import { LedgerState } from "../State/ledger";
import { useEffect } from "react";

export const PrefillLedgerBalance = () => {
  const { allstarUser } = useUser();
  const [ledgerState, setLedgerState] = useRecoilState(LedgerState);
  const { data, error, refetch } = useQuery(MY_BALANCE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context: { headers: { authorization: `Bearer ${allstarUser.token}` } },
    skip: !allstarUser.token,
  });

  useEffect(() => {
    setLedgerState({
      ...ledgerState,
      localBalance: data?.myLedgerBalance?.balance || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.myLedgerBalance?.balance]);

  useEffect(() => {
    if (allstarUser.token) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allstarUser.token]);

  useEffect(() => {
    if (error) {
      window.rudderanalytics.track("Error Loading Nav Montage Credits", {
        msg: "Generic error",
        allstarUserToken: allstarUser?.token,
        localStorageToken: localStorage.getItem("token"),
        error: error.toString(),
      });
    }
  }, [error, allstarUser?.token]);
  return <></>;
};
