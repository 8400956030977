import React from "react";
import { Textarea as RebassTextarea } from "@rebass/forms/styled-components";
import styled from "styled-components";
import theme from "../../../../theme/themeThree";

const StyledTextarea = styled(RebassTextarea)((props: any) => {
  return props.error
    ? {
        borderColor: theme.colors.error,
        ":hover": {
          borderColor: theme.colors.error,
        },
        ":active": {
          borderColor: theme.colors.error,
        },
        ":focus": {
          borderColor: theme.colors.error,
        },
      }
    : {};
});

const TextArea = ({ input, ...props }: any) => {
  return (
    <StyledTextarea
      sx={{
        ...theme.input,
        ...theme.variants.textarea.default,
      }}
      {...props}
      {...input}
    />
  );
};

export { TextArea };
