import React from "react";
import { Box } from "rebass/styled-components";

export default function Large3(props) {
  return (
    <Box variant="text.large3" {...props}>
      {props.children}
    </Box>
  );
}
