import React from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";

let styles = {
  maxWidth: (props) => props.theme.maxWidth,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
};

let StyledFlex = styled(Flex).attrs({ pr: [0] })(styles);
let StyledBox = styled(Box).attrs({ pr: [0] })(styles);

const FullWidthWrapper = (props) => {
  let { display, passedRef, ...rest } = props;
  let El = display === "box" ? StyledBox : StyledFlex;

  return (
    <El ref={passedRef} {...rest}>
      {props.children}
    </El>
  );
};

FullWidthWrapper.defaultProps = {
  display: "flex",
};

export { FullWidthWrapper };
