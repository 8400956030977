import React, { useEffect } from "react";
import styled from "styled-components";
import { FullScreenWrapper } from "../../../components/wrappers/fullScreenWrapper";
import { Image, Flex, Box } from "rebass/styled-components";
import { Body } from "../../../components/text";
import Blur from "../../../assets/images/studio/marketing/card-row.png";
import { DividerAlt } from "../../../components/atoms/dividerAlt";
import { PageHeader } from "../../../components/molecules/pageHeader";
import { ReactComponent as Badge } from "../../../assets/images/badges/remix-icon.svg";

const Freq = styled(Flex)`
  font-size: 16px;
  font-weight: 600;
  padding-left: 25px;
  padding-right: 25px;
`;

const FloatyImage = styled(Image)`
  position: absolute;
  z-index: 99;
  top: ${(props) => props.y}%;
  left: ${(props) => (props.left ? `${props.left}%` : null)};
  right: ${(props) => (props.right ? `${props.right}%` : null)};

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    left: ${(props) => (props.left ? `${(60 + props.left * -1) * -1}%` : null)};
    right: ${(props) =>
      props.right ? `${(65 + props.right * -1) * -1}%` : null};
  }
`;

const FloatyImageHideOnMobile = styled(FloatyImage)`
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const ContentColumn = styled(Flex)`
  position: relative;
  width: 35%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }
`;

const SpeedoColumn = styled(ContentColumn)`
  justify-content: center;
  width: 65%;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ContentContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[8]}px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledFullScreenWrapper = styled(FullScreenWrapper)`
  position: relative;
  min-height: calc(80vh - ${(props) => props.theme.sizes[1]}px);
`;

const Frequency = () => {
  useEffect(() => {
    window.rudderanalytics.page("Pro Landing Upgrade");
  });
  return (
    <>
      <PageHeader
        image={<Box as={Badge} fill="currentColor" width="130px" />}
        title={<>Clip Remix.</>}
        subtitle="Got ideas... bring them to life"
      />
      <StyledFullScreenWrapper>
        <FloatyImageHideOnMobile src={Blur} maxWidth="800px" left="0" y="10" />

        <ContentContainer justifyContent="space-between">
          <SpeedoColumn pr={[0, 0, 10]}></SpeedoColumn>
          <ContentColumn pl={[0]} py={[0]}>
            <Flex alignItems="flex-start" mb={7} flexDirection="column">
              <Freq
                mb={6}
                variant="studio"
                flexDirection="row"
                alignItems="center"
              >
                Find your style
              </Freq>

              <Body>
                Don't like the watermark? Want a different outro, maybe you love
                the new filters. Remixing a clip is all about you and how want
                your content to be seen and with a heap of categories to choose
                from, you'll find yourself.
              </Body>
            </Flex>
          </ContentColumn>
        </ContentContainer>
        <DividerAlt />
      </StyledFullScreenWrapper>
    </>
  );
};

export default Frequency;
