import React, { useEffect } from "react";
import { Flex, Image } from "rebass/styled-components";
import { ClipTitleEdit } from "./content";
import styled from "styled-components";
import { Subheader } from "../Text";
import { RiEditLine } from "react-icons/ri";

import { Media } from "./Components/MediaContent";

export const Clip = ({
  clip,
  onTitleSave,
  titleError,
  setTitleError,
  isFinalizeMontage,
  title,
}) => {
  useEffect(() => {
    // ensure we are not rerendering edit.js
    console.count(`Edit.Clip`);
  }, []);

  return (
    <StyledContainer
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      py={[4, 4, 6, 7]}
      height="100%"
    >
      <Flex
        alignItems="flex-start"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Flex width={"100%"} height="100%" justifyContent={"center"}>
          <Media
            clip={clip.clip}
            isImage={isFinalizeMontage}
            image={
              clip.clip.originalClips?.length
                ? clip.clip.originalClips[0].clipImageThumb
                : clip.clip.clipImageThumb
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
      >
        <Icons
          hideOnMobile={true}
          as={RiEditLine}
          size={36}
          mt={1}
          mr={2}
          color="envy"
        />

        <ClipTitleEdit
          title={title}
          save={onTitleSave}
          titleError={titleError}
          setTitleError={setTitleError}
          clip={clip}
        />
      </Flex>
      <Flex flexDirection="column" justifyContent="center">
        <StyledLine mb={3} ml={[5, 5, 0, 0]}></StyledLine>
        <Subheader my={3} mb={6} ml={[5, 5, 0, 0]} color="chalk">
          Min 3 Chars &nbsp; | &nbsp; Max 40 chars &nbsp; | &nbsp; Can set Clip
          Title once
        </Subheader>
        {isFinalizeMontage && (
          <StyledThumb
            hideOnMobile={true}
            src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clip.clipSnapshot}`}
          />
        )}
      </Flex>
    </StyledContainer>
  );
};

const StyledLine = styled(Flex)`
  background: ${(props) => props.theme.colors.chalkAlpha20};
  border-radius: 999px;
  height: 2px;
  width: 50px;
`;

const Icons = styled(Flex)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (props.hideOnMobile ? "display:none" : null)};
  }
`;

const StyledThumb = styled(Image)`
  background-color: #333f48;
  max-width: 250px;
  height: auto;
  border-radius: 5px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => (props.hideOnMobile ? "display:none" : null)};
  }
`;

const StyledContainer = styled(Flex)`
  height: 100%;
  width: 100%;
`;
