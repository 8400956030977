import React from "react";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri"
import { Flex } from "rebass";
import { TSequence, TSequenceModalState } from "../../@types";

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<TSequenceModalState>>;
  sequence: TSequence | undefined;
}

export const SequenceModal: React.FC<Props> = ({
  isOpen,
  setOpen,
  sequence,
}: Props) => {
  if (!sequence) return <></>;

  const closeModal = () => {
    setOpen({ isOpen: false, sequence: {} as TSequence });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <CloseWrapper
          onClick={() => {
            closeModal();
          }}
        >
          <Close>
            <StyledSVG />
          </Close>
        </CloseWrapper>{" "}
        <Row>
          {" "}
          <Video
            src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${sequence.video}`}
            controls={true}
          />
        </Row>
      </ModalContent>
    </Modal>
  );
};

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: calc(14px);
`;

interface ModalStyledProps {
  isOpen: boolean;
}

const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.ice};
  margin: 15px 15px 15px 15px;
`;

const StyledSVG = styled(RiCloseFill)`
  height: 18px;
  width: 18px;
  color: ${({ theme }) => theme.colors.iceAlpha60};
`;

const ModalContent = styled(Flex)`
  flex-direction: column;
  position: fixed;
  margin-top: auto;
  margin-left: 20px;
  margin-right: 30px;
  margin-bottom: auto;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  flex-direction: column;
  align-items: start;
  max-width: 100%;
  max-height: 650px;
  width: 750px;
  justify-content: start;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 20px;
  z-index: 1050;
  padding: 15px;
`;

const Modal = styled.div<ModalStyledProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
  background-color: rgba(12, 19, 24, .8);
`;

const Close = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  background: ${({ theme }) => theme.colors.ned};
  border: 1px solid ${({ theme }) => theme.colors.darkerNavy};
  height: 26px;
  width: 26px;
  border-radius: 999px;
  transition: 0.3s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.alert};
    border: 1px solid ${({ theme }) => theme.colors.alert};
    cursor: pointer;
  }
  &:hover ${StyledSVG}{ 
    color: ${({ theme }) => theme.colors.ice};
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
`;
