import React from "react";
import styled from "styled-components";
import { PaddedWrapper } from "./paddedWrapper";

let StyledPaddedWrapper = styled(PaddedWrapper)`
  min-height: calc(100vh - ${(props) => props.theme.sizes[1]}px);
`;

function FullScreenWrapper(props) {
  return (
    <StyledPaddedWrapper
      alignItems="center"
      justifyContent={props.justifyContent}
      {...props}
    >
      {props.children}
    </StyledPaddedWrapper>
  );
}

FullScreenWrapper.defaultProps = {
  justifyContent: "center",
};

export { FullScreenWrapper };
