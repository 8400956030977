export function getDisplayName(clip) {
  return clip.username ? clip.username : clip.steamHandle;
}

export function getClipTitle(clip) {
  if (!clip) return;
  if (clip.userClipTitle) {
    return `${clip.userClipTitle}`;
  }
  if (clip.clipTitle && clip.clipTitle !== "") {
    return `${clip.clipTitle}`;
  } else {
    return `${clip.clipTitleSuffix}`;
  }
}

export const clipBucketURL = process.env.REACT_APP_S3_CLIP_BUCKET;

export function getSnapshotPath(snapshot) {
  if (!snapshot) {
    return null;
  }
  const overlayPrefix = "_w_overlay";
  let path = "/";

  if (snapshot.indexOf(overlayPrefix) !== -1) {
    path += "thumbs/" + snapshot.replace(overlayPrefix, "");
  } else {
    path += snapshot;
  }
  return clipBucketURL + path;
}

export function formatNumber(num, lowerBoundary = 10000) {
  if (!num) {
    return 0;
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).toLocaleString() + "M";
  }

  if (num >= lowerBoundary) {
    return (num / 1000).toFixed(1).toLocaleString() + "K";
  }

  return num.toLocaleString();
}
