import React from "react";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { space, color } from "styled-system";
import styled from "styled-components";
import theme from "../../../theme/themeThree";

const StyledIcon = styled(HiOutlineSpeakerphone).attrs((props) => ({
  ...props,
}))((props) => props.styles, space, color);

const AlertIcon = (props) => {
  return <StyledIcon {...props} />;
};

AlertIcon.defaultProps = {
  size: 22,
  styles: {
    position: "relative",
    top: "4px",
    marginRight: "10px",
    color: theme.colors.envy,
  },
};

export default AlertIcon;
