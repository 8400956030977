import React, { useEffect } from "react";
import { FloatingContainer, Modal } from "../../Wrappers";

import { MontagesMTX } from "./Product/montages";
import { AllstarModalState } from "../../../State/modals";
import { useRecoilState } from "recoil";
import { ModalType } from "../../../@types";
import styled from "styled-components";

export const MicroTransactions = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const closeModal = () =>
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.None,
    });

  useEffect(() => {
    if (allstarModalState?.isOpen !== ModalType.MicroTransactions) return;

    window.rudderanalytics.track("Paywall - User Paywalled", {
      feature: `MTX - ${allstarModalState?.data?.type}`,
    });
  }, [allstarModalState]);
  if (allstarModalState?.isOpen !== ModalType.MicroTransactions) return <></>;
  return (
    <>
      <StyledModal
        isModalOpen={allstarModalState?.isOpen === ModalType.MicroTransactions}
        setModalOpen={() => null}
        handleModalClose={closeModal}
        disableOutsideClose={false}
        id="styled-modal"
      >
        <FloatingContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          maxWidth="1153px"
        >
          <MontagesMTX />
        </FloatingContainer>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    align-items: flex-start;
    margin-bottom: -60px;
    position: absolute;
  }
`;
