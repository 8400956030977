import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { Headline, Large2, Subheader } from "../../Text";
import { MdOutlineDesignServices, MdStorage } from "react-icons/md";
import { HiArrowSmDown, HiArrowSmRight, HiSparkles } from "react-icons/hi";
import { Body } from "../../Text";
import { Title1, Large3 } from "../../Text";
import SVG from "react-inlinesvg";

const CountdownDate = styled(Flex)`
  color: #fff;
  z-index: 2;
  flex-grow: 1;
  width: 60%;
`;

const DateBackground = styled(Flex)`
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 10px;
  &:last-child {
    margin-right: 0px;
  }
`;

const StyledClockBody = styled(Large2)`
  font-weight: 600;
  font-size: 40px;
  color: #65c9d1;
  padding-left: 10px;
  padding-right: 10px;
`;

const HideOnMobile = styled(Flex)`
  border-radius: 10px;
  display: flex;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

const StyledDesign = styled(MdOutlineDesignServices)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledSparkles = styled(HiSparkles)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledStorage = styled(MdStorage)<any>`
  height: 32px;
  width: 32px;
  margin-bottom: 5px;
  color: #4dbd94;
`;

const StyledHeader = styled(Headline)`
  font-weight: 600;
  color: #f7fcfd;
`;

const StyledSubheader = styled(Subheader)`
  font-weight: 500;
  color: #8fa9af;
  text-align: center;
  list-style-type: none;
  margin-top: 10px;
  line-height: 1.8;
`;

const StyledBG = styled(Flex)`
  background: #18252d;
  width: 100%;
  padding: 30px;
  justify-content: space-between;
  border-radius: 10px;
`;

const StyledCenterText = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const UserReview = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  @media (max-width: 990px) {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  @media (max-width: 640px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const StarsRow = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-left: 20px;
  height: 46px;
  @media (max-width: 990px) {
    padding-left: 0px;
    padding-top: 10px;
  }
`;

const UserRow = styled(Image)`
  height: 50px;
  width: 200px;
`;

export const CardTitle = styled(Large3)`
  font-weight: 800;
  display: flex;
  color: #f7fcfd;
`;

export const MonthText = styled(CardTitle)`
  text-align: left;
  color: #8fa9af;
  font-size: 22px;
  margin-bottom: 2px;
  margin-left: 5px;
`;
export const ModalFooter = styled(Body)`
  text-align: left;
  max-width: 845px;
  color: #8fa9af;
  font-size: 14px;
`;
const StarsText = styled(ModalFooter)`
  text-align: left;
  color: #cad1d3;
`;

const UpgradeText = styled(ModalFooter)`
  text-align: left;
  color: #d9e3e9;
  font-weight: 500;
`;
export const ProText = styled(ModalFooter)`
  text-align: left;
  color: #d9e3e9;
  font-size: 14px;
  margin-bottom: 15px;
  text-decoration: #336b63 wavy underline;
`;

const Icon = styled.svg`
  max-width: 62px;
  max-height: 62px;
`;

const Pro = styled.svg`
  min-width: 28px;
  min-height: 28px;
`;

const Stars = styled.svg`
  min-width: 112px;
  min-height: 21px;
`;

export const StyledRightArrow = styled(HiArrowSmRight)<any>`
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-top: 2px;
`;

const StyledDownArrow = styled(HiArrowSmDown)<any>`
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-top: 2px;
  color: #8fa9af;
`;
const SubText = styled(Body)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #8fa9af;
`;

export const SubTextStrike = styled(SubText)`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4dbd94;
`;

export const BuyButton = styled.button`
  border: 0;
  border-radius: 8px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  letter-spacing: 0.0016em;
  color: #f7fcfd;
  padding: 2px 60px 4px 60px;
  background: #4dbd94;
  &:hover {
    cursor: pointer;
    background: #45aa85;
  }
  transition: all ease 0.35s;
`;

export const AllPlansButton = styled.button`
  border: 0;
  border-radius: 999px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  letter-spacing: 0.0016em;
  color: #d9e3e9;
  padding: 2px 6px;
  background: #213442;
  &:hover {
    cursor: pointer;
    background: #45aa85;
    color: #f7fcfd;
  }
  transition: all ease 0.35s;
`;

export const CardGrid = styled(Flex)`
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
`;

export const Card = styled.div`
  max-width: 100%;
  width: 280px;
  max-height: 100%;
  height: 185px;
  border-radius: 20px;
  border: 1px solid #0c1318;
  position: relative;
  background: #234342;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  padding: 20px;
  z-index: 5;

  position: relative;
`;

export const CardBelow = styled.div`
  max-width: 100%;
  width: 260px;
  max-height: 100%;
  height: 105px;
  border-radius: 0 0 20px 20px;
  border: 1px solid #0c1318;
  position: relative;
  top: -20px;
  background: #18252d;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 30px 20px 5px 20px;
  z-index: 4;
  position: relative;
`;

export const ModalBody = styled(Body)`
  text-align: left;
  max-width: 845px;
  color: #cad1d3;
`;

export const ModalTitle = styled(Title1)`
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #f7fcfd;
  ${(props) =>
    props.highlight
      ? `
  background-image: linear-gradient(226.68deg, #71D1DD 12.99%, #71ABEE 43.08%);

  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  `
      : ""};
  @media (max-width: 640px) {
    margin-top: 10px;
    margin-left: 10px;
    line-height: 26px;
  }
`;

export const LeftSide = styled(Flex)`
  flex-direction: column;
  max-width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-left: 35px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 0px;
    padding-bottom: 30px;
  }
  @media (max-width: 1200px) {
    padding-left: 25px;
    max-width: 55%;
  }
  @media (max-width: 1100px) {
    padding-left: 0px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const RightSide = styled(Flex)`
  flex-direction: column;
  max-width: 40%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const TopHalf = styled(Flex)`
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`;

export const BottomHalf = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 35px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Wrap = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 5;
  padding: 30px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const StyledBadge = styled<any>(SVG)`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 120px;
  width: 120px;
`;

export const StyledBody = styled(Body)`
  font-weight: 500;
`;

export const Banner = styled(Flex)`
  background: ${(props) => props.theme.colors.saleGradient};
  color: ${(props) => props.theme.colors.saleGradient};
  padding: ${(props) => props.theme.space[3]}px;
  border-radius: 10px;
  margin-bottom: 100px;
  justify-content: center;
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  flex-grow: 2;
  z-index: 100;
`;

const PercentOffText = styled<any>(Body)`
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
  ${(props: any) => {
    if (props.shrinkText) {
      return `
        padding-top:4px;
        font-size: 12px;
      `;
    }
  }}
  position: absolute;
  top: 3px;
  right: 5px;
  height: 120px;
  width: 120px;
  transform: rotate(45deg);
  font-weight: 600;
`;
