import React from "react";
import { PageHeader } from "../../components/molecules/pageHeader";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { ReactComponent as AllstarLogo } from "../../assets/images/logo/AMarkLight.svg";
import { Button } from "../../components/atoms/form/button";
import { Box } from "rebass/styled-components";
import { Brand as BrandContent } from "./content";

export default function Brand() {
  return (
    <>
      <PageHeader
        image={<Box as={AllstarLogo} mb={[5, 5, 7]} />}
        title="Our Brand"
        subtitle="
            Hello, we’re Allstar. We create software that is helping build the
            next generation of gaming content creators. We game, we have fun and
            we like to push boundaries. Below are the building blocks for our
            brand. This page is here for you to get you started, for all media
            inquiries about Allstar, please contact our brand team.
        "
        posttext={
          <Button
            mt={[6]}
            flex={true}
            href="mailto:brand@allstar.gg"
            target="_blank"
          >
            Brand @ Allstar
          </Button>
        }
      />

      <StaticContainer>
        <PaddedWrapper
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          data-testid="brand"
        >
          <BrandContent />
        </PaddedWrapper>
      </StaticContainer>
    </>
  );
}
