import React, { useEffect, useState } from "react";
import Fire from "../../assets/images/reactions/FIRE.svg";
import Hype from "../../assets/images/reactions/HYPE.svg";
import Spicy from "../../assets/images/reactions/SPICY.svg";
import Explode from "../../assets/images/reactions/EXPLODE.svg";
import { Image, Flex } from "rebass/styled-components";
import { formatNumber } from "./clip/utils";
import styled, { keyframes } from "styled-components";

const SVG_MAP = {
  FIRE: Fire,
  HYPE: Hype,
  SPICY: Spicy,
  EXPLODE: Explode,
};

const ReactionImage = styled(Image)`
  height: 24px;
  width: 40px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 20px;
    width: 20px;
  }
`;

const floatAway = keyframes`
  from {
    transform: scale(1.2) translateY(0);
    opacity: 1;
  }
  to {
    transform: scale(2) translateY(-30px) translateX(0) rotate(-25deg);
    opacity: 0;
  }
`;

let ReactionWrapper = styled(Flex)`
  user-select: none;
  position: relative;
  height: 40px;
  width: 80px;
  background-color: ${(props) => props.theme.colors.navy};
  color: ${(props) => props.theme.colors.ice};
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 8px;
  border-radius: 999px;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkerNed};
  }
  @media (max-width: 1278px) {
    height: 36px;
    fontsize: 0.825rem;
    width: 72px;
  }
  @media (max-width: 425px) {
    height: 36px;
    fontsize: 0.825rem;
    width: 65px;
  }
`;

let Emoji = styled(Flex)`
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 1;

  &:hover {
    transform: scale(1.2);
  }
`;

let FloatingEmoji = styled(Flex)`
  animation: ${floatAway} 0.7s ease-out;
  animation-fill-mode: forwards;
  position: absolute;
  z-index: 1;
  top: 0;
`;

let ReactionCount = styled(Flex)`
  cursor: pointer;
  padding: 3px 6px;
  min-height: 26px;
  min-width: 26px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    min-height: 22px;
    min-width: 22px;
    padding: 3px 5px;
  }
`;

const ReactionEmoji = (props) => {
  const delay = 750;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, delay);
  }, [delay]);

  return visible ? (
    <FloatingEmoji key={props.i}>{props.children}</FloatingEmoji>
  ) : null;
};

export function Reaction({ count, ...props }) {
  const [clickCount, setClickCount] = useState(0);
  const currentCount = props.startingNumber;
  let addReaction = (e) => {
    if (props.disableClick) return;

    if (clickCount < 100) {
      e.preventDefault();
      e.stopPropagation();
      props.onClick(props.reactionId);
      setClickCount(clickCount + 1);
    }
  };

  return (
    <>
      {[...Array(clickCount)].map((e, i) => (
        <ReactionEmoji key={i} i={i}>
          <ReactionImage src={SVG_MAP[props.reactionId]}></ReactionImage>
        </ReactionEmoji>
      ))}
      <ReactionWrapper
        onClick={addReaction}
        alignItems="center"
        flexDirection="row"
        justifyContent="center"
      >
        <Emoji
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <ReactionImage src={SVG_MAP[props.reactionId]}></ReactionImage>
        </Emoji>
        <ReactionCount variant="reactionCount">
          {currentCount ? formatNumber(currentCount, 1000) : `-`}
        </ReactionCount>
      </ReactionWrapper>
    </>
  );
}

Reaction.defaultProps = {
  emoji: "🌶️",
  startingNumber: 0,
  onClick: () => {},
};
