import React from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Owner } from "./owner";
import { getDisplayName } from "./utils";
import { getAvatarBorderLevel } from "../../../MVVM/Utilities/User";
import { getAvatarImage } from "../../../MVVM/Utilities/User";
import { Subheader } from "../../text";
import { useTranslation } from "react-i18next";
import { HiOutlineShieldCheck } from "react-icons/hi";

const StyledRemix = styled(Flex)`
  padding: 10px;
  background-color: transparent;
  flex-shrink: 0;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;

export function RemixedClip({
  clip,
  allstarUser,
  followUnfollow,
  setFollowModal,
  ...props
}) {
  const originalUser = {
    username: clip.remix.ui.username,
    discord: { id: clip.remix.ui.discordId },
    _id: clip.remix.originalUser,
    rank: clip.remix.ui.userRank,
    userGroup: { type: clip.remix.ui.userGroup },
    shareId: clip.remix.ui.shareId,
    avatar: clip.remix.ui.allstarAvatar,
  };
  const avatarBorderLevel = getAvatarBorderLevel(props.user);
  const hide = clip.user._id;
  const { t } = useTranslation("translation", {
    keyPrefix: "clip.remixClip",
  });
  const history = useHistory();

  return (
    <StyledFlex
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="row"
    >
      <StyledRemix
        ml={2}
        mr={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <StyledCheckBadge />
        <Subheader
          ml={2}
          color="chalk"
          shareId={clip.shareId}
          clipId={clip._id}
          onClick={() => {
            history.push(`/clip?clip=${clip.remix.ui.shareId}`);
          }}
        >
          {t("body")}
        </Subheader>
      </StyledRemix>
      {/* <StyledOutlineArrowSmRight /> */}
      <StyledRemixOwner
        alignItems="center"
        justifyContent="space-between"
        avatarSize="tiny"
        ownerId={originalUser._id}
        user={originalUser}
        hideTitle={hide}
        hideFollow
        type={avatarBorderLevel}
        displayName={getDisplayName(originalUser)}
        avatar={getAvatarImage(originalUser)}
        allstarUser={allstarUser}
        followUnfollow={followUnfollow}
        setFollowModal={setFollowModal}
      />
    </StyledFlex>
  );
}

const StyledCheckBadge = styled(HiOutlineShieldCheck)`
  color: ${(props) => props.theme.colors.envy};
  width: 20px;
  height: 20px;
`;

const StyledFlex = styled(Flex)`
  background: ${(props) => props.theme.colors.midnightAlpha50};
  width: 100%;
  border-radius: 0 0 8px 8px;
  height: 60px;
`;

const StyledRemixOwner = styled(Owner)`
  color: ${(props) => props.theme.colors.ice};
  font-size: 1em;
  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;
