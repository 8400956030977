import React from "react";
import { Box } from "rebass/styled-components";

export function StaticContainer({ pt, pb, py, className, children, z1 }) {
  return (
    <Box
      pt={pt}
      pb={pb}
      py={py}
      className={className}
      variant="staticContainer"
      style={z1 ? { zIndex: 1 } : undefined}
    >
      {children}
    </Box>
  );
}
