import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "../../Button";
import { Modal } from "../../Wrappers";

export const ContentContainer = styled(Flex)`
  flex-direction: row;
  position: relative;
  border-radius: 10px;
  overflow-y: auto;
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    flex-direction: column;
  }
`;

export const SaveButton = styled(Button)`
  width: 115px;
`;

export const PinnedFooterRow = styled(Flex)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  z-index: 1;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    background-color: ${({ theme }) => theme.colors.darkerNed};
    border-top: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
`;

export const StyledModal = styled(Modal)`
  overflow-y: auto;
  border-radius: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerWrapper = styled(Flex)`
  height: 80vh;
  width: 80vw;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
`;
