import { lazy } from "react";
import { Route } from "react-router-dom";

import { getChunkable } from "./utils";

const LazyOverwolf = lazy(() => import("../MVVM/Views/Overwolf"));

const brandPages = [
  <Route
    exact
    key="overwolf"
    path="/overwolf"
    component={getChunkable(LazyOverwolf)}
  />,
  <Route
    exact
    key="allstar/gamersclub"
    path="/allstar/gamersclub"
    render={getChunkable(LazyOverwolf, {
      cta: "signup",
    })}
  />,
];

export { brandPages };
