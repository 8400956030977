import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";

export const FullWidthWrapper = styled(Flex)`
  width: 100%;

  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.colors.ned};
  }
  ::-webkit-scrollbar {
    width: 18px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.ned};
    border: 5px solid transparent;
    border-radius: 999px;
    background-clip: content-box;
  }
`;

export const VideoPlayerContainer = styled(Box)<{ displayOnTop: boolean }>`
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme.colors.darkerNed};
  padding: 4px;
  border-radius: 9px;
  position: absolute;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    padding: 0;
  }
  z-index: ${({ displayOnTop }) => (displayOnTop ? 1 : 0)};
  .video-js,
  .vjs-tech {
    height: 100%;
    width: 100%;
    border-radius: 9px;
    background-color: transparent;
  }
  .vjs-poster {
    background-color: transparent;
  }
`;
export const SixteenByNineSpaceMaker = styled.div`
  padding-top: 59.25%;
  width: 100%;
`;
