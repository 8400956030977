import { Box } from "rebass/styled-components";
import styled from "styled-components";

const StaticContentCopy = styled(Box)`
  word-break: break-word;

  a {
    color: ${(props) => props.theme.colors.envy};
  }

  strong {
    font-weight: 500;
  }
`;

export default StaticContentCopy;
