import React from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { CreatorCard } from "../../../../Components/CreatorCard";

import { CountIndicator } from "../CountIndicator";
import { MontageSession } from "../../../../State";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { TMontageCreatorCard, IMusicProps } from "../../@types";
import { Controls } from "../../../../Components/CreatorCard/Components/Controls";

import { useUser } from "../../../../Hooks/useUser";
import { useRecoilState } from "recoil";
import { hasScope } from "../../../../Utilities/Studio";
import { TieredCard } from "../../../../Components/Tooltip/Components/TieredCard";
import { Grid } from "../../../../Components/Grid";

export const MusicSelector = ({
  musicLoading,
  musicData,
  setModal,
}: IMusicProps) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const { allstarUser } = useUser();
  if (musicLoading || !musicData) return <MusicLoading />;
  console.log(montageSession);
  const onSelect = (card: TMontageCreatorCard | {}) => {
    setMontageSession({ ...montageSession, music: card });
  };

  return (
    <>
      <CountIndicator
        heading={"Music"}
        count={montageSession?.music?._id ? 1 : 0}
        canProceed={montageSession?.music?._id}
        max={1}
      />
      <Grid>
        {musicData.facets.cards.map((card: TMontageCreatorCard) => {
          const isActive = montageSession?.music?._id === card._id;
          return (
            <StyledBox key={card._id}>
              <CreatorCard
                card={card}
                isModal={false}
                setModal={setModal}
                handleCardClick={() => {}}
                handleModalClose={() => {}}
              />

              <Controls
                handleCardClick={() => {}}
                card={card}
                hideToolTip={
                  allstarUser.loggedIn &&
                  (!!hasScope(allstarUser.user, card.scopeUse) ||
                    montageSession.enableProFeatures)
                }
                tooltipContent={<TieredCard rarity={card.ui.rarity} />}
                handleToggle={(e: any) => {
                  if (
                    allstarUser.loggedIn &&
                    !hasScope(allstarUser.user, card.scopeUse) &&
                    !montageSession.enableProFeatures
                  ) {
                    setModal({
                      type: card.ui.rarity,
                      data: {
                        card: card,
                        hasScope: hasScope(allstarUser.user, card.scopeUse),
                      },
                    });
                    return false;
                  }
                  if (isActive) onSelect({});
                  else onSelect(card);
                  return true;
                }}
                onQuery={() => {}}
                isActive={isActive}
                outsideControl={true} // outside control is false for studio,
                hasScope={hasScope(allstarUser?.user, card.scopeUse)}
                setModal={setModal}
              />
            </StyledBox>
          );
        })}
      </Grid>
    </>
  );
};

const MusicLoading = () => {
  return (
    <>
      <Flex
        justifyContent={"center"}
        width="100%"
        height="100%"
        flexDirection="column"
      >
        <Box alignSelf="flex-start">
          <LoadingSkeleton
            width={"115px"}
            height={"28px"}
            style={{ borderRadius: "8px" }}
            containerClassName={"align-self-start"}
          />
        </Box>
        <Grid>
          {[1, 1, 1, 1, 1, 1, 1, 1].map((_, index: number) => {
            return (
              <LoadingSkeleton
                key={index}
                height={"270px"}
                style={{ borderRadius: "14px" }}
                containerClassName="flex-grow-1"
              />
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.colors.ice};
  border-radius: 14px;
  border: ${(props) => (props.selected ? "2px dotted #4dbd94" : "")};
  &:hover {
    cursor: pointer;
  }
`;
