import React, { useState, useEffect } from "react";
import { Flex } from "rebass/styled-components";
import { TbPinned, TbPinnedFilled } from "react-icons/tb";
import styled from "styled-components";
import { Body } from "../../Text";
import { ApolloError } from "@apollo/client";
import { LoadingSpinner } from "../../Lottie";
import { MdError } from "react-icons/md";
import { useRecoilState } from "recoil";
import { PinnedClipWithError } from "../../../State/clip";

export const PinClipButton = ({
  hasBeenPinned,
  clipId,
  editPlaylist,
  isVisible,
}: {
  hasBeenPinned: boolean;
  clipId: string;
  editPlaylist: (clipId: string, type: "ADD" | "REMOVE") => void;
  isVisible: boolean;
}) => {
  const [isPinned, setIsPinned] = useState(hasBeenPinned);
  const [pinnedClipWithError, setPinnedClipWithError] =
    useRecoilState(PinnedClipWithError);
  const [loading, setLoading] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(isVisible);
  const [forceDisplay, setForceDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (forceDisplay) return setShouldDisplay(true);
    return setShouldDisplay(isVisible);
  }, [forceDisplay, isVisible]);

  useEffect(() => {
    if (errorMessage) {
      setLoading(false);
      setForceDisplay(true);
      setTimeout(() => {
        setErrorMessage("");
        setPinnedClipWithError({
          clipId: "",
          errorMessage: "",
        });
        setForceDisplay(false);
      }, 3000);
    }
  }, [errorMessage, setErrorMessage, setPinnedClipWithError]);

  useEffect(() => {
    if (pinnedClipWithError.clipId === clipId) {
      return setErrorMessage(pinnedClipWithError.errorMessage);
    }
    setErrorMessage("");
  }, [pinnedClipWithError, clipId]);

  useEffect(() => {
    if (loading) setForceDisplay(true);
  }, [loading]);

  return (
    <RoundedPill
      onClick={async () => {
        setLoading(true);

        setTimeout(async () => {
          try {
            if (isPinned) {
              await editPlaylist(clipId, "REMOVE");
              window.rudderanalytics.track("Pinned Clip - Remove Clip", {});
            } else {
              await editPlaylist(clipId, "ADD");
              window.rudderanalytics.track("Pinned Clip - Add Clip", {});
              setIsPinned(true);
            }

            setLoading(false);
          } catch (e) {
            const error = e as ApolloError;
            if (error.message.match(/more than 3/i)) {
              setErrorMessage(
                "You can only pin up to 3 clips at a time. Unpin a clip to free up space.",
              );
              window.rudderanalytics.track("Pinned Clip - Too Many Clips", {});
            }
          }
        }, 1000);
      }}
      hasError={!!errorMessage}
      isPinned={isPinned}
      isVisible={shouldDisplay}
    >
      {errorMessage && (
        <>
          <StyledErrorIcon size={20} />
          <StyledBodyText>{errorMessage}</StyledBodyText>
        </>
      )}
      {loading && <LoadingSpinner height={20} width={20} />}
      {isPinned && !loading && !errorMessage && (
        <>
          <StyledFilledPinnedIcon size={20} />
          <StyledBodyText>Unpin</StyledBodyText>
        </>
      )}
      {!isPinned && !loading && !errorMessage && (
        <>
          <StyledPinnedIcon size={20} />
          <StyledBodyText>Pin</StyledBodyText>
        </>
      )}
    </RoundedPill>
  );
};

const RoundedPill = styled(Flex)<{
  isPinned: boolean;
  hasError: boolean;
  isVisible: boolean;
}>`
  padding: 6px;

  min-width: 100px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  background-color: ${({ theme }) => theme.colors.midnightAlpha70};
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.midnightAlpha90};
    border-color: ${({ theme }) => theme.colors.errorAlpha50};
  }

  ${({ isVisible }) => {
    if (!isVisible) return "display: none;";
  }}
  ${({ isPinned, theme }) =>
    isPinned &&
    `
    border: 1px solid ${theme.colors.chalkAlpha20};
    background-color: ${theme.colors.midnightAlpha50};
    &:hover {
      background-color: ${theme.colors.midnightAlpha90};
      border-color: ${theme.colors.errorAlpha50};
    }
  `}
  ${({ hasError, theme }) =>
    hasError &&
    `
    padding-left: 8px;    
    padding-right: 12px;
    border: 1px solid ${theme.colors.alertAlpha80};
    background-color: ${theme.colors.midnightAlpha90};
    &:hover {
      border: 1px solid ${theme.colors.alertAlpha80};
      background-color: ${theme.colors.midnightAlpha90};
    }
`}
`;

const StyledPinnedIcon = styled(TbPinned)`
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.chalk};
`;
const StyledFilledPinnedIcon = styled(TbPinnedFilled)`
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.error};
`;
const StyledErrorIcon = styled(MdError)`
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.alert};
`;

const StyledBodyText = styled(Body)`
  font-weight: 500;
`;
