import React from "react";
import { Link as BaseLink } from "../link";

export function Link(props) {
  return (
    <BaseLink variant="variants.link.floatingNav" {...props}>
      {props.children}
    </BaseLink>
  );
}
