import React, { useEffect } from "react";
import { FloatingContainer, Modal } from "../../Wrappers";
import { Body } from "../../Text";
import { Link } from "../../Button";
import { Paywalls } from "./Product/paywall";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

export enum EPaywallType {
  "remix" = "remix",
  "mobile" = "mobile",
  "download" = "download",
  "matchHistory" = "matchHistory",
  "signature" = "signature",
  "matchMontage" = "matchMontage",
}

export const Paywall = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const Content = ({ type }: { type: EPaywallType }) => {
    switch (type) {
      case EPaywallType.remix:
        return <Paywalls type={EPaywallType.remix} />;
      case EPaywallType.mobile:
        return <Paywalls type={EPaywallType.mobile} />;
      case EPaywallType.download:
        return <Paywalls type={EPaywallType.download} />;
      case EPaywallType.matchHistory:
        return <Paywalls type={EPaywallType.matchHistory} />;
      case EPaywallType.signature:
        return <Paywalls type={EPaywallType.signature} />;
      case EPaywallType.matchMontage:
        return <Paywalls type={EPaywallType.matchMontage} />;

      default:
        return (
          <Body mb={2}>
            Something went wrong{" "}
            <Link variant="link.title" to="/support">
              Contact Support
            </Link>
          </Body>
        );
    }
  };
  if (allstarModalState.isOpen !== ModalType.Paywall) return <></>;
  return (
    <>
      <Modal
        isModalOpen={allstarModalState?.isOpen === ModalType.Paywall}
        setModalOpen={() => {
          if (
            allstarModalState?.data?.type === EPaywallType.signature &&
            !allstarModalState?.data?.isFtue
          ) {
            return setAllstarModalState({
              isOpen: ModalType.SignatureSelector,
              data: {},
              functions: {},
            });
          }
          setAllstarModalState({
            isOpen: ModalType.None,
            data: {},
            functions: {},
          });
        }}
        handleModalClose={() => {
          setAllstarModalState({
            isOpen: ModalType.None,
            data: {},
            functions: {},
          });
        }}
        disableOutsideClose={false}
      >
        <FloatingContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          maxWidth="1153px"
        >
          <Content type={allstarModalState?.data?.type} />
        </FloatingContainer>
      </Modal>
    </>
  );
};
