import React from "react";
import { NavLink as RoutedLink } from "react-router-dom";
import { Link as RebassLink } from "rebass/styled-components";
import theme from "../../../theme/themeThree";

export function Link({ children, href, to, ...otherProps }: any) {
  if (href === undefined && to === undefined) {
    console.warn(
      "Warning: Link `to` is missing, setting `#` instead:",
      children,
    );
    to = "#";
  }

  return href ? (
    <RebassLink {...otherProps} __css={theme.link} to={to}>
      {children}
    </RebassLink>
  ) : (
    <RebassLink
      __css={theme.link}
      as={RoutedLink}
      exact={true}
      to={to}
      {...otherProps}
    >
      {children}
    </RebassLink>
  );
}
