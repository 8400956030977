import React, { memo } from "react";
import { Headline, Large3, Title1, Title3 } from "../../components/text";
import { Flex, Box } from "rebass/styled-components";
import { RiGlobeLine } from "react-icons/ri";
import { RiFirstAidKitLine } from "react-icons/ri";
import { RiComputerLine } from "react-icons/ri";
import { RiSunFoggyLine } from "react-icons/ri";
import { RiTimeLine } from "react-icons/ri";
import { RiCoinsLine } from "react-icons/ri";
import styled from "styled-components";
import { Divider } from "../../components/atoms/divider";

let Paid = styled(RiCoinsLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

let Time = styled(RiTimeLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

let Globe = styled(RiGlobeLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

let Health = styled(RiFirstAidKitLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

let Comp = styled(RiComputerLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

let Calendar = styled(RiSunFoggyLine).attrs((props) => ({
  size: 36,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

const Rows = styled(Flex)`
  max-width: ${(props) => props.theme.sizes[7]}px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const Columns = styled(Flex)`
  max-width: ${(props) => props.theme.sizes[4]}px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 20px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    align-items: center;
    text-align: center;
  }
`;

const Perks = memo(() => {
  return (
    <>
      <Flex
        mb={9}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={[2, 2, 7, 7]}
      >
        <Flex flexDirection="column" textAlign="center" width={[2, 2, 5, 6]}>
          <Large3 mb={3}>Perks & Benefits </Large3>
          <Title3 color="chalk" mb={9}>
            We offer a wide range of benefits in areas including health, family,
            finance, community and time away.
          </Title3>
        </Flex>
        <Flex
          mb={6}
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Rows mb={[1, 1, 0, 0]}>
            <Columns mb={[7, 7, 8, 10]} mr={[0, 0, 5, 5]}>
              <Box as={Calendar} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Travel & Meetups </Title1>
                <Headline color="chalk">
                  Our global team comes together quarterly to hang out and
                  collaborate in person.
                </Headline>
              </Flex>
            </Columns>
            <Columns mb={[7, 7, 8, 10]}>
              <Box as={Comp} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Technology </Title1>
                <Headline color="chalk">
                  High end PC or MacBook and all the tools you need to do a
                  great job.
                </Headline>
              </Flex>
            </Columns>
          </Rows>
          <Rows mb={[1, 1, 0, 0]}>
            <Columns mb={[7, 7, 8, 10]} mr={[0, 0, 5, 5]}>
              <Box as={Paid} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Salary & Options </Title1>
                <Headline color="chalk">
                  Allstar offers a competitive salary alongside a generous stock
                  option plan.
                </Headline>
              </Flex>
            </Columns>
            <Columns mb={[7, 7, 8, 10]}>
              <Box as={Globe} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Work from anywhere</Title1>
                <Headline color="chalk">
                  Headquarted in New York City, we have a distributed team all
                  across the world.
                </Headline>
              </Flex>
            </Columns>
          </Rows>
          <Rows>
            <Columns mb={[7, 7, 8, 10]} mr={[0, 0, 5, 5]}>
              <Box as={Health} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Stay healthy </Title1>
                <Headline color="chalk">
                  Generous medical coverage for both you and your dependents (US
                  Employees)
                </Headline>
              </Flex>
            </Columns>
            <Columns mb={[7, 7, 8, 10]}>
              <Box as={Time} mb={3} />
              <Flex flexDirection="column">
                <Title1 mb={3}>Time away </Title1>
                <Headline color="chalk">
                  Unlimited vacation days and time off for the important moments
                  in life.
                </Headline>
              </Flex>
            </Columns>
          </Rows>
        </Flex>
        <Divider my={[6, 6, 10, 10]} width={[2, 2, 3, 4]}></Divider>
      </Flex>
    </>
  );
});

export default Perks;
