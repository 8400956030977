import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Modal } from "../../Modal";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { RiotRegionSelector } from "../../RiotRegionSelector";
import { UPDATE_RIOT_REGION } from "../../../GraphQL/riotRSO";
import { useMutation } from "@apollo/client";
import { useUser } from "../../../Hooks/useUser";

export const SetRiotRegionModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const { getFreshProfile } = useUser();
  const [updateRiotRegion] = useMutation(UPDATE_RIOT_REGION, {
    onError: () => {},
  });
  const setRiotRegion = async (region: string) => {
    const response = await updateRiotRegion({
      variables: {
        region: region,
      },
    });
    const {
      data: { editRiotRegion },
    } = response;
    if (editRiotRegion?.errors?.length) {
      const error = editRiotRegion.errors[0];
      if (error.field === "User" && error.code === "NOT_FOUND")
        return {
          error: true,
          message: "Your Riot account was not found in that region",
        };
      else return { error: true, message: "Something went wrong." };
    }
    await getFreshProfile();
    setAllstarModalState((prev) => ({
      ...prev,
      isOpen: ModalType.None,
    }));
    return { error: false, message: "Region updated successfully." };
  };
  return (
    <Modal
      isModalOpen={allstarModalState.isOpen === ModalType.SetRiotRegion}
      setModalOpen={() => {
        setAllstarModalState((prev) => ({
          ...prev,
          isOpen: ModalType.None,
        }));
      }}
      handleModalClose={() => {
        setAllstarModalState((prev) => ({
          ...prev,
          isOpen: ModalType.None,
        }));
      }}
      disableOutsideClose={false}
    >
      <RiotRegionSelector
        title={"No Region Detected!"}
        subtitle="New matches cannot be displayed without selecting a region. You can
          always change your region in your account settings."
        onSave={setRiotRegion}
      />
    </Modal>
  );
};
