import React from "react";
import { Box, Flex } from "rebass/styled-components";

import {
  CardCover,
  CardPreviewContainer,
  CardPreviewThumb,
  SubTitle,
} from "../styledComponents";
import { ICardPreview } from "../@types";
import { TierBadge } from "../../Studio/Components/CardDetail";
import { TagRow } from "../../../Components/CreatorCard/Components/TagRow";
import { useHistory } from "react-router-dom";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

interface ICardPreviewProps {
  card: ICardPreview;
  closeModal?: () => void;
}

const CardPreview = ({ card, closeModal }: ICardPreviewProps) => {
  const history = useHistory();
  const cardUrl = `/studio/${card._id}`;
  const handleClick = () => {
    history.push(cardUrl);
    if (closeModal) closeModal();
  };

  return (
    <CardPreviewContainer onClick={() => handleClick()}>
      <CardCover className="cover">
        <SubTitle onClick={() => handleClick()}>
          Click to view in studio
        </SubTitle>
      </CardCover>
      <Flex flexDirection="column">
        <CardPreviewThumb
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/${card.ui.image}`}
        />
        <Box p={4} pt={0}>
          <SubTitle>{card.ui.title}</SubTitle>
          <Flex justifyContent="space-between" mt={3}>
            <TagRow card={card} />
            <TierBadge rarity={card.ui.rarity} />
          </Flex>
        </Box>
      </Flex>
    </CardPreviewContainer>
  );
};

export { CardPreview };
