import React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";

const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;

  ${(props) => (!props.maxWidth ? `max-width: 425px;` : false)}
`;

export function FloatingContainer(props) {
  let { px, py, ...rest } = props;
  return (
    <StyledBox
      // variant="floatingContainer"
      px={px ? px : [6, 8]}
      py={py ? py : [6, 8]}
      {...rest}
    >
      {props.children}
    </StyledBox>
  );
}
