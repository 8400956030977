import React from "react";
import { Link } from "../../../Button";
import {
  Card,
  CardGrid,
  CardBackGround,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Wrap,
  BuyButton,
  SubText,
  SubTextStrike,
} from "../styles";
import { useRecoilState } from "recoil";
import { StripePaystationState } from "../../../../State";
import styled from "styled-components";
import fifteenOffImage from "./15badge.png";
import twentyFiveOffImage from "./25badge.png";
import fiftyBade from "./50badge.png";
import { ModalType } from "../../../../@types";
import { AllstarModalState } from "../../../../State/modals";
import { useHistory } from "react-router-dom";

export const MTX_Items = [
  {
    quantity: 1,
    cost: "1.99",
    lookup: "montage_1",
    discount: null,
    meta: null,
  },
  {
    quantity: 5,
    cost: "8.45",
    lookup: "montage_5",
    discount: "save 15%",
    meta: { key: "was", value: "$9.95", img: fifteenOffImage },
  },
  {
    quantity: 10,
    cost: "14.90",
    lookup: "montage_10",
    discount: "save 25%",
    meta: { key: "was", value: "$19.90", img: twentyFiveOffImage },
  },
  {
    quantity: 20,
    cost: "19.90",
    lookup: "montage_20",
    discount: "save 50%",
    meta: { key: "was", value: "$39.80", img: fiftyBade },
    highlight: true,
  },
];

const OverLayImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export const MontagesMTX = () => {
  const [paystation, setPaystationOpen] = useRecoilState(StripePaystationState);
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);
  const history = useHistory();
  const closeModal = () =>
    setAllstarModalState({
      isOpen: ModalType.None,
      data: {},
      functions: {},
    });

  return (
    <Wrap>
      <svg
        width="58"
        height="62"
        viewBox="0 0 58 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.6564 2.95282L2.95288 36.6098H28.7861L25.9157 59.0478L54.6193 25.3908H28.7861L31.6564 2.95282Z"
          fill="url(#paint0_linear_16060_96717)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.8509 0.252768C34.0451 0.781337 34.7502 2.03172 34.5845 3.32715L32.1397 22.4381H54.6188C55.7705 22.4381 56.817 23.1078 57.2995 24.1535C57.7821 25.1992 57.6125 26.43 56.8652 27.3063L28.1616 60.9633C27.3142 61.957 25.9146 62.2758 24.7203 61.7472C23.5261 61.2187 22.821 59.9683 22.9868 58.6729L25.4315 39.5619H2.95241C1.80075 39.5619 0.754216 38.8922 0.271685 37.8465C-0.210846 36.8008 -0.0412843 35.57 0.70602 34.6937L29.4096 1.03674C30.257 0.0430498 31.6566 -0.275801 32.8509 0.252768ZM9.35048 33.6571H28.7856C29.6339 33.6571 30.4413 34.022 31.0017 34.6588C31.5623 35.2956 31.8218 36.1427 31.7141 36.9841L30.1017 49.5888L48.2207 28.3429H28.7856C27.9373 28.3429 27.13 27.978 26.5695 27.3412C26.009 26.7044 25.7495 25.8573 25.8571 25.0159L27.4696 12.4112L9.35048 33.6571Z"
          fill="url(#paint1_linear_16060_96717)"
        />
        <rect
          x="26.771"
          y="8.49402"
          width="4.42855"
          height="14.5805"
          rx="2.21427"
          transform="rotate(39.48 26.771 8.49402)"
          fill="white"
          fill-opacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_16060_96717"
            x1="13.497"
            y1="13.1519"
            x2="62.4332"
            y2="25.0722"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F95370" />
            <stop offset="1" stop-color="#FFA87D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_16060_96717"
            x1="11.7492"
            y1="11.2727"
            x2="66.2281"
            y2="24.6514"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F95370" />
            <stop offset="1" stop-color="#FFA87D" />
          </linearGradient>
        </defs>
      </svg>
      <ModalTitle>Aw snap! You're out of montage credits.</ModalTitle>
      <ModalBody>
        You need additional credits to continue. You can purchase more credits
        by{" "}
        <Link
          variant="link.title"
          to="/upgrade"
          onClick={() => closeModal()}
          style={{ color: "white" }}
        >
          upgrading your account
        </Link>{" "}
        or buying credits below. Purchased credits won't expire, and unlock all
        subscriber features.
      </ModalBody>
      <CardGrid flexDirection={"row"} flexWrap={"wrap"}>
        {MTX_Items.map((item) => {
          return (
            <Card highlight={item.highlight} key={item.lookup}>
              <OverLayImage src={item.meta?.img} />
              <ModalTitle
                style={{ fontSize: 22, marginTop: 31, marginBottom: 16 }}
                highlight={item.highlight}
              >
                {item.quantity} Credit{item.quantity > 1 ? "s" : ""}
              </ModalTitle>
              <SubText>{item.meta?.key ?? "-"}</SubText>
              <SubTextStrike>{item.meta?.value ?? "-"}</SubTextStrike>
              <ModalTitle
                style={{ fontSize: 22, marginTop: 13, marginBottom: 24 }}
              >
                ${item.cost}
              </ModalTitle>
              <BuyButton
                highlight={item.highlight}
                onClick={(e) => {
                  e.stopPropagation();
                  setPaystationOpen({
                    ...paystation,
                    isModalOpen: true,
                    type: "purchase",
                    lookUp: item.lookup,
                    // return the user to the exact page they are on:
                    returnUrl: window.location.pathname,
                  });
                  closeModal();
                }}
              >
                Buy Now
              </BuyButton>
              <CardBackGround highlight={item.highlight} />
            </Card>
          );
        })}
      </CardGrid>
      <ModalFooter>
        If you think you are receiving this message in error, please{" "}
        <Link variant="link.title" to="/support" style={{ color: "white" }}>
          contact support
        </Link>
      </ModalFooter>
    </Wrap>
  );
};
