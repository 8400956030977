import React from "react";
import { FaDiscord } from "react-icons/fa";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(FaDiscord).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const DiscordIcon = (props) => {
  return <StyledIcon {...props} />;
};

DiscordIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default DiscordIcon;
