import React from "react";
import { FaTwitter } from "react-icons/fa";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(FaTwitter).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const TwitterIcon = (props) => {
  return <StyledIcon {...props} />;
};

TwitterIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default TwitterIcon;
