import React from "react";
import { Link as BaseLink } from "../link";

export function Link(props) {
  return (
    <BaseLink variant="link.footer" {...props}>
      {props.children}
    </BaseLink>
  );
}
