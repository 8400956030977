import React from "react";
import { Button } from "../button";
import { FaDiscord } from "react-icons/fa";
import styled from "styled-components";

const StyledDiscord = styled(FaDiscord)`
  position: relative;
  top: ${(props) => props.theme.space[2]}px;
  margin-right: ${(props) => props.theme.space[3]}px;
`;

export function DiscordButton(props) {
  return (
    <Button {...props}>
      <StyledDiscord
        color={
          props.classification === "white" ||
          props.classification === "secondary"
            ? "#5865F2"
            : "white"
        }
        size={22}
      />
      {props.children}
    </Button>
  );
}
