import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import {
  useExperiment,
  useStatsigClient,
  useStatsigUser,
} from "@statsig/react-bindings";

import {
  SignatureSelectorState,
  TSelectedSignatureState,
} from "../../State/loadoutSelector";
import { SignatureSelectorViewModel } from "./ViewModel";
import {
  Column,
  NoLoadoutContainer,
  PremiumIconPositionWrapper,
  SectionHeader,
  SelectionRow,
  StopIcon,
  StyleWrapper,
  StyledFootnote,
  StyledImage,
  StyledDropdownImage,
  ToggleDescription,
  ToggleName,
  TopRightImage,
  StyledCountryFlag,
  StyledLinkText,
} from "./styledComponents";
import { Divider } from "../Divider";
import countryList from "react-select-country-list";
import { Toggle } from "../Toggle";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { AddButton } from "../../Views/Setup/Components/AddButton";
import { AllstarModalState } from "../../State/modals";
import { ModalType } from "../../@types";
import {
  RemoveSelectedItemBubble,
  SelectedItemBubble,
} from "../../StyledComponents";
import {
  StyledPlatinumBadge,
  StyledPlusBadge,
  StyledProBadge,
} from "./styledComponents";
import { Body } from "../Text";
import { DropdownSelector } from "../RiotRegionSelector/DropdownSelector";
import { useUser } from "../../Hooks/useUser";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const getPremiumPlanIcon = (scopes: string[]) => {
  if (scopes.includes("CREATOR_PLAT")) {
    return <StyledPlatinumBadge />;
  } else if (scopes.includes("PRO_PLUS")) {
    return <StyledPlusBadge />;
  } else if (scopes.includes("PRO")) {
    return <StyledProBadge />;
  }
  return <></>;
};

export interface ISelectableDropdownStyle {
  name: string;
  themeName: string;
  valueToDisplay: JSX.Element;
  valueToSave: string;
  image: string;
  isPremium: boolean;
  premiumIcon: JSX.Element;
}
export interface ISelectedDropdownStyle extends ISelectableDropdownStyle {
  toggleNameItWasSelectedFor: string;
}

const cutoffDate = new Date("9-11-2024");
export const SignatureSelector = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const { getSelectableLoadouts } = SignatureSelectorViewModel({});
  const [signatureSelectorState, setSignatureSelectorState] = useRecoilState(
    SignatureSelectorState,
  );
  const { allstarUser } = useUser();
  const { getExperiment } = useStatsigClient();
  const { user } = useStatsigUser();

  const isFtue = window.location.pathname.includes("setup");
  const [loadoutsToDisplay, setLoadoutsToDisplay] = useState(
    [] as TSelectedSignatureState[],
  );

  const [optOutSelected, setOptOutSelected] = useState(false);

  const countryOptions = useMemo(() => {
    return countryList()
      .getData()
      .map((country) => {
        return {
          valueToSave: country.value,
          valueToDisplay: (
            <Flex>
              <StyledCountryFlag
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/flags/${country.value.toLowerCase()}.png`}
                mr={4}
              />
              {country.label}
            </Flex>
          ),
        };
      });
  }, []);
  const findSelectedCountryFromCountryList = useCallback(
    (countryCode: string) => {
      return (
        countryOptions.find(
          (country) => country.valueToSave === countryCode.toUpperCase(),
        ) || {
          valueToSave: "",
          valueToDisplay: "Select a Country",
        }
      );
    },
    [countryOptions],
  );
  const [showDropdowns] = useState<boolean>(() => {
    const userCreated = new Date(allstarUser.user?.createdDate);
    const userCreatedBeforeCutoffDate = userCreated < cutoffDate;

    if (userCreatedBeforeCutoffDate) {
      return true;
    }

    const mixAndMatchExperiment = getExperiment(
      "signatures_mix_and_match_-_hide_or_show",
    );

    return !!mixAndMatchExperiment.get("signatures_mix_and_match_hide");
  });

  const [selectedCountry, setSelectedCountry] = useState(
    findSelectedCountryFromCountryList(
      signatureSelectorState.selectedCountryCode,
    ),
  );

  useEffect(() => {
    if (signatureSelectorState.selectedCountryCode) {
      setSelectedCountry(
        findSelectedCountryFromCountryList(
          signatureSelectorState.selectedCountryCode,
        ),
      );
    }
  }, [
    signatureSelectorState.selectedCountryCode,
    findSelectedCountryFromCountryList,
  ]);

  //Gets all the loadouts, sets preselected toggles based on defaultss of the first loadout.
  useEffect(() => {
    if (signatureSelectorState.preloadedUIWithMySignature) {
      return setSignatureSelectorState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
    (async () => {
      const data = await getSelectableLoadouts({
        variables: {
          excludeCampaignLoadouts: isFtue,
        },
      });
      const selectableLoadouts = data.data
        .selectableLoadouts as TSelectedSignatureState[];
      const preselectedToggleNames = [] as string[];
      const firstLoadout = selectableLoadouts[0];
      let dropdownStyles = [] as ISelectableDropdownStyle[];
      if (selectableLoadouts.length) {
        dropdownStyles = selectableLoadouts.map((loadout) => {
          return {
            name: loadout.metadataStyleName,
            themeName: loadout.metadataThemeName,
            image: loadout.metadataStylePreviewColor,
            isPremium: ["PRO", "PRO_PLUS", "CREATOR_PLAT"].some((scope) =>
              loadout.scopeUse.includes(scope),
            ),
            premiumIcon: getPremiumPlanIcon(loadout.scopeUse),
            valueToDisplay: (
              <Flex>
                <StyledDropdownImage
                  src={loadout.metadataStylePreviewColor}
                  mr={4}
                />
                {loadout.metadataDisplayName}
                <Box ml={3}> {getPremiumPlanIcon(loadout.scopeUse)}</Box>
              </Flex>
            ),
            valueToSave: loadout.metadataStyleName,
          };
        });
      }

      for (const card of firstLoadout.clipCards) {
        if (card.enabled)
          preselectedToggleNames.push(card.settingsUiToggleName);
      }
      setSignatureSelectorState((prev) => {
        return {
          ...prev,
          allLoadouts: selectableLoadouts,
          selectedToggleNames: preselectedToggleNames,
          selectableDropdownStyles: dropdownStyles,
        };
      });
    })();
  }, [
    getSelectableLoadouts,
    setSignatureSelectorState,
    isFtue,
    signatureSelectorState.preloadedUIWithMySignature,
  ]);

  //Once we have all loadouts its time to organize the theme.
  useEffect(() => {
    if (!signatureSelectorState.allLoadouts.length) return;

    const themes = signatureSelectorState.allLoadouts.reduce(
      (acc, loadout) => {
        if (
          !acc.some((theme) => theme.themeName === loadout.metadataThemeName)
        ) {
          acc.push({
            themeName: loadout.metadataThemeName,
            themeImageSrc: loadout.metadataThemeImageSrc,
          });
        }
        return acc;
      },
      [] as { themeName: string; themeImageSrc: string }[],
    );

    setSignatureSelectorState((prev) => {
      return {
        ...prev,
        themes,
      };
    });

    if (signatureSelectorState.selectedSignature.metadataThemeName) {
      const filteredLoadouts = signatureSelectorState.allLoadouts.filter(
        (loadout) =>
          loadout.metadataThemeName ===
          signatureSelectorState.selectedSignature.metadataThemeName,
      );
      return setLoadoutsToDisplay(filteredLoadouts);
    }
  }, [
    signatureSelectorState.allLoadouts,
    signatureSelectorState.selectedSignature.metadataThemeName,
    setSignatureSelectorState,
  ]);

  //Once we have all the themes and styles organized AND we have fetched my signature, its time to finalize the UI.
  useEffect(() => {
    //if we haven't gotten all loadouts or fetched my signature, return
    if (
      !signatureSelectorState.allLoadouts.length ||
      !signatureSelectorState.fetchedMySignature ||
      !signatureSelectorState.themes.length
    )
      return;

    // the user could have already loaded the UI with their signature, in this case, we don't want to preload the UI again
    if (signatureSelectorState.preloadedUIWithMySignature) {
      return setSignatureSelectorState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }

    // the user can have a signature with an empty name, this means they have opted out of a signature and saved it.
    if (
      signatureSelectorState.hasASignature &&
      signatureSelectorState.mySignature.metadataThemeName === ""
    ) {
      setSignatureSelectorState((prev) => {
        return {
          ...prev,
          allowSave: true,
          loading: false,
          preloadedUIWithMySignature: true,
        };
      });

      setOptOutSelected(true);
      return setSignatureSelectorState((prev) => {
        return {
          ...prev,

          loading: false,
        };
      });
    }

    // the user can have a signature with an actual name, this means they have a signature and saved it. preload the theme, style, toggles.
    if (
      signatureSelectorState.hasASignature &&
      signatureSelectorState.mySignature.metadataThemeName !== ""
    ) {
      const mySignatureThemeName =
        signatureSelectorState.mySignature.metadataThemeName;
      const loadoutToPreselect = signatureSelectorState.allLoadouts.find(
        (loadout) =>
          loadout.metadataThemeName === mySignatureThemeName &&
          loadout.metadataStyleName ===
            signatureSelectorState.mySignature.metadataStyleName,
      );

      //if we have a loadout to preselect, we will preselect the toggles and styles
      if (loadoutToPreselect) {
        let preselectedToggleNames = [] as string[];
        const mySignatureCardIds =
          signatureSelectorState.mySignature.clipCards.map(
            (card) => card.card._id,
          );

        if (!signatureSelectorState.customStyleSelectionToPreload.length)
          for (const card of loadoutToPreselect.clipCards) {
            if (mySignatureCardIds.includes(card.card._id))
              preselectedToggleNames.push(card.settingsUiToggleName);
            else
              preselectedToggleNames = preselectedToggleNames.filter(
                (name) => name !== card.settingsUiToggleName,
              );
          }
        else
          for (const style of signatureSelectorState.customStyleSelectionToPreload) {
            preselectedToggleNames.push(style.settingsUiToggleName);
          }

        if (signatureSelectorState.mySignature.metadataThemeCountryFlagEnabled)
          preselectedToggleNames.push(
            loadoutToPreselect.metadataThemeCountryFlagToggleName,
          );

        const stylesToPreload = [] as ISelectedDropdownStyle[];

        //Load in the appropriate styles for mix and match.
        if (signatureSelectorState.selectableDropdownStyles.length) {
          const allPossibleToggleNames = new Set(
            signatureSelectorState.allLoadouts
              .map((loadout) =>
                loadout.clipCards.map((card) => card.settingsUiToggleName),
              )
              .flat(),
          );

          for (const toggleName of allPossibleToggleNames) {
            const styleToPreload =
              signatureSelectorState.customStyleSelectionToPreload.find(
                (style) => style.settingsUiToggleName === toggleName,
              );

            const styleWeAreSelecting =
              signatureSelectorState.selectableDropdownStyles.find(
                ({ valueToSave }) =>
                  styleToPreload
                    ? valueToSave === styleToPreload.metadataStyleName
                    : valueToSave === loadoutToPreselect.metadataStyleName,
              );

            if (styleWeAreSelecting) {
              stylesToPreload.push({
                ...styleWeAreSelecting,
                toggleNameItWasSelectedFor: toggleName,
              });
            }
          }
        }

        setSignatureSelectorState((prev) => {
          const filteredLoadouts = prev.allLoadouts.filter(
            (loadout) =>
              loadout.metadataThemeName ===
              loadoutToPreselect.metadataThemeName,
          );
          return {
            ...prev,
            selectedSignature: loadoutToPreselect,
            allowSave: true,
            loading: false,
            preloadedUIWithMySignature: true,
            selectedToggleNames: preselectedToggleNames,
            selectedDropdownStyles: stylesToPreload,
            previewSources: filteredLoadouts.map(
              (loadout) => loadout.metadataPreviewSrc,
            ),
          };
        });
      }
    }

    // the user does not have a signature
    if (!signatureSelectorState.hasASignature) {
      setSignatureSelectorState((prev) => {
        return {
          ...prev,
          loading: false,
          preloadedUIWithMySignature: true,
        };
      });
    }
  }, [
    signatureSelectorState.allLoadouts,
    signatureSelectorState.hasASignature,
    signatureSelectorState.mySignature.metadataThemeName,
    signatureSelectorState.themes.length,
    signatureSelectorState.fetchedMySignature,
    setSignatureSelectorState,
    signatureSelectorState.mySignature.clipCards,
    signatureSelectorState.mySignature.metadataStyleName,
    signatureSelectorState.mySignature.metadataThemeCountryFlagEnabled,
    signatureSelectorState.preloadedUIWithMySignature,
    signatureSelectorState.selectableDropdownStyles,
    signatureSelectorState.customStyleSelectionToPreload,
  ]);

  const handleThemeClick = ({ themeName }: { themeName: string }) => {
    window.rudderanalytics.track("Signature - Theme Selected", {
      theme: themeName,
    });
    if (optOutSelected) {
      setOptOutSelected(false);
    }

    const filteredLoadouts = signatureSelectorState.allLoadouts.filter(
      (loadout) => loadout.metadataThemeName === themeName,
    );

    setLoadoutsToDisplay(filteredLoadouts);

    const signatureToPreselect = filteredLoadouts[0];
    const styleToPreload = signatureToPreselect.metadataStyleName;
    const stylesToPreload = [] as ISelectedDropdownStyle[];
    const allPossibleToggleNames = new Set(
      signatureSelectorState.allLoadouts
        .map((loadout) =>
          loadout.clipCards.map((card) => card.settingsUiToggleName),
        )
        .flat(),
    );

    for (const toggleName of allPossibleToggleNames) {
      const styleWeAreSelecting =
        signatureSelectorState.selectableDropdownStyles.find(
          ({ valueToSave }) => valueToSave === styleToPreload,
        );
      if (!styleWeAreSelecting) return;
      stylesToPreload.push({
        ...styleWeAreSelecting,
        toggleNameItWasSelectedFor: toggleName,
      });
    }

    setSignatureSelectorState((prev) => {
      return {
        ...prev,
        selectedSignature: signatureToPreselect,
        allowSave: true,
        selectedDropdownStyles: stylesToPreload,
        previewSources: filteredLoadouts.map(
          (loadout) => loadout.metadataPreviewSrc,
        ),
      };
    });
    if (
      filteredLoadouts.length === 0 ||
      signatureSelectorState.selectedSignature.metadataStyleName
    )
      return;
  };

  const handleStyleClick = (style: string) => {
    window.rudderanalytics.track("Signature - Style Selected", {
      style,
    });

    //this logic here loops through all the possible toggles and sets their styles if we select a default style.
    const selectedDropdownStylesCopy = [] as ISelectedDropdownStyle[];
    const styleWeAreSelecting =
      signatureSelectorState.selectableDropdownStyles.find(
        ({ valueToSave }) => valueToSave === style,
      );
    if (!styleWeAreSelecting) return;

    const allPossibleToggleNames = new Set(
      signatureSelectorState.allLoadouts
        .map((loadout) =>
          loadout.clipCards.map((card) => card.settingsUiToggleName),
        )
        .flat(),
    );

    for (const toggleName of allPossibleToggleNames) {
      selectedDropdownStylesCopy.push({
        ...styleWeAreSelecting,
        toggleNameItWasSelectedFor: toggleName,
      });
    }

    setSignatureSelectorState((prev) => {
      return {
        ...prev,
        selectedSignature: loadoutsToDisplay.find(
          (loadout) => loadout.metadataStyleName === style,
        ) as TSelectedSignatureState,
        selectedDropdownStyles: selectedDropdownStylesCopy,
      };
    });
  };

  const handleNoneClick = () => {
    window.rudderanalytics.track("Signature - Theme Selected", {
      theme: "none",
    });
    setOptOutSelected(true);
    setSignatureSelectorState((prev) => {
      return {
        ...prev,
        allowSave: true,
        selectedSignature: {
          clipCards: [],
          metadataThemeName: "",
          metadataThemeImageSrc: "",
          metadataStylePreviewColor: "",
          metadataStyleName: "",
          metadataPreviewType: "",
          metadataPreviewSrc: "",
          scopeUse: [],
          metadataThemeCountryFlagEnabled: false,
          metadataThemeCountryFlagDisplay: false,
          metadataThemeCountryFlagToggleName: "",
          metadataThemeCountryFlagToggleDescription: "",
          metadataDisplayName: "",
          active: false,
          _id: "",
        },
      };
    });
  };

  if (signatureSelectorState.loading) {
    return <></>;
  }

  return (
    <Column>
      <Column style={{ gap: 20 }}>
        <Column>
          {!signatureSelectorState.selectedSignature.metadataThemeName &&
            !signatureSelectorState.selectedSignature.metadataStyleName && (
              <TopRightImage
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/SelectTheme.svg`}
              />
            )}

          <SectionHeader>Choose your Theme</SectionHeader>
          <SelectionRow>
            {signatureSelectorState.themes.map((theme) => (
              <StyledImage
                src={theme.themeImageSrc}
                onClick={() => handleThemeClick(theme)}
                selected={
                  signatureSelectorState.selectedSignature.metadataThemeName ===
                  theme.themeName
                }
              />
            ))}
            <NoLoadoutContainer
              onClick={handleNoneClick}
              selected={optOutSelected}
            >
              <StopIcon color="chalk" />
            </NoLoadoutContainer>
          </SelectionRow>
        </Column>
        {!signatureSelectorState.selectedSignature.metadataThemeName &&
          !signatureSelectorState.selectedSignature.metadataStyleName && (
            <Flex
              width="100%"
              height="100%"
              justifyContent={"center"}
              alignItems={"start"}
              flexDirection={"column"}
            >
              <Image
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/SignatureChrome.png`}
                minWidth="600px"
                maxWidth="600px"
              />
            </Flex>
          )}
        {signatureSelectorState.selectedSignature.metadataThemeName && (
          <Column>
            <SectionHeader>Choose your Style</SectionHeader>
            <SelectionRow>
              {loadoutsToDisplay.map((loadout) => {
                return (
                  <StyleWrapper>
                    <PremiumIconPositionWrapper>
                      {getPremiumPlanIcon(loadout.scopeUse)}
                    </PremiumIconPositionWrapper>

                    <StyledImage
                      key={loadout.metadataStyleName}
                      src={loadout.metadataStylePreviewColor}
                      selected={
                        signatureSelectorState.selectedSignature
                          .metadataStyleName === loadout.metadataStyleName
                      }
                      onClick={() =>
                        handleStyleClick(loadout.metadataStyleName)
                      }
                    />
                  </StyleWrapper>
                );
              })}
            </SelectionRow>
            <Flex
              justifyContent={"flex-start"}
              width="100%"
              style={{ gap: 5 }}
              mt={3}
            >
              {getPremiumPlanIcon(["PRO"])}
              {getPremiumPlanIcon(["PRO_PLUS"])}
              {getPremiumPlanIcon(["CREATOR_PLAT"])}
              <Body color={"chalk"}>Premium Styles</Body>
            </Flex>
          </Column>
        )}
      </Column>

      {signatureSelectorState.selectedSignature.metadataThemeName && (
        <>
          <Divider width="100%" />
          <SectionHeader>Choose your Custom Settings</SectionHeader>

          <Column style={{ gap: 20, marginTop: 10 }}>
            {signatureSelectorState.selectedSignature?.clipCards?.map(
              (card) => {
                const toggleActive =
                  signatureSelectorState.selectedToggleNames.includes(
                    card.settingsUiToggleName,
                  );
                return (
                  <Flex key={card.card._id} style={{ gap: "24px" }}>
                    <Box mt={2}>
                      <Toggle
                        width={48}
                        height={28}
                        onChange={(checked: boolean) => {
                          if (checked)
                            return setSignatureSelectorState((prev) => {
                              return {
                                ...prev,
                                selectedToggleNames: [
                                  ...prev.selectedToggleNames,
                                  card.settingsUiToggleName,
                                ],
                              };
                            });

                          return setSignatureSelectorState((prev) => {
                            return {
                              ...prev,
                              selectedToggleNames:
                                prev.selectedToggleNames.filter(
                                  (name) => name !== card.settingsUiToggleName,
                                ),
                            };
                          });
                        }}
                        checked={toggleActive}
                      />
                    </Box>

                    <Column mb={2}>
                      <ToggleName>{card.settingsUiToggleName}</ToggleName>
                      <ToggleDescription>
                        {card.settingsUiToggleDescription}
                      </ToggleDescription>
                      {toggleActive && showDropdowns && (
                        <Box mt={3}>
                          <DropdownSelector
                            values={
                              signatureSelectorState.selectableDropdownStyles
                            }
                            inline={false}
                            disabled={false}
                            onChange={(value) => {
                              let copyOfSelectedDropdownStyles = [
                                ...signatureSelectorState.selectedDropdownStyles,
                              ] as ISelectedDropdownStyle[];

                              //remove the current dropdown selected styles for this toggle name
                              copyOfSelectedDropdownStyles =
                                copyOfSelectedDropdownStyles.filter(
                                  (style: ISelectedDropdownStyle) =>
                                    style.toggleNameItWasSelectedFor !==
                                    card.settingsUiToggleName,
                                );

                              const styleWeAreSelecting =
                                signatureSelectorState.selectableDropdownStyles.find(
                                  (style) => style.valueToSave === value,
                                );
                              if (!styleWeAreSelecting) return;
                              //add the new selected style
                              copyOfSelectedDropdownStyles.push({
                                ...styleWeAreSelecting,
                                toggleNameItWasSelectedFor:
                                  card.settingsUiToggleName,
                              });

                              setSignatureSelectorState((prev) => {
                                return {
                                  ...prev,
                                  selectedDropdownStyles:
                                    copyOfSelectedDropdownStyles,
                                };
                              });
                            }}
                            labelText={
                              signatureSelectorState.selectedDropdownStyles.find(
                                (style) =>
                                  style.toggleNameItWasSelectedFor ===
                                  card.settingsUiToggleName,
                              )?.valueToDisplay || "No Selection"
                            }
                            noSelection={true}
                          />
                        </Box>
                      )}
                    </Column>
                  </Flex>
                );
              },
            )}
            {signatureSelectorState.selectedSignature
              .metadataThemeCountryFlagDisplay && (
              <Flex style={{ gap: "24px" }}>
                <Box mt={2}>
                  <Toggle
                    width={48}
                    height={28}
                    onChange={() => {
                      setSignatureSelectorState((prev) => {
                        return {
                          ...prev,
                          selectedToggleNames:
                            prev.selectedToggleNames.includes(
                              prev.selectedSignature
                                .metadataThemeCountryFlagToggleName,
                            )
                              ? prev.selectedToggleNames.filter(
                                  (name) =>
                                    name !==
                                    prev.selectedSignature
                                      .metadataThemeCountryFlagToggleName,
                                )
                              : [
                                  ...prev.selectedToggleNames,
                                  prev.selectedSignature
                                    .metadataThemeCountryFlagToggleName,
                                ],
                        };
                      });
                    }}
                    checked={signatureSelectorState.selectedToggleNames.includes(
                      signatureSelectorState.selectedSignature
                        .metadataThemeCountryFlagToggleName,
                    )}
                  />
                </Box>
                <Column mb={2}>
                  <ToggleName>
                    {
                      signatureSelectorState.selectedSignature
                        .metadataThemeCountryFlagToggleName
                    }
                  </ToggleName>{" "}
                  <ToggleDescription>
                    {
                      signatureSelectorState.selectedSignature
                        .metadataThemeCountryFlagToggleDescription
                    }
                  </ToggleDescription>
                  {signatureSelectorState.selectedToggleNames.includes(
                    signatureSelectorState.selectedSignature
                      .metadataThemeCountryFlagToggleName,
                  ) && (
                    <Box mt={3}>
                      <DropdownSelector
                        values={countryOptions}
                        inline={false}
                        disabled={false}
                        onChange={(value) => {
                          setSignatureSelectorState((prev) => {
                            return {
                              ...prev,
                              selectedCountryCode: value,
                            };
                          });
                        }}
                        labelText={
                          selectedCountry.valueToDisplay || "No Selection"
                        }
                        noSelection={!!selectedCountry.valueToDisplay}
                      />
                    </Box>
                  )}
                </Column>
              </Flex>
            )}
            <Flex style={{ gap: "24px" }}>
              <Box mt={2}>
                <AddButton
                  onClick={() => {
                    setAllstarModalState({
                      isOpen: ModalType.MusicGenreSelector,
                      functions: {},
                      data: {
                        isFtue: isFtue,
                      },
                    });
                  }}
                />
              </Box>
              <Column mb={2}>
                <ToggleName>Music</ToggleName>
                <ToggleDescription>
                  Add music genres to your Signature and we'll apply music to
                  your clips, synced to the action!
                </ToggleDescription>
              </Column>
            </Flex>

            <Flex flexWrap={"wrap"} style={{ rowGap: 10 }}>
              {signatureSelectorState.selectedGenres.map((card) => {
                return (
                  <>
                    <SelectedItemBubble
                      onClick={() => {
                        setSignatureSelectorState((prev) => {
                          return {
                            ...prev,
                            selectedGenres: prev.selectedGenres.filter(
                              (c) => c._id !== card._id,
                            ),
                          };
                        });
                      }}
                    >
                      <StyledFootnote>
                        <span style={{ color: "#F7FCFD" }}>
                          {card?.ui?.title}
                        </span>
                      </StyledFootnote>
                      <RemoveSelectedItemBubble />
                    </SelectedItemBubble>
                  </>
                );
              })}
            </Flex>
          </Column>
        </>
      )}
    </Column>
  );
};
