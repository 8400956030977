import React, { useState } from "react";
import { Flex } from "rebass/styled-components";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { FloatingContainer } from "../../components/wrappers/floatingContainer";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { FloatingContent } from "../../components/molecules/floatingContent";

import { useUser } from "../../MVVM/Hooks/useUser";
import { URLS } from "../../MVVM/Utilities/Maps";

export default function Verify() {
  const [state, setState] = useState({
    loading: true,
    error: false,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  /*eslint-disable*/
  const { allstarUser, getFreshProfile } = useUser();
  /*eslint-enable*/
  const opt = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/users/verify?token=${token}`,
  };

  verifyToken();
  async function verifyToken() {
    let data = await Axios(opt);

    if (data.data.status === "ok") {
      const jwt = data.data.token;
      localStorage.setItem("token", jwt);

      try {
        let referralCode = localStorage.getItem("referralCode");
        let grsfCode = localStorage.getItem("grsfCode");
        let token = localStorage.getItem("token");
        const [headerEncoded, payloadEncoded] = token.split(".");
        // eslint-disable-next-line
        const [header, payload] = [headerEncoded, payloadEncoded].map(
          decodeTokenComponent
        );
        const currentPartner = localStorage.getItem("currentPartner");
        let analyticsData = {
          userId: payload._id,
          accountSource: "Email",
          partnerSource: currentPartner,
        };

        if (grsfCode) {
          analyticsData.isRaf = true;
          analyticsData.rafSource = "Growsurf";
          analyticsData.rafCode = grsfCode;
        }
        if (referralCode) {
          analyticsData.isRaf = true;
          analyticsData.rafSource = "Allstar";
          analyticsData.rafCode = referralCode;
        }
        window.rudderanalytics.track(`Signup Verified`, analyticsData);

        window.rudderanalytics.track("Email Verified", {
          path: window.location.pathname,
        });
        setState({
          ...state,
          loading: false,
          error: false,
          verified: true,
        });
        return getFreshProfile();
      } catch (e) {
        setState({
          ...state,
          loading: false,
          error: true,
          errorMsg: data.data.msg,
        });
      }

      setState({ loading: false, error: false });
    } else {
      setState({ loading: false, error: true });
    }
  }
  console.log(state);
  if (state.verified || state.error)
    return <Redirect to={`/${URLS.DASHBOARD}`} />;

  return (
    <FullScreenWrapper>
      <FloatingContainer height="125px">
        <Flex justifyContent="center">
          <FloatingContent textAlign="center" title={"Verifying..."} />
        </Flex>
      </FloatingContainer>
    </FullScreenWrapper>
  );
}

function decodeTokenComponent(value) {
  const buff = new Buffer.from(value, "base64");
  const text = buff.toString("ascii");
  return JSON.parse(text);
}
