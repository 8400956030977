import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Flex } from "rebass/styled-components";
import { AnimatePresence } from "framer-motion";

import { ModalType } from "../../../@types";
import { AllstarModalState } from "../../../State/modals";
import { Modal } from "../../Wrappers";
import {
  HeroContainer,
  ModalContainer,
  NavButton,
  StepImage,
  StepText,
  StyledBody,
  StyledFlex,
  StyledLarge2,
} from "../StyledComponents/ftue";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const steps = [
  {
    stepText: "Browse our music & effects",
    stepBody:
      "Using our filters and search, find all your favorite Creator Cards and add them with just one click.",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/studioFTUE/modal/hero1.jpg`,
  },
  {
    stepText: "Set your loadout",
    stepBody:
      "When you click to add a card, it goes into loadout, which activates it for all your new clips.",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/studioFTUE/modal/hero2.jpg`,
  },
  {
    stepText: "Get creating",
    stepBody:
      "That's it! You’re ready to go. Once your loadout is set, play some games and your clips are on the way!",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/studioFTUE/modal/hero3.jpg`,
  },
];

const StudioFTUEModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const [currentStep, setCurrentStep] = useState(0);

  const navigate = (direction: "forward" | "backward") => {
    if (direction === "forward" && currentStep === steps.length - 1) return;
    if (direction === "backward" && currentStep === 0) return;

    direction === "forward"
      ? setCurrentStep(currentStep + 1)
      : setCurrentStep(currentStep - 1);
  };

  if (allstarModalState.isOpen !== ModalType.StudioWelcome) return <></>;
  return (
    <>
      <Modal
        isModalOpen={
          allstarModalState.isOpen === ModalType.StudioWelcome &&
          !localStorage.getItem("closedStudioWelcome")
        }
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.StudioWelcome : ModalType.None,
          })
        }
        handleModalClose={() => {}}
        disableOutsideClose={true}
      >
        <ModalContainer>
          <StyledFlex>
            <Flex alignItems="center" justifyContent={"center"}>
              <StyledLarge2>Allstar Studio</StyledLarge2>
            </Flex>
            <StepText>
              ({currentStep + 1}/{steps.length}) &nbsp;
              {steps[currentStep].stepText}
            </StepText>
            <StyledBody>{steps[currentStep].stepBody}</StyledBody>
            <HeroContainer>
              <Flex>
                <AnimatePresence exitBeforeEnter>
                  <StepImage src={steps[currentStep].stepHero} />
                </AnimatePresence>
              </Flex>
            </HeroContainer>
            <Flex
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              width={"100%"}
              style={{ gap: "12px" }}
            >
              <NavButton
                flex={true}
                size="tiny"
                classification="roundedFilled"
                mt={[3, 3, 6, 6]}
                onClick={() => navigate("backward")}
                disabled={currentStep === 0}
              >
                Prev
              </NavButton>
              {currentStep < steps.length - 1 ? (
                <NavButton
                  flex={true}
                  size="tiny"
                  classification="roundedFilled"
                  mt={[3, 3, 6, 6]}
                  onClick={() => navigate("forward")}
                >
                  Next
                </NavButton>
              ) : (
                <NavButton
                  flex={true}
                  size="tiny"
                  classification="roundedFilled"
                  mt={[3, 3, 6, 6]}
                  onClick={() => {
                    setAllstarModalState({
                      data: {},
                      functions: {},
                      isOpen: ModalType.None,
                    });
                    localStorage.setItem("closedStudioWelcome", "true");
                  }}
                >
                  Finish
                </NavButton>
              )}
            </Flex>
          </StyledFlex>
        </ModalContainer>
      </Modal>
    </>
  );
};

export { StudioFTUEModal };
