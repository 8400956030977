import React from "react";
import { useRecoilState } from "recoil";

import { StripePaystationState } from "../../../State";
import { Stripe } from "../../StripePaystation";
import { Modal } from "../../Modal";

export const StripePayStation = () => {
  const [paystation, setPaystationOpen] = useRecoilState(StripePaystationState);

  const setModalOpen = (isModalOpen: boolean) =>
    setPaystationOpen({ ...paystation, isModalOpen });

  const handleModalClose = () =>
    setPaystationOpen({ ...paystation, isModalOpen: false });

  return (
    <Modal
      isModalOpen={paystation.isModalOpen}
      setModalOpen={setModalOpen}
      handleModalClose={handleModalClose}
      disableOutsideClose={false}
    >
      <Stripe />
    </Modal>
  );
};
