import React, { useEffect } from "react";
import { Subheader } from "../../components/text";
import { Link } from "../../components/atoms/link";
import { FloatingContent } from "../../components/molecules/floatingContent";
import { Row as FormRow } from "../../components/molecules/form/row";
import { DiscordButton } from "../../components/atoms/form/button/discord";
import styled from "styled-components";

const StyledLink = styled(Link)((props) => props.theme.text.subheader);

async function handleDiscord() {
  const discordURL = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_OAUTH}&redirect_uri=${process.env.REACT_APP_BASE_URL}%2Foauth%2Fdiscord&response_type=code&scope=identify%20email%20guilds.join`;
  window.rudderanalytics.track("Link Discord Button Click");
  window.location.href = discordURL;
  return;
}

export function Discord() {
  useEffect(() => {
    window.rudderanalytics.page("Activation", "Link Discord Option");
  });
  return (
    <FloatingContent
      title="Add a Discord account"
      body="To access your clips during Beta, you will need to be assigned the Beta Role on the Allstar Discord. Linking your account allows us to do so."
    >
      <FormRow>
        <DiscordButton onClick={() => handleDiscord()}>
          Link your Discord Account
        </DiscordButton>
      </FormRow>

      <Subheader>Your download should start automatically.</Subheader>
      <StyledLink
        rel="noopener noreferrer"
        href="https://play.allstar.gg/latest"
        target="_blank"
        variant="link.form"
      >
        If not, click here.
      </StyledLink>
    </FloatingContent>
  );
}
