import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { FullPageBackgroundProps } from "./@types";
import { Divider } from "../Components/Divider";
import { HiX } from "react-icons/hi";

export const FullPageBackgroundContainer = styled(Box)<FullPageBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Column = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
`;

export const ResponsiveColumn = styled(Column)`
  flex-basis: 55%;
  align-items: start;
  border-right: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    width: 100%;
    max-height: 150px;
    background-color: ${({ theme }) => theme.colors.darkerNed};
    border-right: unset;
    border-bottom: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
    padding-left: 38px;
    padding-right: 30px;
    padding-top: 25px;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    display: none;
  }
`;
export const StaticColumn = styled(Flex)`
  flex-direction: column;
  flex-basis: 45%;
  width: 100%;
  position: relative;
  backdrop-filter: blur(20px);
  background: ${(props) => props.theme.colors.midnightAlpha40};
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    flex-basis: 100%;
    position: relative;
    backdrop-filter: unset;
    background-color: ${({ theme }) => theme.colors.darkerNed};
  }
`;

export const Page = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 101px;
  padding: 40px;
  padding-top: 80px;
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.colors.ned};
  }
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.ned};
    border: 5px solid transparent;
    border-radius: 999px;
    background-clip: content-box;
  }
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    padding: 20px;
  }
`;

export const RemoveSelectedItemBubble = styled(HiX)`
  padding: 3px;
  border-radius: 50%;
  cursor: inherit;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background: ${(props) => props.theme.colors.darkerNavy};
`;

export const SelectedItemBubble = styled(Flex)`
  margin-right: 10px;
  text-transform: capitalize;
  border-radius: 999px;
  color: ${(props) => props.theme.colors.chalk};
  background: ${(props) => props.theme.colors.navy};
  padding: 5px 5px 5px 15px;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  transition: 0.2s;
  border-color: ${(props) => props.theme.colors.navy};
  &:hover {
    background: #774054;
    border-color: ${(props) => props.theme.colors.alert};
    color: ${(props) => props.theme.colors.ice};
  }
  &:hover ${RemoveSelectedItemBubble} {
    background: ${(props) => props.theme.colors.alert};
  }
`;
