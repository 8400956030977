import { useLazyQuery, useMutation } from "@apollo/client";
import { useRecoilState } from "recoil";
import {
  FOLLOW_UNFOLLOW_MUTATION,
  MY_FOLLOWERS_QUERY,
} from "../../GraphQL/followers";

import { AllstarUser } from "../../State";

export const useMutateFollowers = () => {
  const [allstarUser, setAllstarUser] = useRecoilState(AllstarUser);

  const [mutateFollowers] = useMutation(FOLLOW_UNFOLLOW_MUTATION, {
    refetchQueries: [{ query: MY_FOLLOWERS_QUERY }],
    onCompleted: () => {
      if (allstarUser.loggedIn) {
        requestFollowers({
          variables: { profiles: true, clips: false },
        });
      }
    },
  });

  const [requestFollowers] = useLazyQuery(MY_FOLLOWERS_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const followingData = data.myFollowing.profiles;
      setAllstarUser({
        ...allstarUser,
        followers: followingData,
      });
    },
  });

  return { requestFollowers, mutateFollowers };
};
