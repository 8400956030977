import React from "react";
import { LabeledIcon } from "../../../../Views/Studio/Components/StudioIcons";
import { HideOnSmall } from "../../../Wrappers";
import { Flex } from "rebass";

export const TagRow = ({
  card,
  onQuery,
  setCreatorCardModal,
  isModal,
  hideOnMobile,
}: any) => {
  return (
    <Flex flexWrap="wrap" style={{ gap: "10px" }}>
      {/**
       * @Phillip
       * These labeled icons have a hard coded queryType passed in, not ideal but I can't think of a
       * way to tell the component what kinda of query it needs to make when the LabeledIcon is clicked.
       */}
      <LabeledIcon
        category={card?.facet?.type[0] ?? ""}
        onQuery={onQuery}
        setCreatorCardModal={setCreatorCardModal}
        queryType={"type"}
        isModal={isModal}
      />
      <HideOnSmall>
        <LabeledIcon
          category={card?.facet?.theme?.length ? card?.facet?.theme[0] : ""}
          onQuery={onQuery}
          setCreatorCardModal={setCreatorCardModal}
          queryType={"theme"}
          isModal={isModal}
        />
      </HideOnSmall>
      {isModal ? (
        <LabeledIcon
          category={
            card?.facet?.mechanic?.length ? card?.facet?.mechanic[0] : ""
          }
          onQuery={onQuery}
          setCreatorCardModal={setCreatorCardModal}
          queryType={"mechanic"}
          isModal={isModal}
        />
      ) : (
        <></>
      )}
    </Flex>
  );
};
