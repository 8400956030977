import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Flex } from "rebass/styled-components";

import {
  DisabledOverlay,
  GenreContainer,
  MusicGenreSelectorContainer,
} from "./styledComponents";
import { MusicGenreSelectorViewModel } from "./ViewModel";
import { Large3 } from "../Text";
import { Button } from "../Button";
import { AllstarModalState } from "../../State/modals";
import { ModalType } from "../../@types";
import {
  SignatureSelectorState,
  TSelectedCard,
} from "../../State/loadoutSelector";
import { StyledSubheader } from "../Header/styledComponents";
import { CountIndicator } from "../../Views/CreateMontage/Components/CountIndicator";
const MAX_GENRES = 3;
export const MusicGenreSelector = () => {
  const { getMusicGenres } = MusicGenreSelectorViewModel();
  const [musicGenreCards, setMusicGenreCards] = useState([] as TSelectedCard[]);
  const [selectedGenreCards, setSelectedGenreCards] = useState(
    [] as TSelectedCard[],
  );
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const [signatureSelectorState, setSignatureSelectorState] = useRecoilState(
    SignatureSelectorState,
  );

  useEffect(() => {
    (async () => {
      const { data } = await getMusicGenres({
        variables: {
          type: ["facetLookup"],
          option2: true,
          ignoreStudioVisibilityConstraints: true,
        },
      });

      if (data?.facets?.cards?.length) {
        const cards = data.facets.cards;
        let selectedCards = [] as TSelectedCard[];
        setMusicGenreCards(cards);
        if (signatureSelectorState?.selectedGenres?.length) {
          for (const card of cards) {
            if (
              signatureSelectorState?.selectedGenres
                ?.map((card) => card._id)
                .includes(card._id)
            ) {
              selectedCards.push(card);
            }
          }
        }
        setSelectedGenreCards(selectedCards);
      }
    })();
  }, [getMusicGenres]);

  const handleClose = () => {
    if (allstarModalState?.data?.isFtue)
      return setAllstarModalState({
        isOpen: ModalType.None,
        functions: {},
        data: {},
      });

    setAllstarModalState({
      isOpen: ModalType.SignatureSelector,
      functions: {},
      data: {},
    });
  };

  return (
    <>
      <Large3>Select up to {MAX_GENRES} of your favorite music genres.</Large3>
      <StyledSubheader mb={5}>
        We'll pull songs from these genres and apply them to your clips
        automatically!
      </StyledSubheader>
      <MusicGenreSelectorContainer>
        {musicGenreCards.length &&
          musicGenreCards.map((card) => {
            return (
              <GenreContainer
                selected={selectedGenreCards.some(
                  (genre) => genre._id === card._id,
                )}
                disabled={
                  selectedGenreCards.length >= MAX_GENRES &&
                  !selectedGenreCards.some((genre) => genre._id === card._id)
                }
                onClick={() => {
                  const isSelected = selectedGenreCards.some(
                    (genre) => genre._id === card._id,
                  );
                  if (isSelected) {
                    window.rudderanalytics.track(
                      "Music Selector - Deselected Genre",
                      {
                        genre: card.ui.title,
                      },
                    );

                    setSelectedGenreCards(
                      selectedGenreCards.filter(
                        (genre) => genre._id !== card._id,
                      ),
                    );
                  } else {
                    if (selectedGenreCards.length >= MAX_GENRES) return;
                    window.rudderanalytics.track(
                      "Music Selector - Selected Genre",
                      {
                        genre: card.ui.title,
                      },
                    );
                    setSelectedGenreCards([...selectedGenreCards, card]);
                  }
                }}
              >
                {selectedGenreCards.length >= MAX_GENRES &&
                  !selectedGenreCards.some(
                    (genre) => genre._id === card._id,
                  ) && <DisabledOverlay />}
                {card.ui.title}
              </GenreContainer>
            );
          })}
      </MusicGenreSelectorContainer>{" "}
      <Flex mt={5} justifyContent={"space-between"}>
        <CountIndicator
          heading={"Genres"}
          count={selectedGenreCards.length}
          max={MAX_GENRES}
          canProceed={true}
        />
        <Flex style={{ gap: 10 }}>
          <Button
            styled={{ width: 100 }}
            classification="roundedFilledAlt"
            size="small"
            onClick={() => {
              handleClose();
            }}
            flex={true}
          >
            Cancel
          </Button>
          <Button
            styled={{ width: 100 }}
            classification="roundedFilled"
            size="small"
            onClick={() => {
              setSignatureSelectorState((prev) => {
                return {
                  ...prev,
                  selectedGenres: selectedGenreCards,
                };
              });
              handleClose();
            }}
            flex={true}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
