import React from "react";
import styled from "styled-components";
import { Flex, Image } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { MontageSession } from "../../../../State";
import { VerticalDivider } from "../../../../Components/Divider";
import { GAMES } from "../../@types";

export const GameSelector = () => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      width="100%"
      height="100%"
    >
      <GamesRow>
        <GameButton
          onClick={() => {
            setMontageSession({
              ...montageSession,
              game: GAMES.CounterStrike2,
            });
          }}
          selected={montageSession.game === GAMES.CounterStrike2}
        >
          <GameImage
            src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/static/games/CS2.svg`}
          />
        </GameButton>
        <VerticalDivider height={"50px"} mx={5} width={"1px"} />
        <GameButton
          onClick={() => {
            setMontageSession({ ...montageSession, game: GAMES.CSGO });
          }}
          selected={montageSession.game === GAMES.CSGO}
        >
          <GameImage
            src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/static/games/csgo.svg`}
          />
        </GameButton>
      </GamesRow>
    </Flex>
  );
};

const GameButton = styled(Flex)<{ selected: boolean }>`
  width: 200px;
  padding: 10px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  ${({ selected, theme }) => {
    return (
      selected &&
      `
    background-color: ${theme.colors.midnightAlpha50};
    border: 1px solid ${theme.colors.envy};
    `
    );
  }}
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.midnightAlpha50};
  }
`;
const GameImage = styled(Image)`
  width: 100%;
  max-width: 150px;
`;
const GamesRow = styled(Flex)`
  width: 100%;

  justify-content: center;
  align-items: center;
`;
