export const useIsMobileApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isMobileApp = queryParams.get("external") === "mobileapp";
  if (isMobileApp) localStorage.setItem("isMobileApp", `${isMobileApp}`);

  return {
    isMobileApp: Boolean(isMobileApp || localStorage.getItem("isMobileApp")),
    token: queryParams.get("token"),
  };
};
