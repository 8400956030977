import React from "react";
import { useRecoilState } from "recoil";

import { FloatingContainer, Modal } from "../../Wrappers";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { MusicGenreSelector } from "../../MusicGenreSelector";

export const MusicGenreSelectorModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  if (allstarModalState.isOpen !== ModalType.MusicGenreSelector) return <></>;
  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.MusicGenreSelector}
        setModalOpen={() => {
          setAllstarModalState({
            ...allstarModalState,
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer maxWidth={900}>
          <MusicGenreSelector />
        </FloatingContainer>
      </Modal>
    </>
  );
};
