import { gql } from "@apollo/client";

export const SEQUENCE_QUERY = gql`
  query Sequences {
    sequences {
      sequenceType
      description
      image
      requires {
        text
        icon
      }
      video
      qualify
      buildDoc {
        amsTiming
        minKills
        maxKills
      }
      comingSoon
    }
  }
`;

export const LOADOUT_QUERY = gql`
  query Loadouts($game: Int) {
    loadouts(game: $game) {
      name
      description
      image
      hoverEffect
      badge
      hoverEffectSrc
      displayCards {
        _id
        isActive
        scopeUse
        scopeVis
        userGroups
        ui {
          title
          category
          slug
          useType
          rarity
          badge
          explanation
          flavorText
          hoverEffect
          hoverEffectSrc
          image
          game
        }
        facet {
          type
          mechanic
          game
          theme
        }
      }
      cards {
        _id
        effect
        ui {
          explanation
          title
          image
        }
        facet {
          series
          type
        }
      }
    }
  }
`;

export const QUALIFY = gql`
  query Query($sequence: String, $user: String) {
    montageQualify(sequence: $sequence, user: $user) {
      passed
      message
      failedFromCredits
      balance
    }
  }
`;

export const DELETE_MONTAGE = gql`
  mutation Mutation($montageIdentifier: String) {
    deleteMontage(montageIdentifier: $montageIdentifier)
  }
`;

export const CLIPS = gql`
  query CreateMontageClips(
    $user: String
    $sequence: String
    $filter: [String]
    $sort: String
    $game: Int
  ) {
    createMontageClips(
      user: $user
      sequence: $sequence
      filter: $filter
      sort: $sort
      game: $game
    ) {
      clipTitle
      _id
      shareId
      userClipTitle
      views
      createdDate
      clipLink
      timeline {
        event
      }
      clipImageThumb
      clipProperties {
        key
        value
      }
      user {
        _id
      }
      personalizations {
        data {
          kills {
            event
          }
        }
      }
      cf {
        hls
        readyToStream
        downloadUrl
      }
    }
  }
`;

export const STUDIO_CARDS = gql`
  query Facets($type: [String], $theme: [String]) {
    facets(type: $type, theme: $theme) {
      cards {
        _id
        isActive
        scopeUse
        scopeVis
        userGroups
        ui {
          title
          category
          slug
          useType
          rarity
          badge
          explanation
          flavorText
          hoverEffect
          hoverEffectSrc
          image
          game
        }
        facet {
          type
          mechanic
          game
          theme
        }
      }
    }
  }
`;

export const SINGLE_MONTAGE = gql`
  query Query($clipIdentifier: String) {
    getMontage(clipIdentifier: $clipIdentifier) {
      _id
      originalClips {
        clipImageThumb
        clipImageSource
        clipSnapshot
      }
      clipTitle
    }
  }
`;

export const FINAL_TOUCHES = gql`
  query Query(
    $type: [String]
    $mechanic: [String]
    $memeMechanic: [String]
    $audioType: [String]
  ) {
    memes: facets(type: $type, mechanic: $memeMechanic) {
      cards {
        _id
        ui {
          title
        }
      }
    }
    cameraCards: facets(mechanic: $mechanic) {
      cards {
        _id
        ui {
          title
        }
      }
    }
    audioEffects: facets(type: $audioType) {
      cards {
        _id
        ui {
          title
        }
      }
    }
  }
`;

export const MY_BALANCE = gql`
  query Query {
    myLedgerBalance {
      balance
      enableProFeatures
      block
    }
  }
`;
