import React from "react";
import { Subheader } from "../../Text";

export function Validation(props: any) {
  return (
    <Subheader
      color={props.color ? props.color : "error"}
      mb={props.mb}
      mt={props.mt}
    >
      {props.children}
    </Subheader>
  );
}
