import React from "react";
import styled from "styled-components";
import { Flex, Image } from "rebass/styled-components";
import { HiOutlinePlusCircle } from "react-icons/hi";

export const SelectClipCard = ({
  openClipSelectorModal,
}: {
  openClipSelectorModal: () => void;
}) => {
  return (
    <ClipHandle
      onClick={() => {
        openClipSelectorModal();
      }}
    >
      <Add>
        <StyledAddIcon />
      </Add>
    </ClipHandle>
  );
};

const StyledAddIcon = styled(HiOutlinePlusCircle)`
  width: 24px;
  height: 24px;
  z-index: 1;
  color: rgba(113, 170, 255, 0.5);
  align-self: center;
`;

const Add = styled(Flex)<any>`
  position: absolute;
  display: flex;
  transition: 0.3s ease;
  background: rgba(113, 170, 255, 0.1);
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 14px;
`;

const ClipHandle = styled(Flex)<any>`
  display: flex;
  position: relative;
  height: 35px;
  min-height: 70px;
  width: 100%;
  border-radius: 14px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  ${(props) => {
    return (
      !props.isDisabled &&
      `&:hover {
    cursor: pointer;
  }`
    );
  }}
  &:hover ${StyledAddIcon} {
    color: ${({ theme }) => theme.colors.ice};
  }
  &:hover ${Add} {
    background: ${({ theme }) => theme.colors.turbo};
  }
`;
