import { Flex, Box } from "rebass/styled-components";
import { Large3, Title2 } from "../text";
import styled from "styled-components";

const StyledLarge3 = styled(Large3)`
  font-weight: 600;
`;

const StyledPage = styled(Flex)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-left: 0px;
  }
`;

export function PageHeader({ z1, ...props }) {
  return (
    <StyledPage justifyContent="center" style={z1 ? { zIndex: 1 } : undefined}>
      <Box
        textAlign="center"
        maxWidth={6}
        px={0}
        pt={props.pt || [0]}
        pb={props.pb ? props.pb : [0]}
        mb={props.mb ? props.mb : [0]}
      >
        {props.image}
        <StyledLarge3 my={5}>{props.title}</StyledLarge3>
        {props.subtitle ? <Title2>{props.subtitle}</Title2> : <></>}
        {props.posttext ? props.posttext : null}
      </Box>
    </StyledPage>
  );
}
