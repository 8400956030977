import React from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import theme from "../../../../../theme/themeThree";
import { OverlayShare } from "../../../../Components/Button/PlayerOverlay";
import { isFaceItPartner } from "../../Utilities";
import { PartnerViewSession } from "../../../../State";
import { useRecoilState } from "recoil";

const StyledShare = styled(OverlayShare)`
  height: 55px;
  width: 55px;
  padding-top: 0px;

  @media (max-width: 450px) {
    padding-top: 6px;
  }
`;

const DefaultBorder: any = styled(Box)`
  position: absolute;
  transform: translateX(-50px);
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  opacity: 0;

  ${(props: any) => {
    //if props.display, unset right and set position to relative
    if (props.display) {
      return `
            opacity:1;
            transform: translateX(${props.index * -60}px);
            @media (max-width: 650px) {
              transform: translateX(${props.index * -45}px);
            }
            @media (max-height: 330px) {
              transform: translateX(${props.index * 45}px);
            }
        `;
    }
  }}
`;

export const SharePopout = ({
  overlayConfig,
  display,
  setAttemptedAction,
}: any) => {
  const [partnerViewSession] = useRecoilState(PartnerViewSession);
  const { clip } = partnerViewSession;
  const partnerName = overlayConfig?.partner?.name;  
  const isFaceItPartnerFlag = isFaceItPartner(partnerName);
  const clipLink = `https://${window.location.hostname}/clip?clip=${clip.shareId}`;
  const formattedClipLink = encodeURI(`${clipLink}&utm_source=${clipLink}&utm_medium=share_link&utm_term=${partnerName}`);

  const renderSocialButtons = () => {
    if (isFaceItPartnerFlag) {
      return (
        <>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={1}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"facebook"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "Facebook",
                  });
                } catch (e) {}
                window.open(`https://www.facebook.com/dialog/share?app_id=595195531196882&display=popup&href=${formattedClipLink}&redirect_uri=${formattedClipLink}`, "_blank");
              }}
            />
          </DefaultBorder>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={2}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"reddit"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "Reddit",
                  });
                } catch (e) {}
                window.open(`https://www.reddit.com/submit?url=${formattedClipLink}${clip.clipTitle ? `&title=${clip.clipTitle}` : ""}`, "_blank");
              }}
            />
          </DefaultBorder>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={3}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"twitter"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "Twitter",
                  });
                } catch (e) {}
                window.open(`https://twitter.com/intent/tweet?url=${formattedClipLink}&text=${clip.clipTitle}`, "_blank");
              }}
            />
          </DefaultBorder>
        </>
      );
    } else {
      return (
        <>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={1}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"discord"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "Discord",
                  });
                } catch (e) {}
                if (!overlayConfig.knownUser) {
                  setAttemptedAction("discord");
                } else {
                  window.open(overlayConfig.share.discordShare, "_blank");
                }
              }}
            />
          </DefaultBorder>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={2}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"youtube"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "Youtube",
                  });
                } catch (e) {}
                if (!overlayConfig.knownUser) {
                  setAttemptedAction("youtube");
                } else {
                  window.open(overlayConfig.share.youtubeShare, "_blank");
                }
              }}
            />
          </DefaultBorder>
          <DefaultBorder
            sx={{ ...theme.borders["overlayDefault"] }}
            display={display}
            index={3}
          >
            <StyledShare
              classification={"overlayDefault"}
              size={"overlayOption"}
              href={overlayConfig.share.link}
              type={"tiktok"}
              onClick={() => {
                try {
                  window.rudderanalytics.track("Overlay - Share Click", {
                    knownUser: overlayConfig?.knownUser,
                    shareId: overlayConfig?.shareId,
                    partner: overlayConfig?.partner?.name,
                    useCase: overlayConfig?.partner?.useCase,
                    type: "TikTok",
                  });
                } catch (e) {}
                if (!overlayConfig.knownUser) {
                  setAttemptedAction("tiktok");
                } else {
                  window.open(overlayConfig.share.tiktokShare, "_blank");
                }
              }}
            />
          </DefaultBorder>
        </>
      );
    }
  };

  return (
    <>
      <PopoutFlex display={display}>
        {" "}
        {renderSocialButtons()}
        <DefaultBorder
          sx={{ ...theme.borders["overlayDefault"] }}
          display={display}
          index={4}
        >
          <StyledShare
            classification={"overlayDefault"}
            size={"overlayOption"}
            href={overlayConfig.share.link}
            type={"link"}
            onClick={() => {
              try {
                window.rudderanalytics.track("Overlay - Share Click", {
                  knownUser: overlayConfig?.knownUser,
                  shareId: overlayConfig?.shareId,
                  partner: overlayConfig?.partner?.name,
                  useCase: overlayConfig?.partner?.useCase,
                  type: "Copy Link",
                });
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </DefaultBorder>
      </PopoutFlex>
    </>
  );
};

const PopoutFlex = styled(Flex)`
  position: absolute;
  top: 0px;
  transform: translateX(50px);
  flex-direction: row;
  gap: 5px;
  z-index: -10;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  ${(props: any) => {
    //if props.display, unset right and set position to relative
    if (props.display) {
      return `
            transform: translateX(0px);
        `;
    }
  }}
  @media (max-height: 330px) {
    position: absolute;
    transform: translateX(-10px);
    top: 45px;
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    ${(props: any) => {
      //if props.display, unset right and set position to relative
      if (props.display) {
        return `
              transform: translateX(-45px);
          `;
      }
    }}
  }
`;
