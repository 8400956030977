import { gql } from "@apollo/client";

export const CLIP_FULL_MODAL = gql`
  query GetClip($clipIdentifier: String) {
    getClip(clipIdentifier: $clipIdentifier) {
      _id
      clipTitle
      clipSnapshot
      comments {
        count
        reaction
      }
      cf {
        hls
        downloadUrl
      }
      game
      user {
        _id
        avatar
        rank
        userGroup {
          type
        }
      }
      createdDate
      clipLink
      views
      username
      shareId
      userClipTitle
      remix {
        originalUser
        isRemixed
        clonedVersion
        ui {
          username
          shareId
          allstarAvatar
        }
      }
      personalizations {
        cards {
          _id
          effect
          scopeUse
          ui {
            title
            hoverEffect
            image
            rarity
          }
          facet {
            type
          }
        }
      }
    }
  }
`;

export const USER_DATA = gql`
  query Clips($user: String, $limit: Int, $search: SearchClips!) {
    clips(user: $user, limit: $limit, search: $search) {
      data {
        clipTitle
        clipImageThumb
        views
        createdDate
        shareId
        user {
          avatar
          _id
        }
        remix {
          originalUser
          isRemixed
          clonedVersion
          ui {
            username
            shareId
            allstarAvatar
          }
        }
      }
    }
    userProfile(user: $user) {
      profile {
        username
      }
      stats {
        totalClips
        totalViews
      }
    }
  }
`;
