import React from "react";
import { Flex } from "rebass/styled-components";
import { Modal } from "../../Wrappers";
import styled from "styled-components";
import { CloseButton } from "../../Button";

import { TransitTime } from "../../TransitTime";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

export const DebugModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  if (allstarModalState.isOpen !== ModalType.DebugWindow) return <></>;
  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.DebugWindow}
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FullScreenDebugBox>
          <StyledCloseButton
            size="small"
            classification="round"
            onClick={() => {
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
          />
          {JSON.stringify(allstarModalState.data, null, 2)}
        </FullScreenDebugBox>
      </Modal>
    </>
  );
};

const StyledCloseButton = styled(CloseButton)`
  right: 10px;
  position: absolute;
`;

const FullScreenDebugBox = styled(Flex)`
  flex-direction: column;
  background-color: black;
  color: ${({ theme }) => theme.colors.white};
  height: 95vh;
  width: 95vw;
  overflow-y: auto;
  padding: 20px;
  font-size: 12px;
`;
