import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";

import { Button } from "../../Button";
import { Footnote, Title3 } from "../../Text";

export const StyledButton = styled(Button)`
  white-space: normal;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(theme) => theme.theme.breaks.small}) {
    min-width: min-content;
    padding: 6px 12px;
    max-width: 80px;
  }

  @media (max-width: ${(theme) => theme.theme.breaks.narrow}) {
    padding: 2px 4px;
  }
`;

export const DisableableButton = styled(StyledButton)`
  white-space: normal;

  :disabled {
    opacity: 0.2;
  }

  :disabled:hover {
    cursor: not-allowed;
    border: 1px solid #2f3d48;
  }

  @media (max-width: ${(theme) => theme.theme.breaks.small}) {
    min-width: min-content;
    padding: 6px 12px;
  }

  @media (max-width: ${(theme) => theme.theme.breaks.narrow}) {
    padding: 2px 4px;
  }
`;

export const StyledContainer = styled(Flex)`
  width: 120px;
  max-width: 100%;
  height: auto;
  text-wrap: wrap;
`;

export const RemixModalImage = styled(Image)`
  position: absolute;
  top: -40%;
  left: 20%;
  width: 320px;
  height: auto;

  @media (max-width: ${(theme) => theme.theme.breaks.narrow}) {
    top: -30%;
    left: 15%;
    width: 70%;
  }
`;

export const StyledFootnote = styled(Footnote)`
  color: ${(props) => props.theme.colors.chalk};
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-wrap: wrap;
`;

export const StyledTitle3 = styled(Title3)`
  font-weight: 600;
`;
