import React from "react";
import { Flex } from "rebass/styled-components";
import { Body } from "../Text";
import styled from "styled-components";
import { RiCloseLine, RiMegaphoneFill } from "react-icons/ri";
import { Link } from "../Button";

export function NoticeBanner({
  className,
  children,
  backgroundColor,
  display,
  setShouldShow,
  onClick,
  hash,
  href,
}: any) {
  return (
    <>
      <Banner
        className={className}
        display={display}
        backgroundColor={backgroundColor}
        onClick={onClick}
        href={href}
      >
        <StyledAnnounce />
        <StyledBody>{children}</StyledBody>
        <StyledClose
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShouldShow(false);
            localStorage.setItem(
              `banner-announcement-${hash}-has-been-closed`,
              "true"
            );
          }}
        />
      </Banner>
    </>
  );
}

const StyledAnnounce = styled(RiMegaphoneFill)`
  position: relative;
  min-width: 24px;
  margin-left: 5px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;
const StyledClose = styled(RiCloseLine)`
  position: relative;
  min-width: 24px;
  min-height: 24px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.ice};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.iceAlpha60};
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: 2px;
    margin-left: 5px;
  }
`;

const StyledBody = styled(Body)`
  font-weight: 500;
`;

const Banner = styled(Flex)`
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.saleGradient};

  padding: ${(props) => props.theme.space[3]}px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  margin: 5px;
  border-radius: 8px;
  width: calc(100% - 105px);
  z-index: 100;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(12, 10, 24, 0.25);
  display: ${(props) => props.display};
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: calc(100% - 15px);
    margin-top: 65px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
  }
`;
