import React from "react";
import { Flex } from "rebass/styled-components";

import { DividerAlt } from "../../Divider";
import { Subheader } from "../../Text";
import {
  AppDropdown,
  DropdownTopSection,
  StyledButtonContainer,
  StyledComputer,
  StyledDiscord,
  StyledMobile,
} from "../styledComponents";

const DESKTOP_URL =
  "https://download.overwolf.com/install/Download?Name=Allstar&ExtensionId=ccbanlanlcmninogggoifelckghdgccjaanopmpn";
const ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.androidapp.gg.allstar";
const DISCORD_URL =
  "https://discord.com/application-directory/580572502288498700";

const GetAppList = () => {
  return (
    <AppDropdown>
      <DropdownTopSection>
        <Flex width="100%" data-testid="getappList">
          <StyledButtonContainer
            title="Download Desktop App"
            target="_blank"
            to="#"
            onClick={() => {
              window.rudderanalytics.track("Navbar Application Download", {
                version: "unknown",
                path: window.location.pathname,
              });
              window.location.href = DESKTOP_URL;
            }}
          >
            <StyledComputer size={22} />
            <Subheader>Get Allstar for Desktop</Subheader>
          </StyledButtonContainer>
        </Flex>
        <DividerAlt width="90%" my="10px" />
        <Flex width="100%">
          <StyledButtonContainer
            title="Download Mobile App"
            target="_blank"
            to="#"
            onClick={() => {
              window.rudderanalytics.track(
                "Navbar Application Download Android",
                {
                  version: "unknown",
                  path: window.location.pathname,
                },
              );
              window.location.href = ANDROID_URL;
            }}
          >
            <StyledMobile size={22} />
            <Subheader>Get Allstar for Mobile</Subheader>
          </StyledButtonContainer>
        </Flex>

        <DividerAlt width="90%" my="10px" />

        <Flex width="100%">
          <StyledButtonContainer
            title="Download Discord Bot"
            target="_blank"
            to="#"
            onClick={() => {
              window.rudderanalytics.track(
                "Navbar Application Download Discord",
                {
                  version: "unknown",
                  path: window.location.pathname,
                },
              );
              window.location.href = DISCORD_URL;
            }}
          >
            <StyledDiscord size={22} />
            <Subheader>Get our Discord Bot</Subheader>
          </StyledButtonContainer>
        </Flex>
      </DropdownTopSection>
    </AppDropdown>
  );
};

export { GetAppList };
