import React from "react";
import styled from "styled-components";
import { Box, Image } from "rebass/styled-components";
import { LoadingSkeleton } from "../Skeloton";

type TImageWithLoadingProps = {
  imageSource?: string;
  aspectRatio: number;
};

export const ImageWithLoading = ({
  imageSource,
  aspectRatio,
}: TImageWithLoadingProps) => {
  return (
    <ImageWithLoadingContainer aspectRatio={aspectRatio}>
      <LoadingSkeleton
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "9px",
        }}
      />
      <DisplayedImage src={imageSource} />
    </ImageWithLoadingContainer>
  );
};
const ImageWithLoadingContainer = styled(Box)<any>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => {
    return (1 / props.aspectRatio) * 100;
  }}%;
`;

const DisplayedImage = styled(Image)<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  z-index: 1;
`;
