import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { Modal } from "../../Wrappers";
import styled from "styled-components";
import { CloseButton } from "../../Button";

import { TransitTime } from "../../TransitTime";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

export const TransitTimeModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  if (allstarModalState.isOpen !== ModalType.TransitTime) return <></>;
  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.TransitTime}
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <CenteredModalContents>
          <StyledCloseButton
            size="small"
            classification="round"
            onClick={() => {
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
          />
          <TransitTimeWrapper>
            <TransitTime user={allstarModalState?.data?.user} />
          </TransitTimeWrapper>
        </CenteredModalContents>
      </Modal>
    </>
  );
};

const TransitTimeWrapper = styled(Box)`
  > div > div {
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0px;
    }
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: -18px;
  right: -18px;
  z-index: 100;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    top: 4px;
    right: 4px;
  }
`;

const CenteredModalContents = styled(Flex)`
  flex-direction: column;
  position: relative;
  margin: 3rem auto;
  border-radius: 20px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  transform: translateY(0) scale(1);
  transition:
    opacity 0.2s ease,
    transform 0.3s ease,
    -webkit-transform 0.3s ease;
  transition-delay: 0.2s;
  opacity: 1;
  max-width: 985px;
  padding: 20px;
  box-shadow: 0 0 30px #233438;
  background-color: ${({ theme }) => theme.colors.modalBg};

  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 80%;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    padding: 20px;
  }
`;
