import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { MontageSession } from "../../../../State";

import { countKills } from "../../Components/Clips";

import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { Box, Flex } from "rebass/styled-components";
import { TMontageClip } from "../../@types";
import { Button } from "../../../../Components/Button";
import { Body, Headline } from "../../../../Components/Text";
import { Tooltip } from "../../../../Components/Tooltip";
import { Count, CountIndicator } from "../../Components/CountIndicator";
import { ModalClip } from "../../Components/Clips/Components/ModalClip";
import {
  AddButton,
  AddButtonContainer,
  ClipsList,
  ClipsModal,
  FilterContainer,
  Headers,
  ModalContent,
  Select,
  StyledHeading,
} from "./styledComponents";

interface Props {
  isOpen: boolean;
  children?: JSX.Element | JSX.Element[];
  closeClipSelectorModal: () => void;
  clips: TMontageClip[];
  count: number;
  loading: boolean;
  queryClips: (filter: string[], sort: string) => Promise<void>;
  openProCTA: (modal: { body: string; isOpen: boolean }) => void;
  insertIndex: number;
}

export const Modal: React.FC<Props> = ({
  isOpen,
  closeClipSelectorModal,
  clips,
  loading,
  queryClips,
  openProCTA,
}) => {
  const [selectedClipIds, setSelectedClipIds] = useState<any[]>([]);
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const [selectedKillTotal, setSelectedKillTotal] = useState(0);
  const [selectedClipDocs, setSelectedClipDocs] = useState<TMontageClip[]>([]);
  const [canProceed, setCanProceed] = useState(false);
  const [map, setMap] = useState("All");
  const [kills, setKills] = useState("All");
  const [weapons, setWeapons] = useState("All");
  const [sort, setSort] = useState("createdDate");
  const [canAdd, setCanAdd] = useState(Boolean(selectedClipIds.length));

  const proceedCriteria = (type: string, count: number) => {
    if (type === "freeform")
      return (
        count >= montageSession.sequence.buildDoc.minKills &&
        count <= montageSession.sequence.buildDoc.maxKills
      );

    return false;
  };
  useEffect(() => {
    setCanAdd(Boolean(selectedClipIds.length));
  }, [selectedClipIds]);

  const closeModal = () => {
    setSelectedClipIds([]);
    setSelectedClipDocs([]);
    closeClipSelectorModal();
  };

  useEffect(() => {
    setSelectedKillTotal(0);
  }, [isOpen]);

  useEffect(() => {
    const onFilterAppy = async () => {
      const filter = [];
      if (map !== "All") filter.push(map);
      if (kills !== "All") filter.push(kills);
      if (weapons !== "All") filter.push(weapons);

      queryClips(filter, sort);
    };

    onFilterAppy();
  }, [map, weapons, kills, sort]); //eslint-disable-line

  useEffect(() => {
    setCanProceed(
      proceedCriteria(montageSession.sequence.qualify, montageSession.kills),
    );
  }, [montageSession.clips]); //eslint-disable-line

  const handleSelect = (clip: TMontageClip) => {
    const isActive = selectedClipIds.includes(clip._id);
    const numKills = countKills([clip]);

    if (!isActive) {
      setSelectedClipIds([...selectedClipIds, clip._id]);
      setSelectedClipDocs([...selectedClipDocs, clip]);
      setSelectedKillTotal(selectedKillTotal + numKills);
      setMontageSession({
        ...montageSession,
        kills: montageSession.kills + numKills,
      });
    } else {
      setSelectedClipIds(
        selectedClipIds.filter((id) => {
          return id !== clip._id;
        }),
      );
      setSelectedKillTotal(selectedKillTotal - numKills);
      setSelectedClipDocs(
        selectedClipDocs.filter((selectedClipIds) => {
          return selectedClipIds._id !== clip._id;
        }),
      );
      setMontageSession({
        ...montageSession,
        kills: montageSession.kills - numKills,
      });
    }
  };

  const isDisabled = (clip: TMontageClip) => {
    const numKills = countKills([clip]);

    return (
      montageSession.kills + numKills >
      montageSession?.sequence?.buildDoc?.maxKills
    );
  };

  return (
    <ClipsModal isOpen={isOpen}>
      <ModalContent>
        <Headers mb={2} mt={[4, 4, 4, 0]}>
          <StyledHeading>Show me: </StyledHeading>
          <FilterContainer>
            Map
            <Select
              defaultValue={map}
              onChange={(e: any) => setMap(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Dust 2">Dust 2</option>
              <option value="Inferno">Inferno</option>
              <option value="Mirage">Mirage</option>
              <option value="Cache">Cache</option>
              <option value="Overpass">Overpass</option>
            </Select>
            Kills
            <Select
              defaultValue={kills}
              onChange={(e: any) => setKills(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Kills:2">2</option>
              <option value="Kills:3">3</option>
              <option value="Kills:4">4</option>
              <option value="Kills:5">5</option>
            </Select>
            Weapons
            <Select
              defaultValue={weapons}
              onChange={(e: any) => setWeapons(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Category:CS_ARS">Assualt Rifles</option>
              <option value="Category:CS_Pistols">Pistols</option>
              <option value="Category:CS_Snipers">Snipers</option>
            </Select>
            Sort
            <Select
              defaultValue={"createdDate"}
              onChange={(e: any) => setSort(e.target.value)}
            >
              <option value="createdDate">Newest</option>
              <option value="score">Best</option>
              <option value="views">Popular</option>
            </Select>
          </FilterContainer>
        </Headers>
        <ClipsList>
          {loading && <LoadingClips />}
          {!loading && !clips?.length ? (
            <Flex
              height="250px"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <StyledHeading>
                No clips with these selected filters
              </StyledHeading>
            </Flex>
          ) : null}
          {!loading &&
            montageSession?.sequence?.buildDoc &&
            clips?.map((clip: TMontageClip, index: number) => {
              return (
                <Box style={{ width: "99%" }} key={index}>
                  <ModalClip
                    key={clip._id}
                    clip={clip}
                    isSelected={selectedClipIds.includes(clip._id)}
                    selected={selectedClipIds}
                    willExceedKillLimit={isDisabled(clip)}
                    index={index}
                    isFinale={false}
                    handleSelect={handleSelect}
                    forceColumnDisplay={false}
                    setModal={function (modal: {
                      body: string;
                      isOpen: boolean;
                    }): void {}}
                  />
                </Box>
              );
            })}
        </ClipsList>{" "}
        <AddButtonContainer>
          <Box ml={8}>
            {" "}
            <Tooltip
              position={{
                top: "-65px",
                padding: "7px",
                right: "-188px",
              }}
              type="hover"
              theme="helpTooltip"
              align="right"
              hide={canProceed}
              tooltipContent={
                <>
                  <Flex flexDirection={"column"}>
                    <Box>
                      <Count
                        count={montageSession.kills}
                        max={montageSession.sequence.buildDoc.maxKills}
                        canProceed={canProceed}
                      />
                    </Box>
                    <Body>
                      You need at least{" "}
                      {montageSession.sequence.buildDoc.minKills -
                        montageSession.kills}{" "}
                      more kills.
                    </Body>
                  </Flex>
                </>
              }
            >
              <CountIndicator
                heading={"Kills:"}
                count={montageSession.kills}
                max={montageSession.sequence.buildDoc.maxKills}
                canProceed={canProceed}
              />
            </Tooltip>
          </Box>
          <Box>
            <Button
              type="button"
              size="small"
              flex={true}
              classification="subtle"
              onClick={() => {
                setMontageSession({
                  ...montageSession,
                  kills: montageSession.kills - selectedKillTotal,
                });
                closeModal();
              }}
            >
              Cancel
            </Button>
            <AddButton
              size="small"
              onClick={() => {
                if (montageSession.isFreeUser) {
                  window.rudderanalytics.track(
                    "Montage - Free User Adjust Clips Attempt",
                    {
                      type: "Add",
                    },
                  );
                  return openProCTA({
                    body: `Pick your Montage clips, get Pro Only Creator Cards, a Profile Badge, Exclusive Content and much more!`,
                    isOpen: true,
                  });
                }
                if (!canAdd || !selectedClipIds) return;

                closeModal();
                setMontageSession({
                  ...montageSession,
                  clips: [...selectedClipDocs, ...montageSession.clips],
                });
              }}
              disabled={!canAdd}
            >
              Add
            </AddButton>
          </Box>
        </AddButtonContainer>
      </ModalContent>
    </ClipsModal>
  );
};

const LoadingClips = () => {
  return (
    <>
      {[1, 1, 1, 1].map((_, index: number) => {
        return (
          <Flex
            key={index}
            height={"240px"}
            width="335px"
            flexDirection="column"
          >
            <LoadingSkeleton height="194px" width="335px" />
            <Flex pt={2}>
              <LoadingSkeleton height="30px" width="160px" />
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};
