import React from "react";
import { Button } from "../../button";
import { SiTiktok } from "react-icons/si";
import { luminance } from "../../../../../utils";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: white;
  color: black;
  &:hover {
    background: ${luminance("	#FFFFFF", -0.1)};
  }
`;

const StyledTiktok = styled(SiTiktok)`
  vertical-align: text-top;
  color: black;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

export default function TiktokSocialButton(props) {
  return (
    <StyledButton {...props}>
      <StyledTiktok size={22} mr={!!props.children ? 3 : null} />
      {props.children}
    </StyledButton>
  );
}
