import React from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  &:before,
  &:after {
    content: "";
    display: block;
    height: 1px;
    background: ${(props) => props.theme.colors.navy};
    flex: 1;
    margin-top: 3px;
  }
`;

export function Divider(props) {
  return (
    <StyledFlex variant="divider" {...props}>
      <Box px={props.children ? 15 : null} py={props.children ? 0 : null}>
        {props.children}
      </Box>
    </StyledFlex>
  );
}
