import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { remixModal } from "../../../../globalStorage/remix/index";
import { ShareButton } from "../../../../MVVM/Components/Button/Share";
import styled from "styled-components";
import { useRemixButton } from "../../../../MVVM/Hooks/useRemixButton";
import { ReactComponent as RemixBadge } from "../../../../assets/images/badges/remix-icon.svg";
import { useTranslation } from "react-i18next";

const StyledIcon = styled(RemixBadge)`
  margin-right: 8px;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-right: 5px;
    height: 14px;
    width: 14px;
  }
`;

const StyledShareButton = styled(ShareButton)`
  flex-shrink: 0;
  color: #f7fcfd;
  -webkit-text-fill-color: #f7fcfd;
`;

export default function Remix({
  clip,
  classification,
  onClick,
  disableOnClick,
  ...props
}) {
  const remixButton = useRemixButton();
  const [modalPreferred] = useRecoilState(remixModal);
  const { t } = useTranslation(`translation`, {
    keyPrefix: "button",
  });
  const [sentRemix, setSentRemix] = useState(false);
  const isAllstarProfile = clip?.user?._id === "5ef6904913bcf10f1cdf3746";

  if (isAllstarProfile) return <></>;
  return (
    <>
      <StyledShareButton
        title="Remix Clip"
        classification="remixFeed"
        alignItems="center"
        justifyContent="center"
        flex="true"
        disabled={sentRemix}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            if (modalPreferred && props.setModalOpen) {
              props.setModalOpen(true);
            } else {
              remixButton(clip.shareId);
              if (disableOnClick) setSentRemix(true);
            }
          }
        }}
      >
        <StyledIcon />
        {t("RemixClip")}
      </StyledShareButton>
    </>
  );
}
