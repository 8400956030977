import axios from "axios";

export class Montage {
  private static instance: Montage;
  private isRequesting: boolean = false;

  public static getInstance(): Montage {
    if (!Montage.instance) Montage.instance = new Montage();
    return Montage.instance;
  }

  public async PostMontage(payload: Record<string, any>): Promise<any> {
    if (this.isRequesting) return { status: "requesting" };
    this.isRequesting = true;
    try {
      let URL = `${
        process.env.REACT_APP_API_URL
      }/items/montage/create/payload?token=${get("token")}`;

      const { data } = await axios.post(URL, payload);
      this.isRequesting = false;
      return data;
    } catch (error: any) {
      if (error?.response) {
        return error.response;
      }
      console.log(error, {
        ID: "Clip Class function call GET exception - PostMontage",
      });
      this.isRequesting = false;
      return false;
    }
  }
}

const get = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};
