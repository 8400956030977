import React, { useState, useEffect } from "react";
import { Flex, Box } from "rebass/styled-components";
import axios from "axios";
import { FloatingContainer, FloatingContent } from "../Wrappers";
import { Body } from "../Text";
import Lottie from "lottie-react";
import Security from "../../../animations/security.json";
import { useUser } from "../../Hooks/useUser";
import { User } from "../../Model/User";
import { UserStatePreFill } from "../../Hooks/userStatePreFill";
import { useRecoilState } from "recoil";
import { xsollaModalOpen } from "../../State";
import styled from "styled-components";

const lottieStyle = {
  height: 180,
};

const xsollaCommandsToIgnore = ["dimensions"];

interface IPaystation {
  isMobile: boolean;
  type: any;
  productId?: string | null;
  MTX?: string | null;
}

export const Paystation: React.FC<IPaystation> = ({
  isMobile,
  type,
  productId,
  MTX,
}) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
  });
  const [paystation, setPaystationOpen] = useRecoilState(xsollaModalOpen);
  const [xsollaLink, setXsollaLink] = useState<string | null>(null);
  const { logout } = useUser();
  const userModel = User.getInstance();
  const { allstarUser } = useUser();
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const [done, setDone] = useState(false);
  const [paystationReady, setPaystationReady] = useState(false);
  const version = isMobile ? "mobile" : "desktop";

  useEffect(() => {
    (async function getXsollaToken() {
      if (!allstarUser.loggedIn) {
        return setState({
          error: true,
          loading: false,
        });
      }
      const SAND_BOX_ENVS = ["local", "staging"];
      const email = allstarUser.decoded.sub;
      const userId = allstarUser.user._id;
      const query = type;
      const mode = SAND_BOX_ENVS.includes(process.env.REACT_APP_ENV as string)
        ? "&mode=sandbox"
        : "";
      let url = `${
        process.env.REACT_APP_AWS_SERVERLESS
      }/payment/token?userId=${userId}&email=${encodeURIComponent(
        email,
      )}&query=${query}&version=${version}${mode}`;

      if (productId) url += `&product=${productId}`;
      if (MTX) url += `&MTX=${MTX}`;
      //

      //
      try {
        const res = await axios.get(url);
        if (res.status === 200) {
          setXsollaLink(res.data.URL);
          return setState({
            loading: false,
            error: false,
          });
        } else {
          return setState({
            loading: false,
            error: true,
          });
        }
      } catch {
        return setState({
          loading: false,
          error: true,
        });
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    const refreshUserData = async () => {
      try {
        const decoded = await userModel.DecodeToken();
        const refreshToken = decoded?.refreshToken;
        if (!refreshToken) throw Error("No refresh token found");
        const newToken = await userModel.RefreshToken(refreshToken);
        localStorage.setItem("token", newToken);
        setTokenRefreshed(true);
      } catch (e) {
        logout("Failed to refresh token");
      }
    };

    // only run if there is a product it:
    if (done && productId) {
      refreshUserData();
    }
  }, [done]); // eslint-disable-line

  if (xsollaLink) {
    //handling messages sent from the iframe
    //checks if its an xsolla message
    //checks if there is data to be tracked (the messages are a bit dirty, some are meaningless and provide no data)
    //checks if its a command we want to ignore
    //if all checks pass, track it
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin.includes("xsolla")) {
          if (typeof event.data === "string") {
            try {
              let data = JSON.parse(event.data);
              // console.log({ data });
              if (data.command === "cart_display") {
                console.log("READY");
                setPaystationReady(true);
              }
              if (data.data && Object.keys(data.data).length) {
                if (!xsollaCommandsToIgnore.includes(data.command)) {
                  ///
                  const actionTypes = [
                    "open-paystation",
                    "click-btn-pay",
                    "open-status-error",
                  ];
                  if (actionTypes.includes(data.data.action)) {
                    window.rudderanalytics.track(`Xsolla Paystation Event`, {
                      ...data.data,
                    });
                  }

                  if (
                    data.data.action === "change-status" &&
                    data.data.value === "done"
                  ) {
                    if (productId) setDone(true);
                    if (MTX) {
                      setPaystationOpen({
                        ...paystation,
                        // isModalOpen: false,
                        shouldRefreshMtx: true,
                      });
                    }
                  }
                }
              }
            } catch (e) {
              // for when xsolla throws us literal garbage in the data field for some reason
            }
          }
        }
      },
      false,
    );
  }

  if (state.error) {
    return (
      <FloatingContainer>
        <FloatingContent
          title={
            <Flex justifyContent="center" fontSize="75px" pb={[6]}>
              <span role="img" aria-label="">
                🤔
              </span>
            </Flex>
          }
          body={
            <Body>
              Something went wrong. Contact support for further assistance.
            </Body>
          }
        />
      </FloatingContainer>
    );
  }
  if (tokenRefreshed)
    return (
      <IFrameLoading isMobile={isMobile}>
        <Flex
          justifyContent="center"
          alignItems={"center"}
          flexDirection="column"
        >
          <UserStatePreFill force={true} />
          <Lottie animationData={Security} style={lottieStyle} loop={true} />
          <ConnectionText>Success!</ConnectionText>
          <ConnectionSubText>
            Hold tight... leveling up your account now
          </ConnectionSubText>
        </Flex>
      </IFrameLoading>
    );
  return (
    <Box
      style={{
        paddingBottom: "56.25%",
        paddingTop: "35px;",
        height: 0,
        overflow: "hidden",
      }}
    >
      <IFrameLoading isMobile={isMobile}>
        <Flex
          justifyContent="center"
          alignItems={"center"}
          flexDirection="column"
        >
          {/* <XsollaCheck /> */}
          <Lottie animationData={Security} style={lottieStyle} loop={true} />
          <ConnectionText>Establishing a secure connection</ConnectionText>
          <ConnectionSubText>
            Just a second... We're fetching the subscription and payment info
          </ConnectionSubText>
        </Flex>
      </IFrameLoading>
      <IFrame
        title="XSOLLA"
        isMobile={isMobile} // ? "100%" : "80vw"}
        src={xsollaLink!}
        paystationReady={paystationReady}
      />
    </Box>
  );
};

type iFrameProps = {
  isMobile: boolean;
  paystationReady?: boolean;
};

const ConnectionText = styled(Flex)`
  font-size: 24px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  height: 100%;
`;

const ConnectionSubText = styled(Flex)`
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  height: 100%;
  color: #8fa9af;
`;

const IFrame = styled.iframe<iFrameProps>`
  opacity: ${(props) => (props.paystationReady ? "1" : "0")};
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: ${(props) => (props.isMobile ? "90%" : "60%")};
  border-radius: 10px;
  border-color: transparent;
`;

const IFrameLoading = styled.div<iFrameProps>`
  display: flex;
  opacity: ${(props) => (props.paystationReady ? "0" : "1")};
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  height: 90%;
  width: ${(props) => (props.isMobile ? "90%" : "60%")};
  border-radius: 10px;
  border-color: transparent;
  background: #1f2a33;
`;
