import React from "react";
import { Box } from "rebass/styled-components";

import { LoadingSkeleton } from "../../../Components/Skeloton";
import { StyledFlex } from "../styledComponents";

const ClipFullLoading = ({ modalView }: { modalView: boolean | undefined }) => {
  return (
    <StyledFlex
      modalView={modalView}
      flexDirection="column"
      alignItems="center"
    >
      <Box style={{ width: "100%", maxWidth: "1400px" }}>
        <LoadingSkeleton
          height={40}
          width="60%"
          style={{ marginBottom: "12px" }}
        />
        <LoadingSkeleton
          height={52}
          width="40%"
          style={{ marginBottom: "12px" }}
        />
        <LoadingSkeleton
          width="100%"
          style={{ aspectRatio: "16 / 9", marginBottom: "12px" }}
        />
        <LoadingSkeleton
          height={80}
          width="100%"
          style={{ marginBottom: "12px" }}
        />
      </Box>
    </StyledFlex>
  );
};

export { ClipFullLoading };
