import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Input } from "@rebass/forms";
import Lottie from "lottie-react";
import { Prompt } from "react-router-dom";
import axios from "axios";

import { FloatingContainer } from "../../../../components/wrappers/floatingContainer";
import { Modal } from "../../Wrappers";
import { ModalType } from "../../../@types";
import { AllstarModalState } from "../../../State/modals";
import { Body, Large3Bold } from "../../Text";
import { Button } from "../../Button";
import { DragAndDrop } from "../../../Utilities/DragAndDrop";
import { Overlay } from "./overlay";
import { DemoUploadViewModel } from "./ViewModel";
import { EUploadStatus, IUploadState } from "./@types";
import { Status } from "./Status";
import Loading from "../../../../animations/loading.json";

const errors = {
  exists: { kind: "Duplicate Match", message: "That match already exists!" },
  rateLimit: {
    kind: "Rate limit exceeded",
    message:
      "Sorry, you can only have 3 demo files processing at a time. Try again later!",
  },
  unknown: {
    kind: "Unknown Error",
    message: "Sorry, something went wrong.",
  },
  uploadFailed: {
    kind: "Upload Failed",
    message: "File failed to upload. Please try again or contact support.",
  },
};

const DemoUploaderModal = () => {
  const { loading, matchUploadUpdate, requestMatchUpload } =
    DemoUploadViewModel();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const overlayRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const closeModal = () =>
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.None,
    });
  const [percentCompleted, setPercentCompleted] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>();
  const [uploadState, setUploadState] = useState<IUploadState>({
    status: EUploadStatus.READY,
  });

  useEffect(() => {
    resetState();
  }, [allstarModalState]);

  const resetState = () => {
    setFile(undefined);
    setPercentCompleted(0);
    setUploadState({ status: EUploadStatus.READY, error: undefined });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.files && handleDrop(event.target.files[0]);

  const handleDrop = useCallback((file?: File) => {
    if (!file) return;
    resetState();
    setFile(file);

    if (!file?.name.match(/^.*\.(zip|bz2|gz)$/)) {
      window.rudderanalytics.track("Demo Upload - Incorrect File Type", {
        fileType: file?.name.split(".").pop(),
      });

      return setUploadState({ status: EUploadStatus.FILE_INVALID });
    }

    return setUploadState({ status: EUploadStatus.CONFIRMING });
  }, []);

  const handleUpload = async () => {
    setUploadState({
      status: EUploadStatus.UPLOADING,
      heading: "Uploading Match...",
      text: "This may take a couple minutes. Please do not close this window.",
    });

    const response = await requestMatchUpload({
      variables: { request: { type: "URL", source: file?.name } },
    });

    if (!response.data)
      return setUploadState({
        status: EUploadStatus.ERROR,
        error: errors.unknown,
      });

    const {
      matchId,
      presignedUrl,
      status,
      uploadMatchId: matchUploadId,
    } = response.data.requestUploadMatch;

    if (matchId) {
      window.rudderanalytics.track("Demo Upload - Duplicate Upload");

      return setUploadState({
        status: EUploadStatus.ERROR,
        error: errors.exists,
      });
    }

    if (status === "RATE_LIMIT") {
      window.rudderanalytics.track("Demo Upload - Rate Limit Hit");

      return setUploadState({
        status: EUploadStatus.ERROR,
        error: errors.rateLimit,
      });
    }

    if (presignedUrl && file) {
      await uploadToPresignedUrl(presignedUrl, file);
      const response = await matchUploadUpdate({
        variables: {
          status: "UPLOAD_COMPLETED",
          matchUploadId,
        },
      });

      if (response.data?.matchUploadUpdate.success)
        return setUploadState({ status: EUploadStatus.DONE });

      return setUploadState({
        status: EUploadStatus.ERROR,
        error: errors.uploadFailed,
      });
    }

    return setUploadState({
      status: EUploadStatus.ERROR,
      error: errors.unknown,
    });
  };

  const uploadToPresignedUrl = async (presignedUrl: string, file: File) => {
    try {
      await axios.put(presignedUrl, file, {
        headers: { "Content-Type": file.type, "x-amz-acl": "public-read" },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setPercentCompleted(percentCompleted);
          }
        },
      });
      window.rudderanalytics.track("Demo Upload - Success");
    } catch (e) {
      setUploadState({
        status: EUploadStatus.ERROR,
        error: errors.unknown,
      });
    }
  };

  if (allstarModalState?.isOpen !== ModalType.DemoUploader) return <></>;

  return (
    <Modal
      isModalOpen={allstarModalState.isOpen === ModalType.DemoUploader}
      setModalOpen={() => null}
      handleModalClose={closeModal}
      disableOutsideClose={true}
      data-testid="demo-uploader-modal"
    >
      <Prompt
        when={uploadState.status !== EUploadStatus.UPLOADING}
        message="Are you sure you want to leave? You have a file upload in progress!"
      />
      <StyledFloatingContainer
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth="600px"
      >
        <Flex flexDirection="column">
          <Large3Bold mb={4}>
            {uploadState.heading || "Upload Match"}
          </Large3Bold>
          <Body fontSize="2" color="chalk" mb={5}>
            {uploadState.text || "Upload a match replay file to create clips."}
          </Body>
          <DragAndDrop
            handleDrop={handleDrop}
            overlay={<Overlay ref={overlayRef} />}
            overlayRef={overlayRef}
          >
            <DragAndDropWrapper
              onClick={() =>
                uploadState.status === EUploadStatus.READY
                  ? inputRef.current?.click()
                  : {}
              }
            >
              <Input
                type="file"
                ref={inputRef}
                onChange={handleChange}
                display="none"
                data-testid="file-input"
              />
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                {loading ? (
                  <Lottie
                    animationData={Loading}
                    loop={true}
                    style={{ height: 24, width: 24 }}
                  />
                ) : (
                  <Status
                    state={uploadState}
                    file={file}
                    percentCompleted={percentCompleted}
                  />
                )}
                {[EUploadStatus.ERROR, EUploadStatus.FILE_INVALID].includes(
                  uploadState.status,
                ) && (
                  <ResetText onClick={resetState}>Try another file?</ResetText>
                )}
              </Flex>
            </DragAndDropWrapper>
          </DragAndDrop>
          <Text
            variant="text.labelSmall"
            fontWeight={400}
            color="error"
            textAlign="center"
            pt={4}
          >
            At this point, we only support Competitive & Wingman modes for
            upload.
          </Text>
          <Flex style={{ gap: 10 }} mt={4} justifyContent="flex-end">
            <Button
              flex={true}
              classification="roundedFilledAlt"
              size="tiny"
              py={5}
              onClick={closeModal}
            >
              {uploadState.status === EUploadStatus.DONE ? "Close" : "Cancel"}
            </Button>
            {![EUploadStatus.DONE].includes(uploadState.status) && (
              <Button
                flex={true}
                classification="roundedFilled"
                size="tiny"
                py={5}
                disabled={uploadState.status !== EUploadStatus.CONFIRMING}
                onClick={handleUpload}
              >
                Upload
              </Button>
            )}
          </Flex>
        </Flex>
      </StyledFloatingContainer>
    </Modal>
  );
};

const ResetText = styled(Body)`
  color: ${({ theme }) => theme.colors.envy};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 700;
  margin-top: 20px;
`;

const StyledFloatingContainer = styled(FloatingContainer)`
  background-color: ${({ theme }) => theme.colors.darkerNed};
  box-shadow: none;
`;

const DragAndDropWrapper = styled(Flex)`
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.chalkAlpha20};
  cursor: pointer;
  justify-content: center;
  padding: 80px 60px;
  position: relative;
  width: 100%;
`;

export { DemoUploaderModal };
