import React, { useState } from "react";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { FloatingContainer } from "../../components/wrappers/floatingContainer";
import { AlmostDone } from "./almost";
import { Complete } from "./complete";
import { Discord } from "./discord";
import { Verify } from "./verify";

// import { useUser } from "../../MVVM/Hooks/useUser";

const Activation = ({ step }) => {
  // const { allstarUser } = useUser();
  let [onStep, setOnStep] = useState(step);

  let getStep = () => {
    switch (onStep) {
      case 1:
        return <Verify />;
      case 2:
        return <Discord />;
      case 3:
        return <AlmostDone onStep={onStep} setOnStep={setOnStep} />;
      case 4:
        return <Complete />;
      default:
        return <></>;
    }
  };

  return (
    <FullScreenWrapper flexDirection="column">
      <FloatingContainer>{getStep()}</FloatingContainer>
      {/* {onStep === 1 ? (
        <Box mt={4}>
          <Help
            tooltipContent="In order for Allstar to create your clips automatically for
            you in the cloud, we need to have our desktop app running on
            your computer. Our software is lightweight and secure, designed to have no
            impact on your gameplay or experience."
          >
            Why do I need to download an App?
          </Help>
        </Box>
      ) : null} */}
    </FullScreenWrapper>
  );
};

Activation.defaultProps = {
  step: 1,
};

export default Activation;
