import { BottomContainer } from "./Existing/bottomContainer";
import { Flex, Box } from "rebass/styled-components";
import styled from "styled-components";
import {
  SideRailContent,
  ClipSideRailContent,
  Page,
  StickySideRail,
  MainRail,
  ClipMainRail,
} from "./Existing/contentRails";
import { FloatingContainer } from "./Existing/floatingContainer";
import { FloatingContent } from "./Existing/floatingContent";
import { FloatingDialog } from "./Existing/floatingDialog";
import { FloatingNavContainer } from "./Existing/floatingNavContainer";
import { FullScreenWrapper } from "./Existing/fullScreenWrapper";
import { FullWidthWrapper } from "./Existing/fullWidthWrapper";
import { Modal } from "./Existing/modal";
import { PaddedWrapper } from "./Existing/paddedWrapper";
import { StaticContainer } from "./Existing/staticContainer";

export {
  FloatingContent,
  
  
  Page,
  StickySideRail,
  MainRail,
  
  
  FloatingContainer,
  
  
  FullScreenWrapper,
  FullWidthWrapper,
  Modal,
  PaddedWrapper,
  StaticContainer,
  
};

const Viewport = styled(Flex)<any>`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: ${(props: any) => props.theme.breakpoints[1]}) {
    display: block;
  }
`;

export const HideOnNarrow = styled(Box)`
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    display: none;
  }
`;
export const HideOnSmall = styled(Box)`
  pointer-events: none;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    display: none;
  }
`;
export const HideOnMedium = styled(Box)`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;
const HideOnStandard = styled(Box)`
  pointer-events: none;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    display: none;
  }
`;
