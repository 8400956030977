import React, { memo } from "react";
import styled from "styled-components";
import { FullScreenWrapper } from "../../../components/wrappers/fullScreenWrapper";
import { Box, Flex } from "rebass/styled-components";
import { Headline, Large3 } from "../../../components/text";
import { FiZap } from "react-icons/fi";

const Zap = styled(FiZap).attrs(() => ({
  size: 45,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

const ContentColumn = styled(Flex)`
  position: relative;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
  }
`;

const ContentContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[7]}px;
  width: 100%;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    flex-direction: column;
  }
`;

const StyledFullScreenWrapper = styled(FullScreenWrapper)`
  position: relative;
`;

const StyledHeadline = styled(Headline)`
  font-weight: normal;
  line-height: 1.35em;
`;

const Fps = memo(
  () => {
    return (
      <StyledFullScreenWrapper key="fps-panel">
        <ContentContainer>
          <ContentColumn>
            <Box as={Zap} mb={[4, 4, 5]} />
            <Large3 mb={[6, 6, 8]}>
              Clip quality better than your frame quality
            </Large3>
            <StyledHeadline>
              By using Allstar, your videocard, CPU and connection are 100%
              focused on your game. Never drop a frame again while Allstar’s
              does all the heavy lifting for you. We'll trim, tag, title and
              upload your perfect quality clip. No more capturing video straight
              off of your screen, stressing out your PC and ruining your games!
            </StyledHeadline>
          </ContentColumn>
        </ContentContainer>
      </StyledFullScreenWrapper>
    );
  },
  () => true,
);

export default Fps;
