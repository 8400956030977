import React from "react";
import { RiHomeFill } from "react-icons/ri";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(RiHomeFill).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const HomeIcon = (props) => {
  return <StyledIcon {...props} />;
};

HomeIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default HomeIcon;
