import { Box, Flex } from "rebass";
import { HiMenu } from "react-icons/hi";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

import { ReactComponent as HeaderLogo } from "../../../assets/images/logo/headerLogo.svg";
import { SidebarExpandedState } from "../../State/sidebar";
import { pathExcludesNavigation } from "../../Utilities/Navigation/utils";
import { useIsDesktopApp } from "../../Hooks/useIsDesktopApp";
import { useIsMobileApp } from "../../Hooks/useIsMobileApp";
import { AuthenticatedSection } from "./AuthenticatedSection";

const SiteHeader = () => {
  const [expanded, setExpanded] = useRecoilState(SidebarExpandedState);
  const path = window.location.pathname;
  const isDesktopView = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();

  if (
    pathExcludesNavigation(path) ||
    isDesktopView.isDesktop ||
    isMobileApp.isMobileApp
  )
    return <></>;

  return (
    <SiteHeaderContainer>
      <Box onClick={() => setExpanded(!expanded)}>
        <StyledHiMenu />
      </Box>
      <Link to="/">
        <StyledHeaderLogo />
      </Link>
      <Box ml="auto" pr={[0, 0, 4]}>
        <AuthenticatedSection />
      </Box>
    </SiteHeaderContainer>
  );
};

const StyledHeaderLogo = styled(HeaderLogo)`
  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    width: 94px;
  }
`;

const SiteHeaderContainer = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.darkerNed};
  box-shadow: 0px 2px 4px 0px #00000026;
  gap: 36px;
  padding: 18px 28px;
  z-index: 3;
`;

const StyledHiMenu = styled(HiMenu)`
  color: ${({ theme }) => theme.colors.chalk};
  cursor: pointer;
  height: 24px;
  width: 24px;

  :hover {
    color: ${({ theme }) => theme.colors.envy};
  }
`;

export { SiteHeader };
