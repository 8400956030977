import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import Full from "../../../animations/full.json";
import NotEnough from "../../../animations/notEnough.json";
import { Body, Footnote, Title2 } from "../Text";
import { Box, Flex } from "rebass/styled-components";
import { Link } from "../Button";

type TNoMontagesLeftProps = {
  isFreeUser: boolean;
  disabledFromQualify: boolean;
};

const lottieStyle = {
  height: 50,
};

export const NoMontagesLeft = ({
  isFreeUser,
  disabledFromQualify,
}: TNoMontagesLeftProps) => {
  const content = disabledFromQualify ? (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Lottie animationData={NotEnough} style={lottieStyle} loop={true} />
      <StyledBody width="100%" mb={3} mt={1}>
        Uh Oh... You don't have enough kills in your clips to make a montage.
        Get clipping and come back!
      </StyledBody>
      <Box>
        <Footnote>
          Something not right? Contact{" "}
          <Link variant="link.form" to="/support">
            Support
          </Link>
        </Footnote>
      </Box>
    </Flex>
  ) : (
    <>
      {/* {" "}
      {isFreeUser && (
        
        <Badge
          onClick={() => {
            window.open("https://allstar.gg/upgrade", "_blank")?.focus();
          }}
        />
      )} */}
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Lottie animationData={Full} height={50} width={50} loop={true} />
        <StyledBody mt={1} width="100%" isFreeUser={isFreeUser}>
          You have no more available montages to make. <br />
          Resets on the first of every month.
          {isFreeUser && (
            <>
              <br />
              <Box mt={3}>
                Upgrade to{" "}
                <Link
                  variant="link.title"
                  pt={2}
                  onClick={() => {
                    window.rudderanalytics.track(
                      "Montage Upgrade For More Credits"
                    );
                    window
                      .open("https://allstar.gg/upgrade", "_blank")
                      ?.focus();
                  }}
                >
                  Pro
                </Link>{" "}
                to get more!
              </Box>
            </>
          )}
        </StyledBody>
      </Flex>
    </>
  );
  return (
    <>
      {" "}
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={"row"}
        variant="helpTooltip"
        p={4}
        width="400px"
        style={{ position: "relative", zIndex: 10 }}
      >
        {" "}
        {content}
      </Flex>
    </>
  );
};

export const ComingSoon = () => {
  return (
    <>
      {" "}
      <Flex
        flexDirection={"row"}
        variant="helpTooltip"
        p={4}
        width="200px"
        style={{ position: "relative", zIndex: 10 }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Title2 width="100%" style={{ fontWeight: "600" }}>
            COMING SOON
          </Title2>
        </Flex>
      </Flex>
    </>
  );
};

// const Badge = styled(Pro)<any>`
//   top: 0%;
//   right: ${(props: any) => {
//     return 3 + 10 * props.index;
//   }}%;
//   position: absolute;
//   text-align: center;
//   z-index: 10;
//   &:hover {
//     cursor: pointer;
//   }
// `;

const StyledBody = styled(Body)<any>`
  font-weight: 600;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
`;
