import React from "react";
import { Flex } from "rebass/styled-components";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { Body } from "../../components/text";
import { FloatingContainer } from "../../components/wrappers/floatingContainer";

import { FloatingContent } from "../../components/molecules/floatingContent";
import { Divider } from "../../components/atoms/divider";
import styled from "styled-components";
import { useIsMobileApp } from "../../MVVM/Hooks/useIsMobileApp";
import { useHistory } from "react-router-dom";

const StyledDivider = styled(Divider)`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
`;

export default function CreateOrLoginTikTok() {
  const history = useHistory();
  async function handleTikTok() {
    localStorage.setItem("tiktokSignup", true);
    if (isMobileApp) history.push("/authenticate/tiktok?referrer=mobile_app");
    window.location.href = `https://open-api.tiktok.com/platform/oauth/connect?client_key=awo8h6fk5xb1mfgd&scope=user.info.basic,video.upload&redirect_uri=${process.env.REACT_APP_BASE_URL}/finishsignup`;
    return;
  }
  const { isMobileApp } = useIsMobileApp();
  const view = useViewQuery();
  const viewContentMap = {
    tiktokExistsSignup: tiktokExistsSignup,
    emailExistsSignup: emailExistsSignup,
    noTikTokFound: noTikTokFound,
  };
  const content = viewContentMap[view] ? viewContentMap[view] : noTikTokFound;

  function noTikTokFound() {
    return (
      <Flex justifyContent="center">
        <Body textAlign="center">
          Looks like your TikTok account is not linked to an Allstar account
          yet.
          <br></br>
          <br></br>
          <br></br>
          <AlternativeAuthenticateLink
            onClick={() => {
              if (isMobileApp && window.JSBridge?.returnToLogin())
                return window.JSBridge.returnToLogin();
              return history.push("/login");
            }}
          >
            Log in and link your existing Allstar Account to TikTok
          </AlternativeAuthenticateLink>
          <StyledDivider>or</StyledDivider>
          <AlternativeAuthenticateLink
            onClick={() => {
              handleTikTok();
            }}
          >
            Sign up for a new account with TikTok
          </AlternativeAuthenticateLink>{" "}
        </Body>
      </Flex>
    );
  }

  function tiktokExistsSignup() {
    return (
      <Flex justifyContent="center">
        <Body textAlign="center">
          That TikTok account is already linked to an existing Allstar account.
          <br></br>
          <br></br>
          <br></br>
          <AlternativeAuthenticateLink
            onClick={() => {
              if (isMobileApp && window.JSBridge?.returnToLogin())
                return window.JSBridge.returnToLogin();
              return history.push("/login");
            }}
          >
            Log in with TikTok to access your existing Allstar account.
          </AlternativeAuthenticateLink>
        </Body>
      </Flex>
    );
  }

  function emailExistsSignup() {
    return (
      <Flex justifyContent="center">
        <Body textAlign="center">
          That email is already being used for an Allstar account.
          <br></br>
          <br></br>
          <br></br>
          <AlternativeAuthenticateLink
            onClick={() => {
              if (isMobileApp && window.JSBridge?.returnToLogin())
                return window.JSBridge.returnToLogin();
              return history.push("/login");
            }}
          >
            Log in with email and link your existing Allstar Account to TikTok
          </AlternativeAuthenticateLink>
        </Body>
      </Flex>
    );
  }

  return (
    <FullScreenWrapper>
      <FloatingContainer>
        <FloatingContent
          title={
            <Flex justifyContent="center" fontSize="75px" pb={[6]}>
              <span role="img" aria-label="">
                🤔
              </span>
            </Flex>
          }
          body={content()}
        />
      </FloatingContainer>
    </FullScreenWrapper>
  );
}
function useViewQuery() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const view = params.get("view");
  return view;
}
const AlternativeAuthenticateLink = styled(Body)`
  color: ${(props) => props.theme.colors.ice};
  width: fit-content;
  border-bottom: 1px solid ${(props) => props.theme.colors.envy};
  display: inline;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.envy};
    transition: 0.2s;
  }
`;
