import React from "react";
import { Box } from "rebass/styled-components";

export const Body = (props: any) => {
  return (
    <Box variant="text.body" {...props}>
      {props.children}
    </Box>
  );
};

export const Footnote = (props: any) => {
  return (
    <Box variant="text.footnote" {...props}>
      {props.children}
    </Box>
  );
};

export const Headline = (props: any) => {
  return (
    <Box variant="text.headline" {...props}>
      {props.children}
    </Box>
  );
};

export const Large1 = (props: any) => {
  return (
    <Box variant="text.large1" {...props}>
      {props.children}
    </Box>
  );
};

export const Large2 = (props: any) => {
  return (
    <Box variant="text.large2" {...props}>
      {props.children}
    </Box>
  );
};

export const Large3 = (props: any) => {
  return (
    <Box variant="text.large3" {...props}>
      {props.children}
    </Box>
  );
};

export const Large3Bold = (props: any) => {
  return (
    <Box variant="text.large3bold" {...props}>
      {props.children}
    </Box>
  );
};

export const Subheader = (props: any) => {
  return (
    <Box variant="text.subheader" {...props}>
      {props.children}
    </Box>
  );
};

export const Title1 = (props: any) => {
  return (
    <Box variant="text.title1" {...props}>
      {props.children}
    </Box>
  );
};

export const Title2 = (props: any) => {
  return (
    <Box variant="text.title2" {...props}>
      {props.children}
    </Box>
  );
};

export const Title3 = (props: any) => {
  return (
    <Box variant="text.title3" {...props}>
      {props.children}
    </Box>
  );
};
