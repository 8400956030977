import React from "react";
import { Link } from "./link";
import { Flex } from "rebass/styled-components";
import { RiUserStarLine } from "react-icons/ri";
import theme from "../../theme/themeThree";
import styled from "styled-components";

let avatarStyles = `
  align-items: center;
  justify-content: center;
`;

const AvatarLinkContainer = styled(Link)`
  display: flex;
  ${avatarStyles}
`;

const AvatarNotLinkContainer = styled(Flex)`
  ${avatarStyles}
`;

const AvatarLoadingContainer = styled(Flex)`
  ${avatarStyles}
`;

const iconSizes = {
  tiny: 17,
  nav: 24,
  small: 30,
  medium: 40,
  large: 52,
  extraLarge: 60,
  huge: 80,
};

const Avatar = (props) => {
  let passProps = {
    mr: props.mr,
    mb: props.mb,
    sx: theme.avatar,
    variant: `variants.avatar.${props.size}`,
    style: {
      backgroundImage: `url('${props.image}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    ...props.link,
    ...props,
  };

  if (props.image) {
    passProps.sx.bg = "ice";
  } else {
    passProps.children = <RiUserStarLine size={iconSizes[props.size]} />;
  }

  if (props.to) {
    passProps.to = props.to;
  }

  return props.loading ? (
    <AvatarLoadingContainer
      mr={passProps.mr}
      sx={passProps.sx}
      variant={passProps.variant}
    />
  ) : passProps.to ? (
    <AvatarLinkContainer {...passProps} />
  ) : (
    <AvatarNotLinkContainer {...passProps} />
  );
};

Avatar.defaultProps = {
  size: "small",
  loading: false,
};

export { Avatar };
