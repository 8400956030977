import React from "react";

import { useIsDesktopApp } from "../../Hooks/useIsDesktopApp";
import { GetAppList } from "./GetAppList";
import { AppContainer, DropdownContainer } from "./styledComponents";
import { isSupportedPage } from "./utils";

export const GetApp = () => {
  const { isDesktop } = useIsDesktopApp();

  if (isDesktop || !isSupportedPage(window.location.pathname)) return <></>;

  return (
    <DropdownContainer
      position={{
        bottom: "calc(100% - 20px)",
        right: "-0px",
        zIndex: 10,
      }}
      tooltipContent={<GetAppList />}
    >
      <AppContainer data-testid="getappDropdown">Get Our Apps</AppContainer>
    </DropdownContainer>
  );
};
