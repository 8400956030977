import React from "react";
import Loading from "../../../animations/loading.json";
import Lottie from "lottie-react";

export const LoadingSpinner = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => {
  return (
    <Lottie
      style={{ height: height, width: width }}
      animationData={Loading}
      loop={true}
    />
  );
};
