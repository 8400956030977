import React from "react";
import { FloatingContainer } from "../../Wrappers";
import { Flex } from "rebass/styled-components";
import { Title3, Body, Headline } from "../../Text";
import styled from "styled-components";
import { Modal } from "../../Wrappers";
import { ReactComponent as TikTok } from "../../../Assets/Platforms/tiktokBranded.svg";
import { FaYoutube } from "react-icons/fa";
import { ShareButton } from "../../Button/Share";
import { RiComputerLine } from "react-icons/ri";
import { MdPhoneAndroid } from "react-icons/md";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
const ButtonText = styled.span``;

const StyledMobile = styled(MdPhoneAndroid)`
  position: relative;
  color: ${(props) => props.theme.colors.envy};
`;
const IconWrapper = styled(Flex)`
  position: relative;
  padding: 8px;
  background: #ffffff;
  border-radius: 50%;
`;
const StyledYoutubeIcon = styled(FaYoutube)`
  position: relative;
  color: #ff0000;
`;

const UploadOptionsFlex = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    align-items: center;
  }
`;
export function MobileOrDesktopUpload() {
  // const clipLink = `${window.location.hostname}/clip?clip=${clip.shareId}`;

  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const uploadModalType =
    allstarModalState.data.platform === "TikTok"
      ? ModalType.TikTokUpload
      : ModalType.YoutubeUploadDetails;
  return (
    <Modal
      isModalOpen={allstarModalState.isOpen === ModalType.MobileOrDesktopUpload}
      setModalOpen={() => {
        setAllstarModalState({
          data: {},
          functions: {},
          isOpen: ModalType.None,
        });
      }}
      handleModalClose={() => {}}
      disableOutsideClose={false}
    >
      <FloatingContainer
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        maxWidth="525px"
      >
        <>
          {allstarModalState.data.platform === "TikTok" && (
            <Flex alignItems="center" justifyContent="center">
              <IconWrapper bg="inherit">
                <TikTok
                  style={{
                    height: "36px",
                    width: "36px",
                    position: "relative",
                  }}
                />
              </IconWrapper>
            </Flex>
          )}
          {allstarModalState.data.platform === "YouTube" && (
            <Flex alignItems="center" justifyContent="center">
              <IconWrapper>
                <StyledYoutubeIcon size={36} />
              </IconWrapper>
            </Flex>
          )}
          <Flex
            mb={4}
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Flex mt={2} flexDirection="column">
              <Title3 my={2} textAlign="center" mb={2}>
                What version of your clip?
              </Title3>
              <Body mb={3} textAlign="center" color="chalk">
                You have a mobile and cinema style clip you can choose from,
                which one would you like to publish?
              </Body>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <UploadOptionsFlex
              flexDirection={["column", "column", "column", "row"]}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <MobileDownload
                classification="main"
                onClick={() => {
                  setAllstarModalState({
                    data: { ...allstarModalState.data, aspectRatio: "9:16" },
                    functions: {},
                    isOpen: uploadModalType,
                  });
                }}
                size="social"
              >
                <ButtonText>Publish Mobile</ButtonText>
              </MobileDownload>
              <CinemaDownload
                classification="secondary"
                onClick={() => {
                  setAllstarModalState({
                    data: { ...allstarModalState.data },
                    functions: {},
                    isOpen: uploadModalType,
                  });
                }}
                size="social"
              >
                <ButtonText>Publish Cinema</ButtonText>
              </CinemaDownload>
            </UploadOptionsFlex>
          </Flex>
        </>
      </FloatingContainer>
    </Modal>
  );
}

const StyledComputer = styled(RiComputerLine)`
  position: relative;
  color: ${(props) => props.theme.colors.envy};
`;

const StyledContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.ned};
  padding: 20px;
  border-radius: 10px;
  width: 200px;
`;

function CinemaDownload({ onClick, ...props }: any) {
  return (
    <>
      <StyledContainer
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="column"
      >
        {" "}
        <Flex alignItems="center" flexDirection="column">
          <StyledComputer size={24}></StyledComputer>
          <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
            Cinema Style
          </Headline>
          <Body color="chalk">16:9</Body>
        </Flex>
        <ShareButton
          my={4}
          justifyContent="space-between"
          classification="secondary"
          alignItems="center"
          flex
          href={
            onClick
              ? undefined
              : `${process.env.REACT_APP_S3_CLIP_BUCKET}/${props.clip.clipLink}`
          }
          target={onClick ? undefined : "_blank"}
          onClick={() => {
            onClick();
          }}
          {...props}
        >
          <Flex alignItems="center">{props.children}</Flex>
        </ShareButton>
      </StyledContainer>
    </>
  );
}

function MobileDownload({ onClick, ...props }: any) {
  return (
    <>
      <Flex
        mb={[5, 5, 0, 0]}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <StyledContainer
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="column"
        >
          {" "}
          <Flex alignItems="center" flexDirection={"column"}>
            <StyledMobile size={24}></StyledMobile>
            <Headline mx={[3, 3, 0, 0]} my={[0, 0, 3, 3]}>
              Mobile Optimized
            </Headline>
            <Body color="chalk">9:16</Body>
          </Flex>
          <ShareButton
            my={4}
            justifyContent="space-between"
            classification="main"
            alignItems="center"
            flex
            onClick={onClick}
            {...props}
          >
            <Flex alignItems="center">{props.children}</Flex>
          </ShareButton>
        </StyledContainer>
      </Flex>
    </>
  );
}
