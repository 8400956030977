import { atom } from "recoil";
import { ModalType } from "../@types";

export const PromoSession = atom({
  key: "PromoSession_v0.1",
  default: {
    loaded: false,
    refetch: false,
    activeCarouselPromos: [],
    activeSiteSideRailAd: {
      title: "",
      subtitle: "e",
      image: "",
      link: "",
      buttonCopy: "",
    },
    activeSiteVideoPlayerPromos: [],
    activeSiteTopBanner: {
      buttonAction: ModalType.None,
      link: "",
      colorCSS: "",
      content: "",
      external: "",
      name: "",
      __typename: "",
    },
    activeSiteFirstLandModal: [],
    activeSitePaywallPromo: {
      topBannerCopy: "",
      colorCSS: "",
      link: "",
      endDate: "",
      countdownBackgroundColor: null,
      countdownFontColor: null,
      countdownSubFontColor: null,
    },
    activeUpgradePagePromo: {
      pricing: {
        monthly: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
        annual: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
      },
      savings: {
        monthly: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
        annual: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
      },
      productIds: {
        monthly: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
        annual: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
      },
      pcntOff: {
        upTo: "",
        monthly: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
        annual: {
          pro: "",
          proPlus: "",
          platinum: "",
        },
      },
      imageUrl: "",
      endDate: "",
      countdownBackgroundColor: "",
      countdownFontColor: "",
      countdownSubFontColor: "",
      countdownTimerOffset: 0,
      name: "",
    },
  },
});
