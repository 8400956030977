import React from "react";
import styled from "styled-components";
import { Body } from "../../../Text";
// import { ReactComponent as Pro } from "../../../../../assets/images/badges/pro-badge.svg";
import { Flex } from "rebass/styled-components";
// import { Link } from "../../../Button";
import { useRecoilState } from "recoil";
import { MontageSession } from "../../../../State";
import { ReactComponent as Mythic } from "../../../../Assets/Tiers/Badges/mythic.svg";
import { ReactComponent as Legendary } from "../../../../Assets/Tiers/Badges/legendary.svg";
import { ReactComponent as Rare } from "../../../../Assets/Tiers/Badges/rare.svg";
import { ReactComponent as Premium } from "../../../../Assets/Tiers/Badges/premium.svg";
import { ReactComponent as Standard } from "../../../../Assets/Tiers/Badges/standard.svg";
import { EView } from "../../../../Views/CreateMontage";
import {
  TIER_STRING_MAP,
  TIER_USERGROUP_MAP,
} from "../../../../Utilities/Maps";

export const RarityTooltipContent = ({ rarity }: any) => {
  const [montageSession] = useRecoilState(MontageSession);
  const TIER_BADGE_MAP: any = {
    500: <StyledMythic />,
    400: <StyledLegendary />,
    300: <StyledRare />,
    200: <StyledPremium />,
    100: <StyledStandard />,
  };

  const GoToUpgrade = () => {
    try {
      window.rudderanalytics.track(
        `${TIER_USERGROUP_MAP[rarity]} Tooltip Click`,
        {
          view: EView[montageSession.view],
        }
      );
    } catch {}
    window.open("https://allstar.gg/upgrade", "_blank")?.focus();
  };
  return (
    <>
      {" "}
      <Flex
        flexDirection={"row"}
        flexShrink={0}
        alignContent="center"
        alignItems="center"
        justifyContent={"center"}
      >
        {" "}
        <ClickBox
          onClick={() => {
            GoToUpgrade();
          }}
        >
          {" "}
          {TIER_BADGE_MAP[rarity]}
        </ClickBox>
        <StyledBody>{TIER_STRING_MAP[rarity]} Card</StyledBody>
      </Flex>
    </>
  );
};

const StyledMythic = styled(Mythic)`
  height: 28px;
  width: 28px;
  padding-top: 2px;
`;

const StyledLegendary = styled(Legendary)`
  height: 26px;
  width: 26px;
  padding-top: 3px;
`;

const StyledRare = styled(Rare)`
  height: 28px;
  width: 28px;
  padding-top: 1px;
`;

const StyledPremium = styled(Premium)`
  height: 28px;
  width: 28px;
  padding-top: 3px;
`;

const StyledStandard = styled(Standard)`
  height: 28px;
  width: 28px;
  padding-top: 1px;
`;

const ClickBox = styled(Flex)`
  z-index: 10;
  left: 4px;
  padding-right: 5px;
  padding-right: 5px;
  position: absolute;
  &:hover {
    cursor: pointer;
  }
`;

const StyledBody = styled(Body)<any>`
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
  vertical-align: middle;
  padding-left: 30px;
  font-weight: 600;
`;
