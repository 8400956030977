import React from "react";
import { Box } from "rebass/styled-components";
import { useRecoilState } from "recoil";

import { FloatingContainer, FloatingContent, Modal } from "../../Wrappers";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";

import { Button } from "../../Button";
import { Body } from "../../Text";

export const RemoveConflictingCardsModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  if (allstarModalState.isOpen !== ModalType.DisableConflictingCards)
    return <></>;
  return (
    <>
      <Modal
        isModalOpen={
          allstarModalState.isOpen === ModalType.DisableConflictingCards
        }
        setModalOpen={() => {
          setAllstarModalState({
            ...allstarModalState,
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer maxWidth={500}>
          <FloatingContent
            title={"Conflicting Studio Cards Set"}
            body={
              <Body width="100%">
                You have cards enabled that conflict with your current Signature
                and will be used instead.
                {"\n"}
                Would you like to disable them so that we always use your
                Signature cards?
                {"\n"}
              </Body>
            }
          >
            <Box style={{ textAlign: "right" }}>
              <Button
                classification="subtle"
                flex={true}
                type="button"
                color="chalk"
                size="small"
                mr="15px"
                onClick={() => {
                  window.rudderanalytics.track(
                    "Signature - Conflicting Cards",
                    {
                      action: "keep",
                    },
                  );
                  setAllstarModalState({
                    ...allstarModalState,
                    isOpen: ModalType.None,
                  });
                }}
              >
                No, keep them enabled.
              </Button>
              <Button
                flex={true}
                size="small"
                onClick={() => {
                  window.rudderanalytics.track(
                    "Signature - Conflicting Cards",
                    {
                      action: "remove",
                    },
                  );
                  if (
                    allstarModalState?.functions?.onConfirm instanceof Function
                  )
                    allstarModalState.functions.onConfirm();
                }}
              >
                Yes, disable them.
              </Button>
            </Box>
          </FloatingContent>
        </FloatingContainer>
      </Modal>
    </>
  );
};
