import React from "react";
import { Link } from "../link";

export function MenuLink(props) {
  return (
    <Link variant="menuLink" {...props}>
      {props.children}
    </Link>
  );
}
