import React, { useEffect, useState, useRef } from "react";
import { FiSkipForward } from "react-icons/fi";
import { Box } from "rebass/styled-components";
import theme from "../../../theme/themeThree";
import styled from "styled-components";

const SkipForward = styled(FiSkipForward)`
  transition: color 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

let Svg = styled.svg`
  transform: rotate(-90deg);

  .svg-circle-bg {
    fill: none;
  }

  .svg-circle {
    fill: ${(props) => props.theme.colors.midnightAlpha50};
  }
`;

let LoadingContainer = styled(Box)`
  position: relative;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }
`;

const LoadingPlayButton = ({
  size,
  progress,
  strokeWidth,
  circleStroke,
  duration,
  ...props
}) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);

    circleRef.current.style = `transition: stroke-dashoffset ${duration}s linear`;
  }, [setOffset, progress, circumference, offset, duration]);

  return (
    <LoadingContainer {...props}>
      <Svg width={size} height={size}>
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={circleStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </Svg>
      <SkipForward size="48" />
    </LoadingContainer>
  );
};

LoadingPlayButton.defaultProps = {
  circleStroke: theme.colors.envy,
  size: 100,
  strokeWidth: 5,
  duration: 3,
  progress: 0,
};

export { LoadingPlayButton };
