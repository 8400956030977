import React, { useState } from "react";

import { Box, Flex } from "rebass";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as ProLabel } from "../../../../../assets/images/pro/pro-mark.svg";
import { DividerAlt } from "../../../../Components/Divider";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { Body, Title2 } from "../../../../Components/Text";
import { MontageSession } from "../../../../State";
import { TFinalTouch, TFinalTouchesData } from "../../@types";
import { AddButton } from "./AddButton";

export const FinalTouches: React.FC<TFinalTouchesData> = ({
  finalTouchesData,
  finalTouchesLoading,
  setProFeatureModal,
}) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const [hoveredOption, setHoveredOption] = useState<string>("");
  if (finalTouchesLoading) return <Loading />;

  const handleClick = (option: TFinalTouch) => {
    if (option.pro && montageSession.isFreeUser) {
      setProFeatureModal({
        body: "Access exclusive effects, get Pro Only Creator Cards, a Profile Badge, Exclusive Content and much more!",
        isOpen: true,
      });
      return;
    }

    const isActive = montageSession.finalTouchesSelected.includes(
      option.description,
    );

    //already activated, remove
    if (isActive) {
      setMontageSession({
        ...montageSession,
        finalTouchesSelected: montageSession.finalTouchesSelected.filter(
          (selected: any) => {
            return selected !== option.description;
          },
        ),
      });
    } else {
      setMontageSession({
        ...montageSession,
        finalTouchesSelected: [
          ...montageSession.finalTouchesSelected,
          option.description,
        ],
      });
    }
  };

  return (
    <>
      <FinalTouchesContainer>
        <Flex
          justifyContent={"flex-start"}
          flexDirection={"column"}
          width="100%"
        >
          <Heading pb={8}>Final Touches</Heading>

          {finalTouchesData.map((option, index) => {
            if (!option.game.includes(montageSession.game)) return <></>;
            return (
              <>
                <Box
                  onMouseEnter={() => {
                    setHoveredOption(option.description);
                  }}
                  onMouseLeave={() => {
                    setHoveredOption("");
                  }}
                  onClick={() => {
                    handleClick(option);
                  }}
                >
                  {" "}
                  <FinalTouch>
                    {" "}
                    <Box pr={4}>
                      {" "}
                      <AddButton
                        handleToggle={() => {
                          handleClick(option);
                        }}
                        isActive={montageSession.finalTouchesSelected.includes(
                          option.description,
                        )}
                        parentHovered={hoveredOption === option.description}
                        parentControlsHover
                      />
                    </Box>
                    <SyledBody>{option.description}</SyledBody>
                    {option.pro && (
                      <Box ml={3}>
                        <ProLabel />
                      </Box>
                    )}
                  </FinalTouch>
                  <DividerAlt />
                </Box>
              </>
            );
          })}
        </Flex>
      </FinalTouchesContainer>
    </>
  );
};
/**
 * 
 * interface IAddCardProps {
  card: any;
  handleToggle: (card: any, isToggled: any) => Boolean;
  size?: number;
  handleModal=>Close?: any;
  isActive?: boolean;
  outsideControl?: boolean;
}
 */
const Loading = () => {
  return (
    <FinalTouchesContainer>
      <Flex justifyContent={"flex-start"} width="100%" pb={20}>
        <LoadingSkeleton
          width={"150px"}
          height={"28px"}
          style={{ borderRadius: "8px" }}
        />
      </Flex>
      <Flex pb={10} width="100%">
        <LoadingSkeleton
          count={1}
          width={"100%"}
          height={"360px"}
          style={{ borderRadius: "20px", marginBottom: "10px" }}
          containerClassName="flex-grow-1"
        />
      </Flex>
    </FinalTouchesContainer>
  );
};

const SyledBody = styled(Body)`
  font-weight: 500;
  font-size: 18px;
`;

const FinalTouch = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const Heading = styled(Title2)`
  align-self: flex-start;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
`;

const FinalTouchesContainer = styled(Flex)`
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
