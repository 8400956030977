import { gql } from "@apollo/client";

const CHECK_SINGLE_CLIP_TRANSIT_TIME = gql`
  query TransitTimeCheck(
    $type: TransitTimeValidTypeString!
    $clipIdentifier: String
  ) {
    transitTimeCheck(type: $type, clipIdentifier: $clipIdentifier) {
      processed
      approxTimeToComplete
      localKey
      isMobile
      clip {
        game
        clipTitle
        remix {
          isRemixed
        }
      }
      status
    }
  }
`;
export const CHECK_TRANSIT_TIME = gql`
  query transitTimeCheck(
    $type: TransitTimeValidTypeString!
    $user: String
    $clipIdentifier: String
  ) {
    transitTimeCheck(
      type: $type
      user: $user
      clipIdentifier: $clipIdentifier
    ) {
      processed
      localKey
      approxTimeToComplete
      isMobile
      clip {
        game
        clipTitle
        trackId
        remix {
          isRemixed
        }
        status
      }
    }
  }
`;
