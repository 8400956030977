import React, { useState, useEffect } from "react";
import { Body } from "../../../Text";
import { FloatingContent } from "../../../Wrappers/Existing/floatingContent";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Button } from "../../../../../components/atoms/form/button";
import { Error } from "../../../Inputs";
import { useForm } from "react-hook-form";
import { CheckboxWithLabel } from "../../../Checkbox";

const ButtonContainer = styled(Box)`
  text-align: right;
`;

export function ConfirmSettings({
  clip,
  setModalOpen,
  setClipInactive,
  isMontage,
}) {
  useEffect(() => {
    window.rudderanalytics.page("Clip", "Open clip delete form");
  }, []);
  const { handleSubmit } = useForm();
  const [deleteMobile, setDeleteMobile] = useState(
    localStorage.getItem("prefersDeleteMobile"),
  );
  const hasMobile = clip?.mobile?.some((x) =>
    ["Ready", "Processed"].includes(x.status),
  );
  const [state, setState] = useState({
    error: false,
    errorMsg: "",
    loading: false,
    complete: false,
  });

  const onSubmit = async (deleteMobile) => {
    if (deleteMobile) localStorage.setItem("prefersDeleteMobile", true);
    else localStorage.removeItem("prefersDeleteMobile");
    try {
      setState({ ...state, loading: true });

      const response = await setClipInactive({
        id: clip?._id,
        isMontage,
        deleteMobile,
      });
      if (response.data.status === "ok") {
        window.rudderanalytics.track("Clip removed from Profile", {
          clipID: clip?._id,
          game: clip?.game,
          tags: clip?.clipProperties,
          length: clip?.clipLength,
        });
      }

      setState({
        ...state,
        loading: false,
        error: false,
        complete: true,
      });
      setModalOpen(false);
    } catch (e) {
      setState({
        ...state,
        loading: false,
        error: false,
        complete: true,
      });
      setModalOpen(false);
    }
  };

  const onSubmitThatAcceptsState = onSubmit.bind(null, deleteMobile);
  return (
    <FloatingContent
      title={"Delete Content"}
      body={
        <Body width="100%">
          Are you sure you want to delete this content from your profile?
          {"\n"}
          This content will no longer be available to watch or remix.
          {"\n"}
        </Body>
      }
    >
      {" "}
      <form onSubmit={handleSubmit(onSubmitThatAcceptsState)}>
        {state.error && state.errorMsg ? (
          <Error>{state.errorMsg}</Error>
        ) : (
          <></>
        )}

        <OptionsFlex hasMobile={hasMobile}>
          {hasMobile && (
            <CheckboxWithLabel
              checkbox={{
                checked: deleteMobile,
              }}
              onClick={(e) => {
                setDeleteMobile(e.target.checked);
              }}
            >
              {" "}
              Delete mobile version also?{" "}
            </CheckboxWithLabel>
          )}

          <Flex>
            {" "}
            <ButtonContainer>
              <Button
                classification="subtle"
                flex={true}
                type="button"
                color="chalk"
                size="small"
                mr="15px"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={state.loading}
                type="submit"
                flex={true}
                size="small"
              >
                {state.loading ? "Submitting..." : "Confirm"}
              </Button>
            </ButtonContainer>
          </Flex>
        </OptionsFlex>
      </form>
    </FloatingContent>
  );
}

const OptionsFlex = styled(Flex)`
  ${(props) =>
    props.hasMobile
      ? "justify-content: space-between;"
      : "  justify-content: flex-end;"}

  align-items: center;
`;
