import React, { useState } from "react";
import { InputWithLabel } from "../../components/molecules/form/inputWithLabel";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { Button } from "../../components/atoms/form/button";
import { Error } from "../../components/atoms/form/error";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { Link } from "../../components/atoms/link";
import { FormLink } from "../../components/atoms/form/link";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { AMark } from "../../components/logo";
import { Title1, Subheader } from "../../components/text";

const StyledTitle1 = styled(Title1)`
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
`;

const BrandLogo = styled(AMark)`
  overflow: visible;
  max-height: 35px;
  margin-bottom: 25px;
  }
`;

const StyledLink = styled(Link)``;

const StyledHead = styled(Flex)`
  width: 100%;
  ${(props) => (!props.maxWidth ? `max-width: 370px;` : false)}
`;

const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  background: ${(props) => props.theme.colors.midnightAlpha40};

  ${(props) => (!props.maxWidth ? `max-width: 425px;` : false)}
`;

export function ResetRequest({ setRequestSent }) {
  let [state, setState] = useState({
    loading: false,
    error: false,
    discord: false,
  });
  const isMobileApp = window.location.pathname.includes("mobile-app-link");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async (form) => {
    if (state.loading) {
      return;
    }

    setState({
      ...state,
      loading: true,
    });

    const opt = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/users/reset/password/request`,
      data: form,
    };

    try {
      let response = await Axios(opt);

      if (response.data.status === "ok") {
        setState({
          ...state,
          loading: false,
        });
        return setRequestSent(true);
      }
      if (response.status === 204) {
        return setState({
          ...state,
          discord: true,
          loading: false,
        });
      }
      return setState({
        ...state,
        loading: false,
        error: true,
      });
    } catch (e) {
      setState({
        ...state,
        loading: false,
        error: true,
        errorMsg: e.response.data.msg,
      });
    }
  };

  return (
    <FullScreenWrapper
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      pt={10}
    >
      <StyledHead justifyContent="center">
        <StyledLink to={isMobileApp ? "/mobile-app-link/login" : "/clips"}>
          <BrandLogo
            flex="flex-start"
            id="allstar-logo"
            variant="logo.nav"
            className="logo"
          />
        </StyledLink>
      </StyledHead>
      <StyledBox px={[6, 8]} pt={[6, 6]} pb={[6, 8]}>
        <StyledTitle1>Forgot password?</StyledTitle1>
        <Subheader color="chalk" mb={4}>
          Don't worry! Fill in your email and we'll send you a link to reset
          your password.
        </Subheader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWithLabel
            input={{
              id: "email",
              name: "email",
              placeholder: "Email",
              ...register("email", {
                required: true,
              }),
            }}
            error={errors.email}
            label="Email"
            applySpace={true}
          />

          {state.error && state.errorMsg ? (
            <Error>{state.errorMsg}</Error>
          ) : (
            <></>
          )}

          <Button disabled={state.loading} type="submit">
            {state.loading ? "Submitting..." : "Send email"}
          </Button>
        </form>
      </StyledBox>
      <Flex mt={4} justifyContent="center">
        <Subheader mr={2}>Back to</Subheader>
        <FormLink
          to={isMobileApp ? "/mobile-app-link/login" : "/login"}
          color={"envy"}
        >
          Login
        </FormLink>
      </Flex>
    </FullScreenWrapper>
  );
}
