import React from "react";
import { Box } from "rebass/styled-components";
import { Body } from "../../components/text";
import {
  StaticContentCopy,
  StaticContentTopicTitle,
  StaticContentTopicSubtitle,
} from "../../components/atoms/styledText";

export function About() {
  return (
    <StaticContentCopy maxWidth={6} data-testid="about">
      <StaticContentTopicTitle mb={6}>About Allstar</StaticContentTopicTitle>
      <Box mb={8}>
        <StaticContentTopicSubtitle mb={3}>
          We believe that every gamer can be a content creator
        </StaticContentTopicSubtitle>
        <Body>
          As gamers, we know how amazing it feels to pull off an incredible
          moment. Maybe you nailed an impossible clutch 1v4, or you landed your
          ult at just the right time to win the match. Everyone has these
          moments, but all too often your friends aren't around to see it. So
          often, that moment is gone forever.
        </Body>
      </Box>

      <Box mb={8}>
        <StaticContentTopicSubtitle mb={3}>
          Leveling the playing field
        </StaticContentTopicSubtitle>
        <Body>
          Allstar helps aspiring creators build audience by making it fast, easy
          and fun to capture and share your greatest gaming moments across
          social media. And if your hardware isn't top notch, don’t worry –
          Allstar has zero impact to your PC. Never lose FPS creating content
          again!
        </Body>
      </Box>

      <Box mb={8}>
        <StaticContentTopicSubtitle mb={3}>
          We built Allstar to be a better way
        </StaticContentTopicSubtitle>
        <Body>
          In an age where everyone is sharing real-life moments on Instagram and
          TikTok, everyday gamers have been left behind. But it doesn't have to
          be that way – using cloud computing technology, Allstar eliminates
          every burden of creating and sharing content. All you need to do is
          focus on your game.
        </Body>
      </Box>

      <Box mb={8}>
        <StaticContentTopicSubtitle mb={3}>
          Who we are
        </StaticContentTopicSubtitle>
        <Body>
          We're a group of close knit gamers, colleagues and life long friends.
          We're also career technology, creative, marketing, and business
          professionals who took a leap of faith to follow our passions.
          Together, we're building something awesome that combines our love of
          gaming with our passion for creativity.
        </Body>
      </Box>

      <Box mb={8}>
        <StaticContentTopicSubtitle mb={3}>
          Join the squad
        </StaticContentTopicSubtitle>
        <Body>
          If you're motivated by our vision for a world where every gamer can
          become a content creator, we'd love to talk, just email your cover
          letter, resume, and any work samples or portfolio to{" "}
          <a href="mailto:work@allstar.gg">work@allstar.gg</a>. We're also die
          hard competitive players so we love when people brag about hours
          played, ranks achieved, etc :)
        </Body>
      </Box>
    </StaticContentCopy>
  );
}
