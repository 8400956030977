import React from "react";
import { Link as BaseLink } from "../../components/atoms/link";
import { Image, Flex, Box } from "rebass/styled-components";
import { Large3, Body, Title3, Title2, Headline } from "../../components/text";
import { Button } from "../../components/atoms/form/button";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import WordMarkDarkTag from "../../assets/images/brand/Wordmark-Dark-Tag.png";
import WordMarkLightTag from "../../assets/images/brand/Wordmark-Light-Tag.png";
import WordMarkDark from "../../assets/images/brand/Wordmark-Dark.png";
import WordMarkLight from "../../assets/images/brand/Wordmark-Light.png";
import AMarkDark from "../../assets/images/brand/A-Mark-Dark.png";
import AMarkLight from "../../assets/images/brand/A-Mark-Light.png";
import WordMarkDarkTagSvg from "../../assets/images/brand/Wordmark-Dark-Tag.svg";
import WordMarkLightTagSvg from "../../assets/images/brand/Wordmark-Light-Tag.svg";
import WordMarkDarkSvg from "../../assets/images/brand/Wordmark-Dark.svg";
import WordMarkLightSvg from "../../assets/images/brand/Wordmark-Light.svg";
import AMarkDarkSvg from "../../assets/images/brand/A-Mark-Dark.svg";
import AMarkLightSvg from "../../assets/images/brand/A-Mark-Light.svg";
import SpacingLarge from "../../assets/images/brand/Spacing-Large.png";
import SpacingSmall from "../../assets/images/brand/Spacing-Small.png";
import { colors } from "../../theme/colors";
import styled from "styled-components";

const Link = ({ children, ...props }) => {
  return (
    <BaseLink color="envy" fontSize={1} {...props}>
      {children}
    </BaseLink>
  );
};

const DarkContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.darkerNavy};
  border: 1px solid ${(props) => props.theme.colors.midnightAlpha50};
  border-radius: 9px;
  padding-top: ${(props) => props.theme.space[8]}px;
  padding-bottom: ${(props) => props.theme.space[4]}px;
  padding-left: ${(props) => props.theme.space[6]}px;
  padding-right: ${(props) => props.theme.space[6]}px;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"};
`;

const LightContainer = styled(DarkContainer)`
  background: ${(props) => props.theme.colors.darkerIce};
`;

const Colors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 190px 190px;
  gap: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 160px 160px 160px;
    gap: 20px;
  }
`;

const ColorHex = styled(Headline)`
  margin-top: auto;
  color: ${(props) =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.chalkAlpha60};
`;

const ColorName = styled(Headline)``;

const ColorSwatch = styled(Flex)`
  border-radius: 9px;
  padding: ${(props) => props.theme.space[5]}px;
  background: ${(props) => props.theme.colors[props.name]};
  flex-grow: 1;
  flex-direction: column;
  color: ${(props) =>
    props.name === "ice"
      ? props.theme.colors.midnight
      : props.theme.colors.ice};
`;

const ColorSwatchTall = styled(ColorSwatch)`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const LogoRow = styled(Flex)`
  justify-content: space-between;
  gap: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    gap: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    gap: 20px;
    flex-direction: column;
  }
`;

const LogoRowSpacing = styled(Flex)`
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }
`;

const Name = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NameHideMobile = styled(Name)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
`;

const FiletypeRow = styled(Flex)`
  gap: 30px;
  margin-top: ${(props) => props.theme.space[8]}px;
`;

const LogoImage = styled(Image)`
  width: ${(props) => (props.width ? props.width : "180px")};
`;

const Row = ({ title, instruction, children }) => {
  return (
    <Flex
      alignSelf="center"
      flexDirection="column"
      mt={[8, 6]}
      mb={[8]}
      mx={[6]}
      maxWidth={[6]}
      width="100%"
    >
      <Title3 mb={[2, 2, 3]}>{title}</Title3>
      <Body pb={5}>{instruction}</Body>
      {children}
    </Flex>
  );
};

const SubHead = ({ subtitle }) => {
  return (
    <Flex
      alignSelf="center"
      flexDirection="column"
      mt={[8, 6]}
      mb={[2]}
      mx={[6]}
      maxWidth={[6]}
      width="100%"
    >
      <Headline mb={[3]}>{subtitle}</Headline>
    </Flex>
  );
};

export function Brand() {
  return (
    <>
      <Row
        title="Our Name"
        instruction="You may have heard it before, but we’re one of a kind."
      >
        <DarkContainer flexDirection="row">
          <Name>
            <Title2>Allstar</Title2>
            <Box as={FiCheckCircle} size="22" my={6} color="envy" />
          </Name>
          <Name>
            <Title2>All-Star</Title2>
            <Box as={FiXCircle} size="22" my={6} color="alert" />
          </Name>
          <Name>
            <Title2>AllStar</Title2>
            <Box as={FiXCircle} size="22" my={6} color="alert" />
          </Name>
          <Name>
            <Title2>All star</Title2>
            <Box as={FiXCircle} size="22" my={6} color="alert" />
          </Name>
          <NameHideMobile>
            <Title2>All Star</Title2>
            <Box as={FiXCircle} size="22" my={6} color="alert" />
          </NameHideMobile>
        </DarkContainer>
      </Row>
      <Row
        title="Our Logo"
        instruction=" Please do not edit, change, distort, recolor, or reconfigure the Allstar logo."
      >
        <LogoRow>
          <DarkContainer>
            <LogoImage src={WordMarkLightTag} />
            <FiletypeRow>
              <Link href={WordMarkLightTagSvg} download>
                .svg
              </Link>
              <Link href={WordMarkLightTag} download>
                .png
              </Link>
            </FiletypeRow>
          </DarkContainer>
          <LightContainer>
            <LogoImage src={WordMarkDarkTag} />
            <FiletypeRow>
              <Link href={WordMarkDarkTagSvg} download>
                .svg
              </Link>
              <Link href={WordMarkDarkTag} download>
                .png
              </Link>
            </FiletypeRow>
          </LightContainer>
        </LogoRow>
      </Row>
      <Row
        title="Alternate Logo"
        instruction="
          Use these only when the Allstar brand is clearly visible or has been
          well established elsewhere on the page or in the design. (When in
          doubt, use the OG one.)"
      >
        <SubHead subtitle="Mark only"></SubHead>
        <LogoRow>
          <DarkContainer>
            <LogoImage src={AMarkLight} width="30px" />
            <FiletypeRow>
              <Link href={AMarkLightSvg} download>
                .svg
              </Link>
              <Link href={AMarkLight} download>
                .png
              </Link>
            </FiletypeRow>
          </DarkContainer>
          <LightContainer>
            <LogoImage src={AMarkDark} width="30px" />
            <FiletypeRow>
              <Link href={AMarkDarkSvg} download>
                .svg
              </Link>
              <Link href={AMarkDark} download>
                .png
              </Link>
            </FiletypeRow>
          </LightContainer>
        </LogoRow>
        <SubHead subtitle="Wordmark only"></SubHead>
        <LogoRow>
          <DarkContainer>
            <LogoImage src={WordMarkLight} />
            <FiletypeRow>
              <Link href={WordMarkLightSvg} download>
                .svg
              </Link>
              <Link href={WordMarkLight} download>
                .png
              </Link>
            </FiletypeRow>
          </DarkContainer>
          <LightContainer>
            <LogoImage src={WordMarkDark} />
            <FiletypeRow>
              <Link href={WordMarkDarkSvg} download>
                .svg
              </Link>
              <Link href={WordMarkDark} download>
                .png
              </Link>
            </FiletypeRow>
          </LightContainer>
        </LogoRow>
      </Row>

      <Row
        title="Spacing considerations"
        instruction="We love being close to our mates, but need our space every now and then."
      >
        <LogoRowSpacing mt={6}>
          <Image mr={9} src={SpacingLarge} maxWidth="510px" height="244px" />
          <Image src={SpacingSmall} maxWidth="157px" height="179px" />
        </LogoRowSpacing>
      </Row>
      <Row
        title="Colors"
        instruction="You may have heard it before, but we’re one of a kind."
      >
        <Colors>
          <ColorSwatch name="envy">
            <ColorName>Envy</ColorName>
            <ColorHex color="iceAlpha40">{colors.envy}</ColorHex>
          </ColorSwatch>
          <ColorSwatch name="ice">
            <ColorName>Ice</ColorName>
            <ColorHex>{colors.ice}</ColorHex>
          </ColorSwatch>
          <ColorSwatchTall name="spectrum">
            <ColorName>Spectrum</ColorName>
            <ColorHex color="iceAlpha60">
              Linear gradient @ 45°
              <br />
              <br />
              #72439a
              <br />
              #1278a1
              <br />
              #4dbd94
            </ColorHex>
          </ColorSwatchTall>
          <ColorSwatch name="midnight">
            <ColorName>Midnight</ColorName>
            <ColorHex>{colors.midnight}</ColorHex>
          </ColorSwatch>
          <ColorSwatch name="navy">
            <ColorName>Navy</ColorName>
            <ColorHex>{colors.navy}</ColorHex>
          </ColorSwatch>
        </Colors>
      </Row>

      <Box textAlign="center" alignSelf="center" my={10} maxWidth={5}>
        <Large3 mb={6}>Looking for more?</Large3>
        <Box mb={6}>
          <Body>
            Download the full Allstar media kit and get: icons, banners, logos
            and more
          </Body>
        </Box>
        <Button
          flex={true}
          href="https://media.allstar.gg/static/Allstar-Brand.zip"
          download
        >
          Download Media Kit
        </Button>
      </Box>
    </>
  );
}
