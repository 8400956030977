import React, { useEffect } from "react";
import { Subheader, Title3, Body } from "../../components/text";
import { Link } from "../../components/atoms/link";
import { Row as FormRow } from "../../components/molecules/form/row";
import { DiscordButton } from "../../components/atoms/form/button/discord";
import { FloatingContent } from "../../components/molecules/floatingContent";
import { Box } from "rebass/styled-components";
import { FaRegKeyboard } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import styled from "styled-components";
import { color } from "styled-system";

const iconStyles = {
  marginRight: (props) => props.theme.space[3] + "px",
};

const LoginIcon = styled(MdTimer)(iconStyles, color);

const ClickIcon = styled(FaRegKeyboard)(iconStyles, color);

const SyncIcon = styled(FiShare2)(iconStyles, color);

const StyledTitle = styled(Title3)`
  align-items: center;
  display: flex;
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;

function bodyContent() {
  return <Body>Here’s how to make & get your clips:</Body>;
}

export function Complete() {
  useEffect(() => {
    window.rudderanalytics.page("Activation", "Activation Complete");
  });
  return (
    <FloatingContent title="You're all set!" body={bodyContent()}>
      <Box mb={7}>
        <StyledTitle>
          <ClickIcon color="envy" /> Type !allstar
        </StyledTitle>
        <Body>
          Play a CSGO Competitive Matchmaking game and type{" "}
          <strong>!allstar</strong> in the game’s chat after you do something
          amazing.
        </Body>
      </Box>

      <Box mb={7}>
        <StyledTitle>
          <LoginIcon color="envy" /> We’ll clip the last 30 seconds...
        </StyledTitle>
        <Body>
          Allstar’s cloud technology will automatically create a clip from the
          last 30 seconds of gameplay BEFORE the command.
        </Body>
      </Box>

      <Box mb={7}>
        <StyledTitle>
          <SyncIcon color="envy" /> ...and post to the Allstar Discord
        </StyledTitle>
        <Body>
          Your finished clip will be posted automatically to Allstar’s Discord
          server to the awe of your peers, and for easy sharing =)
        </Body>
      </Box>

      <FormRow>
        <DiscordButton
          href="https://discord.gg/54X5CVX"
          rel="noopener noreferrer"
          target="_blank"
        >
          Join Allstar's Discord
        </DiscordButton>
      </FormRow>

      <Subheader>
        Need help?{" "}
        <Link to="/faq" variant="link.form">
          Read our FAQ here.
        </Link>
      </Subheader>
    </FloatingContent>
  );
}
