import React from "react";
import { Link } from "../link";

export function NavLink(props) {
  return (
    <Link variant="link.nav" {...props}>
      {props.children}
    </Link>
  );
}
