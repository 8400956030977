import { Box, Text } from "rebass";
import styled from "styled-components";

import { useUser } from "../../../Hooks/useUser";
import { Button } from "../../Button";
import { StyledDivider } from "../styledComponents";

const SidebarLoginWidget = () => {
  const { allstarUser } = useUser();
  if (allstarUser.loggedIn) return <></>;

  return (
    <StyledContainer>
      <StyledDivider />
      <StyledText py={3}>
        Log in to curate a personal feed and to access features like Studio.
      </StyledText>
      <Button size="small" mb={3} to="/login">
        Log In
      </Button>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 0 0 0 16px;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.chalk};
  line-height: 1.3em;
  font-weight: 400;
`;

export { SidebarLoginWidget };
