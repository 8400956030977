import React, { ReactElement } from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";
// import SVG from "react-inlinesvg";
import { ReactComponent as PartnerBadgeLarge } from "../../../assets/images/pro/new/PARTNER-large.svg";
import { ReactComponent as PartnerBadgeNav } from "../../../assets/images/pro/new/PARTNER-nav.svg";
import { ReactComponent as PartnerBadgeSide } from "../../../assets/images/pro/new/PARTNER-side.svg";
import { ReactComponent as SuperstarBadgeLarge } from "../../../assets/images/pro/new/SUPERSTARS-large.svg";
import { ReactComponent as SuperstarBadgeNav } from "../../../assets/images/pro/new/SUPERSTARS-nav.svg";
import { ReactComponent as SuperstarBadgeSide } from "../../../assets/images/pro/new/SUPERSTARS-side.svg";
import { ReactComponent as ProBadgeLarge } from "../../../assets/images/pro/new/PRO-large.svg";
import { ReactComponent as ProBadgeNav } from "../../../assets/images/pro/new/PRO-nav.svg";
import { ReactComponent as PlatLarge } from "../../../assets/images/pro/new/PLATINUM-large.svg";
import { ReactComponent as PlatNav } from "../../../assets/images/pro/new/PLATINUM-nav.svg";
import { ReactComponent as PlatSide } from "../../../assets/images/pro/new/PLATINUM-side.svg";
import { ReactComponent as PlusLarge } from "../../../assets/images/pro/new/PLUS-large.svg";
import { ReactComponent as PlusNav } from "../../../assets/images/pro/new/PLUS-nav.svg";
import { ReactComponent as PlusSide } from "../../../assets/images/pro/new/PLUS-side.svg";
import { ReactComponent as ProSide } from "../../../assets/images/pro/new/PRO-side.svg";
import theme from "../../../theme/themeThree";

interface IAvatarUser {
  _id?: string;
  avatarUrl?: string;
  avatar?: string;
  discord?: {
    id?: string;
    avatar?: string;
    username?: string;
  }
}

export function getAvatarImage(user: IAvatarUser) {
  if (!user?._id) return null;

  const userIdMap: Record<string, string> = {
    // user id                   // s3 location for image
    "60ae2d9c3910b22025455be6": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/guardian.png`,
    "60bf54c4b659d357ea261592": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/pimp.png`,
    "5ef6904913bcf10f1cdf3746": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/allstar_profile.png`,
    "60db6cbf88ded470df0a2280": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/nato.png`,
    "61e076d81ecc5b5670a1429d": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/shelbers.png`,
    "61e0af6ec2b73024d8bf3e40": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/jojo.png`,
    "621cb1aad015506d2fdf1caf": `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/broxah.png`,
  };

  // avatar for influencers with discord linked
  if (user._id === "5ef6904913bcf10f1cdf3746") return userIdMap[user._id];
  if (user._id === "61e0af6ec2b73024d8bf3e40") return userIdMap[user._id]; //JOJO partner

  //custom avatars for influencers who have not linked their discord account
  if (
    Object.keys(userIdMap).includes(user._id) &&
    !(user && user.discord && user.discord.avatar)
  ) {
    return userIdMap[user._id];
  }

  if (user.avatarUrl) return user.avatarUrl;
  if (user.avatar) return user.avatar;

  // avatar image fetch for regular users. all special cases should be handled above this ^
  return user.discord?.id
    ? `${process.env.REACT_APP_S3_CLIP_BUCKET}/${user._id}/avatars/${user.discord.id}.jpg`
    : null;
}

export function AvatarBorder(props: any) {
  // @ts-ignore
  const badgeStyles = theme.variants.avatarBadge[props.size][props.type];

  const RENDER_MAP: any = {
    tiny: {
      officialPartner: <PartnerBadgeNav style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeNav style={{ ...badgeStyles }} />,
      pro: <ProBadgeNav style={{ ...badgeStyles }} />,
      creatorPlat: <PlatNav style={{ ...badgeStyles }} />,
      proPlus: <PlusNav style={{ ...badgeStyles }} />,
    },
    small: {
      officialPartner: <PartnerBadgeNav style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeNav style={{ ...badgeStyles }} />,
      pro: <ProBadgeNav style={{ ...badgeStyles }} />,
      creatorPlat: <PlatNav style={{ ...badgeStyles }} />,
      proPlus: <PlusNav style={{ ...badgeStyles }} />,
    },
    nav: {
      officialPartner: <PartnerBadgeNav style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeNav style={{ ...badgeStyles }} />,
      pro: <ProBadgeNav style={{ ...badgeStyles }} />,
      creatorPlat: <PlatNav style={{ ...badgeStyles }} />,
      proPlus: <PlusNav style={{ ...badgeStyles }} />,
    },
    sideRail: {
      officialPartner: <PartnerBadgeSide style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeSide style={{ ...badgeStyles }} />,
      pro: <ProSide style={{ ...badgeStyles }} />,
      creatorPlat: <PlatSide style={{ ...badgeStyles }} />,
      proPlus: <PlusSide style={{ ...badgeStyles }} />,
    },
    medium: {
      officialPartner: <PartnerBadgeLarge style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeLarge style={{ ...badgeStyles }} />,
      pro: <ProBadgeLarge style={{ ...badgeStyles }} />,
      creatorPlat: <PlatLarge style={{ ...badgeStyles }} />,
      proPlus: <PlusLarge style={{ ...badgeStyles }} />,
    },
    large: {
      officialPartner: <PartnerBadgeLarge style={{ ...badgeStyles }} />,
      superstar: <SuperstarBadgeLarge style={{ ...badgeStyles }} />,
      pro: <ProBadgeLarge style={{ ...badgeStyles }} />,
      creatorPlat: <PlatLarge style={{ ...badgeStyles }} />,
      proPlus: <PlusLarge style={{ ...badgeStyles }} />,
    },
  };

  return (
    <StyledFlex>
      {RENDER_MAP[props.size][props.type]}
      {props.children}
    </StyledFlex>
  );
}

export function getAvatarBorderLevel(user: any) {
  if (!user?.rank || !user?.userGroup) return "";
  let determinedLevel = "";
  const userRank = user.rank.toLowerCase();
  const userGroup = user.userGroup.type.toLowerCase();

  //we use values from userRank and userGroup in this array. Priority is in order from 0-n
  const DISPLAY_PRIORITY = [
    "official partner",
    "superstar",
    "pro",
    "creator_plat",
    "pro_plus",
  ];

  //maps a determined borderLevel to a variant in the theme
  const VARIANT_MAP: any = {
    "official partner": "officialPartner",
    superstar: "superstar",
    pro: "pro",
    creator_plat: "creatorPlat",
    pro_plus: "proPlus",
    "": "",
  };

  //check DISPLAY_PRIORITY for rank or userGroup, the first one we find is the priority to display.
  //Search until we have determined a level, otherwise its free, and no border
  DISPLAY_PRIORITY.forEach((level) => {
    if (!determinedLevel) {
      if (level === userRank) determinedLevel = level;
      if (level === userGroup) determinedLevel = level;
    }
  });

  /*
  ~ Map the determined level to a valid key in the variants of the theme ~
    Example of variant in theme.

      officialPartner: {
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: "4px",
        borderRadius: "50% 50%",
      },
  */

  return VARIANT_MAP[determinedLevel];
}

export function getUserTitle(user: any) {
  if (!user.rank || !user.userGroup) return null;
  if (
    user.rank === "Official Partner" ||
    user.rank === "Superstar" ||
    user.rank === "Team Allstar"
  )
    return user.rank;

  if (user.userGroup.type.includes("PRO")) return "Allstar Pro";

  return "Allstar";
}

export type TUserSocials = {
  socials?: {
    tiktok: string;
    steam: string;
    youtube: string;
    twitter: string;
    instagram: string;
    twitch: string;
  };
};

type SocialAccount = {
  externalLink: string;
  socialDisplayName: string;
};
export type TUserSocialsWithPrefills = {
  tiktok: SocialAccount;
  steam: SocialAccount;
  youtube: SocialAccount;
  twitter: SocialAccount;
  instagram: SocialAccount;
  twitch: SocialAccount;
};

export const getUserSocials = (userSocials: TUserSocials) => {
  const { socials } = userSocials;
  const socialsToReturn: TUserSocialsWithPrefills =
    {} as TUserSocialsWithPrefills;

  if (socials?.tiktok) {
    socialsToReturn.tiktok = {
      externalLink: `https://www.tiktok.com/@${socials?.tiktok}`,
      socialDisplayName: socials?.tiktok,
    };
  }

  if (socials?.steam) {
    socialsToReturn.steam = {
      externalLink: `https://steamcommunity.com/id/${socials?.steam}`,
      socialDisplayName: socials?.steam,
    };
  }

  if (socials?.youtube) {
    socialsToReturn.youtube = {
      externalLink: `https://youtube.com/@${socials?.youtube}`,
      socialDisplayName: socials?.youtube,
    };
  }

  if (socials?.twitter) {
    socialsToReturn.twitter = {
      externalLink: `https://twitter.com/${socials?.twitter}`,
      socialDisplayName: socials?.twitter,
    };
  }

  if (socials?.instagram) {
    socialsToReturn.instagram = {
      externalLink: `https://instagram.com/${socials?.instagram}`,
      socialDisplayName: socials?.instagram,
    };
  }

  if (socials?.twitch) {
    socialsToReturn.twitch = {
      externalLink: `https://twitch.tv/${socials?.twitch}`,
      socialDisplayName: socials?.twitch,
    };
  }

  return socialsToReturn;
};

export const userGroupTypeToDisplayString = (userGroupType: string) => {
  if (userGroupType === "PRO_PLUS") return "Pro Plus";
  if (userGroupType === "CREATOR_PLAT") return "Creator Platinum";
  if (userGroupType === "PRO") return "Pro";
  return "";
};

export const getHighestRequiredUsergroup = (requiredScopes: any) => {
  if (requiredScopes.includes("CREATOR_PLAT")) return "CREATOR_PLAT";
  if (requiredScopes.includes("PRO_PLUS")) return "PRO_PLUS";
  if (requiredScopes.includes("PRO")) return "PRO";
  return "FREE";
};

interface AvatarWrapperProps {
  borderLevel?:
  | "officialPartner"
  | "superstar"
  | "pro"
  | "creatorPlat"
  | "proPlus";
  children?: ReactElement;
  size: "tiny" | "small" | "nav" | "sideRail" | "medium" | "large";
}

export const AvatarWrapper = ({
  children,
  borderLevel,
  size,
}: AvatarWrapperProps) => {
  return borderLevel ? (
    <AvatarBorder type={borderLevel} size={size}>
      {children}
    </AvatarBorder>
  ) : (
    <>{children}</>
  );
};

const StyledFlex = styled(Flex)`
  position: relative;
  min-width: 45px;
  z-index: 1;
`;

export const validateUsername = (username: string) => {
  return /^[a-zA-Z0-9](?!.*(--|__|-_|_-))[a-zA-Z0-9-_]{2,16}[a-zA-Z0-9]$/gi.test(
    username,
  );
};
