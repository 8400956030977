import styled from "styled-components";

export const StyledPlayer = styled.div`
  .vjs-play-progress,
  .vjs-slider-bar {
    background: ${({ theme }) => theme.colors.envy};
  }
  .video-js:hover .vjs-big-play-button {
    background-color: unset;
  }

  .vjs-big-play-button {
    height: 5rem;
    width: 5rem;
    background: unset;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    border: 6px;
    border-style: solid;
    border-color: rgb(12, 19, 24, 0.9);
    opacity: 0.8;
    -webkit-transition: opacity 0.15s;
    transition: opacity 0.15s;
    border: unset;

    :focus {
      border-color: rgb(12, 19, 24, 0.9);
    }

    > .vjs-icon-placeholder {
      --width: 40%;

      display: block;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 448 512" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>');
      background-repeat: no-repeat;
      width: var(--width);
      height: var(--width);
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);

      :before {
        content: "";
      }

      :hover {
      }
    }

    > .vjs-control-text {
      border: 0;
      -webkit-clip: rect(0 0 0 0);
      clip: rect(0 0 0 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
`;
