import { RecoilState, atom } from "recoil";
import {
  ISelectableDropdownStyle,
  ISelectedDropdownStyle,
} from "../Components/SignatureSelector";

type TSignatureSelectorState = {
  loading: boolean;
  allowSave: boolean;
  selectedGenres: TSelectedCard[];
  previewSources: string[];
  selectedSignature: TSelectedSignatureState;
  mySignature: TSelectedSignatureState;
  hasASignature: boolean;
  shouldRefetchStudioCards: boolean;
  preloadedUIWithMySignature: boolean;
  fetchedMySignature: boolean;
  allLoadouts: TSelectedSignatureState[];
  themes: { themeName: string; themeImageSrc: string }[];
  selectedToggleNames: string[];
  selectedDropdownStyles: ISelectedDropdownStyle[];
  customStyleSelectionToPreload: ICustomStyleSelectionToPreload[];
  selectableDropdownStyles: ISelectableDropdownStyle[];
  selectedCountryCode: string;
};

export type TSelectedSignatureState = {
  clipCards: TClipCard[];
  metadataThemeName: string;
  metadataThemeImageSrc: string;
  metadataStylePreviewColor: string;
  metadataStyleName: string;
  metadataPreviewType: string;
  metadataPreviewSrc: string;
  metadataThemeCountryFlagEnabled: boolean;
  metadataThemeCountryFlagDisplay: boolean;
  metadataThemeCountryFlagToggleName: string;
  metadataThemeCountryFlagToggleDescription: string;
  metadataDisplayName: string;
  scopeUse: string[];
  active: boolean;
  _id: string;
};

export type TClipCard = {
  settingsUiToggleDescription: string;
  settingsUiToggleName: string;
  settingsUiToggleable: boolean;
  countryFlagEnabled: boolean;
  card: TSelectedCard;
  enabled: boolean;
};

export type TSelectedCard = {
  _id: string;
  ui: {
    title: string;
  };
  effect: string;
};

interface ICustomStyleSelectionToPreload {
  metadataStyleName: string;
  settingsUiToggleName: string;
}

export const SignatureSelectorState: RecoilState<TSignatureSelectorState> =
  atom({
    key: "LoadoutSelector_V0.1",
    default: {
      allowSave: false as boolean,
      loading: true as boolean,
      previewSources: [] as string[],
      selectedGenres: [] as TSelectedCard[],
      hasASignature: false as boolean,
      shouldRefetchStudioCards: false as boolean,
      preloadedUIWithMySignature: false as boolean,
      fetchedMySignature: false as boolean,
      allLoadouts: [] as TSelectedSignatureState[],
      selectedToggleNames: [] as string[],
      themes: [] as { themeName: string; themeImageSrc: string }[],
      selectedDropdownStyles: [] as ISelectedDropdownStyle[],
      customStyleSelectionToPreload: [] as ICustomStyleSelectionToPreload[],
      selectableDropdownStyles: [] as ISelectableDropdownStyle[],
      selectedCountryCode: "",
      mySignature: {
        clipCards: [] as TSelectedSignatureState["clipCards"],
        metadataThemeName: "",
        metadataThemeImageSrc: "",
        metadataStylePreviewColor: "",
        metadataStyleName: "",
        metadataPreviewType: "",
        metadataPreviewSrc: "",
        metadataThemeCountryFlagEnabled: false as boolean,
        scopeUse: [] as string[],
        metadataThemeCountryFlagDisplay: false as boolean,
        metadataThemeCountryFlagToggleName: "",
        metadataThemeCountryFlagToggleDescription: "",
        metadataDisplayName: "",
        active: false as boolean,
        _id: "",
      },
      selectedSignature: {
        clipCards: [] as TSelectedSignatureState["clipCards"],
        metadataThemeName: "",
        metadataThemeImageSrc: "",
        metadataStylePreviewColor: "",
        metadataStyleName: "",
        metadataPreviewType: "",
        metadataPreviewSrc: "",
        metadataThemeCountryFlagEnabled: false as boolean,
        metadataThemeCountryFlagDisplay: false as boolean,
        metadataThemeCountryFlagToggleName: "",
        metadataDisplayName: "",
        metadataThemeCountryFlagToggleDescription: "",
        scopeUse: [] as string[],
        active: false as boolean,
        _id: "",
      },
    },
  });
