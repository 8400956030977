import React, { useState } from "react";
import { Flex, Box } from "rebass/styled-components";
import { FloatingContainer, FloatingContent } from "../Wrappers";
import { Body } from "../Text";
import Lottie from "lottie-react";
import Security from "../../../animations/security.json";
import { useUser } from "../../Hooks/useUser";
import { useRecoilValue } from "recoil";
import { StripePaystationState } from "../../State";
import styled from "styled-components";
import { StripeCheckout } from "./Checkout";

const lottieStyle = {
  height: 180,
};
export const Stripe: React.FC = () => {
  const [state] = useState({
    loading: true,
    error: false,
  });
  const paystation = useRecoilValue(StripePaystationState);
  const { allstarUser } = useUser();

  if (state.error || (!paystation.lookUp && !paystation.type)) {
    return (
      <FloatingContainer>
        <FloatingContent
          title={
            <Flex justifyContent="center" fontSize="75px" pb={[6]}>
              <span role="img" aria-label="">
                🤔
              </span>
            </Flex>
          }
          body={
            <Body>
              Something went wrong. Contact support for further assistance.
            </Body>
          }
        />
      </FloatingContainer>
    );
  }
  if (paystation.type === "session") {
    return (
      <Box
        style={{
          paddingBottom: "56.25%",
          paddingTop: "35px",
          height: 0,
        }}
      >
        <IFrameLoading>
          <Flex
            justifyContent="center"
            alignItems={"center"}
            flexDirection="column"
          >
            <Lottie animationData={Security} style={lottieStyle} loop={true} />
            <ConnectionText>Establishing a secure connection</ConnectionText>
            <ConnectionSubText>
              Just a second... We're fetching the subscription and payment info
            </ConnectionSubText>
          </Flex>
        </IFrameLoading>
      </Box>
    );
  }

  return (
    <StripeCheckout
      userToken={allstarUser.token}
      lookup={paystation.lookUp!}
      coupon={paystation.couponId}
      returnUrl={paystation.returnUrl || "/profile"}
    />
  );
};
type iFrameProps = {
  isMobile: boolean;
  paystationReady?: boolean;
};

const ConnectionText = styled(Flex)`
  font-size: 24px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  height: 100%;
`;

const ConnectionSubText = styled(Flex)`
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  height: 100%;
  color: #8fa9af;
`;

const IFrameLoading = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  min-height: 600px;
  width: 40vw;
  border-radius: 10px;
  border-color: transparent;
  background: #1f2a33;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 90vw;
  }
`;
