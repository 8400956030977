import { useMemo, useState, useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

import { SettingsContainer } from "../../../../../components/molecules/settingsContainer";
import { LabeledSelect } from "../../../../../components/atoms/form/labeledSelect";
import { Error } from "../../../../../components/atoms/form/error";
import { LabeledInputText } from "../../../../../components/atoms/form/labeledInput";
import { Button } from "../../../../../components/atoms/form/button";
import { Success } from "../../../../../components/atoms/form/success";
import { AlertIcon } from "../../../../../components/atoms/icons";
import { DividerAlt } from "../../../../../components/atoms/dividerAlt";
import { URLS } from "../../../../../utils";
import { AccountViewmodel } from "../../ViewModel";

import {
  TCustomizeProfileProps,
  TCustomizeProfileFormFields,
  TCustomizeProfileState,
  TCustomizeProfileSocials,
  TCustomizeProfileSocialObject,
} from "../../@types";
import { Headline } from "../../../../Components/Text";
import { validateUsername } from "../../../../Utilities/User";

const StyledSettingsContainer = styled(SettingsContainer)`
  ${(props) => {
    if (props.isPopup) {
      return `
      background: none;
      border: none;
      padding:0px;`;
    }
  }}
`;

function filterTopCountries() {
  const countries = countryList().getData();
  const COUNTRIES = [
    "Bangladesh",
    "Brazil",
    "India",
    "Pakistan",
    "Philippines",
    "United States",
  ];

  return countries.filter((country: { label: string }) =>
    COUNTRIES.includes(country.label),
  );
}

export function CustomizeProfile({
  allstarUser,
  getFreshProfile,
  isPopup,
  setModalOpen,
}: TCustomizeProfileProps) {
  const options = useMemo(() => countryList().getData(), []);
  const history = useHistory();
  const { mutateProfile } = AccountViewmodel();

  const {
    handleSubmit,
    register,
    reset,
    formState,
    formState: { errors },
  } = useForm<TCustomizeProfileFormFields>();
  const topCountries = filterTopCountries();
  const { t } = useTranslation("translation", {
    keyPrefix: "profile.account",
  });

  const [state, setState] = useState<TCustomizeProfileState>({
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "Profile updated!",
    username: allstarUser?.user?.profile?.username,
    country: allstarUser?.user?.profile?.profile?.countryCode || "",
    socials: {
      youtube: {
        name: "Youtube",
        fieldName: "socials.youtube",
        prefix: "youtube.com/@",
        suffix: allstarUser?.user?.profile?.profile?.socials?.youtube || "",
      },
      twitch: {
        name: "Twitch",
        fieldName: "socials.twitch",
        prefix: "twitch.tv/",
        suffix: allstarUser?.user?.profile?.profile?.socials?.twitch || "",
      },
      tiktok: {
        name: "TikTok",
        fieldName: "socials.tiktok",
        prefix: "tiktok.com/@",
        suffix: allstarUser?.user?.profile?.profile?.socials?.tiktok || "",
      },
      twitter: {
        name: "Twitter / X",
        fieldName: "socials.twitter",
        prefix: "twitter.com/",
        suffix: allstarUser?.user?.profile?.profile?.socials?.twitter || "",
      },
      instagram: {
        name: "Instagram",
        fieldName: "socials.instagram",
        prefix: "instagram.com/",
        suffix: allstarUser?.user?.profile?.profile?.socials?.instagram || "",
      },
      steam: {
        name: "Steam",
        fieldName: "socials.steam",
        prefix: "steamcommunity.com/id/",
        suffix: allstarUser?.user?.profile?.profile?.socials?.steam || "",
      },
    },
  });

  useEffect(() => {
    if (!state.success && !state.error) return;
    setTimeout(() => {
      return setState((state: TCustomizeProfileState) => {
        return {
          ...state,
          success: false,
          error: false,
          errorMsg: "",
        };
      });
    }, 5000);
  }, [state.success, state.error]);

  const onSubmit = async (form: TCustomizeProfileFormFields) => {
    await updateProfileData(form);
  };

  async function updateProfileData(form: TCustomizeProfileFormFields) {
    if (form.username && !validateUsername(form.username)) {
      return setState({
        ...state,
        error: true,
        success: false,
        errorMsg: "Invalid Username",
      });
    }
    try {
      const response = await mutateProfile({ variables: { ...form } });

      if (response?.data) {
        window.rudderanalytics.track("Profile Update", {
          ...form,
        });
        await getFreshProfile();
        reset(form);
        if (isPopup && setModalOpen) {
          setModalOpen(false);
        }
        return setState({
          ...state,
          error: false,
          success: true,
          username: Object.prototype.hasOwnProperty.call(
            formState.dirtyFields,
            "username",
          )
            ? form.username
            : state.username,
          errorMsg: "",
        });
      }
      if (response?.data?.code === 204) {
        return setState({
          ...state,
          error: true,
          success: false,
          errorMsg: "You can't set your username more than once.",
        });
      }
    } catch (e) {
      return setState({
        ...state,
        error: true,
        success: false,
        errorMsg: "Error updating profile.",
      });
    }
  }

  return (
    <StyledSettingsContainer
      title={isPopup ? null : t("yourProfile")}
      mb={5}
      isPopup={isPopup}
    >
      {isPopup ? <></> : <DividerAlt></DividerAlt>}

      {allstarUser.user?.profile?.settings?.usernameChange > 0 || isPopup ? (
        <></>
      ) : (
        <>
          <Flex alignItems="center" pb={5}>
            <Box>
              <AlertIcon size={30} color="envy"></AlertIcon>
            </Box>
            <Box pl={[5, 5, 3, 3]}>
              You only get 1 chance at setting your username, so make it count!
            </Box>
          </Flex>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabeledInputText //@ts-expect-error: I cant figure this out, it will be resolved once we port the input to typescript
          input={{
            id: "username",

            defaultValue: Object.prototype.hasOwnProperty.call(
              formState.dirtyFields,
              "username",
            )
              ? ""
              : state.username,

            disabled: allstarUser.user?.profile?.settings?.usernameChange > 0,
            ...register("username", {
              required: false,
            }),
          }}
          label="username"
          error={errors.username}
          placeholder={t("username")}
        />
        {isPopup ? (
          <></>
        ) : (
          <>
            <Box pt={5}>
              <LabeledSelect //@ts-expect-error: I cant figure this out, it will be resolved once we port the input to typescript
                input={{
                  id: "countryCode",

                  ...register("countryCode", {
                    required: false,
                  }),
                }}
                placeholder={t("country")}
                options={options}
                topList={topCountries}
                defaultValue={state.country ? state.country : ""}
              />
            </Box>

            <Headline mb={6} mt={6}>
              Social Profiles
            </Headline>

            {Object.keys(state.socials).map((social) => {
              const socialData: TCustomizeProfileSocialObject =
                state.socials[social as keyof TCustomizeProfileSocials];

              return (
                <>
                  <LabeledInputText //@ts-expect-error: I cant figure this out, it will be resolved once we port the input to typescript
                    input={{
                      id: socialData.name,
                      defaultValue: Object.prototype.hasOwnProperty.call(
                        formState.dirtyFields,
                        socialData.name,
                      )
                        ? ""
                        : socialData.suffix,

                      disabled: false,
                      ...register(
                        socialData.fieldName as keyof TCustomizeProfileFormFields,
                        {
                          required: false,
                          maxLength: 25,
                          pattern: {
                            value: /^(\p{L}|[0-9]|\.|_|-)+$/u,
                            message: "",
                          },
                        },
                      ),
                    }}
                    error={
                      errors.socials &&
                      Object.getOwnPropertyNames(errors.socials).some(
                        (socialWithError) => socialWithError === social,
                      )
                    }
                    placeholder={socialData.name}
                    prefix={socialData.prefix}
                    forcePlaceholderTop={true}
                  />
                </>
              );
            })}
          </>
        )}
        {state.error ? <Error> {state.errorMsg}</Error> : <></>}
        {state.success ? <Success>{state.successMsg}</Success> : <></>}
        {errors.socials ? (
          <Error>
            Max length is 25 characters and may only include alphanumeric
            characters, underscores and dashes
          </Error>
        ) : (
          <></>
        )}
        <Flex pt={[5]} justifyContent="flex-start">
          <Button size="small" flex={true} mt={3} disabled={!formState.isDirty}>
            {t("saveProfile")}
          </Button>
          <Button
            type="button"
            size="small"
            flex={true}
            classification="subtle"
            ml={4}
            mt={3}
            onClick={() => {
              if (isPopup) {
                history.push(`/${URLS.ACCOUNT_SETTINGS}`);
              }
              reset();
              setState({
                ...state,
                error: false,
                success: false,
                errorMsg: "",
              });
            }}
          >
            {t("cancel")}
          </Button>
        </Flex>
      </form>
    </StyledSettingsContainer>
  );
}
