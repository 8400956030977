import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

const ModalStyle = styled(Flex)`
  // z-index: 5000;
`;

export function Modal({
  isModalOpen,
  setModalOpen,
  children,
  handleModalClose,
  disableOutsideClose,
  ...props
}) {
  const node = useRef();
  const handleClick = ({ target }) => {
    if (node.current && node.current.isSameNode(target)) {
      if (disableOutsideClose) return;
      if (handleModalClose) handleModalClose();

      return setModalOpen(false);
    }
  };

  useEffect(() => {
    if (node.current) {
      node.current.addEventListener("mousedown", handleClick);
      // return () => {
      //   node.current.removeEventListener('click', handleClick);
      // };
    }
  });

  return isModalOpen ? (
    <ModalStyle
      variant="modal"
      ref={node}
      key={`modal-${node}`}
      style={{ display: isModalOpen ? "flex" : "none" }}
      {...props}
    >
      {children}
    </ModalStyle>
  ) : (
    <></>
  );
}
