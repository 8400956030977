import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass";

import { PromoSession } from "../../State/promoSession";
import { getQueryParam } from "../../Utilities/QueryParam";
import { useIsDesktopApp } from "../../Hooks/useIsDesktopApp";
import { AllstarModalState } from "../../State/modals";
import { URLS } from "../../Utilities/Maps";
import { CrossSitePromotion } from "../../Views/CrossSitePromotion";
import { NoticeBanner } from "../NoticeBanner";
import { ECrossSitePromotionType } from "../../@types";

const SiteNotice = () => {
  const hashCode = (s: string) => {
    let h: number | undefined;
    for (let i = 0; i < s.length; i++)
      h = (Math.imul(31, h || 0) + s.charCodeAt(i)) | 0;

    return h;
  };
  const [promoSession] = useRecoilState(PromoSession);
  const hash =
    promoSession?.activeSiteTopBanner?.content &&
    hashCode(promoSession?.activeSiteTopBanner?.content);
  const localStorageKey = `banner-announcement-${hash}-has-been-closed`;
  const [shouldShow, setShouldShow] = useState(
    !localStorage.getItem(localStorageKey) &&
      getQueryParam("hide") !== "popover",
  );
  const history = useHistory();
  const isDesktop = useIsDesktopApp();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  useEffect(() => {
    if (promoSession?.activeSiteTopBanner?.content) {
      setShouldShow(
        !localStorage.getItem(localStorageKey) &&
          getQueryParam("hide") !== "popover",
      );
    }
  }, [promoSession?.activeSiteTopBanner?.content]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    window.location.pathname.includes(URLS.STUDIO) ||
    window.location.pathname.includes(URLS.REMIX) ||
    window.location.pathname.includes(URLS.MONTAGE) ||
    window.location.pathname.includes(URLS.OVERWOLF) ||
    window.location.pathname.includes(URLS.ALLSTAR_GAMERS_CLUB) ||
    window.location.pathname.includes(URLS.PARTNERS) ||
    window.location.pathname.includes(URLS.DEV) ||
    window.location.pathname.match("/setup/.*") ||
    window.location.pathname.match("/register.*") ||
    window.location.pathname.match("/login.*") ||
    window.location.pathname.match("/oauth") ||
    window.location.pathname.match("authenticate/mobile") ||
    !shouldShow
  )
    return <></>;

  if (!promoSession?.activeSiteTopBanner && promoSession?.loaded) return <></>;

  return (
    <NoticeBanner
      className="js-sticky-top"
      hideOnMobile={false}
      backgroundColor={promoSession?.activeSiteTopBanner?.colorCSS}
      display={!promoSession?.loaded ? "none" : "flex"}
      setShouldShow={setShouldShow}
      hash={hash}
      onClick={() => {
        window.rudderanalytics.track("Promotion - Button Click", {
          type: "noticeBanner",
          promotionName: promoSession?.activeSiteTopBanner?.name,
        });

        localStorage.setItem(
          `banner-announcement-${hash}-has-been-closed`,
          "true",
        );

        if (promoSession?.activeSiteTopBanner?.buttonAction) {
          setAllstarModalState({
            isOpen: promoSession?.activeSiteTopBanner?.buttonAction,
            functions: {},
            data: {},
          });

          return setShouldShow(false);
        }
        if (promoSession?.activeSiteTopBanner?.external) {
          if (isDesktop.isDesktop) {
            try {
              window.parent.postMessage(
                {
                  type: "openLink",
                  link: promoSession?.activeSiteTopBanner?.link,
                },
                "*",
              );
              return setShouldShow(false);
            } catch (e) {}
          } else window.open(promoSession?.activeSiteTopBanner?.link, "_blank");
        } else {
          if (isDesktop.isDesktop) {
            window.parent.postMessage(
              {
                type: "openLink",
                link: `https://allstar.gg${promoSession?.activeSiteTopBanner?.link}`,
              },
              "*",
            );
            return setShouldShow(false);
          } else history.push(promoSession?.activeSiteTopBanner?.link);
        }
      }}
    >
      <>
        <CrossSitePromotion
          type={ECrossSitePromotionType.TopSiteBanner}
          loadDataOnly={true}
        />
      </>
      <Flex flexWrap="wrap">{promoSession?.activeSiteTopBanner?.content}</Flex>
    </NoticeBanner>
  );
};

export { SiteNotice };
