import React, { memo } from "react";
import { Large3, Title3, Title2, Body } from "../../components/text";
import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Mousewheel, Pagination, Autoplay, Navigation } from "swiper/modules";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Divider } from "../../components/atoms/divider";
import Number1 from "../../assets/images/culture/number1.png";
import Number2 from "../../assets/images/culture/number2.png";
import Number3 from "../../assets/images/culture/number3.png";
import Number4 from "../../assets/images/culture/number4.png";
import Number5 from "../../assets/images/culture/number5.png";
import Number6 from "../../assets/images/culture/number6.png";

import "./culture.css";

const CoreValue = styled(Flex)`
  background-color: ${(props) => props.theme.colors.darkerNed};
  color: white;
  width: 480px;
  height: 100%;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
`;

const LargeNumber = styled(Image)`
  top: 60px;
  left: 30px;
  position: absolute;
  opacity: 0.5;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    top: 50px;
    left: 50px;
  }
`;

const Container = styled(Flex)`
  background-color: transparent;
  width: 480px;
  height: 340px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 100%;
    height: 100%;
  }
`;

const StyledTitle = styled(Title2)`
  font-weight: 700;
`;

const StyledLeftArrow = styled(HiChevronLeft)`
  positon: absolute;
  padding: 2px;
  z-index: 100000;
  border-radius: 999px;
  width: 38px;
  height: 38px;
  color: ${(props) => {
    return props.theme.colors.envyAlpha80;
  }};
  &:hover {
    cursor: pointer;
    color: ${(props) => {
      return props.theme.colors.envy;
    }};
    background-color: ${(props) => {
      return props.theme.colors.envyAlpha05;
    }};
  }
`;

const StyledRightArrow = styled(HiChevronRight)`
  padding: 2px;
  z-index: 100000;
  border-radius: 999px;
  width: 38px;
  height: 38px;
  color: ${(props) => {
    return props.theme.colors.envyAlpha80;
  }};
  &:hover {
    cursor: pointer;
    color: ${(props) => {
      return props.theme.colors.envy;
    }};
    background-color: ${(props) => {
      return props.theme.colors.envyAlpha05;
    }};
  }
`;

const SwipyFlex = styled(Flex)`
  max-width: 480px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Values = memo(() => {
  const swiper = useSwiper();
  return (
    <>
      <Flex
        mb={9}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Divider my={[0, 0, 3, 3]} width={[2, 2, 3, 4]}></Divider>
        <Flex
          flexDirection="column"
          textAlign="center"
          pt={9}
          width={[2, 2, 5, 6]}
        >
          <Large3 mb={3}>Our core values </Large3>
          <Title3 color="chalk" mb={9}>
            Our values help us with how we think, how we spend our time, and how
            we work together.
          </Title3>
        </Flex>
        <SwipyFlex>
          <Swiper
            centeredSlides={true}
            spaceBetween={10}
            slidesPerView={1}
            // grabCursor={true}
            // mousewheel={true}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[Pagination, Navigation]}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            className="mySwiper"
          >
            <StyledLeftArrow
              style={{
                position: "absolute",
                top: "50%",
                left: "5px",
              }}
              className="swiper-button-prev"
            />
            <StyledRightArrow
              style={{
                position: "absolute",
                top: "50%",
                right: "5px",
              }}
              className="swiper-button-next"
            />
            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      Open Mindedness
                    </StyledTitle>
                    <Body color="ice">
                      Just because the path to an idea isn’t clear, doesn’t mean
                      it’s not possible, or that we won’t find a better idea
                      along the way. Our talent points are spent on steady
                      improvements, loose egos, constructive feedback and
                      sharing ideas often.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number1}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      Build Value
                    </StyledTitle>
                    <Body color="ice">
                      At Allstar, we strive to ship things that are not just
                      good, but also awe-inspiring. We focus on the end user at
                      all times. This quality measure applies to everything we
                      do, both internally and externally.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number2}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      Celebrate the Wins
                    </StyledTitle>
                    <Body color="ice">
                      We think it’s important to recognize and celebrate these
                      wins as they happen in real time. Startups are hard, we
                      move fast and build even faster, so taking the time to
                      recognize people and the wins they put on the board is
                      key. No win is too small.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number3}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>

            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      No More Solo-Ques
                    </StyledTitle>
                    <Body color="ice">
                      “For the strength of the Pack is the Wolf, and the
                      strength of the Wolf is the Pack.” In order to find our
                      way together, we hold each other and ourselves to the
                      highest. We are stronger and better as a team, and we’ll
                      get to where we’re going faster together.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number4}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      Everyone Plays Support
                    </StyledTitle>
                    <Body color="ice">
                      It’s important to understand and care about those around
                      you. We expect all of Team Allstar to put themselves in
                      the shoes of everyone around them, from users to
                      colleagues and to respect that everyone’s situation and
                      perspective is different.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number5}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Container>
                <CoreValue>
                  <Flex
                    mt={[0, 0, 4, 4]}
                    mx={[0, 0, 5, 5]}
                    flexDirection="column"
                  >
                    <StyledTitle mb={4} color="envy">
                      Trust
                    </StyledTitle>
                    <Body color="ice">
                      Without trusting others and having the trust of those
                      around us, we can’t move fast enough, or reach far enough.
                      Building trust, having integrity, being transparent and
                      embracing accountability are core to all high functioning
                      teams.
                    </Body>
                  </Flex>
                  <LargeNumber src={Number6}></LargeNumber>
                </CoreValue>
              </Container>
            </SwiperSlide>
          </Swiper>
        </SwipyFlex>
        <Divider
          mb={[0, 0, 3, 3]}
          mt={[0, 0, 6, 6]}
          width={[2, 2, 3, 4]}
        ></Divider>
      </Flex>
    </>
  );
});

export default Values;
