import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

export const RoundedAddButton = styled(Flex)`
  height: 28px;
  width: 48px;
  border-radius: 99px;
  background-color: ${(props) => props.theme.colors.navy};
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.darkerNavy};
    transition: 0.2s;
  }
`;
