import { useQuery } from "@apollo/client";
import { CROSS_SITE_PROMOTIONS } from "../../GraphQL/promotions";
import { useUser } from "../../Hooks/useUser";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { PromoSession } from "../../State/promoSession";

export const CrossSitePromotionViewModel = () => {
  const [promoSession, setPromoSession] = useRecoilState(PromoSession);

  const {
    data: promotionsData,
    refetch: refetchPromotions,
    loading: promotionsLoading,
    error: promotionsError,
  } = useQuery(CROSS_SITE_PROMOTIONS, {
    onError: (err) => {
      /*
      @JOHN
      Need to handle token expiry or token resets here...
      */

      // logout("token expiry");
      console.log(`promotions error! ${err.message}`);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (promoSession.refetch) {
      refetchPromotions();
    }
    setPromoSession({ ...promoSession, refetch: false });
  }, [promoSession.refetch]);
  return { promotionsData, promotionsError, promotionsLoading };
};
