import { colors } from "./colors";

const themeOne = {
  colors: {
    bodyColor: colors.ice,
  },

  maxWidth: "100%",

  paddedWidth: "1520px",

  nav: {
    color: "ice",
    ".scrolled &": {
      bg: "ned",
      // boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
    },
  },

  studioNavBase: {
    height: "76px",
    width: "100vw",
    position: "fixed",
    zIndex: 20,
    padding: "15px 30px 15px 15px",
    "@media (max-width: 900px)": {
      padding: "15px 10px 15px 10px",
      height: "60px",
    },
  },

  studioNav: {
    bg: "darkerNavy",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
  },

  buttons: {
    subtle: {
      color: "chalkAlpha60",
    },
    filter: {
      bg: "darkerNavy",
      borderRadius: "999px",
      color: "darkerIceAlpha90",
      borderColor: "darkerNavy",
      borderWidth: "1px",
      borderStyle: "solid",
      fontSize: 1,
      ":hover": {
        bg: "darkerNed",
        borderColor: "darkerNed",
      },
      "&.active": {
        bg: "darkerNed",
        borderColor: "envy",
        borderWidth: "1px",
        borderStyle: "solid",
        color: "ice",
      },
    },
  },

  card: {
    bg: "transparent",
    color: "ice",
    maxWidth: "none",
  },

  creatorCard: {
    bg: "darkerNed",
    color: "ice",
    // borderRadius: "10px",
    // ":hover": {
    //   boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.7)",
    // },
  },

  floatingNav: {
    // boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
    color: "ice",
    bg: "darkerNavy",
    borderRadius: "9px",
    padding: 3,
  },

  filterDropdown: {
    bg: "darkerNavy",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
  },

  floatingContainer: {
    bg: "#213442",
    "@supports (backdrop-filter: blur(70px))": {
      "&": {
        bg: "rgba(0, 0, 0, 0.15)",
      },
    },
  },

  menu: {
    borderColor: "#34444d",
    bg: "nedLight",
    color: "ice",
  },

  menuLink: {
    bg: "transparent",
    color: "ice",
    ":hover": {
      bg: "midnightAlpha40",
      color: "envy",
    },
    "&.active": {
      bg: "darkerNed",
      color: "envy",
    },
    "&.active > svg": {
      bg: "transparent",
      color: "envy",
      svg: {
        stroke: "envy",
      },
    },
  },

  menuContainer: {
    color: "iceAlpha60",
    ":hover": {
      bg: "nedLight",
    },
  },

  menuDropdown: {
    bg: "nedLight",
    color: "iceAlpha60",
    padding: 3,
    borderRadius: "16px",
    border: "1px solid #34444d",
    boxShadow: "2px 2px 10px rgba(12, 19, 24, .6)",
    "&:before": {
      borderBottomColor: "darkerNavy",
      opacity: 0,
    },
  },

  staticContainer: {
    color: "ice",
  },

  comingSoon: {
    bg: "ned",
  },

  settingsContainer: {
    borderColor: "#34444d",
    bg: "nedLight",
  },

  descriptionContainer: {
    borderColor: "ned",
    borderWidth: "1px",
    borderStyle: "solid",
    bg: "darkerNavy",
  },

  headerClip: {
    gradient: `linear-gradient(180deg, ${colors.darkerNed} 0%, rgba(12, 19, 24, 0) 18.81%, rgba(12, 19, 24, 0) 70.79%, ${colors.darkerNed} 100%)`,
  },

  input: {
    bg: "#213442",
    borderColor: "#8fa9af2e",
    color: "ice",
    "::boxshadow": {
      color: "midnightAlpha40",
    },
  },

  avatar: {
    bg: "envy",
    color: "midnightAlpha40",
  },

  variants: {
    link: {
      floatingNav: {
        color: "ice",
      },
    },
    select: {
      default: {
        borderColor: "#8fa9af2e",
        bg: "darkerNavy",
        color: "envy",
      },
      small: {
        borderColor: "transparent",
        bg: "transparent",
        height: "2.5rem",
      },
    },
  },
};

export { themeOne };
