import React from "react";
import { Flex } from "rebass/styled-components";
import { FullScreenWrapper } from "../../components/wrappers/fullScreenWrapper";
import { Body } from "../../components/text";
import { FloatingContainer } from "../../components/wrappers/floatingContainer";

import { FloatingContent } from "../../components/molecules/floatingContent";
import { Divider } from "../../components/atoms/divider";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useIsMobileApp } from "../../MVVM/Hooks/useIsMobileApp";

const StyledDivider = styled(Divider)`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
`;

export default function CreateOrLogin() {
  const history = useHistory();
  const { isMobileApp } = useIsMobileApp();
  async function handleDiscord() {
    if (isMobileApp)
      return history.push(
        "/authenticate/discord?referrer=mobile_app&flow=login&hide=popover",
      );

    return history.push(
      "/authenticate/discord?flow=signup&referrer=mobile_app",
    );
  }
  const signUpView = useSignupQuery();

  function bodyContent() {
    return (
      <Flex justifyContent="center">
        <Body textAlign="center">
          Looks like your Discord account is not linked to an Allstar account
          yet.
          <br></br>
          <br></br>
          <br></br>
          <AlternativeAuthenticateLink
            onClick={() => {
              if (isMobileApp && window.JSBridge?.returnToLogin())
                window.JSBridge.returnToLogin();
              history.push("/login");
            }}
          >
            Log in and link your existing Allstar Account to Discord
          </AlternativeAuthenticateLink>
          <StyledDivider>or</StyledDivider>
          <AlternativeAuthenticateLink
            onClick={() => {
              handleDiscord();
            }}
          >
            Sign up for a new account with Discord
          </AlternativeAuthenticateLink>{" "}
        </Body>
      </Flex>
    );
  }

  function failedSignupContent() {
    return (
      <Flex justifyContent="center">
        <Body textAlign="center">
          The email associated with that Discord account is already being used
          for an Allstar account.
          <br></br>
          <br></br>
          <br></br>
          <AlternativeAuthenticateLink
            onClick={() => {
              if (isMobileApp && window.JSBridge?.returnToLogin())
                window.JSBridge.returnToLogin();

              history.push("/login");
            }}
          >
            Log in with email and link your existing Allstar Account to Discord
          </AlternativeAuthenticateLink>
        </Body>
      </Flex>
    );
  }

  return (
    <FullScreenWrapper>
      <FloatingContainer>
        {signUpView ? (
          <FloatingContent
            title={
              <Flex justifyContent="center" fontSize="75px" pb={[6]}>
                <span role="img" aria-label="">
                  🤔
                </span>
              </Flex>
            }
            body={failedSignupContent()}
          />
        ) : (
          <FloatingContent
            title={
              <Flex justifyContent="center" fontSize="75px" pb={[6]}>
                <span role="img" aria-label="">
                  🤔
                </span>
              </Flex>
            }
            body={bodyContent()}
          />
        )}
      </FloatingContainer>
    </FullScreenWrapper>
  );
}
function useSignupQuery() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const signupView = params.get("signup");
  return signupView;
}

const AlternativeAuthenticateLink = styled(Body)`
  color: ${(props) => props.theme.colors.ice};
  width: fit-content;
  border-bottom: 1px solid ${(props) => props.theme.colors.envy};
  display: inline;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.envy};
    transition: 0.2s;
  }
`;
