import React from "react";
import { PaddedWrapper } from "../../components/wrappers/paddedWrapper";
import { StaticContainer } from "../../components/wrappers/staticContainer";
import { FloatingNav } from "../../components/molecules/floatingNav";
import { StaticContentCopy } from "../../components/atoms/styledText";
import styled from "styled-components";

let links = [
  {
    text: "Privacy Policy",
    to: "/legal/privacy-policy",
  },
  {
    text: "Terms of Use",
    to: "/legal/terms-of-use",
  },
  {
    text: "Do not sell my personal information",
    to: "/legal/do-not-sell-my-personal-information",
  },
  {
    text: "Vulnerability Disclosure Policy",
    to: "/legal/vulnerability-disclosure-policy",
  },
];

let StyledPaddedWrapper = styled(PaddedWrapper)`
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

export default function Legal(props) {
  const isDesktop = !window.location.pathname.includes("mobile-app-link");

  return (
    <>
      <StaticContainer>
        {isDesktop ? (
          <StyledPaddedWrapper pt={10} pb={[4, 4, 7]}>
            <FloatingNav links={links} />
          </StyledPaddedWrapper>
        ) : (
          <></>
        )}
        <PaddedWrapper
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pb={10}
          pt={10}
        >
          <StaticContentCopy maxWidth={6}>{props.children}</StaticContentCopy>
        </PaddedWrapper>
      </StaticContainer>
    </>
  );
}
