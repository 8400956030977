import React, { useState } from "react";

import { Box } from "rebass";
import { ReactComponent as Add } from "../../../../../../assets/images/studio/add-mark.svg";
import { ReactComponent as Remove } from "../../../../../../assets/images/studio/remove-mark.svg";
import { ReactComponent as Checked } from "../../../../../../assets/images/studio/check-mark-fill.svg";
import { ReactComponent as HoverAdd } from "../../../../../../assets/images/studio/hover-add-mark.svg";
import styled from "styled-components";
import { useEffect } from "react";

interface IAddCardProps {
  handleToggle: () => void;
  isActive: boolean;
  parentHovered: boolean;
  parentControlsHover?: boolean;
}

export const AddButton = ({
  handleToggle,
  isActive,
  parentHovered,
  parentControlsHover,
}: IAddCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [checked, setChecked] = useState(isActive);

  useEffect(() => {
    if (parentControlsHover) {
      setIsHovered(parentHovered);
    }
  }, [parentHovered]); //eslint-disable-line

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  const AddOrRemove = () => {
    if (checked) {
      return (
        <>
          <CheckedBox
            onClick={(e: any) => {
              if (!parentControlsHover) {
                setIsHovered(false);
              }

              handleToggle();
              e.stopPropagation();
            }}
            // size={size}
          >
            {isHovered ? <StyledRemove /> : <StyledChecked />}
          </CheckedBox>
        </>
      );
    } else
      return (
        <>
          <AddBox
            onClick={(e: any) => {
              if (!parentControlsHover) {
                setIsHovered(false);
              }
              handleToggle();
              e.stopPropagation();
            }}
            // size={size}
          >
            {isHovered ? <StyledHoverAdd /> : <StyledAdd />}
          </AddBox>
        </>
      );
  };

  return (
    <Box
      onMouseEnter={() => {
        if (parentControlsHover) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (parentControlsHover) return;
        setIsHovered(false);
      }}
    >
      <AddOrRemove />
    </Box>
  );
};

const StyledAdd = styled(Add)<any>`
  fill: ${(props) => props.theme.colors.chalkAlpha60};
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const StyledRemove = styled(Remove)<any>`
  fill: ${(props) => props.theme.colors.chalkAlpha60};
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const AddBox = styled(Box)<any>`
  display: flex;
`;

const CheckedBox = styled(Box)<any>`
  display: flex;
`;

const StyledChecked = styled(Checked)<any>`
  transform: scale(1.1);
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const StyledHoverAdd = styled(HoverAdd)<any>`
  fill: ${(props) => props.theme.colors.envy};
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;
