import React, { useCallback } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box } from "rebass";
import styled from "styled-components";

const { REACT_APP_STRIPE_PK, REACT_APP_STRIPE_ENDPOINT } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PK as string);

export const StripeCheckout = ({
  userToken,
  lookup,
  returnUrl,
  coupon,
}: {
  userToken: string;
  lookup: string;
  returnUrl: string;
  coupon?: string;
}) => {
  if (returnUrl) localStorage.setItem("paymentValidationNext", returnUrl);

  const host = window.location.protocol + "//" + window.location.host;

  const fetchClientSecret = useCallback(async () => {
    const res = await fetch(`${REACT_APP_STRIPE_ENDPOINT}/stripe/v1/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        lookup,
        returnUrl: `${host}/payment/validation`,
        coupon,
      }),
    });

    const data = await res.json();
    return data.clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const options = { fetchClientSecret };

  return (
    <CheckoutContainer id="checkout">
      <Box py={4}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Box>
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.navy};
  border-radius: 12px;
  max-width: 1000px;
  overflow: hidden;
  padding: 12;
  width: 90vw;
`;
