import styled, { css } from "styled-components";
import { Flex } from "rebass/styled-components";
import { RiComputerLine } from "react-icons/ri";
import { MdPhoneAndroid } from "react-icons/md";
import { SiDiscord } from "react-icons/si";

import { Subheader } from "../Text";
import { Link } from "../Button";
import { Tooltip } from "../Tooltip";

const iconStyle = css`
  margin-right: 10px;
  align-self: center;
  color: ${(props) => props.theme.colors.envy};
`;

export const StyledComputer = styled(RiComputerLine)`
  ${iconStyle}
`;

export const StyledMobile = styled(MdPhoneAndroid)`
  ${iconStyle}
`;

export const StyledDiscord = styled(SiDiscord)`
  ${iconStyle}
`;

export const DropdownContainer = styled(Tooltip)`
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 7;
  margin-bottom: 22px;
  &:hover {
    cursor: default;
  }
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    bottom: 0px;
    right: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    display: none;
  }
`;

export const AppContainer = styled(Subheader)`
  display: flex;
  background-color: ${(props) => props.theme.colors.envy};
  border: 1px solid ${(props) => props.theme.colors.chalkAlpha20};
  color: ${(props) => props.theme.colors.iceAlpha75};
  height: 34px;
  border-radius: 999px;
  flex-direction: column;
  justify-content: center;
  min-width: 90px;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  box-shadow: 2px 10px 30px #10181d;
`;

export const AppDropdown = styled(Flex)`
  flex-direction: column;
  color: ${(props) => props.theme.colors.ice};
  white-space: nowrap;
`;

export const StyledButtonContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  text-align: left;
  min-width: 75px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  height: 35px;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.midnightAlpha50};
    color: ${(props) => props.theme.colors.envy};
  }
`;

export const DropdownTopSection = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
