import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend"; // Import the i18next-http-backend

i18n
  .use(Backend) // Use the i18next-http-backend
  .use(LanguageDetector) // detects language from browser
  .use(initReactI18next)
  .init({
    // order and from where user language should be detected
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lang", // default is lng
    },
    supportedLngs: ["en", "pt-BR", "de", "es"],
    // Define the backend options for i18next-http-backend
    backend: {
      loadPath: `${process.env.REACT_APP_S3_CLIP_BUCKET}/static/locales/{{lng}}/{{ns}}.json`, // Define the path pattern to your translation files
      errorCallback: (error) => {
        console.error("Error loading translations:", error);
      },
      fallbackLng: "en",
      requestOptions: {
        // used for fetch, can also be a function (payload) => ({ method: 'GET' })

        cache: "no-cache",
      },
    },

    fallbackLng: "en",
  });

