import React from "react";
import { ConfirmSettings } from "../../Clip/Components/ConfirmSettings";
import { FloatingContainer } from "../../Wrappers";
import { Modal } from "../../Wrappers";

import { ShareModal } from "../ShareOptions";
import { DownloadModal } from "../DownloadClip";
import { RemixModal } from "../RemixSurpriseOrStudio";
import { Report } from "../ReportClip";
import { EPaywallType, Paywall } from "../Paywall";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { remixModal } from "../../../../globalStorage/remix";
import { YouTubeShareModals } from "../YoutubeShare";
import { TikTokShareModals } from "../TikTokShare";
import { xsollaModalOpen } from "../../../State";

import theme from "../../../../theme/themeThree";
import { Paystation } from "../../PayStation";

export const ClipModals = () => {
  const [, setModalPreferred] = useRecoilState(remixModal);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const [paystationOpen, setPaystationOpen] = useRecoilState(xsollaModalOpen);

  const isMobile = window.screen.width < parseInt(theme.breakpoints[1]);

  if (
    allstarModalState.isOpen === ModalType.None &&
    !paystationOpen.isModalOpen
  )
    return <></>;

  return (
    <>
      <Modal
        isModalOpen={paystationOpen.isModalOpen}
        setModalOpen={setPaystationOpen}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <Paystation isMobile={isMobile} {...paystationOpen} />
      </Modal>
      <YouTubeShareModals />
      <TikTokShareModals />
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Delete}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.Delete : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <FloatingContainer maxWidth="500px">
          <ConfirmSettings
            setModalOpen={(isOpen: boolean) =>
              setAllstarModalState({
                ...allstarModalState,
                isOpen: isOpen ? ModalType.Delete : ModalType.None,
              })
            }
            clip={allstarModalState.data}
            setClipInactive={allstarModalState.functions.setClipInactive}
            isMontage={allstarModalState.data.isMontage}
          />
        </FloatingContainer>
      </Modal>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Share}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.Share : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <ShareModal
          clip={{
            ...allstarModalState.data,
            isMontage: allstarModalState.data.isMontage,
          }}
        ></ShareModal>
      </Modal>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Download}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.Download : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <DownloadModal
          clip={allstarModalState.data}
          onSuccess={(isOpen: boolean) =>
            setAllstarModalState({
              ...allstarModalState,
              isOpen: isOpen ? ModalType.Download : ModalType.None,
            })
          }
          displayPaywall={() => {
            setAllstarModalState({
              data: { type: EPaywallType.download as EPaywallType },
              isOpen: ModalType.Paywall,
              functions: {},
            });
          }}
        />
      </Modal>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Remix}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.Remix : ModalType.None,
          })
        }
        handleModalClose={() => {
          setModalPreferred(!localStorage.getItem("noRemixModal"));
        }}
        disableOutsideClose={undefined}
      >
        <RemixModal
          clip={allstarModalState.data}
          setRemixModalOpen={(isOpen: boolean) =>
            setAllstarModalState({
              ...allstarModalState,
              isOpen: isOpen ? ModalType.Remix : ModalType.None,
            })
          }
          onSuccess={undefined}
          onRemixError={(res: any) => {
            setAllstarModalState({
              ...allstarModalState,
              data: { type: EPaywallType.remix as EPaywallType },
              isOpen: ModalType.Paywall,
            });
          }}
        />
      </Modal>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.Report}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.Report : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <FloatingContainer>
          <Report
            setModalOpen={(isOpen: boolean) =>
              setAllstarModalState({
                ...allstarModalState,
                isOpen: isOpen ? ModalType.Report : ModalType.None,
              })
            }
            reportedClipId={allstarModalState?.data?._id}
            reportedUserId={allstarModalState?.data?.user?._id}
            isProfile={undefined}
          />
        </FloatingContainer>
      </Modal>
    </>
  );
};

// const TopFlex = styled(Flex)`
//   width: 100%;
//   height: 100%;
//   flex-direction: column;
//   justify-content: space-between;
//   position: relative;
// `;
