import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";

const GlobalStyles = createGlobalStyle`
  html {
    --Chalk-100: ${colors.chalk};
    --Gray-850: ${colors.gray850};
    --Grey-850: ${colors.grey850};
    --Ice-100: ${colors.ice};
  }
`;

export { GlobalStyles };
