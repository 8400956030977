import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

const Tooltip = (props) => {
  const parentRef = useRef(); // this ref is specific to the entire dropdown component. Used to detect clicks within it.
  const childRef = useRef(); // this ref is specific to the children passed into the drop down component. These children are most likely the first thing the user clicks to open the dropdown.
  const [isVisible, setIsVisible] = useState(
    props.isVisible ? props.isVisible : false,
  );

  const action = props.type === "hover" ? "mouseover" : "click";

  const handleAction = ({ target }) => {
    if (parentRef?.current?.contains(target)) {
      if (
        childRef?.current?.contains(target) &&
        childRef?.current?.state &&
        action === "click"
      ) {
        return setIsVisible(false); // If we clicked the child component, and the dropdown is visible. Hide it.
      }
      return setIsVisible(true);
    }

    setIsVisible(false);
  };

  useEffect(() => {
    childRef.current.state = isVisible;
  }, [isVisible]);

  useEffect(() => {
    window.addEventListener(action, handleAction);
    return () => {
      window.removeEventListener(action, handleAction);
    };
  }, [action]); //eslint-disable-line

  //added this to allow for outside control of hiding dropdown
  useEffect(() => {
    setIsVisible((state) => {
      return state ? props.isVisible : state;
    });
  }, [props.isVisible]);

  useEffect(() => {
    if (action !== "click" || !isVisible || !props.timeout) return;
    const timer = setTimeout(() => {
      setIsVisible(!isVisible);
    }, 3000);
    return () => clearTimeout(timer);
  }, [action, props.timeout, isVisible]);

  return (
    <TooltipContainer
      ref={parentRef}
      className={props.className}
      // onClick={(e) => {
      //   e.stopPropagation(); // This doesnt block the event listener we assign above
      // }}
      width={props.width ? props.width : ""}
    >
      <Box
        ref={(ref) => {
          childRef.current = ref;
        }}
      >
        {" "}
        {props.children}
      </Box>

      {isVisible && (
        <ArrowDropdown
          variant={props.theme}
          position={props.position}
          align={props.align}
          wrapText={props.wrapText}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {props.tooltipContent}
        </ArrowDropdown>
      )}
    </TooltipContainer>
  );
};

const TooltipContainer = styled(Box)`
  position: relative;
`;

const ArrowDropdown = styled(Flex)`
  position: absolute;
  z-index: 10;
  white-space: ${(props) => (props.wrapText ? false : "nowrap")};
  ${(props) => props.position}
`;

Tooltip.defaultProps = {
  theme: "menuDropdown",
  align: "right",
  type: "hover",
  wrapText: false,
  timeout: false,
};

export { Tooltip };
