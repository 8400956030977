import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { space, color } from "styled-system";
import styled from "styled-components";

const StyledIcon = styled(FaFacebookF).attrs((props) => ({
  size: props.size,
}))((props) => props.styles, space, color);

const FacebookIcon = (props) => {
  return <StyledIcon {...props} />;
};

FacebookIcon.defaultProps = {
  size: 22,
  styles: {},
};

export default FacebookIcon;
