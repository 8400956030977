import React from "react";
import { Link } from "../link";
import styled from "styled-components";
import theme from "../../../theme/themeThree";
import { Box } from "rebass/styled-components";

import EmailButton from "./button/email";

let flexibleProps = {
  width: (props) => (props.flex ? false : "100%"),
};

const StyledLink = styled(Link)({ ...flexibleProps });

const StyledButton = styled.button({ ...flexibleProps });

const Button = (props) => {
  return (
    <Box
      as={props.to || props.href ? StyledLink : StyledButton}
      sx={{ ...theme.button, ...theme.buttons[props.classification] }}
      variant={`variants.button.${props.size}`}
      {...props}
    >
      {props.children}
    </Box>
  );
};

Button.defaultProps = {
  size: "default",
  classification: "main",
};

export { Button,  };
