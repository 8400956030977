import React, { useState } from "react";
import { FloatingContainer } from "../../Wrappers";
import { Flex, Image, Box, Text } from "rebass/styled-components";
import { Title1, Headline } from "../../Text";
import { ShareContents } from "../../ShareOptions";
import { useInterval } from "../../../../hooks";
import styled from "styled-components";
import saveEdit from "../../../../animations/saveEdit.json";
import Lottie from "lottie-react";
import { Divider } from "../../Divider";
import { YouTubeShare } from "../../Button/PlatformShare/Youtube";
import { TikTokUpload } from "../../Button/PlatformShare/TikTok";

const lottieStyle = {
  height: 80,
};

const StyledThumb = styled(Image)`
  display: flex;
  position: absolute;
  top: -155px;
  object-fit: contain;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 20px 20px rgb(0 0 0 / 15%);
  color: #fff;
  max-width: 410px;
  height: auto;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    max-width: 280px;
    top: -70px;
  }
`;

const StyledTitle1 = styled(Title1)`
  font-weight: 500;
`;

const StyledBox = styled(Box)`
  display: flex;
  position: absolute;
  top: -130px;
  background-color: ${(props) => {
    return props.theme.colors.ned;
  }};
  width: 410px;
  height: 230px;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.breaks.narrow}) {
    width: 280px;
    height: 157px;
    top: -70px;
  }
`;

export function ShareModal({
  clip,
  shareLink,
}: {
  clip: any;
  shareLink?: string;
}) {
  const [imageReady, setImageReady] = useState(!clip.fromEdit);
  const [imageSource, setImageSource] = useState(
    `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipSnapshot}`,
  );
  const doesNotHaveImage = !clip.clipSnapshot && imageReady;
  const isMontage = window.location.href.includes("montage");
  const hasMobile = Boolean(
    clip.mobile?.find(
      (x: any) =>
        x.aspectRatio === "9:16" && ["Processed", "Ready"].includes(x.status),
    ),
  );
  const clipLink = !isMontage
    ? `https://${window.location.hostname}/clip?clip=${clip.shareId}`
    : `https://${window.location.hostname}/montage?montage=${clip.shareId}`;

  let requestCount = 0;

  const checkData = async () => {
    const url = imageSource;

    try {
      const data = await fetch(url, {
        method: "HEAD",
      });
      requestCount += 1;
      if (data.status === 200) {
        setImageReady(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useInterval(() => {
    if (requestCount > 20 && !imageReady) {
      setImageSource(
        `${process.env.REACT_APP_S3_CLIP_BUCKET}/${clip.clipImageThumb}`,
      );
      setImageReady(true);
    }

    if (clip.fromEdit || imageReady) {
      if (!imageReady) {
        checkData();
      }
    }
  }, 1000);

  return (
    <>
      <FloatingContainer maxWidth="500px" maxHeight="700px">
        {doesNotHaveImage ? (
          <></>
        ) : (
          <Flex
            mb={10}
            style={{ position: "relative", justifyContent: "center" }}
          >
            {imageReady ? (
              <StyledThumb src={imageSource} />
            ) : (
              <StyledBox justifyContent="center" alignItems={"center"}>
                <Flex flexDirection="column">
                  <Lottie
                    animationData={saveEdit}
                    style={lottieStyle}
                    loop={true}
                  />
                  <Headline mt={3} color="chalk">
                    Updating your clip thumbnail
                  </Headline>
                </Flex>
              </StyledBox>
            )}
          </Flex>
        )}
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="flex-start"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            name="clip-share-modal"
          >
            <StyledTitle1 mb={5}>Share with your friends</StyledTitle1>
            <ShareContents clip={clip} shareLink={shareLink}></ShareContents>
          </Flex>
        </Flex>
      </FloatingContainer>
    </>
  );
}
