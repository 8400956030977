import { ESupportedGames } from "../../../@types";

export type TProcessingProps = {
  approxTimeToComplete: number;
  localKey: ETransitStatusTypes;
  game: ESupportedGames;
  clipTitle: string;
  trackId: string;
  isMobile?: boolean;
  spectating?: boolean;
  clipStatus?: string;
};

export enum ETransitStatusTypes {
  Error = "TRANSIT_TIME_ERROR",
  ClipError = "TRANSIT_TIME_CLIP_ERROR",
  Slow = "TRANSIT_TIME_BEHIND_PREDICTION",
  SlowFreeUser = "TRANSIT_TIME_FREE_LP_Q",
  UnknownClip = "TRANSIT_TIME_UNKNOWN_CLIP",
  Completed = "TRANSIT_TIME_COMPLETED",
  Good = "TRANSIT_TIME_GOOD",
  Queued = "TRANSIT_TIME_CLIP_QUEUE",
}
