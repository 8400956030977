import React from "react";
import { Flex, Box } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Body, Footnote, Headline, Subheader } from "../Text";
import { URLS } from "../../Utilities/Maps";
import { MenuDivider } from "../Divider";
import { StripePaystationState } from "../../State";
import { MTX_Items } from "../Modals/MTX/Product/montages";

interface IMontageCreditsProps {
  externalHandler?: () => void;
}

export const MontageCredits = ({ externalHandler }: IMontageCreditsProps) => {
  const history = useHistory();
  const [paystation, setPaystationOpen] = useRecoilState(StripePaystationState);

  return (
    <CreditsDropdown>
      <DropdownTopSection>
        <HeaderText>Top up your Montage Credits</HeaderText>
        <SubheaderText>
          <LinkText
            variant={"link.form"}
            onClick={() => {
              if (externalHandler) externalHandler();
              history.push(URLS.UPGRADE);
            }}
          >
            Upgrade your account
          </LinkText>
          or buy credits.
        </SubheaderText>
        <StyledMenuDivider />
      </DropdownTopSection>
      <DropdownBottomSection>
        {MTX_Items.map((item) => {
          return (
            <CreditRow key={item.lookup}>
              <NumberOfCreditsText>{item.quantity} Credits</NumberOfCreditsText>
              <PricingContainer>
                <CreditsButtonContainer
                  highlight={item.highlight}
                  onClick={() => {
                    if (externalHandler) externalHandler();

                    setPaystationOpen({
                      ...paystation,
                      isModalOpen: true,
                      type: "purchase",
                      lookUp: item.lookup,
                      returnUrl: window.location.pathname,
                    });
                  }}
                >
                  <PriceText>${item.cost}</PriceText>
                </CreditsButtonContainer>
                <Box style={{ height: "15px" }}>
                  <SubheaderText>{item.discount}</SubheaderText>
                </Box>
              </PricingContainer>
            </CreditRow>
          );
        })}
      </DropdownBottomSection>
      <DropdownTopSection>
        <StyledMenuDivider />
        <StyledButtonContainer
          onClick={() => {
            if (externalHandler) externalHandler();

            history.push(URLS.MONTAGE);
          }}
        >
          Use credits
        </StyledButtonContainer>
      </DropdownTopSection>
    </CreditsDropdown>
  );
};

const StyledMenuDivider = styled(MenuDivider)`
  width: 100%;
`;

const CreditsDropdown = styled(Flex)`
  color: ${(props) => props.theme.colors.ice};
  flex-direction: column;
  padding: 19px;
  white-space: nowrap;
`;

const HeaderText = styled(Headline)`
  color: ${(props) => props.theme.colors.ice};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const SubheaderText = styled(Footnote)`
  color: ${(props) => props.theme.colors.chalk};
  line-height: 1.5em;
  max-width: 200px;
  text-align: center;
`;

const LinkText = styled(Subheader)`
  color: ${(props) => props.theme.colors.ice};
  display: inline;
  padding-bottom: 1px;

  &:hover {
    cursor: pointer;
  }
`;

const CreditRow = styled(Flex)`
  align-items: start;
  justify-content: space-between;
`;

const StyledButtonContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.turboAlpha20};
  border-radius: 99px;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  justify-content: center;
  min-width: 75px;
  padding-left: 18px;
  padding-right: 18px;
  text-align: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const CreditsButtonContainer = styled(Flex)<{ highlight?: boolean }>`
  background-color: ${(props) => props.theme.colors.navy};
  border-radius: 99px;
  flex-direction: column;
  height: 35px;
  justify-content: center;
  text-align: center;
  width: 75px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.turboAlpha10};
    ${(props) => {
      if (props.highlight) {
        return "background: linear-gradient(226.68deg, #71D1DD 12.99%, #71ABEE 43.08%, .7);";
      }
    }}
  }

  ${(props) => {
    if (props.highlight) {
      return "background: linear-gradient(226.68deg, #71D1DD 12.99%, #71ABEE 43.08%);";
    }
  }}
`;

const PricingContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const DropdownTopSection = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
`;

const NumberOfCreditsText = styled(Body)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding-top: 7px;
`;

const PriceText = styled(Body)`
  font-size: 14px;
  font-weight: 600;
`;

const DropdownBottomSection = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`;
