import React from "react";
import { Button } from "../../button";
import { HiOutlineMail } from "react-icons/hi";
import { luminance } from "../../../../../utils";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: #f7fcfd;

  &:hover {
    background: ${luminance("#F7FCFD", -0.1)};
  }
`;

const StyledEmail = styled(HiOutlineMail)`
  color: #5d7687;
  vertical-align: middle;
  position: relative;
  top: -1px;
  padding-right: 2px;
  margin-right: ${(props) =>
    props.mr ? `${props.theme.space[props.mr]}px` : null};
`;

export default function EmailSocialButton(props) {
  return (
    <StyledButton {...props}>
      <StyledEmail size={24} mr={!!props.children ? 3 : null} />
      {props.children}
    </StyledButton>
  );
}
