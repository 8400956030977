import React, { useEffect } from "react";
import { Body } from "../../components/text";
import Legal from "./index";
import styled from "styled-components";
import Toggle from "../../components/atoms/toggle";
import { Box, Flex } from "rebass/styled-components";
import { Divider } from "../../components/atoms/divider";
import { Link } from "../../components/atoms/link";
import { useMutation } from "@apollo/client";
import { EDIT_CCPA } from "../../MVVM/GraphQL/editCCPA";
import { useUser } from "../../MVVM/Hooks/useUser";

const StyledBody = styled(Body)`
  line-height: 1.444;
  font-size: 1.125rem;
`;

const ToggleShow = ({ onClick, isToggled }) => {
  return (
    <Flex
      my={8}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <StyledBody>
        <strong>Do not sell my personal information</strong>
      </StyledBody>

      <Box px={4}>
        <Toggle
          onChange={(e) => {
            onClick({ isToggled: e });
          }}
          checked={isToggled}
        />
      </Box>
    </Flex>
  );
};

function LocationToggle({ onClick, isToggled }) {
  return (
    <Flex flexDirection={"column"} alignItems="flex-start">
      <Flex>
        {/* Show page for users in California */}
        <ToggleShow onClick={onClick} isToggled={isToggled} />
      </Flex>
    </Flex>
  );
}

export default function Information() {
  const { allstarUser, getFreshProfile, logout } = useUser();

  const [editCCPA] = useMutation(EDIT_CCPA, {
    onError: (err) => {
      console.log(err);
    },
    onCompleted: async () => {
      try {
        await getFreshProfile();
      } catch (e) {
        logout();
      }
    },
  });

  const toggleCCPA = ({ isToggled }) => {
    editCCPA({
      variables: {
        ccpaPrivacyPreference: isToggled,
      },
    });
  };

  useEffect(() => {
    window.rudderanalytics.page("Legal", "Do not sell info");
  });

  return (
    <Legal>
      <StyledBody>Last updated on January 5th, 2023</StyledBody>
      <div
        style={{ paddingBottom: 100 }}
        data-testid="legal/do-not-sell-my-personal-information"
      >
        <StyledBody>&nbsp;</StyledBody>
        <StyledBody>
          <strong>Allstar Gaming, Inc.</strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          <strong>
            <u>Do not sell my personal information</u>
          </strong>
        </StyledBody>
        <br></br>
        <StyledBody>
          Your rights under the California Consumer Privacy Act
        </StyledBody>
        <br></br>
        <StyledBody>
          The California Consumer Privacy Act (CCPA) provides you with rights
          regarding how your data or personal information is treated. Under the
          legislation, California residents can choose to opt out of the “sale”
          of their personal information to third parties. Based on the CCPA
          definition, “sale” refers to data collection for the purpose of
          creating advertising and other communications.{" "}
          <a href="https://oag.ca.gov/privacy/ccpa">
            Learn more about CCPA and your privacy rights.
          </a>
        </StyledBody>
        <br></br>
        {allstarUser?.loggedIn && (
          <>
            {" "}
            <StyledBody>
              <strong>
                <u>How to opt out</u>
              </strong>
            </StyledBody>
            <br></br>
            <StyledBody>
              By clicking on the toggle below, we will no longer collect or sell
              your personal information. This applies to both third-parties and
              the data we collect to help personalize your experience on our
              website or through other communications. For more information,
              view our <Link to="/legal/privacy-policy">privacy policy.</Link>
            </StyledBody>
            {/* {EMAIL_CONTEXTS.map((context, i, array) => {
          return ( */}
            <Divider mt={8}></Divider>
            <LocationToggle
              onClick={toggleCCPA}
              isToggled={allstarUser?.user?.profile?.ccpaPrivacyPreference}
            />
          </>
        )}
      </div>
    </Legal>
  );
}
