import React from "react";
import Switch from "react-switch";
import styled from "styled-components";

export const Toggle = (props: any) => {
  let offHandleColor = props.disabled ? "#4F6672" : "#374E5D";
  let offColor = props.offColor ? props.offColor : "#2C4354";

  return (
    <>
      <StyledSwitch
        offColor={offColor}
        onColor="#4DBD94"
        offHandleColor={offHandleColor}
        onHandleColor="#F7FCFD"
        onChange={props.onChange}
        checked={props.checked}
        height={props.height ?? undefined}
        width={props.width ?? undefined}
        uncheckedIcon={props.uncheckedIcon ?? false}
        checkedIcon={props.checkedIcon ?? false}
        boxShadow="1px 1px 5px rgba(12, 19, 24, .6)"
        activeBoxShadow="1px 1px 5px rgba(12, 19, 24, .1)"
        // variation="switch"
        disabled={props.disabled}
      />
    </>
  );
};

const StyledSwitch = styled(Switch)<any>``;
