import React from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";

let styles = {
  maxWidth: (props) => props.theme.paddedWidth,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
};

let StyledFlex = styled(Flex).attrs({ px: [4, 4, 8] })(styles);
let StyledBox = styled(Box).attrs({ px: [4, 4, 8] })(styles);

const PaddedWrapper = (props) => {
  let { display, passedRef, ...rest } = props;
  let El = display === "box" ? StyledBox : StyledFlex;

  return (
    <El ref={passedRef} {...rest}>
      {props.children}
    </El>
  );
};

PaddedWrapper.defaultProps = {
  display: "flex",
};

export { PaddedWrapper };
