import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Body } from "../../Components/Text";
import { FullScreenWrapper } from "../../Components/Wrappers";
import { FloatingContainer } from "../../Components/Wrappers";
import { FloatingContent } from "../../Components/Wrappers";
import { InputWithLabel } from "../../Components/Inputs";
import { Button } from "../../Components/Button";
import { Error } from "../../Components/Inputs";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useUser } from "../../Hooks/useUser";
import { Note } from "../../Components/Button";
import { validatePassword } from "../../Utilities/Password";

export function ResetPassword() {
  const [resetSuccess, setResetSuccess] = useState(false);

  return (
    <FullScreenWrapper>
      <FloatingContainer>
        {resetSuccess ? (
          <PasswordSuccess />
        ) : (
          <ResetForm setResetSuccess={setResetSuccess} />
        )}
      </FloatingContainer>
    </FullScreenWrapper>
  );
}

function PasswordSuccess() {
  useEffect(() => {
    window.rudderanalytics.track("Password Reset - Success");
  });
  return (
    <FloatingContent title="Password created!">
      <Body mb={4}>Your password has been set successfully.</Body>
      <Note>Redirecting you to login...</Note>
    </FloatingContent>
  );
}

function ResetForm({
  setResetSuccess,
}: {
  setResetSuccess: Dispatch<SetStateAction<boolean>>;
}) {
  const { allstarUser, logout } = useUser();
  const [state, setState] = useState({
    loading: false,
    error: false,
    errorMsg: "",
  });
  const token = useTokenQuery();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = async (form: any) => {
    if (state.loading) {
      return;
    }

    if (form.password !== form.password_confirm) {
      return setState({
        ...state,
        error: true,
        errorMsg: "Passwords must match",
      });
    }

    setState({
      ...state,
      loading: true,
    });

    const opt: any = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/users/reset/password`,
      data: {
        ...form,
        token,
      },
    };

    try {
      const response = await Axios(opt);
      if (response.data.status === "ok") {
        setResetSuccess(true);
        const location = "/login";

        if (allstarUser?.loggedIn) {
          logout("Password Reset");
        } else {
          return (window.location.href = location);
        }
      }
      setState({
        ...state,
        error: true,
      });
    } catch (e: any) {
      setState({
        ...state,
        loading: false,
        error: true,
        errorMsg: e.response.data.msg,
      });
    }
  };

  useEffect(() => {
    window.rudderanalytics.page("Password Reset");
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWithLabel
        input={{
          id: "password",
          type: "password",
          placeholder: "Password",
          ...register("password", {
            required: true,
            minLength: {
              value: 8,
              message: "Minimum 8 characters",
            },
            validate: (value) => validatePassword(value),
          }),
        }}
        error={errors?.password}
        label="Password"
        applySpace={true}
      />
      <InputWithLabel
        input={{
          id: "password_confirm",
          type: "password",
          placeholder: "Re-type Password",
          ...register("password_confirm", {
            required: true,
            minLength: {
              value: 8,
              message: "Minimum 8 characters",
            },
            validate: (value) => validatePassword(value),
          }),
        }}
        error={errors?.password_confirm}
        label="Re-type Password"
        applySpace={true}
      />
      {state.error && state.errorMsg ? <Error>{state.errorMsg}</Error> : <></>}
      <Button disabled={state.loading} type="submit">
        {state.loading ? "Submitting..." : "Submit"}
      </Button>
    </form>
  );
}

function useTokenQuery() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  return token;
}
