import React, { useState } from "react";
import {
  AllPlansButton,
  BuyButton,
  Card,
  CardBelow,
  CardGrid,
  CardTitle,
  MonthText,
  ProText,
  StyledBadge,
  StyledRightArrow,
  SubTextStrike,
} from "../styledComponents";

import { Link } from "../../../Button";
import { Flex } from "rebass/styled-components";
import savePro from "../../../../Assets/Promotions/savePro.svg";
import savePlat from "../../../../Assets/Promotions/savePlat.svg";
import { useRecoilState } from "recoil";
import { StripePaystationState } from "../../../../State";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import {
  StyledPercentOffContainer,
  StyledPlatinumBadge,
  StyledProBadge,
} from "./styledComponents";

interface UpgradeCta {
  price: string;
  hasPromo: boolean;
  planName: string;
  planSubText: string;
  pcntOff?: string;
  PlanIcon: React.ElementType;
}

const getPcntOffSvg = (subTier: string) => {
  switch (subTier) {
    case "PRO":
      return savePro;
    case "CREATOR_PLAT":
      return savePlat;
    default:
      return savePro;
  }
};

export const UpgradeCta = () => {
  const [paystation, setPaystationOpen] = useRecoilState(StripePaystationState);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  // We need to temp block promoSession during the transition,
  // until we get promo working with stripe
  // const [promoSession] = useRecoilState(PromoSession);

  const [lookups] = useState<any>({
    PRO: "pro_monthly",
    CREATOR_PLAT: "plat_monthly",
  });

  const [upgradeCtaState] = useState<UpgradeCta>(() => {
    switch (allstarModalState?.data?.requiredScope) {
      case "PRO":
        return {
          price: "3.99",
          hasPromo: false,
          planName: "Pro",
          planSubText: "MONTHLY PLAN STARTING FROM",
          PlanIcon: StyledProBadge,
        };

      case "CREATOR_PLAT":
        return {
          price: "24.99",
          hasPromo: false,
          planName: "Creator Platinum",
          planSubText: "MONTHLY PLAN STARTING FROM",
          PlanIcon: StyledPlatinumBadge,
        };

      default:
        return {
          price: "3.99",
          hasPromo: false,
          planName: "Allstar Pro",
          planSubText: "MONTHLY PLAN STARTING FROM",
          PlanIcon: StyledProBadge,
        };
    }
  });

  return (
    <>
      <CardGrid flexDirection={"row"} flexWrap={"wrap"}>
        <Card>
          {upgradeCtaState?.pcntOff && (
            <>
              <StyledPercentOffContainer>
                {upgradeCtaState?.pcntOff}
              </StyledPercentOffContainer>
              <StyledBadge
                src={getPcntOffSvg(allstarModalState?.data?.requiredScope)}
              />
            </>
          )}
          {
            <upgradeCtaState.PlanIcon
              style={{ width: "20px", height: "20px" }}
            />
          }
          <CardTitle>{upgradeCtaState.planName}</CardTitle>
          <SubTextStrike>{upgradeCtaState.planSubText}</SubTextStrike>
          <Flex alignContent="flex-end" alignItems="flex-end" mb={2}>
            <CardTitle>${upgradeCtaState.price}</CardTitle>
            <MonthText>/mo</MonthText>
          </Flex>

          <BuyButton
            onClick={(e) => {
              window.rudderanalytics.track("Paywall - Button Click", {
                type: "upgradeButton",
                feature: allstarModalState?.data?.type,
              });

              setPaystationOpen({
                ...paystation,
                isModalOpen: true,
                type: "subscribe",
                lookUp: allstarModalState?.data?.requiredScope
                  ? lookups[allstarModalState?.data?.requiredScope]
                  : lookups.PRO,
                // return the user to the exact page they are on:
                returnUrl: window.location.pathname,
              });

              setAllstarModalState({
                isOpen: ModalType.None,
                data: {},
                functions: {},
              });
              e.stopPropagation();
            }}
          >
            Upgrade
          </BuyButton>
        </Card>

        <CardBelow>
          <ProText>Remixes - Unlimited</ProText>
          <ProText>Mobile Clips - Unlimited</ProText>
          <ProText>Remove Watermarks</ProText>
        </CardBelow>
      </CardGrid>
      {!allstarModalState?.data?.isFtue && (
        <AllPlansButton>
          <Link
            to="/upgrade"
            onClick={() => {
              window.rudderanalytics.track("Paywall - Button Click", {
                type: "seeAllPlans",
                feature: allstarModalState?.data?.type,
              });
              setAllstarModalState({
                isOpen: ModalType.None,
                data: {},
                functions: {},
              });
            }}
          >
            See all plans
          </Link>
          <StyledRightArrow />
        </AllPlansButton>
      )}
    </>
  );
};
