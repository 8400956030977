import React, { forwardRef } from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

import { HiOutlineDocumentAdd } from "react-icons/hi";
import { Body } from "../../Text";

const Overlay = forwardRef((_, ref) => (
  <OverlayActive data-testid="overlay-active">
    <OverlayMask ref={ref} />
    <StyledIcon />
    <Body color="envy" fontSize="4" fontWeight={500}>
      Drop anywhere here
    </Body>
  </OverlayActive>
));

const OverlayMask = styled(Box)`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;
`;

const OverlayActive = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.envy};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 80px 60px;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

const StyledIcon = styled(HiOutlineDocumentAdd)`
  color: ${({ theme }) => theme.colors.envy};
  flex-shrink: 0;
  height: 48px;
  width: 48px;
`;

export { Overlay };
