import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Box } from "rebass";
import { StudioSession } from "../../../../State/studioSession";
import { ReactComponent as Add } from "../../../../../assets/images/studio/add-mark.svg";
import { ReactComponent as Remove } from "../../../../../assets/images/studio/remove-mark.svg";
import { ReactComponent as Checked } from "../../../../../assets/images/studio/check-mark-fill.svg";
import { ReactComponent as HoverAdd } from "../../../../../assets/images/studio/hover-add-mark.svg";
import styled from "styled-components";

interface IAddCardProps {
  card: any;
  handleToggle: (card: any, isToggled: any) => Boolean;
  size?: number;
  handleModalClose?: any;
  isActive?: boolean;
  outsideControl?: boolean;
}

export const AddCard = ({
  card,
  handleToggle,
  // size,
  handleModalClose,
  isActive, // this enables control of the toggle from parents. whereas studio uses session state to control the toggle
  outsideControl = false,
}: IAddCardProps) => {
  const studioSession = useRecoilValue(StudioSession);

  const [isHovered, setIsHovered] = useState(false);

  const [checked, setChecked] = useState(
    studioSession.userActivated?.some((x: any) => x._id === card._id) ||
      isActive
  );
  const handleChange = (nextChecked: any) => {
    const toggleAllowed = handleToggle(card, nextChecked);
    setIsHovered(false);

    if (toggleAllowed) {
      setChecked(nextChecked);
    } else {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    setChecked(
      studioSession.userActivated?.some((x: any) => x._id === card._id)
    );
  }, [studioSession]); //eslint-disable-line

  useEffect(() => {
    if (outsideControl) {
      setChecked(isActive);
    }
  }, [isActive]); //eslint-disable-line

  const AddOrRemove = () => {
    if (checked) {
      return (
        <>
          <CheckedBox
            onClick={(e: any) => {
              handleChange(false);
              e.stopPropagation();
            }}
            // size={size}
          >
            {isHovered ? <StyledRemove /> : <StyledChecked />}
          </CheckedBox>
        </>
      );
    } else
      return (
        <>
          <AddBox
            onClick={(e: any) => {
              handleChange(true);
              e.stopPropagation();
            }}
            // size={size}
          >
            {isHovered ? <StyledHoverAdd /> : <StyledAdd />}
          </AddBox>
        </>
      );
  };

  return (
    <Box
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      padding="2px"
    >
      <AddOrRemove />
    </Box>
  );
};

const StyledAdd = styled(Add)<any>`
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const StyledRemove = styled(Remove)<any>`
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const AddBox = styled(Box)<any>`
  display: flex;
`;

const CheckedBox = styled(Box)<any>`
  display: flex;
`;

const StyledChecked = styled(Checked)<any>`
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;

const StyledHoverAdd = styled(HoverAdd)<any>`
  cursor: pointer;
  height: ${(props: any) => {
    return props.size ?? "28";
  }}px;
  width: ${(props: any) => {
    return props.size ?? "28";
  }}px;
`;
