import React from "react";
import styled from "styled-components";
import { Body } from "../../../Text";
import { ReactComponent as Pro } from "../../../../../assets/images/badges/pro-badge.svg";
import { Flex } from "rebass";
import { Link } from "../../../Button";
import { useRecoilState } from "recoil";
import { MontageSession } from "../../../../State";
import { EView } from "../../../../Views/CreateMontage";

export const ProLoadout = () => {
  const [montageSession] = useRecoilState(MontageSession);
  return (
    <>
      {" "}
      <Flex flexDirection={"row"}>
        {" "}
        <Badge
          onClick={() => {
            try {
              window.rudderanalytics.track("Pro Tooltip Click", {
                view: EView[montageSession.view],
              });
            } catch {}

            window.open("https://allstar.gg/upgrade", "_blank")?.focus();
          }}
        />
        <StyledBody width={200}>
          You need a{" "}
          <Link
            variant="link.title"
            onClick={() => {
              try {
                window.rudderanalytics.track("Pro Tooltip Click", {
                  view: EView[montageSession.view],
                });
              } catch {}
              window.open("https://allstar.gg/upgrade", "_blank")?.focus();
            }}
          >
            Pro
          </Link>{" "}
          account to select a Loadout
        </StyledBody>
      </Flex>
    </>
  );
};

const Badge = styled(Pro)<any>`
  top: 0%;
  right: ${(props: any) => {
    return 3 + 10 * props.index;
  }}%;
  position: absolute;
  text-align: center;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;

const StyledBody = styled(Body)<any>`
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
  padding-left: 50px;
`;
