import React from "react";
import styled from "styled-components";
import { Box, Flex, Image } from "rebass";
import SVG from "react-inlinesvg";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { TRequirement, TSequence, TSequenceProps } from "../../@types";
import { Body, Title3 } from "../../../../Components/Text";

import {
  ComingSoon,
  NoMontagesLeft,
} from "../../../../Components/DisabledMontage";
import { useRecoilValue } from "recoil";
import { MontageSession } from "../../../../State";

export const Sequence = ({
  sequence,
  qualify,
  selected,
  onSelect,
  setModal,
  disabledFromQualify,
}: // proRequired,
TSequenceProps) => {
  const montageSession = useRecoilValue(MontageSession);
  const disabled = disabledFromQualify || Boolean(sequence.comingSoon);

  // console.log({ qualify, disabled, montageSession });
  const Overlay = () => {
    if (qualify !== montageSession.sequence.qualify) return <></>;

    switch (true) {
      case Boolean(sequence.comingSoon):
        return (
          <DisabledOverlay>
            <ComingSoon />
          </DisabledOverlay>
        );

      case disabledFromQualify:
        return (
          <DisabledOverlay>
            <NoMontagesLeft
              isFreeUser={montageSession.isFreeUser}
              disabledFromQualify={disabledFromQualify}
            />
          </DisabledOverlay>
        );
      default:
        return <></>;
    }
  };

  return (
    <SequenceContainer
      flexDirection={["column", "column", "column", "row"]}
      onClick={() => {
        if (disabled) return;
        onSelect(
          selected?.qualify !== sequence.qualify ? sequence : ({} as TSequence)
        );
      }}
      selected={selected?.qualify === sequence.qualify}
      disabled={disabled}
      mb={3}
    >
      <Overlay />

      <ContentWrapper disabled={disabled}>
        <Flex flexDirection={["column", "column", "column", "row"]}>
          <SequenceImage
            src={`${process.env.REACT_APP_S3_CLIP_BUCKET}/${sequence.image}`}
            onClick={(e) => {
              if (disabled) return;
              setModal({ isOpen: true, sequence: sequence });
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <DetailContainer mx={[0, 0, 0, 3]} my={[3, 3, 3, 0]}>
            <Flex>
              <SequenceTitle>{sequence.sequenceType} </SequenceTitle>
              {/* {proRequired && (
                <StyledPro pl={2}>
                  <ProLabel />{" "}
                </StyledPro>
              )} */}
            </Flex>
            <SequenceDesc>{sequence.description} </SequenceDesc>
            <SequenceReq>Requires</SequenceReq>
            {sequence.requires.map((requirement: TRequirement) => {
              return (
                <Requirement key={requirement.text}>
                  <StyledSVG src={decodeURIComponent(requirement.icon)} />
                  <SequenceDesc>{requirement.text}</SequenceDesc>
                </Requirement>
              );
            })}
          </DetailContainer>
        </Flex>
      </ContentWrapper>
      <Add selected={selected?.qualify === sequence.qualify}>
        <StyledAddIcon selected={selected?.qualify === sequence.qualify} />
      </Add>
    </SequenceContainer>
  );
};

const ContentWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
  ${(props) => {
    return props.disabled ? "filter: blur(4px);" : ``;
  }}
`;
const DisabledOverlay = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(34, 50, 62, 0.8);
  border: 2px solid rgba(34, 50, 62);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 16px;
`;
const StyledAddIcon = styled(HiOutlinePlusCircle)`
  width: 24px;
  height: 24px;
  ${(props: { selected: boolean }) =>
    props.selected
      ? ` 
    color: #FFFFF;
    `
      : `color: rgba(113, 170, 255, .5);`};
`;

const SequenceTitle = styled(Title3)`
  font-weight: 700;
  line-height: 28px;
`;

const SequenceDesc = styled(Body)`
  text-align: left;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.iceAlpha75};
`;

const SequenceReq = styled(Body)`
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.ice};
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    margin-bottom: 5px;
  }
`;

const Add = styled.button`
  all: unset;
  display: flex;
  transition: 0.3s ease;
  ${(props: { selected: boolean }) =>
    props.selected
      ? ` 
    background: #71AAFF;
    `
      : `background: rgba(113, 170, 255, .1);`};
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 65px;
  border-radius: 13px;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    height: 100%;
    width: 45px;
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    height: 55px;
    width: 100%;
    display: flex;
  }
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    height: 50px;
    width: 100%;
  }
`;

const SequenceContainer = styled(Flex)`
  padding: 20px;

  max-height: 100%;
  max-width: 100%;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;

  background: rgba(24, 32, 40, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  border: 2px solid transparent;
  padding: 15px;
  transition: 0.3s ease;
  ${(props: { selected: boolean }) =>
    props.selected
      ? ` 
      cursor: pointer;
      border: 2px solid rgba(113, 170, 255, .75);
      box-shadow: 0px 0px 15px rgba(113, 170, 255, .3);
      background: #151C23;
      `
      : ""};

  ${(props) => {
    return props.disabled
      ? ""
      : `  &:hover {
        cursor: pointer;
        background: #151c23;
      }
      &:hover ${Add} {
        background: ${props.theme.colors.turbo};
      }
      &:hover ${StyledAddIcon} {
        color: ${props.theme.colors.ice};
      }`;
  }}
`;

const DetailContainer = styled(Flex)`
  flex-direction: column;
  justify-content: start;
  align-content: start;
  align-items: start;
  width: 300px;
  max-width: 100%;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breaks.standard}) {
    width: 300px;
  }
`;

const StyledSVG = styled(SVG)`
  vertical-align: middle;
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const Requirement = styled(Flex)`
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`;

const SequenceImage = styled(Image)`
  align-self: center;
  display: flex;
  max-height: 330px;
  max-width: 100%;
  border-radius: 10px;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    max-height: 440px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    max-height: 180px;
  }
`;
