import React from "react";
import { FaPlay } from "react-icons/fa";
import { Box } from "rebass/styled-components";
import styled from "styled-components";

const PlayCircle = styled(Box)`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 6px;
  border-style: solid;
  border-radius: 50%;
  border-color: ${(props) => props.theme.colors.midnightAlpha90};
`;

const PlayContainer = styled(Box)`
  ${(props) => (props.size ? { ...props.theme.play[props.size] } : null)}

  &:hover ${PlayCircle} {
    ${(props) => ({ ...props.theme.play.spectrum[":hover"] })}
  }
`;

const PlayIcon = styled(FaPlay)`
  width: 43.75%;
  height: 43.75%;
  position: absolute;
  top: 50%;
  left: 56.25%;
  transform: translateX(-50%) translateY(-50%);
`;

export function Play(props) {
  return (
    <PlayContainer variant="play" size={props.size} {...props}>
      <PlayCircle variant="play.spectrum" />
      <PlayIcon />
    </PlayContainer>
  );
}
