import React from "react";
import { Box } from "rebass/styled-components";

export default function Body(props) {
  return (
    <Box variant="text.body" {...props}>
      {props.children}
    </Box>
  );
}
