import React, { Key, useState } from "react";
import { Flex } from "rebass";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { Title2 } from "../../../../Components/Text";
import { MontageSession } from "../../../../State";
import { TSequence, TSequenceData, TSequenceModalState } from "../../@types";
import { SequenceModal } from "../../Modal/Sequence";
import { Sequence } from "../Sequence";

export const SequenceSelector: React.FC<TSequenceData> = ({
  sequenceData,
  sequencesLoading,
  checkSequenceQualify,
}) => {
  const [montageSession, setMontageSession] = useRecoilState(MontageSession);
  const [modal, setModal] = useState<TSequenceModalState>({
    isOpen: false,
    sequence: sequenceData?.sequences[0], // just default to the one passed
  });

  const onSelect = (sequence: TSequence) => {
    setMontageSession({
      ...montageSession,
      sequence: sequence,
      checkedQualify: false,
    });
  };

  if (sequencesLoading) return <Loading />;

  return (
    <Sequences>
      <SequenceModal
        setOpen={setModal}
        isOpen={modal.isOpen}
        sequence={modal.sequence}
      />
      <Flex justifyContent={"flex-start"} flexDirection={"column"} width="100%">
        <Heading>Sequence Type</Heading>

        {sequenceData?.sequences?.map((sequence: TSequence) => {
          const isSelected =
            sequence.qualify === montageSession.sequence.qualify;

          return (
            <Sequence
              key={sequence.sequenceType as Key}
              qualifyFunction={checkSequenceQualify}
              qualify={sequence.qualify}
              sequence={sequence}
              selected={montageSession.sequence}
              onSelect={onSelect}
              setModal={setModal}
              disabledFromQualify={
                isSelected &&
                !montageSession.qualify &&
                montageSession.checkedQualify
              }
            />
          );
        })}
      </Flex>
    </Sequences>
  );
};

const Loading = () => {
  return (
    <Sequences>
      <Flex justifyContent={"flex-start"} width="100%" pb={20}>
        <LoadingSkeleton
          width={"150px"}
          height={"28px"}
          style={{ borderRadius: "8px" }}
        />
      </Flex>
      <Flex pb={10} width="100%">
        <LoadingSkeleton
          count={1}
          width={"100%"}
          height={"360px"}
          style={{ borderRadius: "20px", marginBottom: "10px" }}
          containerClassName="flex-grow-1"
        />
      </Flex>
    </Sequences>
  );
};

const Heading = styled(Title2)`
  align-self: flex-start;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
`;

const Sequences = styled(Flex)`
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
