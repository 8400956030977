import React from "react";
import { Flex } from "rebass/styled-components";
import { RemixedClip } from "../../../../../components/molecules/clip/remixedClip";
import { useUser } from "../../../../Hooks/useUser";

function ClipRemix({
  clip,
  classification,
  followUnfollow,
  setFollowModal,
  alignment,
  displayMeta = true,
  style = {},
}) {
  const showRemixBadge = clip?.remix?.isRemixed && displayMeta;
  const { allstarUser } = useUser();
  const isOwnRemix = clip?.remix?.originalUser === clip?.user?._id;
  const clone = clip?.remix?.clonedVersion;

  if (clone || isOwnRemix) return <></>;

  return (
    <>
      <Flex
        alignContent={alignment ? [alignment] : "flex-start"}
        justifyContent={alignment ? alignment : "flex-start"}
      >
        {showRemixBadge && (
          <RemixedClip
            clip={clip}
            allstarUser={allstarUser}
            followUnfollow={followUnfollow}
            setFollowModal={setFollowModal}
            style={style}
          />
        )}
      </Flex>
    </>
  );
}

ClipRemix.defaultProps = {
  height: "2em",
  mt: null,
  loading: false,
  views: 0,
  onClick: () => {},
};

export { ClipRemix };
