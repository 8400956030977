import React from "react";
import { Link } from "../link";
import styled from "styled-components";

const StyledLink = styled(Link)`
  ${(props) => props.theme.text.subheader}
`;

export function FormLink(props) {
  return (
    <StyledLink variant="link.form" {...props}>
      {props.children}
    </StyledLink>
  );
}
