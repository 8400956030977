import React from "react";
import { useRecoilState } from "recoil";

import { ClipFullModalState } from "../../../State/modals";
import ClipFull from "../../../Views/ClipFull";
import { Modal } from "../../Wrappers";
import { ModalType } from "../../../@types";
import { StyledFloatingContainer, CloseIcon } from "./styledComponents";
import Close from "../../../../assets/images/floatingannouncement/close.svg";

const ClipModal = () => {
  const [clipFullModalState, setClipFullModalState] =
    useRecoilState(ClipFullModalState);
  const { isOpen, shareId } = clipFullModalState;
  const closeModal = () =>
    setClipFullModalState({
      ...clipFullModalState,
      isOpen: ModalType.None,
    });

  if (isOpen !== ModalType.ClipFull) return <></>;
  return (
    <Modal
      isModalOpen={isOpen === ModalType.ClipFull}
      setModalOpen={() => null}
      handleModalClose={closeModal}
      disableOutsideClose={undefined}
      style={{ overflow: "auto" }}
    >
      <StyledFloatingContainer>
        <CloseIcon size={28} src={Close} onClick={() => closeModal()} />
        {shareId && (
          <ClipFull
            shareId={shareId}
            modalView={true}
            closeModal={closeModal}
          />
        )}
      </StyledFloatingContainer>
    </Modal>
  );
};

export { ClipModal };
