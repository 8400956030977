import { gql } from "@apollo/client";

export const REQUEST_UPLOAD_MATCH = gql`
  mutation RequestUploadMatch($request: RequestUpload!) {
    requestUploadMatch(request: $request) {
      status
      message
      uploadMatchId
      presignedUrl
      matchId
    }
  }
`;

export const MATCH_UPLOAD_UPDATE = gql`
  mutation MatchUploadUpdate(
    $matchUploadId: String!
    $status: MatchUploadInputStatus
  ) {
    matchUploadUpdate(matchUploadId: $matchUploadId, status: $status) {
      success
    }
  }
`;
