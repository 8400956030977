import React from "react";

import { AndroidInterstitial } from "../../../MVVM/Components/AndroidInterstitial";
import { ClipModal } from "../../../MVVM/Components/Modals/Clip";
import { ClipModals } from "../../../MVVM/Components/Modals/ClipModals";
import { CreditsModal } from "../../../MVVM/Components/Modals/Credits";
import { CrossSitePromotion } from "../../../MVVM/Views/CrossSitePromotion";
import { DebugModal } from "../../../MVVM/Components/Modals/DebugModal";
import { DemoUploaderModal } from "../../../MVVM/Components/Modals/DemoUploader/";
import { ECrossSitePromotionType } from "../../../MVVM/@types";
import { MatchHistoryModals } from "../../../MVVM/Components/Modals/MatchHistory";
import { MicroTransactions } from "../../../MVVM/Components/Modals/MTX";
import { MusicGenreSelectorModal } from "../../../MVVM/Components/Modals/MusicGenreSelector";
import { Paywall } from "../../../MVVM/Components/Modals/Paywall";
import { RemoveConflictingCardsModal } from "../../../MVVM/Components/Modals/RemoveConflictingCards";
import { SignatureSelectorModal } from "../../../MVVM/Components/Modals/SignatureSelector";
import { SignupModal } from "../../../MVVM/Components/Modals/Signup";
import { SimpleClipViewModal } from "../../../MVVM/Components/Modals/SimpleClipViewer";
import { StripePayStation } from "../../../MVVM/Components/Modals/StripePaystation";
import { StudioFTUEModal } from "../../../MVVM/Components/Modals/StudioFTUE";
import { TransitTimeModal } from "../../../MVVM/Components/Modals/TransitTime";
import { YoutubeUnavailable } from "../../../MVVM/Components/Modals";
import { SetRiotRegionModal } from "../Modals/SetRiotRegion";
import { Box } from "rebass/styled-components";

const ModalManager = () => (
  <Box id="modal-root">
    <AndroidInterstitial />
    <ClipModal />
    <ClipModals />
    <CreditsModal />
    <CrossSitePromotion type={ECrossSitePromotionType.FirstLandTakeover} />
    <DebugModal />
    <DemoUploaderModal />
    <MatchHistoryModals />
    <MicroTransactions />
    <MusicGenreSelectorModal />
    <Paywall />
    <RemoveConflictingCardsModal />
    <SignatureSelectorModal />
    <SignupModal />
    <SimpleClipViewModal />
    <StripePayStation />
    <StudioFTUEModal />
    <TransitTimeModal />
    <YoutubeUnavailable />
    <SetRiotRegionModal />
  </Box>
);

export { ModalManager };
