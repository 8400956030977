import { useMutation, useQuery } from "@apollo/client";

import { CLIP_FULL_MODAL, USER_DATA } from "../../GraphQL/clipFullModal";
import { MUTATE_REACTION } from "../../GraphQL/addReaction";
import { useMutateFollowers } from "../../Hooks/useMutateFollowers";
import { Clip } from "../../Model/Clip";

interface IClipFullViewModel {
  clipId?: string;
}

const ClipFullViewModel = ({ clipId }: IClipFullViewModel) => {
  const { mutateFollowers } = useMutateFollowers();
  const clipFunctions = Clip.getInstance();
  const editTitle = clipFunctions.Title;
  const { data, loading } = useQuery(CLIP_FULL_MODAL, {
    fetchPolicy: "network-only",
    variables: { clipIdentifier: clipId },
  });

  const [mutateReaction] = useMutation(MUTATE_REACTION);

  const userId = data?.getClip?.user?._id;

  const { data: userData, loading: userDataLoading } = useQuery(USER_DATA, {
    skip: !userId,
    fetchPolicy: "network-only",
    variables: { user: userId, limit: 10, search: "popular" },
  });

  return {
    data,
    mutateFollowers,
    loading,
    mutateReaction,
    userData,
    userDataLoading,
    editTitle,
  };
};

export { ClipFullViewModel };
