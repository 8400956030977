import React from "react";
import styled from "styled-components";
import { ReactComponent as Pro } from "../../../../Assets/CreateMontage/pro.svg";
import { Flex } from "rebass";
import { Button } from "../../../../Components/Button";
import { Body } from "../../../../Components/Text";
import Sparkles from "../../../../../animations/sparkles.json";
import Lottie from "lottie-react";
import { useRecoilState } from "recoil";
import { MontageSession } from "../../../../State";
import { EView } from "../..";

const lottieStyle = {
  height: 90,
};

export const Upgrade = () => {
  const [montageSession] = useRecoilState(MontageSession);

  return (
    <Container>
      <StyledTopFlex>
        <Lottie animationData={Sparkles} style={lottieStyle} loop={true} />
        <ProSvg />
      </StyledTopFlex>
      <StyledBody mb={4} mt={2}>
        Upgrade to Choose Clips & Loadouts
      </StyledBody>
      <UpgradeButton
        size="tiny"
        to="/upgrade"
        onClick={() => {
          window.rudderanalytics.track("Montage Upgrade Button Click", {
            view: EView[montageSession.view],
          });
        }}
      >
        Upgrade
      </UpgradeButton>
    </Container>
  );
};

const StyledTopFlex = styled(Flex)`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-height: 90px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const ProSvg = styled(Pro)`
  position: absolute;
  width: 90px;
  z-index: 2000;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    position: relative;
  }
`;

const StyledBody = styled(Body)`
  font-weight: 600;
`;

const Container = styled(Flex)`
  display: flex;
  text-align: center;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  left: 30px;
  background: rgba(77, 189, 148, 0.05);
  border: 1px solid rgba(77, 189, 148, 0.3);
  border-radius: 16px;
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    left: 25px;
    bottom: 25px;
  }
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const UpgradeButton = styled(Button)``;
