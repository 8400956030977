import React from "react";
import { Flex } from "rebass/styled-components";
import styled from "styled-components";

type TGrid = {
  gap?: number;
  paddingBottom?: number;
  columnGrid?: number;
  columnGrid1600?: number;
  children:
    | JSX.Element
    | JSX.Element[]
    | Element
    | Element[]
    | undefined
    | null;
};

/**
 * Component for wrapping items in a adaptable grid,
 * Main use case is Clips and Clip Thumbnails.
 *
 * @public
 * @type {JSX.Element<TGrid>}
 * @param {number} paddingBottom Add padding to the bottom of the Grid
 * @param {number} columnGrid Defaults to 330, grid-template-columns Min Max Value of the Grid.
 * @param {number} columnGrid1600 Defaults to 336, grid-template-columns @ 1600 min width, Min Max Value of the Grid.
 * @param {number} children The JSX.Elements to wrap in the grid.
 * @returns {React.ReactElement} A fancy grid container
 * @example
 * return (
 *   <Grid paddingBottom={280}>
 *     {array.map(x => {
 *          return <Item>{x}</Item>
 *      })}
 *   </Grid>
 * );
 * @version 1.0.0
 * @since 1.0.0
 */

export const Grid = ({
  children,
  columnGrid,
  columnGrid1600,
  paddingBottom,
  gap,
}: TGrid) => {
  return (
    <GridFlex
      gap={gap || 24}
      column={columnGrid || 330}
      column1600={columnGrid1600 || 336}
      paddingBottom={paddingBottom || 20}
    >
      {children}
    </GridFlex>
  );
};

interface IGridFlex {
  gap: number;
  paddingBottom: number;
  column: number;
  column1600: number;
}

const GridFlex = styled(Flex)<IGridFlex>`
  width: 100%;
  display: grid;
  grid-gap: ${(props) => props.gap}px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.column}px, 1fr)
  );
  list-style: none;
  overflow-y: scroll;
  max-height: 100vh;
  @media (min-width: 1600px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(${(props) => props.column1600}px, 1fr)
    );
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
