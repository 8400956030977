import React from "react";
import { Button } from "../button";
import { HiOutlineTrash } from "react-icons/hi";
import styled from "styled-components";

const StyledRemove = styled(HiOutlineTrash)`
  position: relative;
  top: 7px;
`;

export default function ClipDelete(props) {
  return (
    <Button {...props}>
      <StyledRemove size={18}></StyledRemove>

      {props.children}
    </Button>
  );
}
