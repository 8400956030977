import React from "react";
import { Large3, Subheader } from "../text";
import { Box } from "rebass/styled-components";
import theme from "../../theme/themeThree";
import { Image } from "rebass/styled-components";

export function FloatingContent(props) {
  return (
    <>
      {props.image ? <Image src={props.image} /> : null}
      {props.title ? (
        <Large3 mb={theme.margins.large3} mt={props.mt ? props.mt : null}>
          {props.title}
        </Large3>
      ) : (
        <></>
      )}
      {props.sub ? (
        <Subheader mb={theme.margins.large3} mt={props.mt ? props.mt : null}>
          {props.sub}
        </Subheader>
      ) : (
        <></>
      )}
      {props.body ? <Box mb={theme.margins.body}>{props.body}</Box> : <></>}
      {props.children}
    </>
  );
}
