import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Headline, Title1 } from "../Text";

export const HeaderContainer = styled(Flex)`
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    min-height: 135px;
  }
`;
export const StyledHeader = styled(Title1)`
  color: ${(props) => props.theme.colors.ice};
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 5px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    font-size: 30px;
    text-align: start;
  }
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: 25px;
    text-align: start;
  }
`;

export const StyledSubheader = styled(Headline)`
  color: ${(props) => props.theme.colors.chalk};
  text-align: start;
  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    font-size: 20px;
  }
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: 16px;
  }
`;
