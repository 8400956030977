import React, { useEffect, useRef, useCallback } from "react";
import { ModalContainer, ModalDialog } from "./styledComponents";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { HiOutlineX } from "react-icons/hi";

export const Modal = ({
  isModalOpen,
  setModalOpen,
  children,
  handleModalClose,
  disableOutsideClose,
}: {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  children: React.ReactNode;
  handleModalClose?: () => void;
  disableOutsideClose?: boolean;
}) => {
  const node = useRef<HTMLElement | null>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (node.current && node.current.isSameNode(target)) {
        if (disableOutsideClose) return;
        if (handleModalClose) handleModalClose();

        return setModalOpen(false);
      }
    },
    [setModalOpen, handleModalClose, disableOutsideClose],
  );

  useEffect(() => {
    const currentNode = node.current;
    if (currentNode) {
      currentNode.addEventListener("mousedown", handleClick);
    }
    return () => {
      if (currentNode) {
        currentNode.removeEventListener("mousedown", handleClick);
      }
    };
  }, [handleClick]);

  useEffect(() => {
    if (isModalOpen && document.body.scrollHeight > window.innerHeight) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.overflowY = "scroll";
    } else {
      document.body.style.position = "static";
      document.body.style.overflowY = "none";
    }
    return () => {
      document.body.style.position = "static";
      document.body.style.overflowY = "none";
    };
  }, [isModalOpen]);

  if (!isModalOpen) return <></>;
  return (
    <ModalContainer ref={node} key={`modal-${node}`}>
      <ModalDialog>
        <ModalContentWrapper>
          <CloseButtonWrapper>
            <CloseButton
              onClick={() => {
                setModalOpen(false);
              }}
            />
          </CloseButtonWrapper>

          {children}
        </ModalContentWrapper>
      </ModalDialog>
    </ModalContainer>
  );
};
const ModalContentWrapper = styled(Box)`
  position: relative;
`;

const CloseButtonWrapper = styled(Box)`
  display: flex;
  position: absolute;
  width: 30px;
  height: 30px;
  right: -10px;
  top: -10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.navy};
  transition: background 0.1s;
  z-index: 1;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.darkerNavy};
  }
`;
const CloseButton = styled(HiOutlineX)`
  width: 15px;
  height: 15px;
  color: ${(props) => {
    return props.theme.colors.ice;
  }};
`;
