import React, { DragEvent, useEffect, useState } from "react";
import styled from "styled-components";

interface IUploadDragAndDropProps {
  children: React.ReactNode;
  handleDrop: (file?: File) => void;
  overlay: React.ReactNode;
  overlayRef: React.RefObject<HTMLElement>;
}

const DragAndDrop = ({
  children,
  handleDrop,
  overlay,
  overlayRef,
}: IUploadDragAndDropProps) => {
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const handleDragOverEvent = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnterEvent = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target !== overlayRef.current) setDrag(true);
  };

  const handleDragLeaveEvent = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target === overlayRef.current) setDrag(false);
  };

  const handleDropEvent = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setFile(event.dataTransfer?.files[0]);

    setDrag(false);
  };

  useEffect(() => {
    handleDrop(file);
  }, [file, handleDrop]);

  return (
    <UploadWrapper
      onDragOver={handleDragOverEvent}
      onDragEnter={handleDragEnterEvent}
      onDragLeave={handleDragLeaveEvent}
      onDrop={handleDropEvent}
    >
      {drag && overlay}
      {children}
    </UploadWrapper>
  );
};

const UploadWrapper = styled.div`
  position: relative;
`;

export { DragAndDrop };
