import React, { memo } from "react";
import styled from "styled-components";
import { Image, Flex } from "rebass/styled-components";
import { Title1, Headline, Large3 } from "../../../components/text";
import { StaticContainer } from "../../../components/wrappers/staticContainer";
import { PaddedWrapper } from "../../../components/wrappers/paddedWrapper";

import ImageGameplay from "../../../assets/images/home/fps/Gameplay.png";
import ImageDiscord from "../../../assets/images/home/fps/Discord.png";
import ImageDownload from "../../../assets/images/home/fps/Download.png";

const ContentColumn = styled(PaddedWrapper)`
  position: relative;
  width: 50%;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
`;

const Row = styled(Flex)`
  width: 100%;
  position: relative;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const StyledTitle = styled(Large3)`
  color: ${(props) => props.theme.colors.ice};
`;

const BlockTitle = styled(Title1)`
  font-weight: 500;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${(props) => props.theme.text.title1.fontSize};
  }
`;

const StyledHeadline = styled(Headline)`
  font-weight: normal;
  line-height: 1.35em;
`;

const Copy = styled(StyledHeadline)``;

const StepImage = styled(Image)`
  width: 500px;
  height: 268px;
  max-width: 500px;
  object-fit: contain;
  margin-bottom: ${(props) => props.theme.space[7]}px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    max-width: 100%;
  }
`;

const MobileIcon = styled.div`
  margin-right: ${(props) => props.theme.space[5]}px;
  position: absolute;
  left: calc(38px + ${(props) => props.theme.space[6]}px * -1);
  flex-shrink: 0;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    position: static;
    vertical-align: middle;
    margin-right: ${(props) => props.theme.space[3]}px;
    margin-bottom: ${(props) => props.theme.space[2]}px;
  }
`;

const ContentBlock = styled.div`
  padding-left: calc(38px + ${(props) => props.theme.space[6]}px);
  max-width: 520px;
  margin-top: ${(props) => props.theme.space[5]}px;
  position: relative;

  ${BlockTitle} {
    margin-bottom: ${(props) => props.theme.space[5]}px;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin: auto;
    justify-items: center;
    padding-left: 0;

    ${BlockTitle} {
      text-align: center;
    }
  }
`;

const StyledStaticContainer = styled(StaticContainer)`
  position: relative;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    text-align: center;
  }
`;

const Steps = memo(
  () => {
    return (
      <StyledStaticContainer py={9}>
        <PaddedWrapper flexDirection="column" alignItems="center">
          <StyledTitle mb={[7, 10, 10]}>Start Capturing Now</StyledTitle>
          <Row mb={[9, 9, 10]}>
            <ContentColumn justifyContent="center" flexShrink="0">
              <StepImage src={ImageDownload} />
            </ContentColumn>
            <ContentColumn justifyContent="flex-start">
              <ContentBlock>
                <BlockTitle>Download the Windows App</BlockTitle>
                <Copy>
                  Once you've downloaded the app, you can set a hotkey, view the
                  clip process in the activity feed, share or download your clip
                  and finely tune your processing speeds.
                </Copy>
              </ContentBlock>
            </ContentColumn>
          </Row>

          <Row mb={[9, 9, 10]}>
            <ContentColumn justifyContent="center" flexShrink="0">
              <StepImage src={ImageDiscord} />
            </ContentColumn>
            <ContentColumn justifyContent="flex-start">
              <ContentBlock>
                <BlockTitle>Join our Discord</BlockTitle>
                <Copy>
                  Our awesome Discord community (36,000+ gamers strong!) is
                  where our community lives. Our support team is there 24/7 to
                  help with any questions as well as hosting weekly giveaways,
                  leaderboards and a heap more.
                </Copy>
              </ContentBlock>
            </ContentColumn>
          </Row>

          <Row mb={[9, 9, 10]}>
            <ContentColumn justifyContent="center" flexShrink="0">
              <StepImage src={ImageGameplay} />
            </ContentColumn>
            <ContentColumn justifyContent="flex-start">
              <ContentBlock>
                <BlockTitle>Just type !allstar (or use a hotkey)</BlockTitle>
                <Copy>
                  Allstar will do the heavy lifting. We'll trim, tag, title, add
                  any Studio Creator Cards you select and upload your perfect
                  quality clip, all in the cloud, with no hit on your FPS.
                </Copy>
              </ContentBlock>
            </ContentColumn>
          </Row>
        </PaddedWrapper>
      </StyledStaticContainer>
    );
  },
  () => true,
);

export default Steps;
