import React, { memo, useEffect } from "react";
import { Large3, Title3 } from "../../components/text";
import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import EventOne from "../../assets/images/culture/eventOne.jpg";
import EventTwo from "../../assets/images/culture/eventTwo.jpg";
import EventThree from "../../assets/images/culture/eventThree.jpg";
import EventFour from "../../assets/images/culture/eventFour.jpg";

const Freq = styled(Flex)`
  background-color: ${(props) => props.theme.colors.midnightAlpha40};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.envy};
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 9px;
  font-weight: 500;
`;

const HomeContainer = styled(Flex)`
  margin: auto;
  max-width: ${(props) => props.theme.sizes[7]}px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column;
  }
`;

const ImageContainer = styled(Flex)`
  position: relative;
  width: 500px;
  max-width: 100%;
  height: 500px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 300px;
    max-width: 100%;
    height: 300px;
  }
`;

const PicOne = styled(Image)`
  position: absolute;
  top: 50px;
  right: 240px;
  height: 165px;
  width: 236px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    display: none;
  }
`;

const PicTwo = styled(Image)`
  position: absolute;
  top: 5px;
  right: 20px;
  height: 295px;
  width: 186px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    right: 10px;
  }
`;

const PicThree = styled(Image)`
  position: absolute;
  top: 280px;
  right: -30px;
  height: 195px;
  width: 308px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const PicFour = styled(Image)`
  position: absolute;
  top: 200px;
  right: 200px;
  width: 308px;
  height: 216px;
  transition: 0.8s;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    transform: scale(1.1);
    transform: translateY(-30px);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    right: 100px;
    top: 100px;
  }
`;

const Events = memo(() => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://embedsocial.com/cdn/ht.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <HomeContainer
        pb={["500px", "500px", "500px", "0px"]}
        alignItems="center"
      >
        <Flex
          flex="1"
          alignItems="flex-start"
          flexDirection="column"
          pr={[0, 0, 0, 8]}
        >
          {" "}
          <Freq mb={4}>Annual Event</Freq>
          <Flex flexDirection="column">
            <Large3 mb={6}>Allstar Get Together </Large3>
            <Title3 color="chalk" mb={8}>
              As we have a distributed team, we get together in person once a
              quarter for our Global Allstar Meetup. We spend this time hacking,
              hanging out, getting to know each other on a personal level and
              having fun. We hope to see you at the next one!
            </Title3>
          </Flex>
        </Flex>
        <ImageContainer flex="1">
          <PicTwo src={EventTwo}></PicTwo>
          <PicFour src={EventFour}></PicFour>
          <PicOne src={EventOne}></PicOne>
          <PicThree src={EventThree}></PicThree>
        </ImageContainer>
      </HomeContainer>
    </>
  );
});

export default Events;
