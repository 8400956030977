import React, { useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { Flex } from "rebass/styled-components";
import Lottie from "lottie-react";

import { Subheader } from "../../Text";
import { useFetch } from "../../../../hooks";
import { remixModal } from "../../../../globalStorage/remix";
import { useRemixButton } from "../../../Hooks/useRemixButton";
import { FloatingContainer } from "../../Wrappers";
import { getClipTitle } from "../../../Utilities/Clip";
import DefaultCardsRemixClip from "./Components/DefaultCardsButton";
import { useUser } from "../../../Hooks/useUser";
import { ReactComponent as Customize } from "../../../Assets/Studio/Customize.svg";
import { ReactComponent as Surprise } from "../../../Assets/Studio/Surprise.svg";
import Cards from "../../../Assets/Studio/remixModal.png";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import RemixProcessing from "./RemixProcessing";
import { RemixModalViewModel } from "./ViewModel";
import loading from "../../../../animations/loading.json";
import {
  RemixModalImage,
  StyledButton,
  StyledContainer,
  StyledFootnote,
  StyledTitle3,
} from "./styledComponents";
import { SignatureSelectorState } from "../../../State/loadoutSelector";

const API_URL = process.env.REACT_APP_API_URL;

export function RemixModal({ clip, onRemixError, closeModal, ...props }: any) {
  const [remixQueued, setRemixQueued] = useState(false);
  const { allstarUser } = useUser();
  const remixButton = useRemixButton();
  const { fetch, query }: any = useFetch(undefined, true);
  const [, setModalOpen] = useRecoilState(remixModal);
  const title = getClipTitle(clip);
  const { myCreatorCards, loading: myCreatorCardsLoading } =
    RemixModalViewModel();
  const [signatureState] = useRecoilState(SignatureSelectorState);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  if (remixQueued)
    return (
      <RemixProcessing
        closeModal={() => {
          closeModal();
        }}
      />
    );

  if (query.status === "success") {
    setRemixQueued(true);
    setModalOpen(true);
  }

  if (query?.error?.version === 2) onRemixError();

  if (!myCreatorCards || myCreatorCardsLoading)
    return (
      <Lottie
        animationData={loading}
        style={{ height: 28, width: 28 }}
        loop={true}
      />
    );

  return (
    <>
      <StyledFloatingContainerTwo
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        maxWidth="525px"
      >
        <>
          <RemixModalImage src={Cards} />
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Flex mt={5} flexDirection="column">
              <StyledTitle3 mb={6} textAlign="center">
                Remix {title}
              </StyledTitle3>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Flex
              flexDirection={["row"]}
              justifyContent="space-between"
              alignItems="center"
            >
              <StyledContainer
                justifyContent="space-around"
                alignItems="center"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <StyledButton
                    justifyContent="space-between"
                    classification="actionIcons"
                    alignItems="center"
                    disabled={query.status === "loading"}
                    onClick={async () => {
                      if (!allstarUser?.loggedIn) {
                        return setAllstarModalState({
                          ...allstarModalState,
                          isOpen: ModalType.Signup,
                          data: {
                            action: "signup",
                          },
                        });
                      }
                      window.rudderanalytics.track(
                        "remix surprise me click",
                        {},
                      );
                      fetch({
                        method: "POST",
                        url: `${API_URL}/items/clip/remix?queue=true`,
                        data: {
                          surpriseMe: true,
                          _id: clip._id,
                        },
                      });
                    }}
                    {...props}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Surprise />
                    </Flex>
                    <Subheader mt={3}>Surprise me!</Subheader>
                    <StyledFootnote>
                      We'll add fun effects to your clip
                    </StyledFootnote>
                  </StyledButton>
                </Flex>
              </StyledContainer>
              <DefaultCardsRemixClip
                clip={clip}
                disabled={
                  !myCreatorCards.length && !signatureState?.mySignature?.active
                }
                setRemixQueued={setRemixQueued}
                checked={false}
              ></DefaultCardsRemixClip>
              <StyledContainer
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <StyledButton
                  justifyContent="center"
                  textAlign="center"
                  classification="actionIcons"
                  alignItems="center"
                  onClick={() => {
                    window.rudderanalytics.track("remix customize click", {});
                    setModalOpen(true);
                    remixButton(clip.shareId);
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Customize />
                  </Flex>
                  <Subheader mt={3}>Customize Clip</Subheader>
                  <StyledFootnote>Choose from over 300+ effects</StyledFootnote>
                </StyledButton>
              </StyledContainer>
            </Flex>
          </Flex>
        </>
      </StyledFloatingContainerTwo>
    </>
  );
}
const StyledFloatingContainerTwo = styled(FloatingContainer)`
  display: flex;
  flex-direction: column;
  max-width: 525px;
  position: relative;
`;
