import React, { useEffect, useState } from "react";
import { Flex, Box } from "rebass/styled-components";
import InlineEdit from "../InlineEdit";
import bad from "./bad.json";

export function ClipTitleEdit({
  title,
  save,
  titleError,
  setTitleError,
  clip,
  ...props
}) {
  const [value, setValue] = useState(title);
  const [defaultValue] = useState(title);

  useEffect(() => {
    // ensure we are not rerendering edit.js
    console.count(`Edit.ClipTitleEdit`);
  }, []);

  useEffect(() => {
    setValue(title);
  }, [title]);

  function onBlur(e) {
    if (e.target.value.trim() === "") {
      setValue(value);
    } else {
      setValue(e.target.value);
    }
  }

  function onChange(e) {
    let trimmedVal = e.target.value.trim();
    const RegExp =
      /^[a-zA-Z0-9!$?',][a-zA-Z0-9-_ !$?',]{1,40}[a-zA-Z0-9!$?',]$/gi;
    const regexTitle = RegExp.exec(trimmedVal);
    const un = trimmedVal.toLowerCase();
    setTitleError(!regexTitle);

    for (const badword of bad) {
      if (un.includes(badword)) {
        setTitleError(true);
      }
    }

    setValue(e.target.value);
    save(e.target.value);
  }

  return (
    <Flex mt={props.mt} alignItems={props.align || "center"}>
      <Box height={props.height ? props.height : null}>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          my={[2, 4, 5]}
          onClick={() => {}}
        >
          <InlineEdit
            value={value}
            placeholder={defaultValue}
            setValue={setValue}
            onChange={onChange}
            onBlur={onBlur}
            titleError={titleError}
            autoFocus
          />

          {/* {titleError ? <Error>Sorry, try again</Error> : null} */}
        </Flex>
      </Box>
    </Flex>
  );
}
