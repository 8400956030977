import styled from "styled-components";
import { Box, Flex, Text } from "rebass";
import { Button } from "../../Components/Button";
import { FiGlobe } from "react-icons/fi";
import { AMark } from "../../../components/logo";

export const StyledFlex = styled(Flex)`
  background: ${({ theme }) => theme.colors.midnight};
  width: 100%;
  height: 210px;
  position: fixed;
  bottom: 0;
  z-index: 12;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 4px 20px;
`;

export const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.ice};
  font-family: Barlow;
  font-size: ${({ theme }) => theme.text.title2.fontSize};
  font-style: normal;
  font-weight: 700;
`;

export const StyledButton = styled(Button)`
  border-radius: 20px;
  height: 36px;
  width: 86px;
`;

export const StyledGlobe = styled(FiGlobe)`
  color: ${({ theme }) => theme.colors.turbo};
  height: 100%;
  width: 100%;
`;

export const StyledIconContainer = styled(Box)`
  border-radius: 8px;
  height: 46px;
  width: 46px;
  padding: 9px;
  background-color: ${({ theme }) => theme.colors.ned};
`;

export const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  font-weight: ${({ theme }) => theme.text.large3.fontWeight};
`;

export const StyledAMark = styled(AMark)`
  height: 100%;
  width: 100%;
`;
