import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Box, Image } from "rebass/styled-components";
import { PaddedWrapper } from "../wrappers/paddedWrapper";
import { Link as FooterLink } from "../atoms/footer/link";
import { Title as FooterTitle } from "../atoms/footer/title";
import GooglePlay from "../../MVVM/Assets/googlePlay.svg";
import { Link } from "../atoms/link";
import { FaInstagram, FaDiscord, FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiChevronDown } from "react-icons/fi";
import styled from "styled-components";
import { URLS } from "../../MVVM/Utilities/Maps";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
const Em = styled.span`
  color: ${(props) => props.theme.colors.envy};
  font-weight: 500;
`;
const StyledImage = styled(Image)`
  min-width: 40px;
`;
const HelpLinks = [
  {
    to: "/howitworks",
    title: "How Allstar works",
  },
  {
    to: "/feature/allstarstudio",
    title: "What is Allstar Studio",
  },
  {
    to: URLS.UPGRADE,
    title: "Subscriptions",
  },
  {
    to: "/faq",
    title: "FAQ",
  },
  {
    to: "/support",
    title: "Support Form",
  },
];
const CompanyLinks = [
  {
    to: "/about",
    title: "About Allstar",
  },
  {
    to: "/brand",
    title: "Brand",
  },
  {
    href: "/careers",
    title: (
      <>
        Careers - <Em>We're hiring!</Em>
      </>
    ),
  },
  {
    href: "https://allstar.canny.io/feature-requests",
    target: "_blank",
    rel: "noopener noreferrer",
    title: "Feature Requests",
  },
  {
    href: "https://allstar.canny.io/feature-requests?category=new-games&sort=top",
    title: "Game Requests",
  },
  {
    href: "/partners",
    title: "Partners",
  },
];

const ClipsLinks = [
  {
    to: "/clips?game=730",
    title: "CS:GO Clips",
  },
  {
    to: "/clips?game=570",
    title: "Dota 2 Clips",
  },
  {
    to: "/clips?game=101",
    title: "League of Legends Clips",
  },
  {
    to: "/clips?game=407",
    title: "Fortnite Clips",
  },
  {
    to: "/montages",
    title: "Montages",
  },
];

const LegalLinks = [
  {
    to: "/legal/privacy-policy",
    title: "Privacy Policy",
  },
  {
    to: "/legal/terms-of-use",
    title: "Terms of Use",
  },
  {
    to: "/legal/do-not-sell-my-personal-information",
    title: "Do not sell my personal information",
  },
  {
    to: "/legal/vulnerability-disclosure-policy",
    title: "Vulnerability Disclosure Policy",
  },
];

const MoreLinks = [
  {
    href: "https://discord.com/application-directory/580572502288498700",
    title: "AllstarBot",
  },
  {
    to: "/partners",
    title: "Partners",
  },
];

export const FollowLinks = [
  {
    href: "https://twitter.com/playsharestar",
    icon: <FaXTwitter size={30} color="white" />,
  },
  {
    href: "https://instagram.com/playsharestar",
    icon: <FaInstagram size={30} color="white" />,
  },
  {
    href: "https://www.youtube.com/playsharestar",
    icon: <FaYoutube size={30} color="white" />,
  },
  {
    href: "https://discord.gg/playsharestar",
    icon: <FaDiscord size={30} color="white" />,
  },
  {
    href: "https://www.facebook.com/PlayShareStar",
    icon: <FaFacebookF size={30} color="white" />,
  },
];

const FooterContainer = styled(Box)`
  z-index: 3;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: ${(props) => props.theme.space[5]}px;
    padding-top: ${(props) => props.theme.space[5]}px;
  }
`;

const Column = styled(Box)`
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-bottom: 1px solid ${(props) => props.theme.colors.navy};
    margin-top: ${(props) => props.theme.space[4]}px;
    margin-bottom: ${(props) => props.theme.space[2]}px;
  }
`;

const ResponsiveFooterTitle = styled(FooterTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-grow: 1;
  }
`;

const LinkContainer = styled(Link)`
  display: flex;
`;

const lastColumnWidth = "200px";

const LastColumn = styled(Box)`
  flex-basis: ${lastColumnWidth};
  flex-grow: 0;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: flex;
    flex-direction: column;

    margin-top: ${(props) => props.theme.space[6]}px;

    ${ResponsiveFooterTitle} {
      display: none;
    }
  }
`;

const FooterLinks = styled(Box)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: ${(props) => (props.open ? "block" : "none")};
    padding-bottom: ${(props) => props.theme.space[4]}px;

    a {
      display: block;
      padding-top: ${(props) => props.theme.space[3]}px;
      padding-bottom: ${(props) => props.theme.space[3]}px;
    }
  }
`;

const ResponsivePaddedWrapper = styled(PaddedWrapper)`
  padding-left: 140px;
  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-left: 140px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    padding-left: 15px;
  }
`;

const SocialLinks = styled(Box)`
  margin-bottom: 20px;
  display: flex;
  gap: 40px;
`;

const ResponsiveChevron = styled(FiChevronDown)`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: block;
    align-self: flex-end;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
  }
`;

const LogoImage = styled(Image)`
  width: ${(props) => (props.width ? props.width : "180px")};
`;

function FooterLinkRow({ index, link }) {
  return (
    <Box mb={[1, 1, 4]} key={index}>
      <FooterLink {...link}>{link.title}</FooterLink>
    </Box>
  );
}

function FooterTitleRow({ open, setOpen, children }) {
  return (
    <ResponsiveFooterTitle onClick={() => setOpen(!open)}>
      {children}
      <ResponsiveChevron size={22} open={open} />
    </ResponsiveFooterTitle>
  );
}

function Footer({ showModal, history }) {
  const [helpOpen, setHelpOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [clipsOpen, setClipsOpen] = useState(false);
  const [legalOpen, setLegalOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setHelpOpen(false);
      setCompanyOpen(false);
      setClipsOpen(false);
      setLegalOpen(false);
      setMoreOpen(false);
    });
    return unlisten;
  });

  return (
    <FooterContainer variant="footer">
      <ResponsivePaddedWrapper justifyContent="space-between">
        <Column>
          <FooterTitleRow setOpen={setHelpOpen} open={helpOpen}>
            Help
          </FooterTitleRow>
          <FooterLinks open={helpOpen}>
            {HelpLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>

        <Column>
          <FooterTitleRow setOpen={setCompanyOpen} open={companyOpen}>
            Company
          </FooterTitleRow>
          <FooterLinks open={companyOpen}>
            {CompanyLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>

        <Column>
          <FooterTitleRow setOpen={setClipsOpen} open={clipsOpen}>
            Clips & Montages
          </FooterTitleRow>
          <FooterLinks open={clipsOpen}>
            {ClipsLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>

        <Column>
          <FooterTitleRow setOpen={setLegalOpen} open={legalOpen}>
            Legal
          </FooterTitleRow>
          <FooterLinks open={legalOpen}>
            {LegalLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>

        <Column>
          <FooterTitleRow setOpen={setMoreOpen} open={moreOpen}>
            More
          </FooterTitleRow>
          <FooterLinks open={moreOpen}>
            {MoreLinks.map((link, index) => {
              return <FooterLinkRow index={index} key={index} link={link} />;
            })}
          </FooterLinks>
        </Column>

        <LastColumn>
          <ResponsiveFooterTitle>Social</ResponsiveFooterTitle>
          <SocialLinks>
            {FollowLinks.map((link, index) => {
              return (
                <FooterLink
                  style={{ width: "20px", height: "20px" }}
                  key={index}
                  index={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.href}
                >
                  {link.icon}
                </FooterLink>
              );
            })}
          </SocialLinks>{" "}
          <LinkContainer
            target="_blank"
            href={
              "https://play.google.com/apps/testing/com.androidapp.gg.allstar"
            }
          >
            <LogoImage src={GooglePlay} mt={4} width="180px" />
          </LinkContainer>
        </LastColumn>
      </ResponsivePaddedWrapper>
    </FooterContainer>
  );
}

export default withRouter(Footer);
