import { Route } from "react-router-dom";
import { lazy } from "react";

import { getChunkable } from "./utils";
import ResetRequest from "../pages/resetRequest/index";
import { EAuthenticationTypes } from "../MVVM/Views/Authentication/@types";
import { ResetPassword } from "../MVVM/Views/ResetPassword";

const LazyAuthenticate = lazy(() => import("../MVVM/Views/Authenticate"));
const LazyAuthentication = lazy(() => import("../MVVM/Views/Authentication"));
const LazyOauthPage = lazy(() => import("../MVVM/Views/Oauth"));

const authenticationPages = [
  <Route
    exact
    key="login"
    path="/login"
    render={getChunkable(LazyAuthentication, {
      authType: EAuthenticationTypes.LOGIN,
    })}
  />,
  <Route
    key="auth"
    path="/authenticate/:route"
    render={getChunkable(LazyAuthenticate)}
  />,
  <Route
    key="oauth"
    path="/oauth/:route"
    render={getChunkable(LazyOauthPage)}
  />,
  <Route
    exact
    key="register"
    path="/register"
    render={getChunkable(LazyAuthentication, {
      authType: EAuthenticationTypes.SIGNUP,
    })}
  />,
  <Route exact key="reset" path="/reset" component={ResetRequest} />,
  <Route
    exact
    key="reset-form"
    path="/reset/form"
    render={(props) => <ResetPassword {...props} />}
  />,
];

export { authenticationPages };
