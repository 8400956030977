import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useFetch } from "../../../../../hooks";
import { Modal } from "../../../Wrappers";
import { Flex } from "rebass";
import { Button } from "../../../Button";
import { Title3 } from "../../../Text";
import { FloatingContainer } from "../../../Wrappers";
import { trackClipSocialShare } from "../../../../Analytics";
import { getQueryParam } from "../../../../Utilities/QueryParam";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";

export function AlreadyPublished() {
  const { fetch, query }: any = useFetch(undefined, true);
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const onSubmit = (queue: any) => {
    trackClipSocialShare({
      platform: "TikTok",
      handler: fetch,
      clip: allstarModalState.data.clip,
      queue,
      aspectRatio: allstarModalState.data.aspectRatio,
    });

    return;
  };

  useEffect(() => {
    if (query.status === "success") {
      setAllstarModalState({
        ...allstarModalState,
        functions: {},
        isOpen: ModalType.Uploading,
      });
    }
  }, [query.status]);
  return (
    <>
      <Modal
        isModalOpen={
          allstarModalState.isOpen === ModalType.DuplicateUploadTikTok
        }
        setModalOpen={() => {
          setAllstarModalState({
            data: {},
            functions: {},
            isOpen: ModalType.None,
          });
        }}
        handleModalClose={() => {}}
        disableOutsideClose={false}
      >
        <FloatingContainer>
          <Title3 mb={5}>Clip already published.</Title3>
          {query.error?.url && (
            <p>
              <b>Upload Url:</b>{" "}
              <a
                href={query.error.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                {query.error.url}
              </a>
            </p>
          )}
          <Button
            onClick={() => {
              onSubmit(true);
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
          >
            Publish again
          </Button>
          <Button
            classification="subtle"
            size="small"
            onClick={() => {
              setAllstarModalState({
                data: {},
                functions: {},
                isOpen: ModalType.None,
              });
            }}
          >
            Cancel
          </Button>
        </FloatingContainer>
      </Modal>
    </>
  );
}
