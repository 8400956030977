import React, { memo } from "react";
import styled from "styled-components";
import { PaddedWrapper } from "../../../components/wrappers/paddedWrapper";
import { Headline, Large3 } from "../../../components/text";
import { Box, Flex } from "rebass/styled-components";
import { FiFilm } from "react-icons/fi";

const Film = styled(FiFilm).attrs(() => ({
  size: 45,
}))`
  color: ${(props) => props.theme.colors.envy};
`;

const ContentColumn = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-self: center;
  text-align: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    align-items: flex-start;
    text-align: left;
  }
`;

const StyledHeadline = styled(Headline)`
  font-weight: normal;
  line-height: 1.35em;
`;

const Clips = memo(() => {
  return (
    <PaddedWrapper flexDirection="column">
      <ContentColumn maxWidth={[6]}>
        <Box as={Film} mb={[4, 4, 5]} />
        <Large3 mb={[6, 6, 8]}>
          Perfect quality, every time, on any setup.
        </Large3>
        <StyledHeadline mb={[8, 8, 9]}>
          Allstar creates your content for you in the Cloud. We fine tune every
          single setting and option to make sure your content looks insane and
          at a professional level, no matter what your graphic settings are.
        </StyledHeadline>
      </ContentColumn>
    </PaddedWrapper>
  );
});

export default Clips;
