import { gql } from "@apollo/client";

export const MUTATE_PROFILE = gql`
  mutation EditProfile(
    $socials: SocialsTypeInput
    $email: String
    $countryCode: String
    $username: String
  ) {
    editProfile(
      socials: $socials
      email: $email
      countryCode: $countryCode
      username: $username
    )
  }
`;

export const MUTATE_AVATAR = gql`
  mutation Mutation($key: String) {
    avatar(key: $key)
  }
`;
