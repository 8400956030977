import { gql } from "@apollo/client";

export const RIOT_RSO_LINK = gql`
  query Query {
    riotRsoLink
  }
`;

export const VERIFY_AUTH = gql`
  mutation Mutation($input: AuthValidateInput!) {
    authValidate(input: $input) {
      errors {
        code
        field
        message
      }
      flow
      success
      token
    }
  }
`;

export const UPDATE_RIOT_REGION = gql`
  mutation Mutation($region: String!) {
    editRiotRegion(region: $region) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`;
