import { gql } from "@apollo/client";

export const FTUE_STATE_SYNC = gql`
  mutation Ftue($payload: FtueInput) {
    ftue(payload: $payload)
  }
`;

export const FTUE_STATE = gql`
  query Ftue {
    ftue {
      hasCompleted
      step
      stepHistory
      isCS
      gameSelection
    }
  }
`;
