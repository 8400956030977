import { useMutation } from "@apollo/client";
import { MUTATE_PROFILE } from "../../GraphQL/mutateUser";

export const AccountViewmodel = () => {
  const [mutateProfile, { data, loading, error }] = useMutation(MUTATE_PROFILE);
  return {
    mutateProfile,
    data,
    loading,
    error,
  };
};
