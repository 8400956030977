import React from "react";
import { Flex, Box } from "rebass/styled-components";
import styled from "styled-components";
import { HiInformationCircle, HiXCircle } from "react-icons/hi";
import { MdPhoneAndroid } from "react-icons/md";
import { useTranslation, Trans } from "react-i18next";
import { Body, Title2 } from "../../../../Components/Text";
import { Subheader } from "../../../Text";
import { ETransitStatusTypes, TProcessingProps } from "../../@types";
import { GameLogos } from "../../../Icons";
import { Link } from "../../../Button";
import { useUser } from "../../../../Hooks/useUser";
import { ESupportedGames } from "../../../../@types";
import Lottie from "lottie-react";
import Spectating from "../../../../../animations/fine-lines-loading.json";

const EXPECTED_ARRIVAL_STATUSES = [
  ETransitStatusTypes.Good,
  ETransitStatusTypes.SlowFreeUser,
  ETransitStatusTypes.Slow,
];
const SLOW_STATUSES = [
  ETransitStatusTypes.Slow,
  ETransitStatusTypes.SlowFreeUser,
];
const ERROR_STATUSES = [
  ETransitStatusTypes.Error,
  ETransitStatusTypes.ClipError,
  ETransitStatusTypes.UnknownClip,
];

export function Processing({
  approxTimeToComplete,
  localKey,
  game,
  clipTitle,
  trackId,
  isMobile,
  spectating,
}: TProcessingProps) {
  const isSlow = SLOW_STATUSES.includes(localKey);
  const isExpectedToArrive = EXPECTED_ARRIVAL_STATUSES.includes(localKey);
  const isError = ERROR_STATUSES.includes(localKey);
  const minutesToCompletion = isExpectedToArrive
    ? Math.ceil(approxTimeToComplete / 60000)
    : 0;
  const expectedDeliveryTimePassed = minutesToCompletion <= 0;

  if (spectating) {
    return (
      <SpectatingContent flexDirection="column" maxWidth="100%">
        <ResponsiveLottie animationData={Spectating} loop={true} />
        <Flex ml={2} mr={2} justifyContent={"center"} alignItems={"center"}>
          <StyledTitle2>{clipTitle}</StyledTitle2>
        </Flex>
        {isExpectedToArrive && !expectedDeliveryTimePassed && (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Body color="chalk">Clip Ready in</Body>
            <StyledBody ml={2} color={isSlow ? "error" : "envy"}>
              {getTimeDisplay(minutesToCompletion)}
            </StyledBody>
          </Flex>
        )}
      </SpectatingContent>
    );
  } else {
    return (
      <ProcessingContent flexDirection="column" maxWidth="100%">
        <StyledBox flex={1}>
          <Flex
            justifyContent={["space-between"]}
            alignItems={["flex-start", "flex-start", "center"]}
            flexDirection={["column", "column", "row"]}
          >
            <HeaderFlex
              flexDirection={"column"}
              width={["100%", "100%", "50%", "50%"]}
              justifyContent={[
                "space-between",
                "space-between",
                "start",
                "start",
              ]}
            >
              <Flex mb={2}>
                <GameLogos
                  type={game}
                  size={["20", "25"]}
                  styles={{ marginRight: 0 }}
                />
                <StyledMiniDivider />
                <StyledTitle2>{clipTitle}</StyledTitle2>
              </Flex>

              {expectedDeliveryTimePassed && !isError && (
                <Flex justifyContent={"start"} alignItems={"center"}>
                  <StyledBody ml={2} color={"error"}>
                    En Route / Delayed
                  </StyledBody>
                </Flex>
              )}

              {isExpectedToArrive && !expectedDeliveryTimePassed && (
                <Flex justifyContent={"start"} alignItems={"center"}>
                  <Body color="chalk">Ready in</Body>
                  <StyledBody ml={2} color={isSlow ? "error" : "envy"}>
                    {getTimeDisplay(minutesToCompletion)}
                  </StyledBody>
                </Flex>
              )}
              {isMobile && (
                <Flex alignItems="center" mt={2}>
                  <StyledMobile />
                  <StyledBody color="envy">Mobile</StyledBody>
                </Flex>
              )}
            </HeaderFlex>
            <Message status={localKey} trackId={trackId} game={game} />
          </Flex>
        </StyledBox>
      </ProcessingContent>
    );
  }
}

const getTimeDisplay = (minutesToCompletion: number) => {
  // When it's in minutes
  if (minutesToCompletion < 60) {
    const lowerBound = Math.max(2, minutesToCompletion - 5);
    return `${lowerBound}-${minutesToCompletion}min`;
  }

  // When it's in hours but less than 24 hours
  if (minutesToCompletion < 24 * 60) {
    const hours = minutesToCompletion / 60;
    const lowerBound = hours - 0.1 * hours;
    return `${lowerBound.toFixed(1)}-${hours.toFixed(1)}hrs`;
  }

  // When it's in days
  const days = minutesToCompletion / 60 / 24;
  return `Approximately ${days} days`;
};

type TMessageProps = {
  status: ETransitStatusTypes;
  trackId: string;
  game: ESupportedGames;
};

const Message = ({ status, trackId, game }: TMessageProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "transitTime",
  });
  const { allstarUser } = useUser();
  const STATUS_ICON_MAP = {
    [ETransitStatusTypes.Error]: <IconError />,
    [ETransitStatusTypes.ClipError]: <IconError />,
    [ETransitStatusTypes.Slow]: <IconIncomplete />,
    [ETransitStatusTypes.SlowFreeUser]: <IconIncomplete />,
    [ETransitStatusTypes.UnknownClip]: <IconNoClip />,
    [ETransitStatusTypes.Completed]: <IconComplete />,
    [ETransitStatusTypes.Good]: <IconComplete />,
    [ETransitStatusTypes.Queued]: <IconIncomplete />,
  };

  type TGameToSupportTag = {
    [key: number | string]: string;
  };

  const GAME_TO_SUPPORT_TAG: TGameToSupportTag = {
    7302: "cs2",
    730: "csgo",
    570: "dota2",
    704: "fortnite",
    101: "league_of_legends",
    NONE: "none",
  };
  const buildSupportLink = () => {
    const profileLink = `https://allstar.gg/profile?user=${allstarUser?.user?._id}`;
    let supportLink = `https://help.allstar.gg/hc/en-us/requests/new?ticket_form_id=12129622526487&tf_12391937440663=${trackId}&tf_12579506242199=${profileLink}&tf_12070892016151=${GAME_TO_SUPPORT_TAG[game]}`;

    if (status === ETransitStatusTypes.UnknownClip) {
      supportLink += "&tf_12153827873687=missing_clip_issue";
    } else if (status === ETransitStatusTypes.ClipError) {
      supportLink += "&tf_12153827873687=errored_clip";
    }
    return supportLink;
  };

  return (
    <StyledMessage width={["100%", "100%", "50%", "50%"]}>
      {" "}
      {STATUS_ICON_MAP[status]}
      <Flex ml={2} flexDirection={"column"}>
        {" "}
        <StyledSubheader color="ice">{t(`${status}.title`)}</StyledSubheader>
        <Subheader color="chalk">
          <Trans
            i18nKey={`transitTime.${status}.body`}
            components={{
              supportlink: (
                <StyledLink
                  target="_blank"
                  href={buildSupportLink()}
                  onClick={() => {
                    window.rudderanalytics.track("Transit Time Link Click", {
                      type: "support",
                    });
                  }}
                />
              ),
              prolink: (
                <StyledLink
                  target="_blank"
                  href={"https://allstar.gg/upgrade"}
                  onClick={() => {
                    window.rudderanalytics.track("Transit Time Link Click", {
                      type: "upgrade",
                    });
                  }}
                />
              ),
              statuslink: (
                <StyledLink
                  target="_blank"
                  href={"https://status.allstar.gg/"}
                  onClick={() => {
                    window.rudderanalytics.track("Transit Time Link Click", {
                      type: "status",
                    });
                  }}
                />
              ),
            }}
          />
        </Subheader>
      </Flex>
    </StyledMessage>
  );
};

const ResponsiveLottie = styled(Lottie)`
  height: 96px;
  width: 96px;
  margin: 0 auto;
`;

const HeaderFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    padding: 0 0 10px 3px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.envy};
  text-underline-offset: 2px;
  color: ${({ theme }) => theme.colors.ice};
  &:hover {
    color: ${({ theme }) => theme.colors.envy};
    cursor: pointer;
  }
`;

const StyledMiniDivider = styled(Flex)`
  width: 1px;
  height: 28px;
  position: relative;
  top: -1px;
  margin: 0 8px 0 8px;
  background-color: ${(props) => {
    return props.theme.colors.navyAlpha90;
  }};
  border-bottom-right-radius: 999px;
  border-bottom-left-radius: 999px;
  border-top-right-radius: 999px;
  border-top-left-radius: 999px;
`;
const StyledMessage = styled(Flex)`
  max-width: 420px;
  min-width: 200px;
  background-color: ${(props: any) => props.theme.colors.midnightAlpha40};
  border-radius: 8px;
  border: 1px solid ${(props: any) => props.theme.colors.chalkAlpha20};
  padding: 30px;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    padding: 15px;
  }
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    padding: 15px;
    max-width: unset;
  }
`;
const StyledSubheader = styled(Subheader)`
  font-weight: 600;
`;

const IconComplete = styled(HiInformationCircle)`
  color: ${(props: any) => props.theme.colors.envy};
  flex-shrink: 0;
`;

const IconIncomplete = styled(HiInformationCircle)`
  color: ${(props: any) => props.theme.colors.error};
  flex-shrink: 0;
`;

const IconError: any = styled(HiInformationCircle)`
  color: ${(props: any) => props.theme.colors.alert};
  flex-shrink: 0;
`;

const IconNoClip: any = styled(HiXCircle)`
  color: ${(props: any) => props.theme.colors.alert};
  flex-shrink: 0;
`;

const StyledBox = styled(Box)`
  background-color: ${(props: any) => props.theme.colors.navy};
  border-radius: 8px;
  border: 1px solid ${(props: any) => props.theme.colors.chalkAlpha20};

  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    padding: 15px;
  }
`;

const StyledBody = styled(Body)`
  font-weight: 600;
`;

const StyledTitle2 = styled(Title2)`
  font-weight: 600;
`;

const ProcessingContent = styled(Flex)`
  gap: 30px;
  width: 100%;

  @media (max-width: ${(props: any) => props.theme.breaks.standard}) {
    flex-direction: column;
  }
`;

const SpectatingContent = styled(Flex)`
  gap: 30px;
  width: 100%;
`;

const StyledMobile = styled(MdPhoneAndroid)`
  padding-top: 2px;
  margin-right: 2px;
  margin-left: -1px;
  align-self: center;
  color: ${(props) => props.theme.colors.envy};
`;
