import React from "react";
import { Subheader } from "../../text";
import { Flex } from "rebass/styled-components";
import { FaCheckCircle } from "react-icons/fa";
import styled from "styled-components";
import { space } from "styled-system";

const SuccessIcon = styled(FaCheckCircle)(space);

export function Success(props) {
  return (
    <Flex variant="formSuccess" my={3} {...props}>
      <SuccessIcon size={22} mr={4} />
      <Subheader mb={1} color={props.color ? props.color : "navy"}>
        {props.children}
      </Subheader>
    </Flex>
  );
}
