import React, { useState } from "react";
import { Flex } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import { AnimatePresence } from "framer-motion";

import { ModalType } from "../../../@types";
import { ReactComponent as Info } from "../../../Assets/information-circle.svg";
import { AllstarModalState } from "../../../State/modals";
import { Modal } from "../../Wrappers";
import "./mh-styles.css";
import {
  HeroContainer,
  ModalContainer,
  NavButton,
  StepImage,
  StepText,
  StyledBody,
  StyledFlex,
  StyledHeadline,
  StyledLarge2,
} from "../StyledComponents/ftue";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const steps = [
  {
    stepText: "Link your Account",
    stepBody:
      "To get started, link your Steam and/or FACEIT account to access past Counter-Strike 2 matches. Or Link your Riot account to access past League of Legends matches.",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/matchHistory/modal/hero1.webp`,
  },
  {
    stepText: "Select a Match",
    stepBody:
      "Browse through found matches, choose your best rounds and transform your best plays into clips.",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/matchHistory/modal/hero2.webp`,
  },
  {
    stepText: "Create your clips",
    stepBody:
      "You can create clips, remix your past clips and view your Play of The Game all from here.",
    stepHero: `${REACT_APP_S3_CLIP_BUCKET}/static/matchHistory/modal/hero3.webp`,
  },
];

export const MatchHistoryModals = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const [currentStep, setCurrentStep] = useState(0);

  const navigate = (direction: "forward" | "backward") => {
    if (direction === "forward" && currentStep === steps.length - 1) return;
    if (direction === "backward" && currentStep === 0) return;

    direction === "forward"
      ? setCurrentStep(currentStep + 1)
      : setCurrentStep(currentStep - 1);
  };
  if (allstarModalState.isOpen !== ModalType.MatchHistoryWelcome) return <></>;
  return (
    <>
      <Modal
        isModalOpen={
          allstarModalState.isOpen === ModalType.MatchHistoryWelcome &&
          !localStorage.getItem("closedMatchHistoryWelcome")
        }
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.MatchHistoryWelcome : ModalType.None,
          })
        }
        handleModalClose={() => {
          localStorage.setItem("closedMatchHistoryWelcome", "true");
        }}
        disableOutsideClose={undefined}
      >
        <ModalContainer>
          <StyledFlex>
            <Flex alignItems="center" justifyContent={"center"}>
              <StyledLarge2>Match History</StyledLarge2>
            </Flex>
            <StepText>
              ({currentStep + 1}/{steps.length}) &nbsp;
              {steps[currentStep].stepText}
            </StepText>
            <StyledBody>{steps[currentStep].stepBody}</StyledBody>
            <HeroContainer>
              <Flex>
                <AnimatePresence exitBeforeEnter>
                  <StepImage src={steps[currentStep].stepHero} />
                </AnimatePresence>
              </Flex>
            </HeroContainer>
            <Flex
              alignItems="center"
              justifyContent={"center"}
              mt={[2, 2, 4, 4]}
              mb={[1, 1, 2, 2]}
            >
              <Info />
              <StyledHeadline>Supported Games</StyledHeadline>
            </Flex>
            <StyledBody>
              Match History currently only supports Counter-Strike 2 and League
              of Legends. We will be rolling out support for other games soon.
            </StyledBody>
            <Flex
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              width={"100%"}
              style={{ gap: "12px" }}
            >
              <NavButton
                flex={true}
                size="tiny"
                classification="roundedFilled"
                mt={[3, 3, 6, 6]}
                onClick={() => navigate("backward")}
                disabled={currentStep === 0}
              >
                Prev
              </NavButton>
              {currentStep < steps.length - 1 ? (
                <NavButton
                  flex={true}
                  size="tiny"
                  classification="roundedFilled"
                  mt={[3, 3, 6, 6]}
                  onClick={() => navigate("forward")}
                >
                  Next
                </NavButton>
              ) : (
                <NavButton
                  flex={true}
                  size="tiny"
                  classification="roundedFilled"
                  mt={[3, 3, 6, 6]}
                  onClick={() => {
                    setAllstarModalState({
                      data: {},
                      functions: {},
                      isOpen: ModalType.None,
                    });
                    localStorage.setItem("closedMatchHistoryWelcome", "true");
                  }}
                >
                  Finish
                </NavButton>
              )}
            </Flex>
          </StyledFlex>
        </ModalContainer>
      </Modal>
    </>
  );
};
