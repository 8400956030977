import React from "react";
import { FloatingNavContainer } from "../wrappers/floatingNavContainer";
import { Link as FloatingNavLink } from "../atoms/floatingNav/link";

export function FloatingNav(props) {
  return (
    <FloatingNavContainer>
      {props.links.map((el, index) => (
        <FloatingNavLink mx={5} to={el.to} key={index}>
          {el.text}
        </FloatingNavLink>
      ))}
    </FloatingNavContainer>
  );
}
