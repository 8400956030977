import { Redirect, Route } from "react-router-dom";

const externalLinks = [
  <Route
    exact
    key="gavin"
    path="/gavin"
    component={() => {
      window.location.replace("https://linkedin.com/in/gavinsilver");
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="download"
    path="/download"
    component={() => {
      window.location.replace(
        "https://download.overwolf.com/install/Download?Name=Allstar&ExtensionId=ccbanlanlcmninogggoifelckghdgccjaanopmpn",
      );
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="cs2betaapp"
    path="/cs2betaapp"
    component={() => {
      window.location.replace(
        "https://media.allstar.gg/static/Allstar-Desktop-Application-Setup-3.0.14.exe",
      );
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="discordinvite"
    path="/discordinvite"
    component={() => {
      window.location.replace("https://discord.gg/playsharestar");
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="faq"
    path="/faq"
    component={() => {
      window.open("https://help.allstar.gg/", "_blank");
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="support"
    path="/support"
    component={() => {
      window.open("https://help.allstar.gg/hc/en-us/requests/new", "_blank");
      return <Redirect to="/" />;
    }}
  />,
  <Route
    exact
    key="mobileplaystore"
    path="/mobileplaystore"
    component={() => {
      window.open(
        "https://play.google.com/apps/testing/com.androidapp.gg.allstar",
        "_blank",
      );
      return <Redirect to="/clips" />;
    }}
  />,
];

export { externalLinks };
