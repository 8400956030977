import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const SERVERLESS_URL = `${process.env.REACT_APP_AWS_SERVERLESS}`;

export class Clip {
  private static instance: Clip;

  public static getInstance(): Clip {
    if (!Clip.instance) Clip.instance = new Clip();
    return Clip.instance;
  }

  public async Title(
    clipId: string,
    clipTitle: string,
    isMontage: boolean,
  ): Promise<any> {
    try {
      const URL = `${SERVERLESS_URL}/clips/title?token=${get("token")}`;
      const { data } = await axios.patch(URL, { clipId, clipTitle, isMontage });
      // console.log("Clip Class function call - Title");
      return data;
    } catch (error: any) {
      console.log({ error });
      console.log(error, { ID: "Clip Class function call PATCH exception" });
      return false;
    }
  }

  public async RequestMobile(_id: string): Promise<any> {
    try {
      const URL = `${BASE_URL}/items/clip/mobile?token=${get("token")}`;
      await axios.post(URL, {
        _id,
        aspectRatio: "9:16",
        resolution: "1080x1920",
      });
      // console.log("Clip Class function call - Title");
      return true;
    } catch (error: any) {
      console.log(error, { ID: "Clip Class function call PATCH exception" });
      return { error: error };
    }
  }

  public async RequestValidCreatorCards(shareId: string): Promise<any> {
    try {
      const URL = `${BASE_URL}/items/clip/creatorcards/${shareId}?token=${get(
        "token",
      )}`;
      const { data } = await axios.get(URL);
      // console.log("Clip Class function call - Title");
      return data.data;
    } catch (error: any) {
      console.log(error, {
        ID: "Clip Class function call GET exception - RequestValidCreatorCards",
      });
      return null;
    }
  }

  public async PostRemix(
    studioSession: Record<string, any>,
    clip: { _id: string },
    isMobileApp: boolean,
    populateReturnCards: boolean,
  ): Promise<any> {
    try {
      let URL = `${BASE_URL}/items/clip/remix?token=${get("token")}`;
      if (studioSession.validatedSelection) {
        URL += `&queue=true`;
      }
      const { data } = await axios.post(URL, {
        cards: studioSession.userActivated.map((x: any) => x._id),
        isMobileApp: isMobileApp,
        _id: clip._id,
        useSignature: studioSession.useSignature,
        populateReturnCards: populateReturnCards,
      });

      return data;
    } catch (error: any) {
      if (error?.response) {
        return error.response;
      }
      console.log(error, {
        ID: "Clip Class function call GET exception - RequestValidCreatorCards",
      });
      return false;
    }
  }
}

const get = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};
