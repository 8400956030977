import React, { useCallback, useEffect } from "react";
import { FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import { useUser } from "../../../../Hooks/useUser";
import { useRecoilState } from "recoil";
import { AllstarModalState } from "../../../../State/modals";
import { Button } from "../..";
import { ModalType } from "../../../../@types";
import { URLS } from "../../../../Utilities/Maps";
import { ShareButton } from "../../Share";

const StyledShare = styled(FaYoutube)`
  position: relative;
  color: white;
  height: 30px;
  width: auto;
  flex-shrink: 0;
`;

export function YouTubeShare({ clip, hasMobile, children }: any) {
  const { allstarUser } = useUser();
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  if (!clip) return null;

  const handleYoutubeModalOpen = useCallback(() => {
    if (!allstarUser?.loggedIn) {
      return setAllstarModalState({
        data: {
          action: "signup",
        },
        functions: {},
        isOpen: ModalType.Signup,
      });
    }
    if (!allstarUser?.user?.google?.active) {
      return setAllstarModalState({
        data: {
          platform: "Google",
          next: `/clip?clip=${clip.shareId}&youtube=true`,
        },
        functions: {},
        isOpen: ModalType.LinkYourSocial,
      });
    }
    if (!hasMobile) {
      return setAllstarModalState({
        data: {
          clip: clip,
        },
        functions: {},
        isOpen: ModalType.YoutubeUploadDetails,
      });
    }
    return setAllstarModalState({
      data: { clip: clip },
      functions: {},
      isOpen: ModalType.MobileOrDesktopUpload,
    });
  }, [
    allstarUser?.loggedIn,
    allstarUser?.user?.google,
    clip,
    hasMobile,
    setAllstarModalState,
  ]);

  useEffect(() => {
    if (window.location.search.includes("youtube")) handleYoutubeModalOpen();
  }, [handleYoutubeModalOpen]);
  return (
    <ShareButton
      title="Publish to YouTube"
      display="flex"
      size="publish"
      classification="youtube"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      flex={true}
      onClick={handleYoutubeModalOpen}
    >
      <StyledShare />
      {children}
    </ShareButton>
  );
}
